/**
 *
 * @param {string} copyToChange text that will be edited
 * @param {string} newCopy text to be replaced
 * @param {string} key key or word to replace
 */
export function editCopyByReplaceString(copyToChange, newCopy, key) {
  return copyToChange.replace(key, newCopy);
}

/**
 *
 * @param {string} copyToChange text that will be edited
 * @param {string} newCopy text to be replaced
 * @param {string} key key or word to replace
 */
export function editCopyByReplaceStringWithBold(copyToChange, newCopy, key) {
  const splittedText = copyToChange.split(key);
  return (
    <>
      {splittedText[0]}
      <b>{newCopy}</b>
      {splittedText[1]}
    </>
  );
}

/**
 *
 * @param {string} copyToChange text that will be edited
 * @param {JSX component} newCopy component to be used, it needs to be JSX
 * @param {string} key key or word to replace
 */
export function editCopyByReplaceWithComponent(copyToChange, newCopy, key) {
  const stringArray = copyToChange.split(' ');
  const indexOfKey = stringArray.indexOf(key);
  const componentToUse = [' ', newCopy, ' '];
  stringArray[indexOfKey] = componentToUse;

  const result = [''];
  for (const iterator of stringArray) {
    if (typeof iterator === 'string') {
      const index = result.length - 1;
      const textValue = result[index];
      result[index] = textValue.concat(' ', iterator);
    } else {
      if (typeof iterator === 'object') {
        result.push(iterator);
        result.push('');
      }
    }
  }
  return result;
}

/**
 *
 * @param {string} copyToChange
 * @param {object} newCopys
 */
export function editCopyByReplaceMultipleStrings(copyToChange, newCopys) {
  const allKeysToReplace = Object.keys(newCopys);
  let result = copyToChange;

  for (const keyWord of allKeysToReplace) {
    result = result.replace(keyWord, newCopys[keyWord]);
  }

  return result;
}

/**
 *
 * @param {string} copyToChange
 * @param {object} newCopys
 */
export function editCopyByReplaceWithMultipleComponent(copyToChange, newCopys) {
  const stringArray = copyToChange.split(' ');
  const allKeysToReplace = Object.keys(newCopys);

  for (const keyToReplace of allKeysToReplace) {
    const indexOfKey = stringArray.indexOf(keyToReplace);
    const componentToUse = [' ', newCopys[keyToReplace], ' '];
    stringArray[indexOfKey] = componentToUse;
  }

  const result = [''];
  for (const iterator of stringArray) {
    if (typeof iterator === 'string') {
      const index = result.length - 1;
      const textValue = result[index];
      result[index] = textValue.concat(' ', iterator);
    } else {
      if (typeof iterator === 'object') {
        result.push(iterator);
        result.push('');
      }
    }
  }
  return result;
}

export const editCopyFindAndReplaceByKey = (copyToChange, keyToReplace, newKeyToAdd) => {
  const copyHasNewKey = copyToChange.split(' ').includes(newKeyToAdd);
  if (copyHasNewKey) return copyToChange;

  const newCopy = copyToChange.replace(keyToReplace, newKeyToAdd);
  return newCopy;
};

// PLURALIZE WORDS DICTIONARY FOR WORDS THAT CAN'T BE PLURALIZED BY ADDING AN S
const pluralizeWordsDictionary = {
  person: 'people',
  pass: 'passes'
};

/**
 * This function will pluralize a word if the count is greater than 1
 * @param {string} word
 * @param {number} count
 * @param {string} suffix
 * @param {boolean} ignoreDictionary some legal words can be pluralized by adding an s like persons, this will ignore the dictionary
 */
export function pluralize(word, count, suffix = 's', ignoreDictionary = false) {
  if (count > 1) {
    if (ignoreDictionary) {
      return `${word}${suffix}`;
    } else {
      return pluralizeWordsDictionary[word] || `${word}${suffix}`;
    }
  }
  return word;
}

/**
 * Formats price to have two decimals when it has more than two decimals but leaves it as it is when it is less or equal than two decimals
 * @param {number} price
 * @returns formated price
 */
export const formatPrice = price => {
  if (price == null || typeof price !== 'number') return '0';
  if (price.toString().includes('.') && price.toString().split('.')[1].length > 2) return price.toFixed(2);
  return price.toString();
};
