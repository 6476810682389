import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { NOTIFICATION_TYPE } from '../../../../../../utilities/constants';
import { ALERT_TYPES, Alert } from '../../../../../Alert';
import { Box, Text } from '../../../../../ui-kit';
import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Flag to indicate if the alert is shown */
  showNotification: PropTypes.bool,

  /** Message for the notification */
  notificationMessage: PropTypes.string,

  /** Type for the notification */
  notificationType: PropTypes.string
};

export const SummaryAlert = ({ showNotification, notificationMessage, notificationType }) => {
  const [renderAlert, setRenderAlert] = useState(showNotification);

  useEffect(() => {
    setRenderAlert(showNotification);
  }, [showNotification]);

  if (!renderAlert) return null;

  const variant = notificationType === NOTIFICATION_TYPE.failed ? ALERT_TYPES.warning : ALERT_TYPES.success;

  return (
    <SummaryContent.Item>
      <Box mt="1px" mb="-10px" width="100%">
        <Alert variant={variant} icon>
          <Text color="nightSkyBlack.80" style={{ 'font-weight': '500' }}>
            {ReactHtmlParser(notificationMessage)}
          </Text>
        </Alert>
      </Box>
    </SummaryContent.Item>
  );
};

SummaryAlert.propTypes = propTypes;
SummaryAlert.defaultProps = {
  showNotification: false,
  notificationType: NOTIFICATION_TYPE.success
};
