import React from 'react';
import { getTheFirstElementOfArray, getTheLastElementOfArray } from '../../../utilities/arraysUtils';
import LoadingspinnerIcon from '../LoadingSpinner';
import Text from '../Text';
import theme from '../themes/theme';

const SupAmount = ({ textToUse, avoidFormat, isLoading, ...props }) => {
  const { fontSize } = props;
  let textArray = textToUse?.toString().split('.');
  const fontSizeValue = theme.fontSizes[fontSize] / 2;

  return (
    <>
      {isLoading ? (
        <LoadingspinnerIcon size={40} color={theme.colors.digitalBrightRed[100]} />
      ) : (
        <Text {...props}>
          {textToUse && avoidFormat ? (
            textToUse
          ) : (
            <>
              {getTheFirstElementOfArray(textArray)}
              <Text
                as="span"
                fontSize={`${fontSizeValue > 10 ? fontSizeValue : 10}px`}
                style={{ verticalAlign: 'super' }}>
                {getTheLastElementOfArray(textArray)}
              </Text>
            </>
          )}
        </Text>
      )}
    </>
  );
};

export default SupAmount;
