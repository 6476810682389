import PropTypes from 'prop-types';
import React from 'react';

import { isValidPrice } from '../../../../../../utilities/cmpPackagesUtils';
import theme from '../../../../../ui-kit/themes/theme';
import { handleDueAmounts, handleTotalWithTaxes } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';

const propTypes = {
  /** Due Amount for today */
  dueTodayAmount: PropTypes.number.isRequired,

  /** Due Amount at check in */
  dueAtCheckinAmount: PropTypes.number.isRequired
};

export const DueAmount = ({
  dueTodayAmount,
  dueAtCheckinAmount,
  roomAdjustmentTax,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  numberOfNights,
  isUserAuthenticated,
  userOffers,
  activeOffers,
  setExcedent,
  isParkingFeeABCDTestActive,
  parkingFeeTotal,
  taxesAndFees,
  currentSelectedPackages,
  summaryTotal,
  optimizelyHierarchyFlip
}) => {
  const shouldDisplay = isValidPrice(dueTodayAmount) && isValidPrice(dueAtCheckinAmount);

  if (!shouldDisplay) return null;

  const handleSummaryTotal = isUserAuthenticated
    ? handleTotalWithTaxes(
        userOffers,
        activeOffers,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        taxesAndFees,
        roomAdjustmentTax,
        currentSelectedPackages,
        setExcedent
      )
    : summaryTotal.toFixed(2);

  const handleDueToday = isUserAuthenticated
    ? handleDueAmounts(
        dueTodayAmount,
        dueAtCheckinAmount,
        roomAdjustmentTax,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        numberOfNights,
        userOffers,
        activeOffers,
        setExcedent,
        handleSummaryTotal
      ).today
    : dueTodayAmount.toFixed(2);

  const handleDuetAtCheckIn = isUserAuthenticated
    ? handleDueAmounts(
        dueTodayAmount,
        dueAtCheckinAmount,
        roomAdjustmentTax,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        numberOfNights,
        userOffers,
        activeOffers,
        setExcedent,
        handleSummaryTotal
      ).atCheckIn
    : dueAtCheckinAmount.toFixed(2);

  /* ABCD TEST FOR PARKING FEE */
  const ABCDTestForParkingFeeDueAtCheckIn = (+handleDuetAtCheckIn + parkingFeeTotal).toFixed(2);
  /* ABCD TEST FOR PARKING FEE */

  return (
    <>
      <SummaryContent.Divider />
      <SummaryContent.Item fontSize={!optimizelyHierarchyFlip ? 0 : 7} fontWeight="bold">
        <SummaryContent.Description lineHeight={optimizelyHierarchyFlip ? '24px' : '18px'}>
          {optimizelyHierarchyFlip ? COPY.DUE_TODAY_CAP : COPY.DUE_TODAY}
        </SummaryContent.Description>
        <SummaryContent.Amount width={'unset!important'} lineHeight={optimizelyHierarchyFlip && '24px'}>
          {`$${handleDueToday}`}
        </SummaryContent.Amount>
      </SummaryContent.Item>
      <SummaryContent.Item fontSize={0} fontWeight={theme.fontWeights.medium}>
        <SummaryContent.Description lineHeight={optimizelyHierarchyFlip && '24px'}>
          {COPY.DUE_CHECKIN}
        </SummaryContent.Description>
        <SummaryContent.Amount width={'unset!important'} lineHeight={optimizelyHierarchyFlip && '24px'}>
          {`$${isParkingFeeABCDTestActive ? ABCDTestForParkingFeeDueAtCheckIn : handleDuetAtCheckIn}`}
        </SummaryContent.Amount>
      </SummaryContent.Item>
    </>
  );
};

DueAmount.propTypes = propTypes;
