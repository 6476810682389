import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Upgrade room endpoints
 *
 * @class Shift4i4GoApiClient
 * @extends {GWRApiClient}
 */

export default class Shift4i4GoApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */

  constructor(url = '', token = '') {
    super(url, token);
  }

  /**
   * function to get vaulted card token
   * @function
   * @param {Object} data - form data to send card details get token
   * @param {string} url  - shifti4Go server url
   */
  async getVaultedCardDetails(urlReq, data) {
    const urlRequest = `${urlReq}?fuseaction=api.jsonPostCardEntry`;
    var formData = new FormData();
    Object.keys(data).forEach((value, index) => {
      formData.append(value, data[value]);
    });
    let response = await super.postApiCall(urlRequest, formData, false);
    return response;
  }
}
