export const FLOW_NAVIGATION_ACTIONS = {
  FLOW_TRIGGER_NEXT_STEP: 'flow.navigateToNextStep',
  FLOW_TRIGGER_PREVIOUS_STEP: 'flow.navigateToPreviousStep',
  FLOW_TRIGGER_FIRST_STEP: 'flow.navigateToFirstStep',
  FLOW_TRIGGER_LAST_STEP: 'flow.navigateToLastStep'
};

export const STALE_TIME_LIMIT = 5 * 60 * 1000; // 5 minutes in milliseconds

export const FLOW_NAMES = {
  BOOKING: 'booking',
  BIRTHDAY: 'birthday',
  DAYPASS: 'daypass'
};
