import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { TESTS_DICTIONARY, getTestValueAB } from '../../../../../../utilities/ABTesting';
import { COSTSUMMARY_TOOLTIP, ROOM_RATE_TYPE } from '../../../../../../utilities/constants';
import { Box } from '../../../../../ui-kit';
import Tooltip from '../../../../../ui-kit/Tooltip';
import QuestionIcon from '../../../../../ui-kit/icons/Question';
import theme from '../../../../../ui-kit/themes/theme';
import { handleTaxes } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';

const SummaryTooltip = ({ isDesktop, title, children }) => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.xl})`
  });
  return (
    <Tooltip
      title={title}
      borderColor={theme.colors.snowMountainGrey[100]}
      textAlign="left"
      fontSize={theme.fontSizes[0] + 'px'}
      textColor="nightSkyBlack.100"
      tooltipWidth={!isDesktop ? 210 : 290}
      alignment={!isDesktop || isTablet ? 'right' : 'center'}
      triggerComponent={({ triggerRef }) => {
        return (
          <Box ref={triggerRef}>
            <SummaryContent.Tooltip>{children}</SummaryContent.Tooltip>
          </Box>
        );
      }}
    />
  );
};

const propTypes = {
  /** Flag that indicates that the reservation has additional charges */
  hasAdditionalCharges: PropTypes.bool.isRequired,

  /** Array with the taxes and fees for reservation*/
  taxesAndFees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
      tooltip: PropTypes.string
    })
  ),

  /** Size for the tooltip icons */
  iconSize: PropTypes.number
};

export const AdditionalCharges = ({
  hasAdditionalCharges,
  taxesAndFees,
  iconSize,
  roomAdjustmentTax,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  userOffers,
  activeOffers,
  numberOfNights,
  isParkingFeeABCDTestActive,
  currentParkingFeeAmount,
  isDesktop,
  rateType
}) => {
  const shouldRenderAdditionalChargers = hasAdditionalCharges && taxesAndFees != null;

  if (!shouldRenderAdditionalChargers) return null;

  /* ABCD TEST FOR PARKING FEE */

  let ABTestTaxesAndFees = [...taxesAndFees];
  if (isParkingFeeABCDTestActive) {
    const parkingFee = {
      id: 'testParkingFee' + Math.floor(Math.random()),
      name: 'Parking Fee',
      amount: currentParkingFeeAmount * numberOfNights
    };

    if (!taxesAndFees.find(i => i.name === 'Parking Fee')) ABTestTaxesAndFees.push(parkingFee);
  }

  const optimizelyDisplayResortFeeWithTotalInSuiteCardJ = getTestValueAB(
    TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardJ,
    false
  );

  const optimizelyDisplayResortFeeWithTotalInSuiteCardH = getTestValueAB(
    TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardH,
    false
  );

  const isRoomOnly = rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass;

  /* ABCD TEST FOR PARKING FEE */

  return (
    <>
      <SummaryContent.Divider />
      <SummaryContent.Item fontSize={0} fontWeight="bold">
        <SummaryContent.Description lineHeight="16px">{COPY.ADDITIONAL_CHARGES}</SummaryContent.Description>
      </SummaryContent.Item>

      {ABTestTaxesAndFees.map(item => {
        let taxAmount = handleTaxes(
          item.amount,
          showLoyalty,
          loyaltyOptIn,
          pointsBalance,
          suiteTotal,
          roomAdjustmentTax,
          userOffers,
          activeOffers
        ).toFixed(2);

        return (
          <SummaryContent.Item fontSize={0} key={item.id}>
            <SummaryContent.Description lineHeight="18px">
              {item.name}
              {item.tooltip && (
                <SummaryTooltip
                  isDesktop={isDesktop}
                  title={
                    (optimizelyDisplayResortFeeWithTotalInSuiteCardJ ||
                      optimizelyDisplayResortFeeWithTotalInSuiteCardH) &&
                    isRoomOnly &&
                    item.name === 'Resort Fee'
                      ? COSTSUMMARY_TOOLTIP.resortFeeJVersion
                      : item.tooltip
                  }
                  ariaKey={item.name}>
                  <QuestionIcon size={iconSize} />
                </SummaryTooltip>
              )}
            </SummaryContent.Description>
            <SummaryContent.Amount>{`$${
              item.name === 'Taxes' ? taxAmount : item.amount.toFixed(2)
            }`}</SummaryContent.Amount>
          </SummaryContent.Item>
        );
      })}
    </>
  );
};

AdditionalCharges.propTypes = propTypes;

AdditionalCharges.defaultProps = {
  hasAdditionalCharges: false,
  taxesAndFees: [],
  iconSize: 16
};
