import get from 'lodash-es/get';

export const ErrorMessages = {
  UserNotLoggedInErrorMessage: 'You are not logged in',
  UnkownErrorMessage: 'An unkown error happened, please contact to the admin',
  NotAuthorizedMessage: 'You are not authorized',
  NotFoundMessage: "We couldn't find the resource we are trying to connect",
  InternalServerErrorMessage: 'An Error happened, please try again later or contact to the admin',
  MissingRequiredFieldMessage: 'Missing fields for API:'
};

export const ErrorTypes = {
  UserNotLoggedInError: 'LOGIN_ERROR',
  ResponseError: 'RESPONSE_ERROR',
  ValidationError: 'VALIDATION_ERROR',
  MissingRequiredField: 'MISSING_REQUIRED_FIELD'
};

export const CommonRequestStatus = {
  BadRequest400: 400,
  NotAuthorized401: 401,
  NotFound404: 404,
  InternalServerError500: 500
};

const buildResponseForErrorResponseWithStatusCode = (requestStatus, err) => {
  switch (requestStatus) {
    case CommonRequestStatus.BadRequest400:
      const existsValidationError = get(err, 'response.data');
      if (!existsValidationError) {
        return getErrorResponse(ErrorMessages.UnkownErrorMessage, requestStatus);
      }

      const validationData = get(err, 'response.data.errors') ? err.response.data.errors : err.response.data;
      return getErrorResponse('Bad Request, please check your data and try again', requestStatus, validationData);
    case CommonRequestStatus.NotAuthorized401:
      return getErrorResponse(ErrorMessages.NotAuthorizedMessage, requestStatus);
    case CommonRequestStatus.NotFound404:
      return getErrorResponse(ErrorMessages.NotFoundMessage, requestStatus);
    case CommonRequestStatus.InternalServerError500:
      const message = err.response.data?.Error?.ErrorMessage
        ? err.response.data.Error.ErrorMessage
        : ErrorMessages.InternalServerErrorMessage;
      return getErrorResponse(message, requestStatus);
    default:
      return getErrorResponse(ErrorMessages.UnkounErrorMessage, requestStatus);
  }
};

const getErrorResponse = (message, code, validationErrors = null) => {
  return {
    msg: message,
    code: code,
    validationErrors: validationErrors
  };
};

export function getErrorMessageByErrorType(errorType, err, details) {
  switch (errorType) {
    case ErrorTypes.UserNotLoggedInError:
      return getErrorResponse(`${ErrorMessages.UserNotLoggedInErrorMessage}`, null, details);
    case ErrorTypes.ValidationError:
      return getErrorResponse('A validation error occurred', null, details);
    case ErrorTypes.ResponseError:
      const existsStatusError = get(err, 'request.status');
      const requestStatus = existsStatusError ? err.request.status : null;

      if (!requestStatus || !Object.values(CommonRequestStatus).includes(requestStatus)) {
        const errorMessage = get(err, 'response.data.messages')
          ? err.response.data.messages.join()
          : get(err, 'message')
          ? err.message
          : err;

        return getErrorResponse(`${errorMessage}. ${details}`, requestStatus);
      }

      return buildResponseForErrorResponseWithStatusCode(requestStatus, err);
    case ErrorTypes.MissingRequiredField:
      return getErrorResponse(`${ErrorMessages.MissingRequiredFieldMessage} ${details}`, requestStatus);
    default:
      return getErrorResponse(`${ErrorMessages.UnkownErrorMessage}. ${details}`, requestStatus);
  }
}
