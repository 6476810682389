import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import Text from './Text';
import CheckIcon from './icons/Check';
import theme from './themes/theme';

const Checkbox = props => {
  const {
    redesignWidth,
    redesignHeight,
    disabled,
    size,
    id,
    isRedesign,
    tabIndex,
    checked,
    secondary,
    valign,
    ...inputProps
  } = props;

  return (
    <CheckBoxWrapper disabled={disabled} valign={valign} redesignWidth={redesignWidth} redesignHeight={redesignHeight}>
      {isRedesign ? (
        <RedesignInput
          redesignWidth={redesignWidth}
          redesignHeight={redesignHeight}
          disabled={disabled}
          checked={checked}
          secondary={secondary}>
          <input id={id} type="checkbox" checked={checked} {...inputProps} />
          <CheckIcon size={16} color={theme.colors.pureWhite[0]}></CheckIcon>
        </RedesignInput>
      ) : (
        <input type="checkbox" checked={checked} id={id} {...inputProps} />
      )}
      <Text name="boxChecked" size={size} data-name="checked" />
      <Text name="boxEmpty" size={size} data-name="empty" />
    </CheckBoxWrapper>
  );
};

const RedesignInput = styled('div')`
  display: inline-block;
  width: ${({ redesignWidth }) => (redesignWidth ? redesignWidth : '16')}px;
  height: ${({ redesignHeight }) => (redesignHeight ? redesignHeight : '16')}px;
  border-radius: 2px;
  position: relative;
  vertical-align: 'top';
  border: 1px solid ${theme.colors.deepForestGreen[100]};
  background-color: ${({ checked, secondary }) =>
    checked
      ? secondary
        ? theme.colors.preciousStoneBlue[100]
        : theme.colors.successGreen
      : theme.colors.pureWhite[0]};

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  input {
    opacity: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    &:checked ~ svg {
      display: block;
    }
  }
`;

const CheckBoxWrapper = styled(Box)`
  width: ${({ redesignWidth }) => (redesignWidth ? redesignWidth : '16')}px;
  height: ${({ redesignHeight }) => (redesignHeight ? redesignHeight : '16')}px;
  display: inline-block;
  position: relative;
  vertical-align: ${props => (props.valign ? props.valign : 'top')};
  margin-top: ${props => (props.valign ? '0' : '-2px')};
  cursor: ${({ disabled }) => (disabled ? null : 'pointer')};
  color: ${props => (props.disabled ? props.theme.colors.deepForestGreen[20] : props.theme.colors.deepForestGreen[50])};
  svg[data-name='checked'] {
    display: none;
  }
  > input:checked {
    & ~ svg[data-name='checked'] {
      display: inline-block;
      color: ${props =>
        props.disabled ? props.theme.colors.deepForestGreen[20] : props.theme.colors.deepForestGreen[50]};
    }
    & ~ svg[data-name='empty'] {
      display: none;
    }
  }
`;

CheckBoxWrapper.defaultProps = { theme };

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  size: PropTypes.number,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  redesignHeight: PropTypes.number,
  redesignWidth: PropTypes.number
};

Checkbox.defaultProps = {
  size: 20,
  disabled: false,
  onChange: () => {},
  theme: theme
};

export default Checkbox;
