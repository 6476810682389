import React from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from '../../../../components/ui-kit/themes/theme';
import CloseIcon from '../../../ui-kit/icons/Close';
import { ModalContent } from './ModalContent';
import { CloseButtonContainer, Divider, Header, ModalWrapper, Title } from './styles';

export const SummaryModal = ({ opened, children, ...restProps }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`
  });
  return (
    <ModalWrapper
      isOpen={opened}
      padding={0}
      {...restProps}
      height={isMobile || isTablet ? 'calc(100% - 74px)' : null}
      borderRadius={isMobile || isTablet ? '25px 25px 0 0' : '25px'}
      width={isMobile ? '85%' : '466px'}>
      {children}
    </ModalWrapper>
  );
};

SummaryModal.CloseButton = ({ onClick }) => {
  return (
    <CloseButtonContainer onClick={onClick}>
      <CloseIcon />
    </CloseButtonContainer>
  );
};

SummaryModal.Content = ModalContent;

SummaryModal.Divider = () => {
  return <Divider />;
};

SummaryModal.Header = ({ children, ...restProps }) => {
  return <Header {...restProps}>{children}</Header>;
};

SummaryModal.Title = ({ children, ...restProps }) => {
  return <Title {...restProps}>{children}</Title>;
};
