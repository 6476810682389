import React from 'react';
import { PropTypes } from 'prop-types';

import { Container, IconContainer, Icon } from './styles';
import { CheckInOutDate } from './CheckInOutDate';

const CheckInOutDates = ({ checkIn, checkOut, size }) => {
  return (
    <Container>
      <CheckInOutDate date={checkIn} size={size}>
        <CheckInOutDate.Label>Check-In</CheckInOutDate.Label>
        <CheckInOutDate.Date size={size}>{checkIn}</CheckInOutDate.Date>
      </CheckInOutDate>
      <IconContainer>
        <Icon />
      </IconContainer>
      <CheckInOutDate date={checkIn} size={size}>
        <CheckInOutDate.Label>Check-Out</CheckInOutDate.Label>
        <CheckInOutDate.Date size={size}>{checkOut}</CheckInOutDate.Date>
      </CheckInOutDate>
    </Container>
  );
};

CheckInOutDates.propTypes = {
  size: PropTypes.string,
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired
};

CheckInOutDates.defaultProps = {
  size: 'small'
};

export { CheckInOutDates };
