import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Box, Flex, Text } from '../../ui-kit';
import CarouselChevronRightIcon from '../../ui-kit/icons/CarouselChevronRigthThin';
import theme from '../../ui-kit/themes/theme';
import { Icon, LoyaltyAppliedContainer } from '../assets/styles';
import { LOYALTY_ICON_LINK_WHITE } from '../utilities/constants';
import * as COPY from '../utilities/copy';
import { editCopyByReplaceString, editCopyByReplaceWithComponent } from './../../utilities/copyFunctions';

const calculatePointsToEarn = ({
  userOffers,
  activeOffers,
  pointsBalance,
  suitNightRate,
  currentSelectedPackages,
  pointsMultiplier
}) => {
  let total = 0;

  // USE VOYAGERS POINTS
  let roomRateWithPoints = 0;
  if (!pointsBalance || pointsBalance === 0) {
    roomRateWithPoints = suitNightRate;
  } else {
    if (pointsBalance > suitNightRate) {
      roomRateWithPoints = 0;
    } else {
      roomRateWithPoints = suitNightRate - pointsBalance;
    }
  }

  // USE PACKAGES
  let packagesTotal = 0;

  if (currentSelectedPackages && currentSelectedPackages.length > 0) {
    currentSelectedPackages.forEach(currentPackage => {
      if (currentPackage.amount && currentPackage.quantity) {
        let calculateQuantityPrice = currentPackage.amount * currentPackage.quantity;
        packagesTotal = packagesTotal + calculateQuantityPrice;
      }
    });
  }

  // USE OFFERS
  let offersSavings = 0;
  if (userOffers && userOffers.length > 0) {
    offersSavings = userOffers.reduce((previousValue, currentValue) => {
      if (activeOffers.length > 0 && activeOffers.includes(currentValue.id)) {
        return previousValue + currentValue.dollarValue;
      } else {
        return previousValue;
      }
    }, 0);
  }

  // USE MULTIPLIER
  total = roomRateWithPoints + packagesTotal - offersSavings;
  let points = total * pointsMultiplier;
  return points.toFixed(0);
};

const LoyaltyEarn = ({
  iconLink,
  numberOfNights,
  isUserAuthenticated,
  suitNightRate,
  pointsMultiplier,
  userOffers,
  activeOffers,
  currentSelectedPackages,
  pointsBalance,
  arrowVariation,
  loyaltyEarnThresholdNights = 2
}) => {
  const points =
    suitNightRate && suitNightRate > 0
      ? calculatePointsToEarn({
          suitNightRate,
          pointsMultiplier,
          currentSelectedPackages,
          pointsBalance,
          userOffers,
          activeOffers
        })
      : 0;

  const openSignUp = useCallback(() => {
    if (!isUserAuthenticated && window.openSignUp) {
      window.openSignUp();
    }
  }, [isUserAuthenticated]);

  const pointsToEarn = () => {
    return <Text as="strong">{editCopyByReplaceString(COPY.NUMBER_POINTS, points, '<TOTALPOINTS>')}</Text>;
  };

  const voyagersPointsCopy = () => {
    return <Text as="strong">{COPY.VOYAGERS_POINTS}</Text>;
  };

  const joinVoyagerUnderline = () => {
    return (
      <Text as="span" underline={true} fontWeight="bold" capitalize>
        {COPY.JOIN_VOYAGERS}
      </Text>
    );
  };

  return (
    <LoyaltyAppliedContainer
      isUserAuthenticated={isUserAuthenticated}
      alignItems="center"
      enablePadding
      onClick={openSignUp}>
      <Icon size="20px" src={iconLink} alt="Loyalty" alignSelf="flex-start" />
      <Box ml={2}>
        {isUserAuthenticated && numberOfNights >= loyaltyEarnThresholdNights && (
          <Flex alignItems={arrowVariation ? 'center' : 'left'}>
            <Box width={arrowVariation ? '681px' : 'auto'}>
              <Text as="p" lineHeight="20px" fontSize={1} fontWeight="medium" my="0" color={theme.colors.pureWhite[0]}>
                {editCopyByReplaceWithComponent(COPY.YOU_WILL_EARN, pointsToEarn(), '<POINTS>')}
              </Text>
              <Text
                as="p"
                fontSize={0}
                fontWeight="medium"
                mt="2"
                mb="0"
                color={theme.colors.pureWhite[0]}
                lineHeight="20px">
                {COPY.POINTS_EARNED_UPPON_COMPLETION}
              </Text>
            </Box>
            {arrowVariation ? (
              <Flex width="24px" height="24px" justifyContent="center" alignItems="center">
                <CarouselChevronRightIcon size={20}></CarouselChevronRightIcon>
              </Flex>
            ) : null}
          </Flex>
        )}
        {isUserAuthenticated && numberOfNights < loyaltyEarnThresholdNights && (
          <Flex alignItems={arrowVariation ? 'center' : 'left'}>
            <Box width={arrowVariation ? '681px' : 'auto'}>
              <Text lineHeight="20px" as="p" fontSize={1} fontWeight="medium" my="0" color={theme.colors.pureWhite[0]}>
                {editCopyByReplaceWithComponent(COPY.ADD_MORE_NIGHTS, voyagersPointsCopy(), '<POINTS>')}
              </Text>
              <Text
                as="p"
                fontSize={0}
                fontWeight="medium"
                mt="2"
                mb="0"
                color={theme.colors.pureWhite[0]}
                lineHeight="20px">
                {COPY.POINTS_EARNED_UPPON_COMPLETION}
              </Text>
            </Box>
            {arrowVariation ? (
              <Flex width="24px" height="24px" justifyContent="center" alignItems="center">
                <CarouselChevronRightIcon size={20}></CarouselChevronRightIcon>
              </Flex>
            ) : null}
          </Flex>
        )}
        {!isUserAuthenticated && !arrowVariation && numberOfNights < loyaltyEarnThresholdNights && (
          <>
            <Text lineHeight="20px" as="p" fontSize={1} fontWeight="medium" my="0" color={theme.colors.pureWhite[0]}>
              <Text as="span" underline={true} fontWeight="bold">
                {COPY.JOIN_VOYAGERS}
              </Text>
              {editCopyByReplaceWithComponent(COPY.JOIN_COPY, voyagersPointsCopy(), '<JOIN>')}
            </Text>
          </>
        )}
        {!isUserAuthenticated && !arrowVariation && numberOfNights >= loyaltyEarnThresholdNights && (
          <>
            <Text lineHeight="20px" as="p" fontSize={1} fontWeight="medium" my="0" color={theme.colors.pureWhite[0]}>
              <Text as="span" underline={true} fontWeight="bold">
                {COPY.JOIN_VOYAGERS}
              </Text>{' '}
              {editCopyByReplaceWithComponent(COPY.EARN_BY_JOIN, voyagersPointsCopy(), '<JOIN>')}
            </Text>
            <Text
              as="p"
              fontSize={0}
              fontWeight="medium"
              mt="2"
              mb="0"
              color={theme.colors.pureWhite[0]}
              lineHeight="20px">
              {COPY.POINTS_EARNED_UPPON_COMPLETION}
            </Text>
          </>
        )}
        {!isUserAuthenticated && arrowVariation ? (
          <Flex alignItems="center">
            <Box width="681px">
              <Text fontSize={1} fontWeight="bold" color={theme.colors.pureWhite[0]} lineHeight={18}>
                {editCopyByReplaceWithComponent(
                  COPY.JOIN_VOYAGERS_CLUB_COPY,
                  joinVoyagerUnderline(),
                  '<JOIN_UNDERLINE>'
                )}
              </Text>
              <Text fontSize={1} fontWeight="medium" color={theme.colors.pureWhite[0]} lineHeight={20}>
                {COPY.POINTS_EARNED_UPPON_COMPLETION}
              </Text>
            </Box>
            <Flex width="24px" height="24px" justifyContent="center" alignItems="center">
              <CarouselChevronRightIcon size={20}></CarouselChevronRightIcon>
            </Flex>
          </Flex>
        ) : null}
      </Box>
    </LoyaltyAppliedContainer>
  );
};

LoyaltyEarn.propTypes = {
  iconLink: PropTypes.string,
  numberOfNights: PropTypes.number
};
LoyaltyEarn.defaultProps = {
  iconLink: LOYALTY_ICON_LINK_WHITE,
  numberOfNights: 1
};

export default LoyaltyEarn;
