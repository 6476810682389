import React from 'react';
import Modal from 'react-modal';

const ModalAdapter = ({ className, modalClassName, ...props }) => {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
};

export default ModalAdapter;
