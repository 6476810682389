import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text, TextLink } from '..';
import FacebookButtonIcon from '../icons/FacebookButtonIcon';
import GoogleButtonIcon from '../icons/GoogleButtonIcon';

import { EmailText, StyledConnectionButton, Wrapper } from './styles';
import * as COPY from './utilities/copy';

const propTypes = {
  /**
   * Connected email address
   */
  email: PropTypes.string,
  /**
   * Bool that indicates if the user has already linked its social account
   */
  isLinked: PropTypes.bool,
  /**
   * Enables or disable the unlink button
   */
  enableUnlink: PropTypes.bool,
  /**
   * Function that is invoked when the user clicks 'unlink'
   */
  handleUnlink: PropTypes.func
};

const defaultProps = {
  email: '',
  isLinked: false,
  enableUnlink: false,
  handleUnlink: () => {}
};

const SocialConnectionButton = props => <StyledConnectionButton {...props}></StyledConnectionButton>;

export default SocialConnectionButton;

export const SocialConnectionSuccess = ({ enableUnlink, handleUnlink, children }) => (
  <Wrapper>
    <Flex width="100%" flexDirection="row" alignItems="center">
      {children}
      <EmailText fontSize={0}>{COPY.CONNECT}</EmailText>
      {enableUnlink && (
        <TextLink fontSize={9} fontWeight="medium" onClick={handleUnlink}>
          {COPY.UNLINK}
        </TextLink>
      )}
    </Flex>
  </Wrapper>
);

export const FacebookConnectionButton = props => {
  const { isLinked, ...restProps } = props;
  return (
    <>
      {!isLinked ? (
        <SocialConnectionButton variant="socialConnection" {...restProps}>
          <FacebookButtonIcon size="22" />
          <Text fontWeight="regular" fontSize={0}>
            {COPY.CONNECT_FACEBOOK}
          </Text>
        </SocialConnectionButton>
      ) : (
        <SocialConnectionSuccess {...restProps}>
          <FacebookButtonIcon size="22" />
        </SocialConnectionSuccess>
      )}
    </>
  );
};

export const GoogleConnectionButton = props => {
  const { isLinked, ...restProps } = props;
  return (
    <>
      {!isLinked ? (
        <SocialConnectionButton variant="socialConnection" {...restProps}>
          <GoogleButtonIcon size="19" />
          <Text fontWeight="regular" fontSize={0}>
            {COPY.CONNECT_CONNECT}
          </Text>
        </SocialConnectionButton>
      ) : (
        <SocialConnectionSuccess {...restProps}>
          <GoogleButtonIcon size="19" />
        </SocialConnectionSuccess>
      )}
    </>
  );
};

SocialConnectionButton.defaultProps = defaultProps;
SocialConnectionButton.propTypes = propTypes;
