import PropTypes from 'prop-types';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { isValidPrice } from '../../../../../utilities/cmpPackagesUtils';
import { Flex } from '../../../../ui-kit';
import theme from '../../../../ui-kit/themes/theme';
import {
  HeaderTextDesktop,
  HeaderTextMobile,
  HeaderTextSubtotal,
  HeaderTextTotalContainer,
  HeaderTextTotalMobile
} from './styles';
import * as COPY from '../utilities/copy';

const propTypes = {
  /** Flag to indicate if the component is on the payment page */
  onPaymentPage: PropTypes.bool,

  /** Total for the reservation */
  total: PropTypes.number
};

export const Header = ({ onPaymentPage, total }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  });

  if (isDesktop) {
    return <HeaderTextDesktop lineHeight="20px">{COPY.STAY_SUMMARY_DESKTOP}</HeaderTextDesktop>;
  }

  return (
    <Flex>
      <HeaderTextMobile lineHeight="20px">{COPY.STAY_SUMMARY_MOBILE}</HeaderTextMobile>
      {isValidPrice(total) && (
        <HeaderTextTotalContainer>
          <HeaderTextTotalMobile lineHeight="18px">{`$${total}`}</HeaderTextTotalMobile>
          <HeaderTextSubtotal>{onPaymentPage ? '(Total)' : '(Subtotal)'}</HeaderTextSubtotal>
        </HeaderTextTotalContainer>
      )}
    </Flex>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = {
  onPaymentPage: false,
  total: 0
};
