import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontSize,
  space,
  color,
  style,
  border,
  borderTop,
  borderBottom,
  fontWeight,
  background,
  flex,
  letterSpacing,
  width
} from 'styled-system';
import theme, { fontFamily } from './themes/theme';

export const capitalize = props =>
  props.capitalize
    ? css`
        text-transform: capitalize;
      `
    : null;
export const caps = props =>
  props.caps
    ? css`
        text-transform: uppercase;
      `
    : null;

export const toLower = props =>
  props.toLower
    ? css`
        text-transform: lowercase;
      `
    : null;

export const strikeThrough = props =>
  props.strikeThrough
    ? css`
        text-decoration: line-through;
      `
    : null;

export const underline = props =>
  props.underline
    ? css`
        text-decoration: underline;
      `
    : null;

export const regular = props => (props.regular ? { fontWeight: props.theme.regular } : null);

export const italic = props => (props.italic ? { fontStyle: 'italic' } : null);

const textAlign = style({
  // React prop name
  prop: 'textAlign',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'text-align'
});
const lineHeight = style({
  // React prop name
  prop: 'lineHeight',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'line-height'
});

const whiteSpace = style({
  // React prop name
  prop: 'whiteSpace',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'white-space'
});

const display = style({
  // React prop name
  prop: 'display',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'display'
});

const Text = styled.div`
${border} ${borderTop} ${borderBottom} ${strikeThrough} ${italic} ${underline} ${fontSize} ${fontFamily}
${space} ${color} ${caps} ${toLower} ${fontWeight} ${textAlign} ${lineHeight} ${capitalize}
${background} ${flex} ${letterSpacing} ${whiteSpace} ${display} ${width};
`;

Text.displayName = 'Text';

const numberStringOrArray = PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]);

Text.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  caps: PropTypes.bool,
  toLower: PropTypes.bool,
  regular: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  color: PropTypes.string,
  /** Margin */
  m: numberStringOrArray,
  mt: numberStringOrArray,
  mr: numberStringOrArray,
  mb: numberStringOrArray,
  ml: numberStringOrArray,
  mx: numberStringOrArray,
  my: numberStringOrArray,
  /** Padding */
  p: numberStringOrArray,
  pt: numberStringOrArray,
  pr: numberStringOrArray,
  pb: numberStringOrArray,
  pl: numberStringOrArray,
  px: numberStringOrArray,
  py: numberStringOrArray
};

Text.Span = Text.withComponent('span');
Text.P = Text.withComponent('p');
Text.S = Text.withComponent('s');

Text.defaultProps = {
  theme: theme,
  fontFamily: theme.fontSecondary
};
Text.P.defaultProps = {
  theme: theme,
  fontFamily: theme.fontSecondary
};
Text.Span.defaultProps = {
  theme: theme,
  fontFamily: theme.fontSecondary
};
Text.S.defaultProps = {
  theme: theme,
  fontFamily: theme.fontSecondary
};

export default Text;
