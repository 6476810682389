import React from 'react';

import theme from '../../../../../../components/ui-kit/themes/theme';
import { store } from '../../../../../../store/configureStore';
import { Text } from '../../../../../ui-kit';
import { SummaryContent } from '../../../Content';

export const ParkingFee = () => {
  const { lodgeConfig } = store?.getState() || '';
  return (
    <>
      {lodgeConfig?.bookingSummaryParkingFee ? (
        <SummaryContent.Item id="BookingSummaryParkingFee">
          <Text
            textAlign="left"
            fontWeight={theme.fontWeights.medium}
            color="deepLakeBlue.100"
            fontSize={9}
            lineHeight="16px">
            {lodgeConfig.bookingSummaryParkingFee}
          </Text>
        </SummaryContent.Item>
      ) : null}
    </>
  );
};
