// Import types
import geolocationActionTypes from './geolocation.types';

// Import API client
import GeolocationApiClient from '../../../api/clients/GeolocationApiClient';

export const getGeolocationData = () => async dispatch => {
  try {
    const apiClient = new GeolocationApiClient();
    const configurationData = await apiClient.getGeolocationData();
    dispatch({
      type: geolocationActionTypes.GET_GEOLOCATION,
      geolocationData: configurationData.data
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: geolocationActionTypes.GET_GEOLOCATION_ERROR,
      geolocationData: error
    });
  }
};
