import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import FinancingOptionText from '../../../../../FinancingOptionText';
import theme from '../../../../../ui-kit/themes/theme';

const propTypes = {
  /** Summary total for the reservation */
  summaryTotal: PropTypes.number.isRequired,

  /** Flag to indicate if affirm should refresh */
  refreshAffirm: PropTypes.bool
};

export const AffirmLink = ({ summaryTotal, refreshAffirm, optimizelyHierarchyFlip, resortLocation }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  });
  return (
    <FinancingOptionText
      shouldRefresh={refreshAffirm}
      productType="cart"
      amount={summaryTotal * 100}
      textDecoration={{ sm: 'underline', lg: 'none' }}
      fontSize={isDesktop || optimizelyHierarchyFlip ? 9 : 0}
      fontWeight={theme.fontWeights.bold}
      lineHeight={isDesktop || optimizelyHierarchyFlip ? '14px' : '16px'}
      color={theme.colors.preciousStoneBlue[100]}
      resortLocation={resortLocation}
    />
  );
};

AffirmLink.propTypes = propTypes;

AffirmLink.defaultProps = {
  refreshAffirm: false
};
