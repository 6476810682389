import styled, { css } from 'styled-components';
import Text from '../Text';
import theme from '../themes/theme';

export const StyledBadge = styled(Text)`
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : theme.radii[1] + 'px')};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  text-align: center;
  ${({ width }) => (width ? width + ';' : null)}
  white-space: nowrap;
  font-family: ${theme.fonts.quaternary};
  font-weight: bold;

  display: inline-block;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: ${theme.fontSizes[9]}px;
          line-height: 14px;
          padding: 1px 8px;
        `;
      case 'large':
        return css`
          font-size: ${theme.fontSizes[0]}px;
          line-height: 18px;
          padding: 1px 8px;
        `;
      default:
        return css`
          padding: 0 2px;
          font-size: ${theme.fontSizes[9]}px;
          line-height: 14px;
        `;
    }
  }}
`;
