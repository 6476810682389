import React from 'react'

const MinusThinIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 32 32'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M27 15.4c.552 0 1 .403 1 .9 0 .462-.386.842-.883.894L27 17.2H5c-.552 0-1-.403-1-.9 0-.462.386-.842.883-.894L5 15.4h22z' />
  </svg>
)

MinusThinIcon.displayName = 'MinusThinIcon'

MinusThinIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default MinusThinIcon