import { isDevEnvironment } from '../utilities/env';

const mysteryDealPromoList = {
  data: [
    {
      offerCode: 'PROMO10',
      rateCode: 'PROMO10',
      percentage: 10
    },
    {
      offerCode: 'PROMO20',
      rateCode: 'PROMO20',
      percentage: 20
    },
    {
      offerCode: 'ESAVE',
      rateCode: 'ESAVE',
      percentage: 25
    },
    {
      offerCode: 'MOREFUN',
      rateCode: 'MOREFUN',
      percentage: 40
    }
  ]
};

if (isDevEnvironment) {
  window.mysteryDealPromoList = JSON.stringify(mysteryDealPromoList);
}
