import { useCallback, useState } from 'react';
import LodgeApiClient from '../../api/clients/LodgeApiClient';
//import { waitFor } from '../../utilities/waitFor';
/**
 * This hook is to get the user data for lead gen
 * @returns array with status, fetch function and reset function
 */
const useGetLodgeConfig = () => {
  // State Valeus on the hook
  const [isCalled, toggleCalled] = useState(false);
  const [hasError, toggleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Reset funciton
  const reset = useCallback(() => {
    toggleCalled(false);
    toggleError(false);
    setErrorMessage('');
  }, []);

  const fetch = useCallback(
    async (property, application, { onSuccess = () => {}, toggleLoading = () => {}, onFailure = () => {} }) => {
      reset();
      toggleLoading(true);

      const apiClient = new LodgeApiClient();

      try {
        const result = await apiClient.getConfiguration(property, application);
        toggleCalled(true);
        toggleLoading(false);
        onSuccess(result.data);
      } catch (error) {
        toggleCalled(true);
        toggleLoading(false);
        toggleError(true);
        setErrorMessage(error);
        onFailure(error);
      }
    },
    [reset]
  );

  return [fetch, reset, { isCalled, hasError, errorMessage }];
};

export default useGetLodgeConfig;
