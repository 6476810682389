import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '../../../../../ui-kit';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';

const propTypes = {
  handleSavings: PropTypes.func
};

export const Footer = ({ handleSavings, ...restProps }) => {
  return (
    <>
      {handleSavings() > 0 && (
        <SummaryContent.Bottom {...restProps}>
          <Text fontSize={0} lineHeight="16px">
            {COPY.YOUR_SAVINGS}
          </Text>
          <Text fontSize={0} lineHeight="16px" color="deepForestGreen.100">{`$${handleSavings()}`}</Text>
        </SummaryContent.Bottom>
      )}
    </>
  );
};

Footer.propTypes = propTypes;
