import React from 'react';
import Text from './Text';
import styled from 'styled-components';
import theme from './themes/theme';
import ArrowDownThinIcon from './icons/ArrowDownThin';
import { Flex } from '.';
// PlusMinus :before psuedoElement is purposely not found here
// It is instead defined in App.js createGlobalStyle since it is the only way to hook into 'aria-...'
const StyledTitleText = styled(Text)`
  font-size: ${props => (props.fontSize && props.fontSize) || '12px'};
  line-height: 1.8;
  font-weight: bold;
  display: inline-block;
  font-family: ${theme.fontSecondary};
  cursor: pointer;
  color: ${props => props.color || theme.colors.digitalBrightRed[100]};
`;

const StyledContent = styled(Text)`
  font-size: ${props => (props.fontSize && props.fontSize) || '12px'};
  font-family: ${theme.fontSecondary};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: ${theme.space[2]}px;
`;

export const DescriptionIcon = styled(ArrowDownThinIcon)`
  transition-duration: 0.4s;
  transition-property: transform;
  transform: rotate(${props => props.rotate || '0deg'});
  height: 12px;
  width: 36px;
  align-self: center;
  padding-left: ${theme.space[1]}px;
  padding-right: ${theme.space[1]}px;
`;

export const AccordionTitleTextRetro = props => {
  const { children, expanded, ...rest } = props;
  return (
    <Flex justifyContent="space-between">
      <StyledTitleText ml={2} {...rest}>
        {children}
      </StyledTitleText>
      <Flex flexDirection="column-direction" width="24px">
        <DescriptionIcon rotate={expanded ? '-180deg' : '0deg'} />
      </Flex>
    </Flex>
  );
};

export const AccordionContentRetro = props => {
  const { children, ...rest } = props;
  return <StyledContent {...rest}>{children}</StyledContent>;
};

AccordionTitleTextRetro.defaultProps = {
  color: theme.colors.digitalBrightRed[100]
};
