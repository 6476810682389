import styled, { css } from 'styled-components';
import Box from './Box';
import theme from './themes/theme';
import PropTypes from 'prop-types';
import {
  borderRadius,
  propTypes,
  borderColor,
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  borderTop,
  borderBottom,
  flex
} from 'styled-system';

const boxShadow = props => {
  const boxShadows = {
    sm: {
      'box-shadow': props.theme.boxShadows[0]
    },
    md: {
      'box-shadow': props.theme.boxShadows[1]
    },
    lg: {
      'box-shadow': props.theme.boxShadows[2]
    },
    xl: {
      'box-shadow': props.theme.boxShadows[3]
    }
  };
  return boxShadows[props.boxShadowSize];
};

const boxBorder = props => ({
  border:
    props.borderWidth === 0
      ? '0'
      : `${props.borderWidth}px solid ${props.theme.colors[props.borderColor]}`
});

const Card = styled(Box)`
overflow: hidden;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${borderColor} ${boxShadow} ${boxBorder} ${borderRadius} ${borderTop} ${borderBottom};
`;
export const FlexCard = styled(Card)`
  display: flex;
  ${alignItems} ${justifyContent} ${flexWrap} ${flexDirection} ${flex};
`;
Card.propTypes = {
  boxShadowSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  borderColor: PropTypes.string,
  ...propTypes.borderRadius,
  borderWidth: PropTypes.oneOf([0, 1, 2]),
  borderTop: PropTypes.string,
  borderBottom: PropTypes.string
};

Card.defaultProps = {
  borderColor: 'deepLakeBlue.20',
  borderRadius: 1,
  borderWidth: 1,
  borderTop: 'none',
  borderBottom: 'none',
  theme: theme
};

Card.displayName = 'Card';

export default Card;
