import React from 'react';

import { SummaryContent } from '../../../Content';
import { AdditionalCharges } from './AdditionalCharges';
import { Deposit } from './Deposit';
import { DiscountCharges } from './DiscountCharges';
import { Header } from './Header';
import { PackagesList } from './PackagesList';
import { SuiteDetails } from './SuiteDetails';
import { SummaryAlert } from './SummaryAlert';
import { Total } from './Total';

export const Content = ({ suppressRate, ...restProps }) => {
  return (
    <SummaryContent fontSize="14px">
      <SummaryAlert {...restProps} />
      <Header {...restProps} />
      {!suppressRate && <SuiteDetails {...restProps} />}
      <PackagesList {...restProps} />
      {!suppressRate && (
        <>
          <DiscountCharges {...restProps} />
          <AdditionalCharges {...restProps} />
          <Total {...restProps} />
          <Deposit {...restProps} />
        </>
      )}
    </SummaryContent>
  );
};
