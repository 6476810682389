import React from 'react'

const ArrowDownThinIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 24 14'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M 23 0.580643089 L 12 12 L 1 0.580643089 L 3.64848169e-29 1.52588617 L 12 14 L 24 1.52588617 z' />
  </svg>
)

ArrowDownThinIcon.displayName = 'ArrowDownThinIcon'

ArrowDownThinIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default ArrowDownThinIcon