import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import theme from './themes/theme';

const StyledBox = styled(Box)`
  overflow-x: ${props => (props.shouldPreventOverflowX ? 'hidden' : 'unset')};
`;

const Container = ({ children, shouldPreventOverflowX }) => {
  const maxWidth = theme.maxContainerWidthAkela;

  return (
    <StyledBox
      shouldPreventOverflowX={shouldPreventOverflowX}
      maxWidth={maxWidth}
      width={1}
      px={[0, 0, 0, 3]}
      mx="auto">
      {children}
    </StyledBox>
  );
};

export default Container;
