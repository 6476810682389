import PropTypes from 'prop-types';
import React from 'react';
import routes from '../../../../../../routes';
import { TIMER_MODAL_CONTENT_IMAGES } from '../../../../../TimerModalContent/assets/images';
import { Box, Button, Flex, Text } from '../../../../../ui-kit';
import * as COPY from '../../utilities/copy';
import { SUMMARY_IMAGES } from '../assets/images';
import { EmptyCartImageContainer } from '../styles';

const propTypes = {
  resortLocationUrlParam: PropTypes.string
};

const defaultProps = {
  resortLocationUrlParam: ''
};

const EmptyCartContent = ({ resortLocationUrlParam, isCartExpired }) => {
  // Redirect to plan page
  const redirectToPlanPage = () => {
    window.location.href = resortLocationUrlParam ? `${resortLocationUrlParam}plan` : routes.plan.self;
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      <EmptyCartImageContainer mt={['96px', '96px', 10, 10, 10]}>
        <img
          src={isCartExpired ? TIMER_MODAL_CONTENT_IMAGES.BOOKING_EXPIRED : SUMMARY_IMAGES.CART_EMPTY_LOGO}
          alt="Empty Cart Logo"
        />
      </EmptyCartImageContainer>
      <Text color="deepLakeBlue.100" fontSize={[7]} fontWeight="bold" lineHeight={[22]} mt={11}>
        {isCartExpired ? COPY.EXPIRED_BOOKING_TITLE : COPY.BOOKING_CART_EMPTY}
      </Text>
      {isCartExpired ? (
        <Text
          color="deepLakeBlue.100"
          fontSize={[1]}
          fontWeight="medium"
          lineHeight={[20]}
          mt={[7, 7, 7, 3, 3]}
          textAlign="center"
          width="268px">
          {COPY.EXPIRED_BOOKING_TEXT}
        </Text>
      ) : null}
      <Box
        width={isCartExpired ? '247px' : '187px'}
        mt={isCartExpired ? [7, 7, 7, 4, 4] : ['88px', '88px', '91px', '91px', '91px']}
        mb={1}>
        <Button variant={isCartExpired ? 'primary' : 'book'} fluid onClick={redirectToPlanPage}>
          {isCartExpired ? COPY.RESTART_MY_BOOKING : COPY.BOOKING_CART_BOOK_NOW}
        </Button>
      </Box>
    </Flex>
  );
};

EmptyCartContent.propTypes = propTypes;
EmptyCartContent.defaultProps = defaultProps;

export default EmptyCartContent;
