import React from 'react';
import LoadingSpinnerIcon from '../ui-kit/LoadingSpinner';
import theme from '../ui-kit/themes/theme';
import Modal from './Modal';

const ModalSpinner = props => (
  <Modal {...props}>
    <LoadingSpinnerIcon size={80} color={theme.colors.woodpeckerRed[100]} />
  </Modal>
);

export default ModalSpinner;
