import React from 'react'

const CarouselChevronLeftThinIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 551.13 551.13'
    width={size}
    height={size}
    fill={color}
  >
    <path d='m189.451 275.565 223.897-223.897v-51.668l-275.565 275.565 275.565 275.565v-51.668z' />
  </svg>
)

CarouselChevronLeftThinIcon.displayName = 'CarouselChevronLeftThinIcon'

CarouselChevronLeftThinIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CarouselChevronLeftThinIcon