import PropTypes from 'prop-types';
import React from 'react';

import LoyaltyApplied from './components/LoyaltyApplied';
import LoyaltyAvailable from './components/LoyaltyAvailable';
import LoyaltyAvailableMobileSearch from './components/LoyaltyAvailableMobileSearch';
import LoyaltyAvailableWithButton from './components/LoyaltyAvailableWithButton';
import LoyaltyEarn from './components/LoyaltyEarn';
import LoyaltyProfilePoints from './components/LoyaltyProfilePoints';
import LoyaltyProfilePointsHistory from './components/LoyaltyProfilePointsHistory';
import LoyaltyWithExpirationDate from './components/LoyaltyWithExpirationDate';
import { LOYALTY_TYPES } from './utilities/constants';

const Loyalty = ({ isNewWidgetStyleActive, loyaltyType, ...props }) => {
  const LoyaltyOptions = {
    [LOYALTY_TYPES.available]: <LoyaltyAvailable isNewWidgetStyleActive={isNewWidgetStyleActive} {...props} />,
    [LOYALTY_TYPES.applied]: <LoyaltyApplied {...props} />,
    [LOYALTY_TYPES.availableWithButton]: <LoyaltyAvailableWithButton {...props} />,
    [LOYALTY_TYPES.withExpirationDate]: <LoyaltyWithExpirationDate {...props} />,
    [LOYALTY_TYPES.earn]: <LoyaltyEarn {...props} />,
    [LOYALTY_TYPES.profilePoints]: <LoyaltyProfilePoints {...props} />,
    [LOYALTY_TYPES.availableMobileSearch]: (
      <LoyaltyAvailableMobileSearch isNewWidgetStyleActive={isNewWidgetStyleActive} {...props} />
    ),
    [LOYALTY_TYPES.profilePointsHistory]: <LoyaltyProfilePointsHistory {...props} />
  };

  return LoyaltyOptions[loyaltyType];
};

Loyalty.propTypes = {
  amount: PropTypes.number,
  location: PropTypes.string,
  loyaltyType: PropTypes.string.isRequired,
  iconSize: PropTypes.string
};

Loyalty.defaultProps = {
  amount: '153',
  location: 'Great Wolf Lodge-Chicago / Gurnee, IL',
  loyaltyType: 'available',
  iconSize: '24px'
};

export default Loyalty;
