export function getTheFirstElementOfArray(array) {
  const [firstElement] = array;
  return firstElement;
}

export function getTheLastElementOfArray(array) {
  const lastElement = array[array.length - 1];
  return lastElement;
}

export function isArrayEmpty(array = []) {
  return array.length === 0;
}
