import styled from 'styled-components';
import Box from './Box';
import { zIndex, top, bottom, left, right, color } from 'styled-system';

const Fixed = styled(Box)`
  position: fixed;
  ${top} ${bottom} ${left} ${right}
  ${zIndex} ${color}
`;

Fixed.displayName = 'Fixed';

Fixed.propTypes = {
  ...top.propTypes,
  ...left.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...zIndex.propTypes
};

export default Fixed;
