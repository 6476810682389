import axios from 'axios';

function encodeUrlQueryParameters() {
  axios.defaults.paramsSerializer = params => {
    let result = '';
    Object.keys(params).forEach(key => {
      const values = Array.isArray(params[key]) ? params[key] : [params[key]];
      values.forEach(value => {
        result += `${key}=${encodeURIComponent(value)}&`;
      });
    });
    return result.substr(0, result.length - 1);
  };
}

export { encodeUrlQueryParameters };
