import React, { createContext, useState, useContext } from 'react';
import { Container } from './styles';
import { SummaryAccordionContent } from './SummaryAccordionContent';
import { SummaryAccordionHeader } from './SummaryAccordionHeader';

const AccordionContext = createContext();

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error('Child components of SummaryAccordion cannot be rendered outside the SummaryAccordion Component!');
  }

  return context;
};

export const SummaryAccordion = ({ shouldStartOpened, children, ...restProps }) => {
  const [expanded, setExpanded] = useState(shouldStartOpened);

  return (
    <AccordionContext.Provider value={{ expanded, setExpanded }}>
      <Container {...restProps}>{children}</Container>
    </AccordionContext.Provider>
  );
};

SummaryAccordion.Content = SummaryAccordionContent;

SummaryAccordion.Header = SummaryAccordionHeader;
