import React from 'react';
import { Box, Text } from '../../../ui-kit';
import { Divider } from '../Content/styles';
import SummaryItem from '../SummaryItem';
import * as COPY from './utilities/copy';

const TextWrapperDueToday = ({ children }) => {
  return (
    <Text lineHeight={18} fontSize={0} fontWeight="bold" color="deepLakeBlue.100">
      {children}
    </Text>
  );
};

const TextWrapperDueBeforeParty = ({ children }) => {
  return (
    <Text lineHeight={18} fontSize={0} fontWeight="medium" color="deepLakeBlue.100">
      {children}
    </Text>
  );
};

const SimpleSummaryDue = ({ dueToday, dueBeforeParty }) => {
  if (!dueToday && !dueBeforeParty) return null;
  return (
    <Box>
      <Divider />
      <SummaryItem
        labelTitle={COPY.DUE_TODAY_SECTION}
        leftColumn={<TextWrapperDueToday>{COPY.PARTY_DUE_TODAY}</TextWrapperDueToday>}
        rightColumn={<TextWrapperDueToday>${dueToday.toFixed(2)}</TextWrapperDueToday>}
      />
      {dueBeforeParty ? (
        <SummaryItem
          mb={8}
          labelTitle={COPY.DUE_BEFORE_PARTY_SECTION}
          leftColumn={<TextWrapperDueBeforeParty>{COPY.PARTY_DUE_BEFORE_PARTY}</TextWrapperDueBeforeParty>}
          rightColumn={<TextWrapperDueBeforeParty>${dueBeforeParty.toFixed(2)}</TextWrapperDueBeforeParty>}
        />
      ) : null}

      <Text lineHeight={14} fontSize={11} fontWeight="bold" color="deepLakeBlue.100" mb={7}>
        {dueBeforeParty ? COPY.PARTY_REFUND_DISCLAIMER : COPY.PARTY_NON_REFUND_DISCLAIMER}
      </Text>
    </Box>
  );
};

export default SimpleSummaryDue;
