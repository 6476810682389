import { createGlobalStyle } from 'styled-components';
import { colors, fontWeights } from './components/ui-kit/themes/theme';
const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  *[data-accordion-component='AccordionItemButton']:focus {
    outline: 0;
  }

  .gpay-card-info-container {
    border-radius: 35px!important;
  }

  .DateRangePicker, .DayPicker {
    font-family: Montserrat;
    border-radius: 5px;
  }
  .DateRangePicker_picker {
    z-index: 7000;
    border-radius: 5px;
  }

  .DateRangePicker_picker__fullScreenPortal {
    z-index: 9000;
  }

  .DateInput_fang {
    z-index: 8000;
    margin-top: 2px;
    top: -28px;
  }
  table.CalendarMonth_table {
    border-collapse: unset;
  }
  td.CalendarDay__default {
    font-weight: bold;
    border: 2px solid transparent;
    color: ${colors.nightSkyBlack[80]};
    border-collapse: unset;
    border-radius: 4px;

    &:hover {
      background: transparent;
      border: none;
    }
  }

  td.CalendarDay__selected {
    &,
    &:active,
    &:hover {
      background: ${colors.deepLakeBlue[100]};
      border: 2px solid transparent;
      color: ${colors.pureWhite[0]} !important;
    }
  }

  td.CalendarDay__hovered_span,
  td.CalendarDay__selected_span {
    &,
    &:hover {
      background: transparent;
      color: ${colors.nightSkyBlack[80]};
      border-radius: 0;
    }
    &:hover {
      border-radius: 4px;
    }
  }

  ul.DayPicker_weekHeader_ul {
    font-weight: ${fontWeights.bold};
  }

  div.DayPickerNavigation_button__default {
    border: none;
    color: ${colors.digitalBrightRed[100]};
  }

  svg.DayPickerNavigation_svg__horizontal {
    fill: ${colors.digitalBrightRed[100]};
  }
  input.DateInput_input__focused {
    border-bottom: 2px solid ${colors.digitalBrightRed[100]};
  }
  td.CalendarDay__blocked_calendar,
  td.CalendarDay__blocked_calendar:active,
  td.CalendarDay__blocked_calendar:hover {
    background: none;
    border: none;
    color: ${colors.nightSkyBlack[60]};
  }

  td.CalendarDay__blocked_out_of_range,
  td.CalendarDay__blocked_out_of_range:active,
  td.CalendarDay__blocked_out_of_range:hover {
    color: ${colors.deepLakeBlue[20]};
  }

  .accordion__body {
    display: block;
  }

  .accordion__body--hidden {
    display: none;
  }

  .accordion__title {
    cursor: pointer;
  }

  .accordion__arrow {
    transition: transform 0.25s ease;
  }

  [aria-expanded='true'] .accordion__arrow,
  [aria-selected='true'] .accordion__arrow {
      transform: rotate(-180deg);
  }
  .ReactModal__Overlay {
    z-index: 2
  }
  
  body.ReactModal__Body--open {

  }

  input[type=button], input[type=search] {
    -webkit-appearance: none;
  }

  select[multiple] {
    min-height: 55px;
  }
`;

export default GlobalStyle;
