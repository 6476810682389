import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from '../../../../components/ui-kit/themes/theme';
import { Box } from '../../../ui-kit';
import useWindowDimensions from '../../../utilities/hooks/useWindowDimensions';
import { Content } from './styles';

export const ModalContent = ({ children, ...restProps }) => {
  const [currentHeight, setCurrentHeight] = useState(0);
  const { height } = useWindowDimensions();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`
  });

  const maxHeightModalsMargin = 1417;
  const headerDistance = 68 + 24;
  const verticalMarginsLessThanMaxHeight = 141 * 2;
  const verticalMarginsAfterMaxHeight = 280 * 2;

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setCurrentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const contentHeight =
    height < maxHeightModalsMargin
      ? height - (verticalMarginsLessThanMaxHeight + headerDistance)
      : height - (verticalMarginsAfterMaxHeight + headerDistance);

  const calculatedHeight = currentHeight > 0 && currentHeight < contentHeight ? currentHeight : contentHeight;

  return (
    <Content
      {...restProps}
      height={isMobile || isTablet ? `${calculatedHeight + 215}px` : `${calculatedHeight + 96}px`}>
      <Box ref={measuredRef}>{children}</Box>
    </Content>
  );
};
