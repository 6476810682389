// Birthday Party action types

const birthdayPartyActionTypes = {
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA',
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_SUCCESS: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_SUCCESS',
  FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_ERROR: 'FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_ERROR',
  SET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA: 'SET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA',
  RESET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA: 'RESET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA',
  SET_SELECTED_BIRTHDAY_PARTY: 'SET_SELECTED_BIRTHDAY_PARTY',
  TOGGLE_BIRTHDAY_PARTY_DATE_PICKER: 'TOGGLE_BIRTHDAY_PARTY_DATE_PICKER',
  CREATE_PARTY_BOOKING_RESERVATION_SUCCESS: 'CREATE_PARTY_BOOKING_RESERVATION_SUCCESS',
  CREATE_PARTY_BOOKING_RESERVATION_ERROR: 'CREATE_PARTY_BOOKING_RESERVATION_ERROR',
  IS_LOADING_CREATE_PARTY_BOOKING_RESERVATION: 'IS_LOADING_CREATE_PARTY_BOOKING_RESERVATION',
  CLEAR_PARTY_BOOKING_RESERVATION: 'CLEAR_PARTY_BOOKING_RESERVATION'
};

export default birthdayPartyActionTypes;
