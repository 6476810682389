import React, { useCallback, useEffect, useState } from 'react';
import { ASSETS_LOCATIONS, getAssetsUrl } from '../../utilities/constants';
import CloseIcon from '../ui-kit/icons/Close';
import { FLOATING_MESSAGE_IMAGES } from './assets/images';
import {
  CloseFloatingMessage,
  FloatingInnerMessageContainer,
  FloatingMessageContainer,
  FloatingMessageImg,
  InnerMessage
} from './assets/styles';
import * as COPY from './utilities/copy';

const FloatingMessage = () => {
  const [innerMessage, setInnerMessage] = useState('');
  const [messageVariant, setMessageVariant] = useState('success');
  const [onCloseFunction, setOnCloseFunction] = useState(null);
  const [visible, setVisible] = useState(false);

  const showFloatingMessage = e => {
    if (e.detail) {
      const { message, variant, onCloseFunction } = e.detail;
      if (message) setInnerMessage(message);
      if (variant) setMessageVariant(variant);
      if (onCloseFunction) setOnCloseFunction(() => () => onCloseFunction());
    }
    setVisible(true);
  };

  const closeFloatingMessage = useCallback(() => {
    setVisible(false);
    if (onCloseFunction) onCloseFunction();
  }, [onCloseFunction]);

  useEffect(() => {
    window.addEventListener('floating-message', showFloatingMessage);
    return function cleanupListener() {
      window.removeEventListener('floating-message', showFloatingMessage, false);
    };
  }, []);

  useEffect(() => {
    if (!visible) return;
    const timer = setTimeout(() => {
      closeFloatingMessage();
    }, 10000);
    return () => clearTimeout(timer);
  }, [visible, closeFloatingMessage]);

  return (
    <>
      {visible && (
        <FloatingMessageContainer variant={messageVariant}>
          <FloatingInnerMessageContainer>
            <FloatingMessageImg
              src={
                messageVariant === 'error'
                  ? getAssetsUrl(ASSETS_LOCATIONS.SVG, FLOATING_MESSAGE_IMAGES.WARNING)
                  : getAssetsUrl(ASSETS_LOCATIONS.SVG, FLOATING_MESSAGE_IMAGES.SUCCESS_BLUE)
              }
              alt={messageVariant === 'error' ? COPY.ERROR_ICON : COPY.SUCCESS_ICON}
            />

            <InnerMessage>{innerMessage}</InnerMessage>
          </FloatingInnerMessageContainer>
          <CloseFloatingMessage>
            <CloseIcon onClick={closeFloatingMessage} />
          </CloseFloatingMessage>
        </FloatingMessageContainer>
      )}
    </>
  );
};

export default FloatingMessage;
