import cloneDeepWith from 'lodash-es/cloneDeepWith.js';
import forOwn from 'lodash-es/forOwn.js';

/**
 * Masking value used for Personally Identifiable Information (PII) properties.
 * @constant
 * @type {string}
 */
const PII_MASK_VALUE = '**MASKED**';

/**
 * List of property names considered as Personally Identifiable Information (PII).
 *
 * @constant
 * @type {string[]}
 *
 */
const PII_PROPERTIES = [
  // Define property names commonly associated with PII data here.
  'email',
  'firstName',
  'lastName',
  'holder',
  'phoneNumber',
  'street1',
  'street2',
  'city',
  'state',
  'country',
  'postalCode',
  'token'
];

/**
 * List of property names commonly associated with Personally Identifiable Information (PII) URLs.
 *
 * This array stores property names that are typically used to represent URLs containing PII.
 *
 * @constant
 * @type {string[]}
 *
 */
const PII_URLS_PROPERTIES = [
  // Define property names commonly associated with PII URLs here.
  'refUri',
  'uri',
  'url'
];

/**
 * Object containing special Personally Identifiable Information (PII) properties and associated functions.
 *
 * This object stores special PII properties and their corresponding functions for masking.
 * You can use this object to define custom behavior for specific PII properties.
 *
 * @type {Object.<string, function>}
 */
const PII_SPECIAL_PROPERTIES = {
  // Define special PII properties and their associated functions here.
  errorSrc: extracAndReplaceUrlInErrorSrc
};

/**
 * Mask Personally Identifiable Information (PII) properties in an object.
 *
 * This function recursively traverses the input object and replaces the values
 * of specified PII properties with a masking value or applies custom masking functions.
 *
 * @param {Object} obj - The input object containing PII properties to be masked.
 * @returns {Object} - A new object with PII properties masked.
 *
 * @see {@link PII_PROPERTIES} - List of properties considered as PII properties.
 * @see {@link PII_URLS_PROPERTIES} - List of properties that contain PII URLs.
 * @see {@link PII_SPECIAL_PROPERTIES} - Object with custom PII properties and corresponding masking functions.
 * @see {@link PII_MASK_VALUE} - Masking value used for PII properties.
 *
 * @example
 * const inputObject = {
 *   email: 'user@example.com',
 *   firstName: 'User',
 *   address: {
 *     city: 'New York',
 *     postalCode: '12345'
 *   }
 * };
 *
 * const maskedObject = maskPIIProperties(inputObject);
 * // Result:
 * // {
 * //   email: '**MASKED**',
 * //   firstName: '**MASKED**',
 * //   address: {
 * //     city: '**MASKED**',
 * //     postalCode: '**MASKED**'
 * //   }
 * // }
 */
export function maskPIIProperties(obj) {
  /**
   * Recursively traverse the object and masking PII properties.
   *
   * @param {*} value - The current property value.
   * @param {string} key - The current property key.
   * @returns {*} - The modified property value.
   */
  return cloneDeepWith(obj, (value, key) => {
    if (!key) return;

    if (isStringifiedObject(value)) {
      const parsedValue = JSON.parse(value);
      maskPIIPropertiesRecursively(parsedValue);
      return JSON.stringify(parsedValue);
    }

    if (isObject(value)) {
      maskPIIPropertiesRecursively(value);
      return value;
    }

    return getMaskedPropertyValue(key, value);
  });
}

/**
 * Checks if a value represents a valid stringified JSON object.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} - `true` if the value is a valid stringified JSON object, `false` otherwise.
 */
function isStringifiedObject(value) {
  try {
    if (typeof value !== 'string') return false;

    const parsed = JSON.parse(value);
    return isObject(parsed);
  } catch (error) {
    return false;
  }
}

/**
 * Checks if a value is a non-null object (excluding arrays).
 *
 * @param {*} value - The value to check.
 * @returns {boolean} - `true` if the value is a non-null object (excluding arrays), `false` otherwise.
 */
function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

/**
 * Mask Personally Identifiable Information (PII) properties recursively in an object.
 *
 * @param {Object} obj - The input object containing PII properties to be masked.
 * @returns {void} - The function modifies the input object in place.
 */
function maskPIIPropertiesRecursively(obj) {
  forOwn(obj, (value, key) => {
    if (isStringifiedObject(value)) {
      const parsedValue = JSON.parse(value);
      obj[key] = JSON.stringify(maskPIIProperties(parsedValue));
    } else if (isObject(value)) {
      obj[key] = maskPIIProperties(value);
    } else {
      obj[key] = getMaskedPropertyValue(key, value);
    }
  });
}

/**
 * Get the property value, masking it if it matches predefined Personally Identifiable Information (PII) properties or URLs.
 *
 * This function checks if a given property key matches predefined lists of PII properties, PII URLs, or custom PII properties.
 * If a match is found, it returns a masking value or applies a custom masking function; otherwise, it returns the original value.
 *
 * @param {string} key - The property key to check.
 * @param {*} value - The current property value.
 * @returns {*} - The original property value or a masking value if it matches PII properties or URLs.
 *
 * @see {@link PII_PROPERTIES} - List of properties considered as PII properties.
 * @see {@link PII_URLS_PROPERTIES} - List of properties that contain PII URLs.
 * @see {@link PII_SPECIAL_PROPERTIES} - Object with custom PII properties and corresponding masking functions.
 * @see {@link PII_MASK_VALUE} - Default masking value used for PII properties.
 *
 */
function getMaskedPropertyValue(key, value) {
  if (PII_PROPERTIES.includes(key)) {
    return PII_MASK_VALUE;
  } else if (PII_URLS_PROPERTIES.includes(key)) {
    return maskPIIPropertyInUrl(value);
  } else if (PII_SPECIAL_PROPERTIES.hasOwnProperty(key)) {
    const customMaskFunction = PII_SPECIAL_PROPERTIES[key];
    return customMaskFunction(value);
  }

  return value;
}

/**
 * Extract and replace the URL in an error source string while masking Personally Identifiable Information (PII).
 *
 * @param {string} errorSrc - The error source string containing a URL.
 * @returns {string} - The error source string with the URL replaced by a masked URL or the original string if extraction fails.
 *
 */
function extracAndReplaceUrlInErrorSrc(errorSrc) {
  const originalUrl = extractUrlFromErrorSrc(errorSrc);
  if (!originalUrl) {
    return errorSrc;
  }

  const maskedUrl = maskPIIPropertyInUrl(originalUrl);
  return errorSrc.replace(originalUrl, maskedUrl);
}

/**
 * Extract the URL from an error source string in the format "window.onerror@URL:line:column".
 *
 * @param {string} errorSrc - The error source string containing a URL.
 * @returns {string|null} - The extracted URL's href or `null` if extraction fails.
 */
function extractUrlFromErrorSrc(errorSrc) {
  try {
    const windowOnError = 'window.onerror@';
    const indexOfWindowOnError = errorSrc.indexOf(windowOnError);

    let errorSrcModified = errorSrc;

    if (indexOfWindowOnError === 0) {
      errorSrcModified = errorSrcModified.substring(windowOnError.length);
    }

    const windowOnErrorLineAndColumnNumbersRegex = /:[0-9]+:[0-9]+/;
    errorSrcModified = errorSrcModified.replace(windowOnErrorLineAndColumnNumbersRegex, '');

    return new URL(errorSrcModified).href;
  } catch (error) {
    return null;
  }
}

/**
 * Mask Personally Identifiable Information (PII) properties in a URL.
 *
 * @param {string} value - The URL string to process.
 * @returns {string} - The modified URL with PII properties masked or the original value if the value is not a valid URL.
 *
 * @see {@link PII_PROPERTIES} - List of properties considered as PII properties.
 * @see {@link PII_MASK_VALUE} - Masking value used for PII properties.
 */
function maskPIIPropertyInUrl(value) {
  try {
    const url = new URL(value);
    PII_PROPERTIES.forEach(property => {
      if (url.searchParams.has(property)) {
        url.searchParams.set(property, PII_MASK_VALUE);
      }
    });
    return url.href;
  } catch (error) {
    return value;
  }
}
