import styled, { css } from 'styled-components';
import Text from '../Text';
import theme from '../themes/theme';

const getColor = color => theme.colors[color] || color;

const spanStyle = ({ underline, color, hoverColor }) => {
  const colorFocus = getColor(color);
  const colorHover = hoverColor ? getColor(hoverColor) : colorFocus;

  if (!underline)
    return css`
      text-decoration: none;
      > span {
        :hover {
          background-size: 0 1px, 100% 1px;
          color: ${() => colorHover};
        }
      }
    `;

  return css`
    > span {
      background-size: 100% 1px, 0 1px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 200ms linear;

      :hover {
        background-size: 0 1px, 100% 1px;

        background-image: linear-gradient(#0000, #0000), linear-gradient(${colorHover}, ${colorHover});
        color: ${colorHover};
      }
    }
  `;
};

export const TextLikeLink = styled(Text)`
  display: inline-flex;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: ${({ fontWeight }) => fontWeight};
  align-items: center;

  ${spanStyle} :hover {
    color: ${({ underline, hoverColor, color }) => (hoverColor ? getColor(hoverColor) : getColor(color))};
  }
  :hover {
    text-decoration: none;
  }
`;
