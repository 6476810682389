import { LocalStorageKeys } from '../../../utilities/storageUtils';

export const loadState = (preloadedStates = {}) => {
  try {
    const serializedState = localStorage.getItem(LocalStorageKeys.REDUX_LOCAL_STORAGE);
    if (serializedState === undefined || serializedState === null) {
      return undefined;
    }

    let jsonSerializedState = JSON.parse(serializedState);

    for (let key in preloadedStates) {
      let [stateName, stateProperty, stateSubProperty] = key.split('.');
      if (jsonSerializedState[stateName]) {
        if (stateSubProperty && jsonSerializedState[stateName][stateProperty]) {
          jsonSerializedState[stateName][stateProperty][stateSubProperty] = preloadedStates[key];
        } else {
          jsonSerializedState[stateName][stateProperty] = preloadedStates[key];
        }
      }
    }

    return jsonSerializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state, itemsToRemove) => {
  try {
    const stateToSave = makeCopy(state, Object.keys(itemsToRemove));
    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem(LocalStorageKeys.REDUX_LOCAL_STORAGE, serializedState);
  } catch {
    // ignore write errors
    return null;
  }
};

export const deleteState = () => {
  try {
    localStorage.removeItem(LocalStorageKeys.REDUX_LOCAL_STORAGE);
  } catch (err) {
    return null;
  }
};

const objConstrcutor = {}.constructor;
const dehydrateCopy = true;

const makeCopy = (src, exceptionKeys, srcKey = '') => {
  srcKey = srcKey || '';
  exceptionKeys = exceptionKeys || [];
  if (src != null && src !== undefined && (srcKey.length === 0 || !exceptionKeys.includes(srcKey))) {
    const isObject = src.constructor === objConstrcutor;

    if (isObject) {
      let copyObj = {};
      Object.keys(src).forEach(key => {
        const compositeKey = srcKey.length > 0 ? srcKey + '.' + key : key;

        const copiedVal = makeCopy(src[key], exceptionKeys, compositeKey);
        if (!dehydrateCopy || (copiedVal != null && copiedVal !== undefined)) {
          copyObj[key] = copiedVal;
        }
      });
      return !dehydrateCopy || Object.keys(copyObj).length > 0 ? copyObj : null;
    } else if (Array.isArray(src)) {
      const compositeKey = srcKey.length > 0 ? srcKey + '.[]' : '[]';

      const copiedVal = src
        .map(item => makeCopy(item, exceptionKeys, compositeKey))
        .filter(item => !dehydrateCopy || (item != null && item !== undefined));
      return !dehydrateCopy || copiedVal.length > 0 ? copiedVal : null;
    } else {
      return JSON.parse(JSON.stringify(src));
    }
  }
};
