import React from 'react';

import { Box } from '../../../ui-kit';
import { Header, HeaderContainer, SummaryIcon } from './styles';
import { useAccordionContext } from './SummaryAccordion';

export const SummaryAccordionHeader = ({ children, ...restProps }) => {
  const { expanded, setExpanded } = useAccordionContext();

  return (
    <HeaderContainer>
      <Header {...restProps}>{children}</Header>
      <Box onClick={() => setExpanded(!expanded)}>
        <SummaryIcon rotate={expanded ? '-180deg' : '0deg'} data-testid="`SummaryAccordion:expand-icon`" />
      </Box>
    </HeaderContainer>
  );
};
