const internalDataLayer = (event, data) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
    console.warn('Data Layer not found, creating instance...');
  }

  return window.dataLayer[event](data);
};

export const pushEvent = data => {
  if (!data) return;
  internalDataLayer('push', data);
};
