import styled from 'styled-components';
import { border, space, themeGet } from 'styled-system';
import { Flex } from '../../ui-kit';
import ArrowDownThinIcon from '../../ui-kit/icons/ArrowDownThin';
import Text from '../../ui-kit/Text';
import theme from '../../ui-kit/themes/theme';

export const AccordionIcon = styled(ArrowDownThinIcon)`
  ${space}
  height: 16px;
`;

export const SummaryAccordionIcon = styled(ArrowDownThinIcon)`
  ${space}
  height: 16px;
`;

export const BoldText = styled(Text)`
  font-size: ${props => (props.large ? themeGet('fontSizes.7')(props) : themeGet('fontSizes.1')(props))}px;
  font-weight: ${themeGet('fontWeights.bold')};
  font-family: ${themeGet('fonts.secondary')};
  line-height: 22px;
`;

BoldText.defaultProps = {
  theme: theme
};

export const SummaryBottom = styled(Flex)`
  ${border}
  background: ${props => (props.background ? props.background : themeGet('colors.deepForestGreen.100'))}${props =>
  props.background ? '' : '06'};
`;

export const GreenText = styled(BoldText)`
  color: ${themeGet('colors.deepForestGreen.100')};
`;
