// IEModal actions

import ieModalActionTypes from './ieModal.types';

export const setIEModalAlreadyShown = params => dispatch => {
  dispatch({
    type: ieModalActionTypes.SET_IEMODAL_IS_ALREADY_SHOWN,
    isAlreadyShown: params
  });
};
