// TODO - revisit this component to fix how hooks are working
/* eslint-disable react-hooks/exhaustive-deps */
import throttle from 'lodash-es/throttle';
import React, { createRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AffirmContainer, StyledFinancingOptionText } from './styles';

import { useHistory } from 'react-router';
import { getAffirmPubKey, isLocationAvailableWithAffirm } from '../../api/Utils/affirmUtils';

const getAffirmScript = affirmPubKey => {
  const shouldCreateScript = affirmPubKey && process.env.REACT_APP_AFFIRM_URL;

  if (!shouldCreateScript) return null;

  return `_affirm_config = {
      public_api_key: "${affirmPubKey}",
      script: "${process.env.REACT_APP_AFFIRM_URL}"
    };
    (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c, arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length; a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length; a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");`;
};

const refreshAffirm = throttle(() => {
  try {
    window.affirm.ui.refresh();
  } catch (e) {}
}, 500);

const FinancingOptionText = ({ productType, amount, shouldRefresh, rightAlign, resortLocation, ...restProps }) => {
  const rateContainer = createRef();
  const shouldEnableAffirm = isLocationAvailableWithAffirm(resortLocation);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (history?.action === 'POP') window.affirm?.ui?.modal?.close();
    };
  }, [history]);

  useEffect(() => {
    refreshAffirm();
  }, [shouldRefresh]);

  useEffect(() => {
    if (shouldEnableAffirm) {
      const setListeners = event => {
        const { target } = event;
        target.parentElement.querySelector('.affirm-modal-trigger').click();
      };
      // This sucks but is the only way to emulate production behavior
      rateContainer.current.addEventListener('click', setListeners);

      return () => {
        if (rateContainer && rateContainer.current) {
          rateContainer.current.removeEventListener('click', setListeners);
        }
      };
    }
  }, []);

  if (!shouldEnableAffirm) return null;

  return (
    <>
      <Helmet>
        <script>{getAffirmScript(getAffirmPubKey())}</script>
      </Helmet>
      <AffirmContainer ref={rateContainer} rightAlign={rightAlign}>
        <StyledFinancingOptionText
          m={0}
          className="affirm-as-low-as"
          data-page-type={productType}
          data-amount={amount}
          {...restProps}
        />
      </AffirmContainer>
    </>
  );
};

export default FinancingOptionText;
