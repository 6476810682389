import styled, { css } from 'styled-components';
import { zIndex } from 'styled-system';
import { Absolute } from '..';
import { HINT_WIDTH } from './constants';

export const HintWrapper = styled.div`
  display: inline-block;
  position: relative;
  & > div {
    pointer-events: none;
  }
`;

HintWrapper.displayName = 'HintWrapper';

const withPlacementStyles = ({ placement }) => {
  if (placement && placement.includes('-')) {
    const [yPlacement, xPlacement] = placement.split('-');
    const yAnchor = yPlacement === 'bottom' ? 'top' : 'bottom';
    const xOffset = xPlacement === 'left' ? `-${HINT_WIDTH}px` : '100%';

    return css`
      ${yAnchor}: 100%;
      left: ${xOffset};
    `;
  }
  return css`
    top: 100%;
    left: 100%;
  `;
};

export const ContentWrapper = styled(Absolute)`
  width: ${HINT_WIDTH}px;

  ${zIndex}
  ${withPlacementStyles}
`;

ContentWrapper.displayName = 'ContentWrapper';
