import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button } from '../Button';
import Flex from '../Flex';
import theme from '../themes/theme';
import { VARIANTS } from './constants';
import { Container, Description, Image, RightArrow, Title } from './styles';

const propTypes = {
  /** Variant of the callout, changes the theme of the component */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** Object image, contains src and alt props */
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  /** String that sets the title of the Callout */
  title: PropTypes.string.isRequired,
  /** String that sets the description of the Callout */
  description: PropTypes.string,
  /** String that sets the label of the CTA Button */
  buttonLabel: PropTypes.string,
  /** Callback function triggered when user clicks on CTA */
  onClick: PropTypes.func,
  /** String that sets the variant of the CTA Button */
  buttonVariant: PropTypes.string
};

const defaultProps = {
  variant: VARIANTS.LIGHT_BLUE,
  description: '',
  buttonVariant: 'primary'
};

const Callout = ({ variant, image, title, description, buttonLabel, buttonVariant, onClick }) => {
  const { bgColor, borderColor, titleColor, descriptionColor } = variant;

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  });

  return (
    <Container onClick={isDesktop ? null : onClick} background={bgColor} borderColor={borderColor}>
      <Flex>
        {image && <Image src={image.src} alt={image.alt} />}
        <Flex pl={7} pr={3} py={isDesktop && 7} flexDirection="column">
          <Title color={titleColor}>{title}</Title>
          {description && <Description color={descriptionColor}>{description}</Description>}
        </Flex>
      </Flex>
      {buttonLabel && isDesktop && (
        <Button onClick={onClick} variant={buttonVariant}>
          {buttonLabel}
        </Button>
      )}
      {buttonLabel && !isDesktop && <RightArrow aria-label={buttonLabel} size="24px" color={titleColor} />}
    </Container>
  );
};

Callout.propTypes = propTypes;
Callout.defaultProps = defaultProps;
Callout.VARIANTS = VARIANTS;

export default Callout;
