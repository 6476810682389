import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { Box, Flex } from '../../ui-kit/index';
import theme from '../../ui-kit/themes/theme';

export const PackageBox = styled(Box)`
  background: ${themeGet('colors.pureWhite.0')};
`;

export const Wrapper = styled(Box)`
  ${props =>
    props.applyBorder
      ? css`
      border-radius: ${theme.radii[5]}px;
      box-shadow: ${theme.boxShadows[21]}
      border: ${theme.borders[4]}
      background-image: linear-gradient(
        to right,
        ${themeGet('colors.pureWhite.0')} -11%,
        ${themeGet('colors.snowMountainGrey.20')} 138%
      )`
      : css`
          border-radius: ${theme.radii[1]}px;
          border-bottom: ${theme.borders[4]};
        `}
`;

export const PriceDivider = styled(Box)`
  opacity: 0.4;
  border-top: ${props => (props.dashed ? 'dashed' : 'solid')} 1px ${themeGet('colors.kiddiePoolBlue.50')};
`;

export const SustainabilityTooltip = styled.span`
  color: ${theme.colors.deepLakeBlue[100]};
  background: ${theme.colors.pureWhite[0]};
  font-size: ${`${theme.fontSizes[0]}px`};
  font-weight: ${theme.fontWeights.semibold};
  cursor: pointer;
`;

export const SustainabilityContainer = styled(Flex)`
  min-height: 22px;
`;

export const DayPassContainer = styled(Flex)`
  position: relative;
  border-bottom: 1px solid ${theme.colors.deepLakeBlue[20]};

  ${Flex} {
    &:not(:last-child) {
      border-right: 1px solid ${theme.colors.deepLakeBlue[20]};
    }
  }
`;
