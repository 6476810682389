import React, { useRef } from 'react';
import useHistoryPagination from '../../../hooks/api_hooks/useHistoryPagination';
import useScrollToItem from '../../../hooks/ui_hooks/useScrollToItem';
import { GwDatesWrapper } from '../../_internal_date_/gwDatesWrapper';
import { Box, Text } from '../../ui-kit';
import { editCopyByReplaceString } from '../../utilities/copyFunctions';
import {
  LoyaltyProfilePointsHistoryContainer,
  LoyaltyProfilePointsHistoryDate,
  LoyaltyProfilePointsHistoryDisclaimer,
  LoyaltyProfilePointsHistoryTableRow,
  LoyaltyProfilePointsHistoryTableRowJoinColum,
  SeeMoreContainer,
  SpanDot,
  StyledChevronDownIcon
} from '../assets/styles';
import * as COPY from '../utilities/copy';

const HISTORY_TYPES = {
  referred_a_friend: 'Referred a friend',
  points_used_on_a_booking: 'Reservation',
  points_returned_from_a_cancellation: 'Canceled Booking',
  completed_stay: 'Stayed at lodge',
  sl_expire: 'Points expired'
};

const LoyaltyProfilePointsHistory = ({ profileId }) => {
  const { displayArray, seeMoreRows, resetAvailable, resetRows, total } = useHistoryPagination(profileId);
  const { customScrollTo } = useScrollToItem();
  const seeMore = useRef(null);
  const historyTitle = useRef(null);

  const handleSeeMore = () => {
    resetAvailable ? resetRows() : seeMoreRows();
    customScrollTo({
      ref: resetAvailable ? historyTitle : seeMore,
      behavior: 'smooth',
      block: 'end',
      delay: 500,
      custom: {
        yOffset: -380
      }
    });
  };

  return (
    <Box>
      {displayArray.length > 0 ? (
        <>
          <Text
            ref={historyTitle}
            color="deepLakeBlue.100"
            fontWeight="bold"
            lineHeight={[20, 20, 20, 20, 32]}
            fontSize={[3, 3, 3, 3, 4]}
            mb={[3, 3, 3, 37]}>
            {COPY.VOYAGERS_POINTS_HISTORY}
          </Text>
          <Box>
            <LoyaltyProfilePointsHistoryContainer mb={7}>
              {displayArray.map(item => (
                <LoyaltyProfilePointsHistoryTableRow key={item.uuid}>
                  <LoyaltyProfilePointsHistoryTableRowJoinColum>
                    <Text color="deepLakeBlue.100" fontWeight="bold" lineHeight={[18, 18, 18, 18, 20]} fontSize={[1]}>
                      {HISTORY_TYPES[item.type]}
                    </Text>
                    <LoyaltyProfilePointsHistoryDate
                      color="deepLakeBlue.100"
                      fontWeight="medium"
                      lineHeight={[20]}
                      fontSize={[1]}>
                      {GwDatesWrapper.format(item.date, 'MMM DD, YYYY')}
                    </LoyaltyProfilePointsHistoryDate>
                  </LoyaltyProfilePointsHistoryTableRowJoinColum>
                  <Text
                    color={verifyIfNegative(item.points) ? 'deepLakeBlue.100' : 'voyagersColors.100'}
                    fontWeight="bold"
                    lineHeight={[18, 18, 18, 18, 20]}
                    fontSize={[1]}>
                    {editCopyByReplaceString(
                      COPY.HISTORY_POINTS,
                      verifyIfNegative(item.points) ? item.points : `+${item.points}`,
                      '<POINTS>'
                    )}
                  </Text>
                </LoyaltyProfilePointsHistoryTableRow>
              ))}
            </LoyaltyProfilePointsHistoryContainer>
            {total && total > 5 ? (
              <SeeMoreContainer onClick={handleSeeMore}>
                <Text
                  color="preciousStoneBlue.100"
                  fontWeight="bold"
                  lineHeight={[18, 18, 18, 18, 20]}
                  fontSize={[1]}
                  mr={8}
                  ref={seeMore}>
                  {resetAvailable ? COPY.SEE_LESS : COPY.SEE_MORE}
                </Text>
                <StyledChevronDownIcon resetAvailable={resetAvailable} />
              </SeeMoreContainer>
            ) : null}
          </Box>
        </>
      ) : null}

      <LoyaltyProfilePointsHistoryDisclaimer>
        <SpanDot width={8} height={8} mr={8} />
        <Text color="deepLakeBlue.100" fontWeight="bold" lineHeight={[16, 16, 16, 16, 20]} fontSize={[0, 0, 0, 0, 1]}>
          {COPY.VOYAGERS_EXPIRATION_HISTORY}
        </Text>
      </LoyaltyProfilePointsHistoryDisclaimer>
    </Box>
  );
};

export default LoyaltyProfilePointsHistory;

// Check if number is negative or positive
function verifyIfNegative(number) {
  return number < 0 ? true : false;
}
