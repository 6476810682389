// Guests reducer

import { initializeState } from '../../../utilities/storageUtils';
import guestsModalActionTypes from './guests.types';

const INITIAL_STATE = { adultsCount: 0, kidsCount: 0, kidsAges: [] };

const guestsReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case guestsModalActionTypes.SET_GUESTS_ADULTS_COUNT:
      return {
        ...state,
        adultsCount: action.count
      };
    case guestsModalActionTypes.SET_GUESTS_KIDS_COUNT:
      return {
        ...state,
        kidsCount: action.count
      };
    case guestsModalActionTypes.SET_GUESTS_KIDS_AGES:
      return {
        ...state,
        kidsAges: action.kidsAges
      };
    default:
      return state;
  }
};

export default guestsReducer;
