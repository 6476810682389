import { isDevEnvironment } from '../../../utilities/env';

/**
 * Simple redux middleware to use redux actions as input of tracking!
 * this will call the track function from the provided instance of tracker on every action
 * and use the action type as the event type and the action payload as the event data
 * @param {Object} tracker
 */
const trackingMiddleware = tracker => store => next => action => {
  const actionWithOrigin = {
    ...action,
    ...store,
    ...{
      from: 'REDUX'
    }
  };

  // For dev purposes emulate a dataLayer push with a console log so dev can actually see what is the object that eventually will get pushed.
  if (isDevEnvironment) {
    window.dataLayer = {
      push: console.log
    };
  }

  tracker.trackEvent(actionWithOrigin);
  next(action);
};

export default trackingMiddleware;
