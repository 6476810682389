// Import types
import routes from '../../../routes';
import cartActionTypes from './cart.types';

// Set cart location
export const setCartResortLocation = location => dispatch => {
  dispatch({
    type: cartActionTypes.SET_CART_RESORT_LOCATION,
    cartResortLocation: location
  });
};

// Reset cart location
export const resetCartResortLocation = () => dispatch => {
  dispatch({
    type: cartActionTypes.RESET_CART_RESORT_LOCATION
  });
};

// Set last visited step
export const setCartLastVisitedStep = step => dispatch => {
  dispatch({
    type: cartActionTypes.SET_CART_LAST_VISITED_STEP,
    cartLastVisitedStep: step
  });
};

// Push to last visited step
export const pushToLastVisitedStep = () => (dispatch, getState) => {
  const { cartLastVisitedStep } = getState().cart;
  const { resortLocationUrl } = getState().currentLodge;
  const { selectedPackages } = getState().packages;
  const hasDiningPackages = selectedPackages?.some(pkg => pkg.packageType?.includes('dining'));
  if (!cartLastVisitedStep || !resortLocationUrl) return;

  if (cartLastVisitedStep === routes.plan.dining.replace(/\/$/, '') && hasDiningPackages) {
    return (window.location.href = resortLocationUrl.replace(/\/$/, '') + routes.plan.payment.replace(/\/$/, ''));
  }

  window.location.href = resortLocationUrl.replace(/\/$/, '') + cartLastVisitedStep;
};

// Set expired cart
export const setExpiredCart = () => dispatch => {
  dispatch({
    type: cartActionTypes.SET_EXPIRED_CART,
    expiredCart: true
  });
};

// Reset expired cart
export const resetExpiredCart = () => dispatch => {
  dispatch({
    type: cartActionTypes.RESET_EXPIRED_CART,
    expiredCart: false
  });
};
