export const specialRequestOptions = [
  {
    label: 'Close to elevator',
    value: 'EC'
  },
  {
    label: 'Close to the Waterpark',
    value: 'CWP'
  },
  {
    label: 'Far from Elevator',
    value: 'EF'
  },
  {
    label: 'Lobby Level',
    value: 'LL'
  },
  {
    label: "Pack 'n Play",
    value: 'CRB'
  },
  {
    label: 'Top Floor',
    value: 'TF'
  },
  {
    label: 'Other',
    value: 'OTHR'
  }
];
