const RESORT_LOCATION_URL_PARAM_POSITION = 1;

/**
 * This function gets the resort location from the URL.
 * @returns {string} The resort location URL.
 */
export const getResortLocationUrl = () => {
  const urlParts = window.location.pathname.split('/');
  const resortLocation = urlParts[RESORT_LOCATION_URL_PARAM_POSITION];

  return `/${resortLocation}/`;
};
