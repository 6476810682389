import { ASSETS_LOCATIONS, PACKAGE_TYPE, getAssetsUrl } from '../../utilities/constants';
import { RESERVATION_DETAIL_IMAGES } from './assets/images';

export const ORDER_STATUS = {
  canceled: 'CANCELED',
  reserved: 'RESERVED'
};
export const INFO_SECTION_ID = 'info-section';
export const ADDITIONAL_PACKAGE_SECTION = 'additional-package-section';

// This is gonna be hard coded while we give priority to other AEM stuff, after that, we should manage this from AEM.
const DINNING_TILE = {
  image: `${getAssetsUrl(ASSETS_LOCATIONS.CONTENT, RESERVATION_DETAIL_IMAGES.PACKAGE_DINNER)}`,
  headline: `Wake up to breakfast savings`,
  description: `Begin your days with dining deals, which may include a breakfast voucher and credits for lunch and dinner. Only available pre-arrival.`,
  buttonLabel: 'Add a Deal',
  packageType: PACKAGE_TYPE.dining
};
const ACTIVITIES_TILE = {
  image: `${getAssetsUrl(ASSETS_LOCATIONS.CONTENT, RESERVATION_DETAIL_IMAGES.MAGIQUEST)}`,
  headline: `Play favorites with a pass`,
  description: `Combine a bunch of experiences, including MagiQuest & Build-a-Bear, into a single activity pass for the best value & more fun.`,
  buttonLabel: 'Add a Pass',
  packageType: PACKAGE_TYPE.pass
};
const CABANAS_TILE = {
  image: `${getAssetsUrl(ASSETS_LOCATIONS.CONTENT, RESERVATION_DETAIL_IMAGES.CABANAS)}`,
  headline: `Relax with a private cabana`,
  description: `Reserve a cabana for your pack. Enjoy private water park seating, refreshing drinks, complimentary towels, and more!`,
  buttonLabel: 'RESERVE A CABANA',
  packageType: PACKAGE_TYPE.cabanasAndFlexTrip
};
const EVENT_CALENDAR_TILE = {
  image: `${getAssetsUrl(ASSETS_LOCATIONS.CONTENT, RESERVATION_DETAIL_IMAGES.GREAT_CLOCK_TOWER_SHOW)}`,
  headline: `Keep playing with our events`,
  description: `Explore the lodge and discover new fun with your pack in between water park splashes!`,
  buttonLabel: 'Download Our App',
  highlight: true,
  packageType: 'Default'
};

export const TIERS_CONTENT_MY_RESERVATIONS = {
  A: DINNING_TILE,
  B: ACTIVITIES_TILE,
  C: CABANAS_TILE,
  default: EVENT_CALENDAR_TILE
};

export const TIERS_CONTENT_DAYPASS = {
  A: CABANAS_TILE,
  B: DINNING_TILE,
  default: EVENT_CALENDAR_TILE
};

export const TIERS_CONTENT_DAYPASS_CONFIRMATION = {
  A: CABANAS_TILE,
  default: EVENT_CALENDAR_TILE
};

export const IMAGES = {
  wileyReservation: getAssetsUrl(ASSETS_LOCATIONS.ROOT, RESERVATION_DETAIL_IMAGES.WILEY_RESERVATION),
  waveSVG: getAssetsUrl(ASSETS_LOCATIONS.SVG, RESERVATION_DETAIL_IMAGES.WAVE_BG),
  bestValueRibbon3: getAssetsUrl(ASSETS_LOCATIONS.ROOT, RESERVATION_DETAIL_IMAGES.BEST_VALUE_RIBBON_3),
  bestValueRibbon4: getAssetsUrl(ASSETS_LOCATIONS.ROOT, RESERVATION_DETAIL_IMAGES.BEST_VALUE_RIBBON_4),
  bestValueRibbon5: getAssetsUrl(ASSETS_LOCATIONS.ROOT, RESERVATION_DETAIL_IMAGES.BEST_VALUE_RIBBON_5)
};

export const ADDONS_PACKAGE_NAME = {
  Passes: 'Passes',
  Birthday: 'Birthday Packages',
  Dining: 'Dining Deals'
};

export const ADDONS_PACKAGE_IMAGE = {
  Passes: getAssetsUrl(ASSETS_LOCATIONS.SVG, RESERVATION_DETAIL_IMAGES.ATTRACTION_ADDONS),
  Birthday: getAssetsUrl(ASSETS_LOCATIONS.SVG, RESERVATION_DETAIL_IMAGES.BIRTHDAY_ADDONS),
  Dining: getAssetsUrl(ASSETS_LOCATIONS.SVG, RESERVATION_DETAIL_IMAGES.DINING_ADDONS),
  Cabana: getAssetsUrl(ASSETS_LOCATIONS.SVG, RESERVATION_DETAIL_IMAGES.CABANA_ADDONS)
};

export const ADDONS_CARD_TITLE = {
  Passes: 'Attraction Passes',
  Birthday: 'Birthday Package',
  Dining: 'Dining Deals'
};

export const ADDONS_CARD_MODAL_TITLE = {
  Passes: 'Your Attraction Passes',
  Birthday: 'Your Birthday Packages',
  Dining: 'Your Dining Packages'
};

export const ADDONS_CTA_TEXT = {
  Passes: 'Add More Passes',
  Birthday: 'Add a Package',
  Dining: 'Add a Deal',
  Bundle: 'See Details'
};

// Standard success and error messages
export const NOTIFICATION_MESSAGES = {
  addLCOSuccess: 'You added late Check-Out to your reservation!',
  addLCOFailed: 'Failed to add late Check-Out to your reservation'
};

// Type of response, success or error
export const NOTIFICATION_TYPE = {
  success: 'success',
  failed: 'error'
};

export const PRICE_LABEL = 'Price excludes tax';
