import React from 'react'

const CheckedIconIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 32 32'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M24.571 12.49l-9.43 9.092a1.544 1.544 0 0 1-2.113 0L7.466 16.41a1.452 1.452 0 0 1-.043-2.091 1.547 1.547 0 0 1 2.147-.042l4.485 4.185 8.399-8.07a1.545 1.545 0 0 1 2.108.046 1.45 1.45 0 0 1 .022 2.054zM14.58.065a15.999 15.999 0 1 0 14.917 24.528 15.997 15.997 0 0 0-2.179-19.902A15.954 15.954 0 0 0 14.58.065z' />
  </svg>
)

CheckedIconIcon.displayName = 'CheckedIconIcon'

CheckedIconIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CheckedIconIcon