import uuidv4 from 'uuid/v4';

export const getBrowserId = () => {
  let browserId = localStorage.getItem('browserId');
  if (!browserId) {
    const newUuid = uuidv4();
    localStorage.setItem('browserId', newUuid);
    browserId = newUuid;
  }
  return browserId;
};

export const isInternetExplorer = () => {
  return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;
};
