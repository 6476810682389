// Import types
import geolocationActionTypes from './geolocation.types';

// Initial State
const INITIAL_STATE = {
  geolocationStateCode: '',
  geolocationClosestLodge: '',
  geolocationErrorMsg: null
};

const geolocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case geolocationActionTypes.GET_GEOLOCATION:
      return {
        ...state,
        geolocationStateCode: action.geolocationData.stateCode,
        geolocationClosestLodge: action.geolocationData.closestLodge
      };
    case geolocationActionTypes.GET_GEOLOCATION_ERROR:
      return {
        ...state,
        geolocationErrorMsg: action.geolocationData.err
      };
    case geolocationActionTypes.GET_GEOLOCATION_CLEAR:
      return {
        ...state,
        geolocationStateCode: INITIAL_STATE.geolocationStateCode,
        geolocationClosestLodge: INITIAL_STATE.geolocationClosestLodge,
        geolocationErrorMsg: INITIAL_STATE.geolocationErrorMsg
      };
    default:
      return state;
  }
};

export default geolocationReducer;
