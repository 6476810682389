import React from 'react'

const PlusThinIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 16 16'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M7.9 2c.256 0 .468.185.497.424l.003.056-.001 5.219L13.5 7.7a.5.5 0 0 1 .058.997L13.5 8.7l-5.101-.001.001 4.821a.49.49 0 0 1-.5.48.493.493 0 0 1-.497-.424L7.4 13.52l-.001-4.821L2.5 8.7a.5.5 0 0 1-.058-.997L2.5 7.7l4.899-.001L7.4 2.48A.49.49 0 0 1 7.9 2z' />
  </svg>
)

PlusThinIcon.displayName = 'PlusThinIcon'

PlusThinIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default PlusThinIcon