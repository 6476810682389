import { ASSETS_LOCATIONS, getAssetsUrl } from '../../../utilities/constants';
import { LOYALTY_IMAGES } from '../assets/images';

export const LOYALTY_ICON_LINK = getAssetsUrl(ASSETS_LOCATIONS.SVG, LOYALTY_IMAGES.ADVENTURE_BUCK_COIN);
export const LOYALTY_ICON_LINK_WHITE = getAssetsUrl(ASSETS_LOCATIONS.SVG, LOYALTY_IMAGES.ADVENTURE_BUCK_COIN_WHITE);

export const LOYALTY_TYPES = {
  available: 'available',
  availableMobile: 'availableMobile',
  earn: 'earn',
  applied: 'applied',
  availableWithButton: 'availableWithButton',
  withExpirationDate: 'withExpirationDate',
  profileDesktop: 'profileDesktop',
  profilePoints: 'profilePoints',
  availableMobileSearch: 'availableMobileSearch',
  profilePointsHistory: 'profilePointsHistory'
};
