import { useState, useEffect } from 'react';
import useWindowEventListener from './useWindowEventListener';

// Correct sizing (viewport) on mobile view
const useCorrectedHeight = height => {
  const [correctedHeight, setHeight] = useState();

  const handleViewportUnitsMobile = () => {
    const vh = window.innerHeight * 0.01;
    setHeight(height * vh);
  };

  useEffect(handleViewportUnitsMobile, [height]);

  useWindowEventListener('resize', handleViewportUnitsMobile);

  return correctedHeight;
};

export default useCorrectedHeight;
