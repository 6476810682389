import React from 'react';
import { OFFERS_IMAGES } from '../../../../../LoyaltySummary/assets/images';
import { Flex, Text } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import { editCopyByReplaceWithComponent } from '../../../../../utilities/copyFunctions';
import { handlePointsBalance } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { OfferImageContainer, PointsContainer } from '../styles';

const Points = ({
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  userOffers,
  activeOffers,
  isCartVariation
}) => {
  const pointsBalanceAmount = handlePointsBalance(
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    userOffers,
    activeOffers
  );

  const pointsBalanceValue = () => {
    return <strong>{`${pointsBalanceAmount.toFixed(0)}`}</strong>;
  };
  return (
    <PointsContainer isCartVariation={isCartVariation}>
      {!isCartVariation ? <SummaryContent.Divider /> : null}
      <SummaryContent.Description>
        <Flex flexDirection="row" justifyContent="space-between" width={1}>
          <Flex>
            <OfferImageContainer mr="8px">
              <img src={OFFERS_IMAGES.OFFERS_V_GREEN_ICON} alt="" />
            </OfferImageContainer>
            <Text my="0" fontSize={0} color={theme.colors.deepLakeBlue[100]} fontWeight="medium">
              {editCopyByReplaceWithComponent(COPY.POINTS_APPLIED, pointsBalanceValue(), '<POINTS>')}
            </Text>
          </Flex>
          <Text fontSize={0} color={theme.colors.deepLakeBlue[100]} fontWeight="bold">{`-$${pointsBalanceAmount.toFixed(
            2
          )}`}</Text>
        </Flex>
      </SummaryContent.Description>
    </PointsContainer>
  );
};

export default Points;
