import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { showLoyalty } from '../../../store/reducers';
import { Box, Text } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';
import { Icon, LoyaltyAppliedContainer } from '../assets/styles';
import { LOYALTY_ICON_LINK } from '../utilities/constants';

const LoyaltyApplied = ({ iconLink, adventureBucks, showLoyalty }) => {
  const LoyaltyContainer = (
    <LoyaltyAppliedContainer alignItems="center" enablePadding>
      <Icon src={iconLink} alt="Loyalty" />
      <Box ml={3}>
        <Text as="p" my="0" color={theme.colors.pureWhite[0]} fontWeight={theme.fontWeights.medium} fontSize={1}>
          {adventureBucks}{' '}
          <Text as="span" fontWeight={theme.fontWeights.bold} color={theme.colors.pureWhite[0]}>
            Voyagers Points
          </Text>{' '}
          were applied!
        </Text>
      </Box>
    </LoyaltyAppliedContainer>
  );

  return <>{showLoyalty && LoyaltyContainer}</>;
};

LoyaltyApplied.propTypes = {
  iconLink: PropTypes.string,
  adventureBucks: PropTypes.number
};
LoyaltyApplied.defaultProps = {
  iconLink: LOYALTY_ICON_LINK,
  adventureBucks: 200
};

export default connect(state => {
  return {
    showLoyalty: showLoyalty(state)
  };
})(LoyaltyApplied);
