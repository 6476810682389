import { store } from '../../store/configureStore';
const codesWithAffirm = ['US', 'PR', 'UM', 'GU', 'VI', 'AS', 'MP'];

export const isLocationAvailableWithAffirm = resortLocation => {
  return resortLocation !== 'NIAGON';
};

export const isAffirmAvailable = country => {
  return codesWithAffirm.includes(country);
};

export const getAffirmPubKey = () => {
  const { lodgeConfig } = store?.getState() || '';
  const key = lodgeConfig?.affirmPubKey;

  if (!key) {
    return '';
  }

  return key;
};
