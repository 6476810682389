// Reservation Details Reducer

import { combineReducers } from 'redux';
import addAPackageActionTypes from '../addAPackage/addAPackage.types';
import reservationDetailsActionTypes from './reservationDetailsEntity.types';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST:
      return true;
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
    case addAPackageActionTypes.SET_ADD_A_PACKAGE_FLASH_MESSAGE:
    case reservationDetailsActionTypes.PACKAGE_RESERVATION_DETAILS_MESSAGE_ERROR:
      return false;
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const data = (state = {}, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS:
      const formatedData = { ...action.response };
      if (!formatedData.arrival) {
        formatedData.arrival = formatedData?.selection?.arrival ?? null;
      }
      if (!formatedData.departure) {
        formatedData.departure = formatedData?.selection?.departure ?? null;
      }
      return formatedData;
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS:
      return { ...state, checkIn: { ...action.response } };
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
      return {};
    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE:
      return action.message;
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
    case reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_DETAILS_ERROR:
      return null;
    default:
      return state;
  }
};

const requestSuccess = (state = false, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS:
      return true;
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
      return false;
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isCabanaLoading = (state = false, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_IS_LOADING:
      return true;
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_SUCCESS:
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_FAILURE:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
    case reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_CABANA_DETAILS:
      return false;
    default:
      return state;
  }
};

const cabanaErrorMessage = (state = null, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_FAILURE:
      return action.message;
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_IS_LOADING:
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_SUCCESS:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
    case reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_CABANA_DETAILS:
      return null;
    default:
      return state;
  }
};

const cabanaRequestSuccess = (state = false, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_SUCCESS:
      return true;
    case reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_FAILURE:
    case reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_CABANA_DETAILS:
    case reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR:
      return false;
    default:
      return state;
  }
};

const packageErrorMessageData = (state = null, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.PACKAGE_RESERVATION_DETAILS_MESSAGE_ERROR:
      return action.message;
    case reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_PACKAGE_MESSAGE_ERROR:
      return null;
    default:
      return state;
  }
};

const addGuestReservationSuccess = (state = null, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_SUCCESS:
      return action.addGuestsReservationObj;
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_CLEAR:
      return null;
    default:
      return state;
  }
};

const addGuestReservationErrorMessage = (state = null, action) => {
  switch (action.type) {
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR:
      return action.message;
    case reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR_CLEAR:
      return null;
    default:
      return state;
  }
};

const reservationDetailsEntityReducer = combineReducers({
  isLoading,
  requestSuccess,
  data,
  errorMessage,
  isCabanaLoading,
  cabanaErrorMessage,
  cabanaRequestSuccess,
  packageErrorMessageData,
  addGuestReservationSuccess,
  addGuestReservationErrorMessage
});

export default reservationDetailsEntityReducer;
