import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex, Text } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';
import { BookNowButton, Icon, LoyaltyWithExpirationDateContainer } from '../assets/styles';
import { LOYALTY_ICON_LINK } from '../utilities/constants';

const LoyaltyAvailableWithButton = ({ amount, iconLink }) => {
  return (
    <LoyaltyWithExpirationDateContainer
      alignItems="center"
      bgColor={theme.colors.pureWhite}
      maxWidth="100%"
      enableMobilePadding>
      <Icon src={iconLink} alt="Loyalty" />
      <Box ml={2}>
        <Flex alignItems="center">
          <Box maxWidth="110px" minWidth="125px">
            <Text as="b" fontSize={0}>
              ${amount} Wolf Dollars
            </Text>
          </Box>
          <Box>
            <BookNowButton variant="book">Book now</BookNowButton>
          </Box>
        </Flex>
      </Box>
    </LoyaltyWithExpirationDateContainer>
  );
};

LoyaltyAvailableWithButton.propTypes = {
  amount: PropTypes.number,
  iconLink: PropTypes.string
};
LoyaltyAvailableWithButton.defaultProps = {
  amount: '153',
  iconLink: LOYALTY_ICON_LINK
};

export default LoyaltyAvailableWithButton;
