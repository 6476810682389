export const HINT_WIDTH = 120;

export const PLACEMENTS = {
  TOP_RIGHT: 'top-right',
  TOP_LEFT: 'top-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left'
};

export const PLACEMENT_CORNERS = {
  'bottom-right': 0,
  'bottom-left': 1,
  'top-left': 2,
  'top-right': 3
};
