import PropTypes from 'prop-types';
import React from 'react';
import { VARIANTS } from './constants';
import { StyledBadge } from './styles';

const SIZES = {
  SMALL: 'small',
  LARGE: 'large'
};

/** Default Badge/Tag component */
const Badge = ({
  children,
  variant,
  className,
  customColorBg,
  customColorText,
  borderRadius,
  width,
  customColorBorder,
  size,
  caps = true,
  // This component allows to pass any other prop to the styled component of Text
  ...restProps
}) => {
  return (
    <StyledBadge
      className={className}
      as="span"
      width={width}
      borderRadius={borderRadius}
      color={customColorText ? customColorText : variant.color}
      background={customColorBg ? customColorBg : variant.background}
      borderColor={customColorBorder ? customColorBorder : variant.borderColor}
      caps={caps}
      size={size ?? null}
      {...restProps}>
      {children}
    </StyledBadge>
  );
};

Badge.defaultProps = {
  variant: VARIANTS.YELLOW,
  className: null
};

Badge.VARIANTS = VARIANTS;
Badge.SIZES = SIZES;

Badge.propTypes = {
  /** Variant defines a set of styling such as color, bg, border */
  variant: PropTypes.shape({
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired
  }),
  /** Children allows to render anything inside the Badge */
  children: PropTypes.node,
  /** ClassName property to allow styled components to apply styling */
  className: PropTypes.string,
  /** Custom color for the background */
  customColorBg: PropTypes.string,
  /** Custom color for the text */
  customColorText: PropTypes.string,
  /** Custom color for the border */
  customColorBorder: PropTypes.string,
  /** Boolean to define if the text is uppercase defaults to true */
  caps: PropTypes.bool
};

export default Badge;
