import React from 'react';

const FacebookButtonIconIcon = ({ size, ...props }) => (
  <svg {...props} viewBox="0 0 21 21" width={size} height={size}>
    <path
      d="M10.5 0C4.701 0 0 4.725 0 10.554c0 5.291 3.878 9.66 8.93 10.423v-7.626H6.334v-2.774H8.93V8.731c0-3.056 1.481-4.398 4.008-4.398 1.21 0 1.85.09 2.154.131v2.422h-1.724c-1.073 0-1.448 1.022-1.448 2.175v1.516h3.145l-.427 2.774H11.92V21C17.046 20.301 21 15.897 21 10.554 21 4.725 16.299 0 10.5 0z"
      fill="#1876F1"
      fill-rule="nonzero"
    />
  </svg>
);

FacebookButtonIconIcon.displayName = 'FacebookButtonIconIcon';

FacebookButtonIconIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
};

export default FacebookButtonIconIcon;
