import PropTypes from 'prop-types';
import React from 'react';
import { Box, TextLink } from '../../../ui-kit';
import Text from '../../../ui-kit/Text';
import AlertSuccessIcon from '../../../ui-kit/icons/AlertSuccessIcon';
import colors from '../../../ui-kit/themes/colors';
import { editCopyByReplaceString } from '../../../utilities/copyFunctions';
import { SummaryContent } from '../Content/SummaryContent';
import SummaryItem from '../SummaryItem';
import * as COPY from './utilities/copy';

const SimpleSummaryInfo = ({
  promoCode,
  description,
  fullWidthDescription,
  productTitle,
  price,
  navigationText,
  onNavigateLinkClick
}) => {
  return (
    <Box pt={3} color="deepLakeBlue.100">
      <SummaryItem
        labelTitle={COPY.PRICE_SECTION}
        leftColumn={
          <Text lineHeight={16} fontSize={0} fontWeight="bold">
            {productTitle}
          </Text>
        }
        rightColumn={
          <Text fontSize={0} fontWeight="bold">
            ${price}
          </Text>
        }
      />
      {!fullWidthDescription ? (
        <SummaryItem
          labelTitle={COPY.PACKAGE_DESCRIPTION_SECTION}
          leftColumn={
            <Text lineHeight={18} fontSize={0} fontWeight="medium">
              {description}
            </Text>
          }
        />
      ) : (
        <Text lineHeight={18} fontSize={0} fontWeight="medium" mb={3}>
          {description}
        </Text>
      )}
      {navigationText ? (
        <TextLink lineHeight={16} fontSize={9} fontWeight="bold" onClick={onNavigateLinkClick}>
          {navigationText}
        </TextLink>
      ) : null}

      {promoCode ? (
        <SummaryContent.Item fontWeight="bold" color="deepForestGreen.100">
          <SummaryContent.Description>
            <Text lineHeight={16} fontSize={0}>
              {editCopyByReplaceString(COPY.PROMOCODE_APPLIED, promoCode, '<PROMOCODE>')}
            </Text>
          </SummaryContent.Description>
          <AlertSuccessIcon color={colors.successGreen} size={20.5} />
        </SummaryContent.Item>
      ) : null}
    </Box>
  );
};

SimpleSummaryInfo.propTypes = {
  passTitle: PropTypes.string,
  rate: PropTypes.number,
  promoCode: PropTypes.string,
  dayPassPromoDescription: PropTypes.string,
  amountOfDayPasses: PropTypes.number
};

SimpleSummaryInfo.defaultProps = {
  rate: 0.0
};

export default SimpleSummaryInfo;
