import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Lodge MicroService endpoints
 *
 * @class LodgeApiClient
 * @extends {GWRApiClient}
 */
export default class LodgeApiClient extends GWRApiClient {
  constructor(version = '', token = '', tiogasAPI = false) {
    let apiVersion = version !== '' ? version : `v1`;
    const urlAux = getApiUrl(API_URLS.REACT_APP_LODGE_SERVICE_ENDPOINT, apiVersion, tiogasAPI);

    super(urlAux, token);
  }

  /**
   * function returns the lodge configuration for given application
   *
   * @function
   * @param {Property: string, Application: string}
   * Example: {Property: GURNIL, Application: payment}
   */
  async getConfiguration(property, application) {
    const urlRequest = `${this.url}/lodges/${property}/applications/${application}`;
    let response = await super.getApiCall(urlRequest);

    return response;
  }

  /**
   * function returns the lodge location information
   * THIS FUNCTION WAS IMPORTED FROM LodgeConfigurationApiClient.js REMOVED ON PR 9614
   * @function
   *
   */
  async getLodgesLocations() {
    const urlRequest = this.url + `/lodges`;
    let response = await super.getApiCall(urlRequest);

    return response;
  }
}
