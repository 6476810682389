import styled from 'styled-components';
import { themeGet, variant } from 'styled-system';
import { Box, Button, Flex, Text } from '../ui-kit';
import CloseIcon from '../ui-kit/icons/Close';
import theme from '../ui-kit/themes/theme';

export const CloseButton = styled(Button)`
  color: unset;
  height: 18px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  width: 14px;
  align-self: flex-start;

  :focus {
    border: none;
  }
`;

export const ActionButton = styled(Button)`
  margin-top: ${themeGet('space.3')}px;
  padding: 0px;
  font-size: ${themeGet('fontSizes.9')}px;
  border: 2px solid ${themeGet('colors.preciousStoneBlue.100')};
  background-color: transparent;
  color: ${themeGet('colors.preciousStoneBlue.100')};
  width: fit-content;
  padding: 6px ${themeGet('space.2')}px;
  letter-spacing: normal;
  line-height: 1;
  transition: filter 0.2s;

  :hover {
    border: 2px solid ${themeGet('colors.preciousStoneBlue.100')};
    filter: opacity(0.7);
  }
`;

export const AlertIcon = styled(Box)`
  display: flex;
  height: 20px;
  margin-right: 10px;
  align-items: center;
  svg {
    color: ${props => (props.variant === 'success' ? themeGet('colors.successGreen') : '')};
  }
`;

export const AlertContent = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 3px;
`;

export const AlertDescription = styled(Text)`
  font-weight: ${themeGet('fontWeights.regular')};
  font-size: ${theme.fontSizes[0]}px;
  line-height: 18px;
  color: ${themeGet('colors.nightSkyBlack.80')};
`;

export const AlertCloseIcon = styled(CloseIcon)`
  float: right;
  shape-outside: circle(60%);
  margin-left: 16px;
  cursor: pointer;
  line-height: 1;
  height: 13px;
  width: 13px;
`;

export const AlertTitle = styled(Text)`
  line-height: 16px;
  font-size: ${themeGet('fontSizes.9')}px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const alertStyle = variant({
  key: 'alerts'
});

export const AlertWrapper = styled(Flex)`
  ${alertStyle}
  display: flex;
  border-radius: 10px;
  position: relative;
  padding: 8px 16px;
  background-color: ${props => (props.variant === 'success' ? themeGet('colors.deepForestGreen.10') : '')};
  background-color: ${props =>
    props.variant === 'warning' && props.abTest ? themeGet('colors.woodpeckerRed.20') : ''};
  width: ${props => ((props.abTest && !props.isHorizontal) || props.width) && '100%'};
  font-size: ${props => props.fontSize}px;
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;
