//REVIEW AND COMPLETE SECTION COPY
export const SECTION_TITLE = 'Review and Complete';
export const TAKE_A_MOMENT =
  'Take a moment to ensure all the entered information is correct before clicking the <METHOD> button.';
export const BOOKING_CONSENT = `By booking this reservation, I am providing an electronic signature confirming that I have read and that I agree to Great Wolf Lodge's <TERMS> and <POLICY> as well as confirming that I acknowledge and assume the inherent risk of exposure to COVID-19 that exists by visiting any public place where other people are present. By providing a mobile phone number, I expressly consent to receiving informational SMS texts and calls related to my booking and/or stay. I am also confirming that I am 21 years of age or older.`;
export const TERMS = 'Terms and Conditions';
export const POLICY = 'Privacy Policy';
export const TOTAL = 'Total';
export const DUE_TODAY = 'Due today';
export const DUE_CHECKIN = 'Due at Check-In';
export const REFER_OR_CONTACT = `Please refer to our <POLICY> or <CONTACT> for more details.`;
export const ARIA_MAILING = 'Allow to receive Great Wolf emails';
export const ARIA_SIGNATURE = 'Electronic signature compliance';

//RENDER PAYMENT BUTTON COPY
export const AGREE_AND_BOOK = 'Agree & Book';
export const CC_CHARGED = 'Your credit card will be charged <TOTAL>';

//ERROR ALERT COPY
export const ERROR = 'Something went wrong with your payment.';
export const ERROR_DESCRIPTION =
  'Sorry but there was a problem using <PAYMENT_METHOD>. Please try again or use “edit” to select a different payment option.';

//FLEX FARE COPY
export const FLEX_FARE = 'Protect my Vacation (Highly Recommended)';
export const CANCEL_RESERVATION = `<YES> I want the ability to cancel my reservation up until midnight 2 days prior to
the arrival date. I understand that if I don't select this, cancellation fees may apply. Flex trip is
non-refundable.`;
export const ARIA_HEADER = 'Add flexibility to your trip';
export const ARIA_CHECKBOX = 'Allow ability to cancel reservation';

export const ACCEPT_VISA = 'We accept Visa';
export const ACCEPT_MASTER = 'We accept MasterCard';
export const ACCEPT_AMERICAN = 'We accept American Express';
export const ACCEPT_DISCOVER = 'We accept Discover';
export const CREDIT_CARD_CONTINUE_BUTTON = 'Continue';
export const CREDIT_CARD_INFO = 'Credit Card Info';
export const FORM_NAME_ON_CARD = 'Name on card';
export const FORM_CARD_NUMBER = 'Card Number';
export const FORM_MONTH = 'Month';
export const FORM_YEAR = 'Year';
export const FORM_CVV = 'CVV';
export const FORM_CVV_ALT = '3 digit security code on back of credit card';
export const FORM_CARD_HOLDER = 'Card holder not present at check-in?';
export const FORM_DONT_WORRY = "Don't Worry!";
export const FORM_WARN_FORM_ONLINE =
  'If the Card Holder will not be at check-in, complete the credit card authorization form online.';
export const FORM_COMPLETE_ONLINE_PROCESS = 'Complete Online process';
export const FORM_BACK_PAYMENT = 'Back to payment';

//PAYMENT FROM HELPER COPY
export const QUALIFYING_ID = 'Qualifying ID: <ID>';
export const WISH_HAPPY_BIRTH_DAY = 'Please wish a happy birthday to <BIRTH_DAY>';
export const GREAT_WOLF_LODGE = 'Great Wolf Lodge';
export const US = 'US';

// AFFIRM TEXTS
export const AFFIRM_NO_LATE_FEES = `No late fees, hidden insterest, or surprises. Ever. Get a real-time decision without affecting your credit score.`;
export const AFFIRM_AFTER_CHECKING = `After clicking `;
export const AFFIRM_CONTINUE = `"Continue"`;
export const AFFIRM_MESSAGE = `, you will be redirected to Affirm to securely complete your purchase. Just fill out a few pieces of basic information, and get a real-time decision without affecting your credit score.`;
export const AFFIRM_ADITIONAL = `* Affirm Monthly Payments cannot be combined with gift cards or eGift certificates.`;
export const AFFIRM_CONTINUE_BUTON = `Continue with <ICON>`;
export const AFFIRM_EDIT_BUTON = `Save changes with <ICON>`;
export const SAVE_CHANGES_BUTTON = `Save changes`;
export const AFFIRM_CHECKOUT_BUTTON = `Check out with <ICON>`;

// BILLING INFORMATION
export const BILLING_INFORMATION = `Billing Info`;
export const USE_SAME_BILLING_ADDRESS = `Use the same address I used for my booking`;
export const ADDRESS_LINE_2 = `+ Add Address Line 2 (optional)`;

// INDEX
export const CONTACT_INFO = 'Contact Info';
export const PAYMENT_BILLING = 'Payment & Billing';
export const MONTHLY_PAYMENTS = 'Monthly payments';
export const PAYMENTS_OPTIONS = 'Payment Options';
export const AFFIRM_PAYMENTS_OPTIONS = 'Affirm Monthly Payments not available to non-US residents.';
export const REVIEW_COMPLETE = 'Review & Complete';
export const EMAIL_TAKEN = 'Email is already taken.';

//PaypalSuccessAlert
export const CONTINUE = "'continue'";

//index.js
export const FREE_CANCELATION_TITLE = 'Free Cancellation for 24 hours!';
export const FREE_CANCELATION_TEXT =
  'Any cancellation or change to your reservation within 24 hours is free for this booking!';
export const BEST_RATE_GUARANTEE_TITLE = 'Best rate Guarantee';
export const BEST_RATE_GUARANTEE_TEXT =
  'Found a better price on another site? We’ll match it up to 24 hours after you have booked!';

//GooglePay.js
export const BUTTON_WARNING = 'Continue on the popup window';

//AvailabilityAlert.js
export const GREAT_DEALS = "<strong>Don't miss out on this great deal!</strong> Act fast, this price might change!";
