import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { Box, Flex } from '../../../ui-kit';
import ArrowDownThinIcon from '../../../ui-kit/icons/ArrowDownThin';
import theme, { mediaQueries } from '../../../ui-kit/themes/theme';

export const Container = styled(Box)`
  font-family: ${theme.fontSecondary};

  ${space}
  ${mediaQueries.lg} {
    margin: 0;
  }
`;

export const Content = styled(Box)`
  ${({ isCartVariation }) => {
    if (!isCartVariation) {
      return css`
        border: ${({ expanded }) => (expanded ? `1px solid ${theme.colors.kiddiePoolBlue[20]}` : 'none')};
        padding: 0px ${theme.space[3]}px 0px;
        border-radius: 0px 0px 20px 20px;
      `;
    }
  }}
`;

export const Header = styled(Box)`
  flex: 1;
`;

export const HeaderContainer = styled(Flex)`
  background-color: ${theme.colors.kiddiePoolBlue[20]};
  padding: 16px;
  border-radius: 0px;
  border: 1px solid ${theme.colors.kiddiePoolBlue[20]};
  align-items: center;
  color: ${theme.colors.deepLakeBlue[100]};

  ${mediaQueries.lg} {
    border-radius: 20px 20px 0px 0px;
  }
`;

export const SummaryIcon = styled(ArrowDownThinIcon)`
  transition-duration: 0.4s;
  transition-property: transform;
  transform: rotate(${props => props.rotate || '0deg'});
  height: 12px;
  width: 20px;

  ${mediaQueries.lg} {
    height: 7px;
    width: 12px;
  }
`;
