import { ASSETS_LOCATIONS, getAssetsUrl } from '../../../utilities/constants';
export const RESERVATION_DETAIL_IMAGES = {
  PRINT: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'Redeem-Print.svg'),
  TWENTY_ONE_OLDER: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'Redeem-21years.svg'),
  PACKAGE_DINNER: 'dining_passes/package-dinner-380x180.jpg',
  MAGIQUEST: 'attraction_passes/magiquest-380x180.jpg',
  CABANAS: 'cabanas/cabanas-380x180.jpg',
  GREAT_CLOCK_TOWER_SHOW: 'great-clock-tower-show-380x180.jpg',
  WILEY_RESERVATION: 'wiley-reservation.png',
  WAVE_BG: 'wave-bg.svg',
  GROUP_ICON: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'add-guests-group-icon.svg'),
  CALENDAR_ICON: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'add-guests-calendar-icon.svg'),
  CHECK_ICON: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'add-guests-check-icon.svg'),
  LOCATION_ICON: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'add-guests-location-icon.svg'),
  BEST_VALUE_RIBBON_3: 'Best_Value_Ribbon_3.svg',
  BEST_VALUE_RIBBON_4: 'Best_Value_Ribbon_4.svg',
  BEST_VALUE_RIBBON_5: 'Best_Value_Ribbon_5.svg',
  ATTRACTION_ADDONS: 'AttractionPictograms.svg',
  BIRTHDAY_ADDONS: 'BirthdayPictograms.svg',
  DINING_ADDONS: 'DiningPictograms.svg',
  CABANA_ADDONS: 'CabanaPictograms.svg',
  APP_ILLUSTRATION_ATTRACTIONS: 'app-illustrations-attractions.webp',
  CMP_NEW_MOBILE_APP: 'cmp-new-mobile-app.png',
  APP_STORE_CHROME_PLAY_BADGE: 'App_Store_Google-play-badge.png',
  APP_STORE_APPLE_DOWNLOAD_BADGE: 'App_Store_Apple_Download_Badge.png',
  ADD_GUESTS_GUESTS: 'guests.png',
  ADD_GUESTS_SUITE: 'suite.png',
  INFO_ICON: 'warningIcon.svg',
  CARD_LODGE_INFORMATION: 'paw-location-icon.png',
  CARD_STAY_DATES: 'stay_dates_calendar_icon.png',
  CARD_GUESTS: 'card-guests-icon.png',
  GREEN_CHECK: 'check-guest-form-icon.png',
  CARD_PASSES: 'passes-card-icon.png',
  CARD_PROMOS_AND_DISCOUNTS: 'pass-type-icon.png',
  PLUS_CIRCLE: 'PlusCircle.svg',
  CMP_CALLOUT_PARKING_DESKTOP: 'cmp-callout-parking-desktop.png',
  BUNDLE_TILE_DINING: 'Dining.svg',
  BUNDLE_TILE_ATTRACTIONS: 'WolfPackage.svg',
  BUNDLE_TILE_MORE: 'Sippers.svg',
  CHECK_TRANSPARENT: 'check-transparent.svg',
  ADVENTURE_BUCK_COIN: 'v-adventure-buck-coin.svg',
  ERROR_MESSAGE_ICON: 'ErrorImage.svg'
};
