import theme from '../themes/theme';

export const VARIANTS = {
  LIGHT: {
    bgColor: theme.colors.pureWhite[0],
    borderColor: theme.colors.kiddiePoolBlue[100],
    titleColor: theme.colors.preciousStoneBlue[100],
    descriptionColor: theme.colors.deepLakeBlue[100]
  },
  LIGHT_BLUE: {
    bgColor: theme.colors.kiddiePoolBlue[10],
    titleColor: theme.colors.preciousStoneBlue[100],
    descriptionColor: theme.colors.nightSkyBlack[80]
  },
  DARK: {
    bgColor: theme.colors.preciousStoneBlue[100],
    titleColor: theme.colors.pureWhite[0],
    descriptionColor: theme.colors.pureWhite[0]
  }
};
