import styled, { css } from 'styled-components';
import { fontSize, fontWeight } from 'styled-system';

import { Box, Flex, Text } from '../../../ui-kit/index';
import theme, { mediaQueries } from '../../../ui-kit/themes/theme';

export const Amount = styled(Text)`
  text-align: right;
  width: 55px;
  ${fontWeight}

  ${mediaQueries.lg} {
    width: 48px;
  }
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const Action = styled(Box)`
  color: ${theme.colors.preciousStoneBlue[100]};
  font-weight: bold;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '16px')};
  text-decoration: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${props => (props.fontSize && props.fontSize.sm) || `${props.fontSize}px` || `${theme.fontSizes[9]}px`};

  ${mediaQueries.lg} {
    font-size: ${props => (props.fontSize && props.fontSize.lg) || `${props.fontSize}px` || '8px'};
  }
`;

export const Bottom = styled(Flex)`
  color: ${theme.colors.deepLakeBlue[100]};
  padding: ${theme.space[3]}px 0px;
  margin: 0 -16px;
  flex-direction: column;
  background: ${theme.colors.deepForestGreen[100]}06;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  font-weight: bold;
  gap: 4px;

  font-size: ${props => (props.fontSize && props.fontSize.sm) || props.fontSize || `${theme.fontSizes[1]}px`};

  ${mediaQueries.lg} {
    font-size: ${props => (props.fontSize && props.fontSize.lg) || props.fontSize || `${theme.fontSizes[0]}px`};};
  }
`;

export const Container = styled(Box)`
  font-family: ${theme.fontSecondary};
  ${fontSize}

  font-size: ${props => (props.fontSize && props.fontSize.sm) || props.fontSize || `${theme.fontSizes[1]}px`};

  ${mediaQueries.lg} {
    font-size: ${props => (props.fontSize && props.fontSize.lg) || props.fontSize || `${theme.fontSizes[0]}px`};};
  }
`;

export const Description = styled(Flex)`
  align-items: center;
  flex: 1;
  margin: 0;
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const Divider = styled(Box)`
  border-bottom: ${props => (props.borderThickness ? props.borderThickness + 'px' : `1px`)} solid
    ${theme.colors.snowMountainGrey[100]};
  margin-bottom: ${props => props.mb || `${theme.space[7]}px`};
  margin-top: ${props => props.mb || `${theme.space[7]}px`};
  width: 100%;
  opacity: 1;
`;

export const Item = styled(Flex)`
  font-family: ${theme.fontSecondary};
  width: 100%;
  font-weight: ${props => props.fontWeight || theme.fontWeights.medium};
  color: ${props => props.color || theme.colors.deepLakeBlue[100]};
  ${fontSize}
  ${props => {
    if (!props.mb && props.mb !== 0) return `margin-bottom: ${theme.space[3]}px`;
  }};
  ${props => {
    if (!props.mt && props.mt !== 0) return `margin-top: ${theme.space[3]}px`;
  }};
  align-items: center;
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
  gap: 14px;
`;

export const SubtotalAmount = styled(Text)`
  text-align: right;
  width: 100px;
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const NewTagContainer = styled(Flex)`
  margin-left: 12px;
`;

export const Tooltip = styled(Flex)`
  align-items: center;
  color: ${theme.colors.deepLakeBlue[100]};
  margin-left: 8px;
  cursor: pointer;
`;

export const NewGuestsTag = styled(Text)`
  color: ${theme.colors.pureWhite[0]};
  background-color: ${theme.colors.deepOrange[100]};
  height: 18px;
  border-radius: 2px;
  padding: 2px 8px;
  margin-left: 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: ${theme.fontWeights.bold};
  span {
    display: block;
    position: relative;
    top: 1px;
    height: 14px;
  }
`;

export const NewGuestsMessageContainer = styled(Flex)`
  align-items: flex-start;
  background-color: ${theme.colors.deepForestGreen[10]};
  border: 1px solid ${theme.colors.successGreen};
  border-radius: 10px;
  padding: 12px 16px;
  margin-top: 33px;
`;

export const DataDescription = styled(Text)`
  font-size: ${theme.fontSizes[0]}px;
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.deepLakeBlue[100]};
  line-height: 18px;
  margin-left: 12px;

  b {
    color: ${theme.colors.nightSkyBlack[80]};
  }
`;
