import uuidv4 from 'uuid/v4';
import DocumentStoreApiClient from '../api/clients/DocumentStoreApiClient';

export default class DocumentStoreRepository {
  constructor(url, storageKey, token = '') {
    this.storageKey = storageKey;
    this.apiClient = new DocumentStoreApiClient(url, token);
  }

  async set(data) {
    let stringData = JSON.stringify(data);
    localStorage.setItem(this.storageKey, stringData);
  }

  async setAuth(data) {
    let stringData = JSON.stringify(data);
    const requestObject = { data: stringData };
    return this.apiClient.upsert(requestObject);
  }

  async getAll() {
    let documentData = [];

    documentData = JSON.parse(localStorage.getItem(this.storageKey));
    documentData = documentData ? documentData : [];

    return documentData;
  }

  async getAllAuth() {
    let documentData = [];

    await this.apiClient.getAll().then(
      response => {
        try {
          documentData = JSON.parse(response.data);
          // The response may comes as a string over string
          if (typeof documentData === 'string') {
            documentData = JSON.parse(documentData);
          }
        } catch {
          documentData = [];
        }
      },
      err => {
        return err;
      }
    );

    return documentData;
  }

  async getById(dataList, dataId) {
    return dataList.find(x => x.key === dataId);
  }

  async add(dataList, data) {
    let dataGuuid = uuidv4();
    dataGuuid = dataGuuid.replace(/-/g, '');

    try {
      let dataToAdd = {
        ...data,
        key: dataGuuid,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      // add element at the top of the array
      dataList.unshift(dataToAdd);

      await this.set(dataList);

      return dataList;
    } catch (error) {
      throw new Error('Error adding the item');
    }
  }

  async addAuth(dataList, data) {
    let dataGuuid = uuidv4();

    try {
      let dataToAdd = {
        ...data,
        key: dataGuuid,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      // add element at the top of the array
      dataList.unshift(dataToAdd);

      await this.setAuth(dataList);

      return dataList;
    } catch (error) {
      throw new Error('Error adding the item');
    }
  }

  async update(dataList, data) {
    try {
      let foundIndex = dataList.findIndex(x => x.key === data.key);
      dataList[foundIndex] = data;

      await this.set(dataList);

      return dataList;
    } catch (error) {
      throw new Error('Error on update the item');
    }
  }

  async updateAuth(dataList, data) {
    try {
      let foundIndex = dataList.findIndex(x => x.key === data.key);
      dataList[foundIndex] = data;

      await this.setAuth(dataList);

      return dataList;
    } catch (error) {
      throw new Error('Error on update the item');
    }
  }

  async deleteById(dataList, dataId) {
    let filteredData = dataList.filter(x => x.key !== dataId);

    await this.set(filteredData);

    return filteredData;
  }

  async deleteByIdAuth(dataList, dataId) {
    let filteredData = dataList.filter(x => x.key !== dataId);

    await this.setAuth(filteredData);

    return filteredData;
  }

  async deleteAll() {
    localStorage.removeItem(this.storageKey);
    return [];
  }

  async deleteAllAuth() {
    return this.apiClient.deleteAll().then(
      response => {
        return [];
      },
      err => {
        return err;
      }
    );
  }
}
