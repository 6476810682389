import PropTypes from 'prop-types';
import React from 'react';

import { Button, Flex, Text } from '../../ui-kit';
import { ButtonAndTermContainer, Icon, LoyaltyWithExpirationDateContainer, TermText } from '../assets/styles';
import { LOYALTY_ICON_LINK } from '../utilities/constants';

const LoyaltyWithExpirationDate = ({ amount, expirationDate, iconLink }) => {
  return (
    <LoyaltyWithExpirationDateContainer flexDirection="column" enablePadding>
      <Flex maxWidth="100%">
        <Flex mr={3}>
          <Icon src={iconLink} alt="Loyalty" />
        </Flex>
        <Flex flexDirection="column" justifyContent="center" width="100%" alignItems="center">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text as="b">Available Wolf Dollars</Text>
            <Text as="b">${amount}</Text>
          </Flex>
          <Flex mt={3} justifyContent="space-between" width="100%">
            <Text fontSize={0}>Expiration Date</Text>
            <Text fontSize={0}>{expirationDate}</Text>
          </Flex>
        </Flex>
      </Flex>
      <ButtonAndTermContainer>
        <Flex mt={3} width="100%" justifyContent="center">
          <Button variant="book">Book now</Button>
        </Flex>
        <Flex mt={3} width="100%" justifyContent="center">
          <TermText>See Terms & Conditions</TermText>
        </Flex>
      </ButtonAndTermContainer>
    </LoyaltyWithExpirationDateContainer>
  );
};

LoyaltyWithExpirationDate.propTypes = {
  amount: PropTypes.number,
  expirationDate: PropTypes.string,
  iconLink: PropTypes.string
};
LoyaltyWithExpirationDate.defaultProps = {
  amount: '153',
  expirationDate: '08/21/2022',
  iconLink: LOYALTY_ICON_LINK
};

export default LoyaltyWithExpirationDate;
