import styled from 'styled-components';
import { Box } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';

// Cart Container
export const CartCustomContainer = styled(Box)`
  position: relative;
  max-width: 24px;
  max-height: 24px;
  margin: 0 auto;
  ${theme.mediaQueries.md} {
    max-width: 32px;
    max-height: 32px;
  }
`;
