import styled from 'styled-components';
import theme from './themes/theme';
import Box from './Box';

const floatLabel = (active, type, error) => {
  if (active) {
    if (type === 'textarea') {
      return {
        transform: 'translateY(-8px) scale(0.57)',
        color: `${theme.colors.preciousStoneBlue[100]}`
      };
    } else {
      return {
        fontSize: '8px',
        transform: `translateY(${error ? '15px' : '-15px'})`,
        color: `${theme.colors.preciousStoneBlue[100]}`
      };
    }
  }
};

const positionLabel = (type, error) => {
  if (type === 'textarea') {
    return {
      top: '15px'
    };
  } else if (type === 'select') {
    return {
      top: 'calc(100% - 31px)'
    };
  } else {
    if (error) {
      return {
        top: 0,
        overflow: 'hidden',
        minHeight: '15px',
        left: 0,
        margin: '8px 0'
      };
    }

    return {
      top: '25px',
      transform: 'translateY(-50%)',
      overflow: 'visible',
      minHeight: 'auto'
    };
  }
};

const errorFieldProps = props => {
  if (props.error) {
    return {
      position: 'relative',
      transition: 'none'
    };
  }
};

const Label = styled(Box).attrs(props => ({
  as: 'label'
}))`
  font-size: ${props => (props.error ? '10px' : `${theme.fontSizes[1]}px`)};
  position: absolute;
  transform-origin: top left;
  color: ${props => (props.error ? theme.colors.digitalBrightRed[100] : props.color || theme.colors.nightSkyBlack[60])};
  left: 16px;
  font-family: ${theme.fontSecondary};
  font-weight: ${theme.fontWeights.medium};
  margin: 0;
  transition: all 0.1s ease-in-out;
  pointer-events: ${props => (props.clickable ? 'unset' : 'none')};
  padding-bottom: 0;
  display: inline-block;
  line-height: normal;
  ${props => positionLabel(props.type, props.error)};
  ${props => floatLabel(props.active, props.type, props.error)};
  ${errorFieldProps};
`;

Label.displayName = 'Label';

Label.defaultProps = {
  theme: theme,
  active: false
};

export default Label;
