import PropTypes from 'prop-types';
import React from 'react';
import { ASSETS_LOCATIONS, getAssetsUrl } from '../../utilities/constants';
import { ALERT_ICONS } from './assets/images';
import {
  ActionButton,
  AlertCloseIcon,
  AlertContent,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AlertWrapper,
  CloseButton,
  Icon
} from './styles';

export const ALERT_TYPES = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  offer: 'offer'
};

const propTypes = {
  /** Title of the alert. If not used the content is rendered directly. */
  title: PropTypes.string,
  /** Variant of the alert */
  variant: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  /** Defines if the icon for the alert is visible or not. */
  icon: PropTypes.bool,
  /** Determines if the alert is closed */
  isClosed: PropTypes.bool,
  /** Adds dismissible option to close the alert */
  onClose: PropTypes.func,
  /** Text of the action for the button */
  actionText: PropTypes.string,
  /** Callback function triggered when clicked on CTA. */
  onActionClick: PropTypes.func,
  /** Custom close component */
  customCloseComponent: PropTypes.element
};

const defaultProps = {
  variant: ALERT_TYPES.info,
  message: null,
  icon: false,
  isClosed: false,
  actionText: null,
  onClose: null,
  onActionClick: null,
  customCloseComponent: null
};

const SelectedIcon = ({ variant }) => {
  return (
    <Icon
      src={getAssetsUrl(ASSETS_LOCATIONS.SVG, ALERT_ICONS[variant])}
      size="16px"
      data-testid={`Alert:${variant}icon`}
    />
  );
};

const Alert = ({
  children,
  title,
  variant,
  icon,
  isClosed,
  onClose,
  actionText,
  onActionClick,
  abTest,
  isHorizontal,
  customCloseComponent,
  width
}) => {
  if (isClosed) {
    return null;
  }

  return (
    <AlertWrapper width={width} abTest={abTest} isHorizontal={isHorizontal} variant={variant} fontSize={10}>
      {icon && (
        <AlertIcon variant={variant} className="icon">
          <SelectedIcon variant={variant} />
        </AlertIcon>
      )}
      <AlertContent>
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription abTest={abTest}>{children}</AlertDescription>
        {actionText && onActionClick && typeof onActionClick === 'function' && (
          <ActionButton onClick={onActionClick}>{actionText}</ActionButton>
        )}
      </AlertContent>
      {customCloseComponent}
      {!customCloseComponent && onClose && typeof onClose === 'function' && (
        <CloseButton variant="ghost">
          <AlertCloseIcon onClick={onClose} data-testid="Alert:closeicon" />
        </CloseButton>
      )}
    </AlertWrapper>
  );
};

Alert.VARIANTS = ALERT_TYPES;
Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
