import PropTypes from 'prop-types';
import React from 'react';
import * as COPY from '../utilities/copy';

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import { isValidPrice } from '../../../../../../utilities/cmpPackagesUtils';
import { Flex, Text } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import { AccordionStyle, ArrowIcon, Leftbar, RightBar } from '../styles';

const propTypes = {
  /** Deposit paid for the reservation */
  depositPaid: PropTypes.number.isRequired,

  /** Due amount at check in */
  dueAtCheckinAmount: PropTypes.number.isRequired
};

const DepositSummary = props => {
  return (
    <>
      <AccordionStyle allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <SummaryContent.Item>
                <SummaryContent.Description>
                  <Flex alignItems="center">
                    <Text fontSize={0} fontWeight={700} lineHeight={18}>
                      {COPY.SUMMARY_DEPOSIT_PAID}
                    </Text>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <ArrowIcon isExpanded={expanded}>
                          <Leftbar />
                          <RightBar />
                        </ArrowIcon>
                      )}
                    </AccordionItemState>
                  </Flex>
                </SummaryContent.Description>

                <SummaryContent.SubtotalAmount>
                  <Text fontSize={0} fontWeight={700} lineHeight={18}>{`$${props.totalDeposit.toFixed(2)}`}</Text>
                </SummaryContent.SubtotalAmount>
              </SummaryContent.Item>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SummaryContent.Item>
              <SummaryContent.Description>
                <Text fontSize={0} fontWeight={500} lineHeight={18}>
                  {COPY.SUMMARY_CABANA_DEPOSIT}
                </Text>
              </SummaryContent.Description>
              <SummaryContent.SubtotalAmount>
                <Text fontSize={0} fontWeight={500} lineHeight={18}>{`$${props.cabanaDeposit.toFixed(2)}`}</Text>
              </SummaryContent.SubtotalAmount>
            </SummaryContent.Item>

            <SummaryContent.Item>
              <SummaryContent.Description>
                <Text fontSize={0} fontWeight={500} lineHeight={18}>
                  {COPY.SUMMARY_SUITE_RESERVATION}
                </Text>
              </SummaryContent.Description>
              <SummaryContent.SubtotalAmount>
                <Text fontSize={0} fontWeight={500} lineHeight={18}>{`$${props.roomDeposit.toFixed(2)}`}</Text>
              </SummaryContent.SubtotalAmount>
            </SummaryContent.Item>
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionStyle>

      <SummaryContent.Divider />
    </>
  );
};

const SimpleDeposit = ({ depositPaid, newCMPGuestsCount, addGuestReservation }) => {
  const removeNewGuestsValue = () => {
    return `$${(depositPaid - newCMPGuestsCount?.feeGuests * newCMPGuestsCount?.extraPerGuestAmount).toFixed(2)}`;
  };
  return (
    <>
      {newCMPGuestsCount?.feeGuests > 0 && !addGuestReservation ? (
        <>
          <AccordionStyle>
            <Accordion allowZeroExpanded preExpanded={['depositPaid']}>
              <AccordionItem uuid={'depositPaid'}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <SummaryContent.Item>
                      <SummaryContent.Description>
                        <Flex alignItems="center">
                          <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={700} lineHeight={18}>
                            {COPY.SUMMARY_DEPOSIT_PAID}
                          </Text>
                          <AccordionItemState>
                            {({ expanded }) => (
                              <ArrowIcon isExpanded={expanded}>
                                <Leftbar />
                                <RightBar />
                              </ArrowIcon>
                            )}
                          </AccordionItemState>
                        </Flex>
                      </SummaryContent.Description>

                      <SummaryContent.SubtotalAmount>
                        <Text
                          color={theme.colors.deepLakeBlue[100]}
                          fontSize={0}
                          fontWeight={700}
                          lineHeight={18}>{`$${depositPaid.toFixed(2)}`}</Text>
                      </SummaryContent.SubtotalAmount>
                    </SummaryContent.Item>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Flex width="100%" mb="10px" mt="-5px">
                    <SummaryContent.Description>
                      <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={500} lineHeight={18}>
                        {COPY.SUMMARY_SUITE_RESERVATION}
                      </Text>
                    </SummaryContent.Description>
                    <SummaryContent.SubtotalAmount>
                      <Text
                        color={theme.colors.deepLakeBlue[100]}
                        fontSize={0}
                        fontWeight={500}
                        lineHeight={18}>{`${removeNewGuestsValue()}`}</Text>
                    </SummaryContent.SubtotalAmount>
                  </Flex>
                  <Flex width="100%">
                    <SummaryContent.Description>
                      <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={500} lineHeight={18}>
                        {newCMPGuestsCount?.feeGuests}{' '}
                        {newCMPGuestsCount?.feeGuests > 1 ? COPY.NEW_GUESTS : COPY.NEW_GUEST}
                      </Text>{' '}
                      <SummaryContent.NewTag />
                    </SummaryContent.Description>
                    <SummaryContent.SubtotalAmount>
                      <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={500} lineHeight={18}>{`$${(
                        newCMPGuestsCount?.feeGuests * newCMPGuestsCount?.extraPerGuestAmount
                      ).toFixed(2)}`}</Text>
                    </SummaryContent.SubtotalAmount>
                  </Flex>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionStyle>
          <SummaryContent.Divider />
        </>
      ) : (
        <SummaryContent.Item>
          <SummaryContent.Description>
            <Text fontSize={0} fontWeight={700} lineHeight={18}>
              {COPY.SUMMARY_DEPOSIT_PAID}
            </Text>
          </SummaryContent.Description>
          <SummaryContent.SubtotalAmount>
            <Text fontSize={0} fontWeight={700} lineHeight={18}>{`$${
              addGuestReservation ? addGuestReservation.costSummary.depositPaid.toFixed(2) : depositPaid.toFixed(2)
            }`}</Text>
          </SummaryContent.SubtotalAmount>
        </SummaryContent.Item>
      )}
    </>
  );
};

export const Deposit = ({
  cabanaDeposit,
  depositPaid,
  dueAtCheckinAmount,
  roomDeposit,
  newCMPGuestsCount,
  addGuestReservation
}) => {
  const amountsNonValid = !isValidPrice(depositPaid) || !isValidPrice(dueAtCheckinAmount);
  if (amountsNonValid) return null;
  const totalDeposit = cabanaDeposit + roomDeposit;

  return (
    <>
      <SummaryContent.Divider />
      {cabanaDeposit > 0 ? (
        <DepositSummary totalDeposit={totalDeposit} cabanaDeposit={cabanaDeposit} roomDeposit={roomDeposit} />
      ) : (
        <SimpleDeposit
          addGuestReservation={addGuestReservation}
          newCMPGuestsCount={newCMPGuestsCount}
          depositPaid={depositPaid}
        />
      )}
      <Flex mb="25px">
        <SummaryContent.Description>
          <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={500} lineHeight={18}>
            {COPY.SUMMARY_DUE_CHECKIN}
          </Text>
        </SummaryContent.Description>
        <SummaryContent.SubtotalAmount>
          <Text color={theme.colors.deepLakeBlue[100]} fontSize={0} fontWeight={500} lineHeight={18}>
            {`$${
              addGuestReservation
                ? addGuestReservation.costSummary.dueAtCheckIn.toFixed(2)
                : dueAtCheckinAmount.toFixed(2)
            }`}
          </Text>
        </SummaryContent.SubtotalAmount>
      </Flex>
    </>
  );
};

Deposit.propTypes = propTypes;
