import * as Yup from 'yup';
import { SUPPORTED_COUNTRIES, VALID_COUNTRIES } from '../../../utilities/constants';
import countries from '../../utilities/countries';
import provinces from '../../utilities/provinces';
import states from '../../utilities/states';
import { onlyDigitsValidation, strLenIsBetweenRange } from '../../utilities/validation/ValidationHelperMethods';
import { errors, paymentFieldsLengths } from '../ValidationMessages';

const partyReservationSchema = Yup.object().shape({
  userId: Yup.string()
    .default(null)
    .notRequired()
    .nullable(),
  payment: Yup.object().shape({
    type: Yup.number()
      .positive()
      .integer()
      .required(),
    creditCard: Yup.object().when('type', {
      is: 1,
      then: Yup.object().shape({
        number: Yup.string().required(),
        holder: Yup.string().required(),
        expiration: Yup.date().required(),
        cardCode: Yup.string().required(),
        cidNumber: Yup.string()
          .required()
          .test('is-number', errors.CVV_ONLY_NUMBERS, val => onlyDigitsValidation(val))
          .test('len', errors.CVV_LENGTH, val =>
            strLenIsBetweenRange(val, paymentFieldsLengths.CVV_MIN - 1, paymentFieldsLengths.CVV_MAX + 1)
          )
      }),
      otherwise: Yup.object().notRequired()
    }),
    affirm: Yup.object().when('type', {
      is: 3,
      then: Yup.object().shape({
        checkoutToken: Yup.string().required(),
        property: Yup.string().required(),
        amount: Yup.number().required(),
        resvId: Yup.string().nullable()
      }),
      otherwise: Yup.object().notRequired()
    })
  }),
  address: Yup.object().shape({
    street1: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string()
      .when('country', {
        is: SUPPORTED_COUNTRIES.UnitedStates,
        then: Yup.string()
          .oneOf(states.map(state => state.abbreviation))
          .required()
      })
      .when('country', {
        is: SUPPORTED_COUNTRIES.Canada,
        then: Yup.string()
          .oneOf(provinces.map(province => province.abbreviation))
          .required()
      })
      .when('country', {
        is: val => !VALID_COUNTRIES.includes(val),
        then: Yup.string().notRequired()
      }),
    country: Yup.string()
      .oneOf(countries.map(country => country.code))
      .required(),
    postalCode: Yup.string()
      .when('country', {
        is: val => val === SUPPORTED_COUNTRIES.UnitedStates,
        then: Yup.string().length(5)
      })
      .required(),
    email: Yup.string()
      .email()
      .required()
  }),
  profileId: Yup.string()
    .notRequired()
    .nullable(),
  customer: Yup.object().shape({
    gender: Yup.number()
      .oneOf([1, 2])
      .required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    isCASLMarked: Yup.boolean().default(false)
  }),
  party: Yup.object().shape({
    partyDate: Yup.string().required(),
    partyType: Yup.string().required(),
    partySize: Yup.object().shape({
      adults: Yup.number().required(),
      kids: Yup.number().required(),
      infants: Yup.number().required(),
      spectators: Yup.number().required()
    }),
    partyTime: Yup.object().shape({
      name: Yup.string().required(),
      startTime: Yup.string().required(),
      endTime: Yup.string().required()
    })
  })
});

export default partyReservationSchema;
