import PropTypes from 'prop-types';
import React from 'react';
import { AccordionItem, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { propTypes as StyledSystemPropTypes } from 'styled-system';
import routes from '../../../routes';
import { compareUrlPathnames } from '../../../utilities/navigationUtils';
import Text from '../Text';
import theme from '../themes/theme';
import {
  NavigationItemWrapper,
  NavigationMenuWrapper,
  SelectedOption,
  StyledAccordion,
  StyledAccordionItemButton
} from './styles';

const MODES = {
  ACCORDION: 'accordion',
  LIST: 'list'
};

const propTypes = {
  ...StyledSystemPropTypes.space,
  /** Array of available routes objects*/
  options: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  /** Callback function triggered when the user selects an option */
  onChange: PropTypes.func,
  /** Display mode, by default it shows an accordion for mobile res and list for desktop */
  mode: PropTypes.oneOf(Object.values(MODES)),
  showLoyalty: PropTypes.bool,
  isReferralEnabled: PropTypes.bool
};

const defaultProps = {
  width: '',
  onChange: () => {},
  mode: null,
  showLoyalty: false,
  isReferralEnabled: false
};

const NavigationMenu = ({ width, options, onChange, mode, showLoyalty, isReferralEnabled }) => {
  const location = useLocation();

  if (showLoyalty && isReferralEnabled) {
    //RAF=Refer A Friend
    let hasRAF = options.find(option => option.title === 'Refer a Friend');
    !hasRAF && options.splice(1, 0, { route: routes.referAFriend, title: 'Refer a Friend' });
  }

  const activeOption = options.find(option => compareUrlPathnames(option.route, location.pathname)) || options[0];

  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.xl });

  const isAccordion = mode ? mode === MODES.ACCORDION : !isDesktop;

  const isActive = option => compareUrlPathnames(option.route, activeOption.route);

  const handleSelect = option => {
    if (activeOption !== option) {
      onChange(option);
    }
  };

  const renderOptionsList = () => (
    <NavigationMenuWrapper width={isAccordion ? '100%' : width}>
      {options.map(option => (
        <NavigationItemWrapper
          aria-label={`${option.title} button`}
          aria-selected={isActive(option)}
          tabindex="-1"
          onClick={() => handleSelect(option)}
          isActive={isActive(option)}
          isAccordion={isAccordion}
          to={option.route}
          key={option.route}>
          <Text color={!isActive(option) ? '#66727f' : null}>{option.title}</Text>
        </NavigationItemWrapper>
      ))}
    </NavigationMenuWrapper>
  );

  if (!isAccordion) {
    return renderOptionsList();
  }

  return (
    <StyledAccordion optionsNumber={options.length} data-testid="nav-accordion" allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <StyledAccordionItemButton>
            <SelectedOption>{activeOption.title}</SelectedOption>
          </StyledAccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{renderOptionsList()}</AccordionItemPanel>
      </AccordionItem>
    </StyledAccordion>
  );
};

NavigationMenu.MODES = MODES;
NavigationMenu.propTypes = propTypes;
NavigationMenu.defaultProps = defaultProps;

export default NavigationMenu;
