// TODO - revisit this component to fix how hooks are working
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setIEModalAlreadyShown } from '../../store/componentStores/IEModal/ieModal.actions';
import { MODAL_KEYS } from '../GlobalModal/modalSource';
import withGlobalModal from '../GlobalModal/withGlobalModal';

const IEModal = ({ isAlreadyShown, setIEModalAlreadyShown, openModal }) => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIEBrowser = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    if (isIEBrowser && !isAlreadyShown) {
      openModal(
        {
          keyContentComponent: MODAL_KEYS.IE_MODAL,
          params: {}
        },
        true
      );
      setIEModalAlreadyShown(true);
    }
  }, []);

  return null;
};

const mapStateToProps = state => ({
  ...state.ieModal
});

const mapDispatchToProps = {
  setIEModalAlreadyShown
};

export default connect(mapStateToProps, mapDispatchToProps)(withGlobalModal(IEModal));
