import React from 'react'

const CloseIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 24 24'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z' />
  </svg>
)

CloseIcon.displayName = 'CloseIcon'

CloseIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CloseIcon