import styled from 'styled-components';
import { alignSelf, background, bottom, left, right, top, zIndex } from 'styled-system';
import Box from './Box';

const Absolute = styled(Box)`
  position: absolute;
  ${top} ${bottom} ${left} ${right} ${zIndex} ${alignSelf} ${background}
`;

Absolute.propTypes = {
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes,
  ...alignSelf.propTypes,
  ...background.propTypes
};

Absolute.displayName = 'Absolute';

export default Absolute;
