import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import { buildAddToCartEnhancedEcommerceObject } from '../../../../../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import routes from '../../../../../../routes';
import { EVENT_CATEGORIES } from '../../../../../../utilities/constants';
import { pushEvent } from '../../../../../../utilities/dataLayerUtils';
import { Box, Flex, Text } from '../../../../../ui-kit';
import AlertSuccessIcon from '../../../../../ui-kit/icons/AlertSuccessIcon';
import CheckedIconIcon from '../../../../../ui-kit/icons/CheckedIcon';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import { CART_MODIFY_MODAL_TYPES, CART_MODIFY_MODAL_VARIATIONS } from '../../utilities/constants';
import * as COPY from '../../utilities/copy';
import { CartCardImageContainer, CartCardTitle, CartCardsContainer, SummaryPackagesCardContainer } from '../styles';
import { editCopyByReplaceMultipleStrings, editCopyByReplaceString } from './../../../../../utilities/copyFunctions';

const propTypes = {
  /** Title of the suite */
  suiteTitle: PropTypes.string.isRequired,

  /** Total for the suite */
  suiteTotal: PropTypes.number.isRequired,

  /** Promo code used in the reservation */
  promoCode: PropTypes.string,

  /** Number of guests as `1 Adult, 0 Kid` or `2 Adults, 2 Kids` */
  guestCountText: PropTypes.string.isRequired,

  /** Size for the promoCode icon */
  iconSize: PropTypes.number,

  promotionalSavings: PropTypes.string,

  optimizelyChangingSavingsDisplay: PropTypes.bool
};

export const Details = ({
  suiteTitle,
  suiteTotal,
  guestCountText,
  promoCode,
  iconSize,
  promotionalSavings,
  optimizelyChangingSavingsDisplay,
  selectedSuite,
  checkIn,
  checkOut,
  isCartVariation = false,
  openModifyCartItemModal,
  clearPlanPackages,
  clearSelectedSuite,
  toggleOpen,
  setCartReturnedPageLinkClicked,
  resortLocationUrlParam,
  onPlanPage,
  clearSelectedPackages,
  kidsAges,
  resortLocation,
  arrival,
  departure,
  adultsCount,
  kidsCount
}) => {
  const history = useHistory();

  const copyObjectDates = {
    '<CHECK_IN>': checkIn,
    '<CHECK_OUT>': checkOut
  };

  // Go to plan page, used on edit and remove suite based on last search
  const goToLastPlanSearch = () => {
    const lastSearch = JSON.parse(localStorage.getItem('GWL_LAST_SEARCH'));
    setTimeout(() => {
      window.location.href =
        resortLocationUrlParam +
        `plan?
          numberOfAdults=${lastSearch.numberOfAdults}&
          arrival=${lastSearch.arrival}&
          departure=${lastSearch.departure}&
          offerCode=${lastSearch.offerCode}&
          kidsAges=${lastSearch.kidsAges}&
          cal=${lastSearch.cal}`.replace(/\s/g, '');
    }, 500);
  };

  // Handle remove suite modal
  const handleRemoveSuiteModal = () => {
    const goToPlanPageOnRemove = () => {
      const eventObject = buildAddToCartEnhancedEcommerceObject(
        EVENT_CATEGORIES.GTM.removeFromCart,
        kidsAges,
        selectedSuite,
        resortLocation,
        departure,
        arrival,
        adultsCount,
        kidsCount,
        1,
        'standard purchase - cart'
      );
      pushEvent(eventObject);

      toggleOpen();
      clearPlanPackages();
      clearSelectedSuite();
      if (!onPlanPage) {
        goToLastPlanSearch();
      }
    };
    openModifyCartItemModal(
      selectedSuite,
      CART_MODIFY_MODAL_TYPES.REMOVE,
      CART_MODIFY_MODAL_VARIATIONS.SUITE,
      goToPlanPageOnRemove
    );
  };

  // Handle edit suite modal and dont remove the suite
  const handleEditSuiteModal = () => {
    const goToPlanPageOnEdit = () => {
      setCartReturnedPageLinkClicked('plan');
      toggleOpen();
      clearSelectedPackages();
      goToLastPlanSearch();
    };
    openModifyCartItemModal(
      selectedSuite,
      CART_MODIFY_MODAL_TYPES.EDIT,
      CART_MODIFY_MODAL_VARIATIONS.SUITE,
      goToPlanPageOnEdit
    );
  };

  const goToPlanPageByTitle = () => {
    setCartReturnedPageLinkClicked('plan');
    goToLastPlanSearch();
    toggleOpen();
  };

  return (
    <>
      {!isCartVariation ? <SummaryContent.Divider /> : null}

      <SummaryPackagesCardContainer isCartVariation={isCartVariation} pt={isCartVariation ? [7] : 0}>
        <CartCardsContainer isCartVariation={isCartVariation}>
          {/* Suite Card Image */}
          {isCartVariation ? (
            <CartCardImageContainer mr={3} cover={true}>
              <img src={selectedSuite.mainImage} alt={selectedSuite.headline} />
            </CartCardImageContainer>
          ) : null}

          <Flex flexDirection="column" flexGrow="1">
            <Box>
              {/* Suite Card title */}
              <SummaryContent.Item
                fontSize={0}
                fontWeight="bold"
                mb={isCartVariation ? 0 : null}
                mt={isCartVariation ? 0 : null}>
                {!isCartVariation ? (
                  <SummaryContent.Description lineHeight="16px">{suiteTitle}</SummaryContent.Description>
                ) : (
                  <CartCardTitle
                    fontSize={[0]}
                    fontWeight="bold"
                    lineHeight={[18, 18, 18, 18, 16]}
                    color="deepLakeBlue.100"
                    mb={2}
                    onClick={goToPlanPageByTitle}>
                    {selectedSuite.headline}
                  </CartCardTitle>
                )}
                {!isCartVariation ? (
                  <SummaryContent.Amount lineHeight="16px" fontWeight="bold">{`$${suiteTotal.toFixed(
                    2
                  )}`}</SummaryContent.Amount>
                ) : null}
              </SummaryContent.Item>
              {/* Guest Count */}
              <SummaryContent.Item fontSize={0} mb={isCartVariation ? 0 : null} mt={isCartVariation ? 0 : null}>
                {!isCartVariation ? (
                  <SummaryContent.Description lineHeight="18px">{guestCountText}</SummaryContent.Description>
                ) : (
                  <Text fontSize={[0]} lineHeight={[18]} fontWeight="medium" color="deepLakeBlue.100" capitalize mb={1}>
                    {guestCountText}
                  </Text>
                )}
              </SummaryContent.Item>
              {/* Dates */}
              {isCartVariation ? (
                <Text fontSize={[0]} lineHeight={[18]} fontWeight="medium" color="deepLakeBlue.100" capitalize mb={3}>
                  {editCopyByReplaceMultipleStrings(COPY.DATES, copyObjectDates)}
                </Text>
              ) : null}
              {/* Promo Code  */}
              {!isCartVariation &&
                promoCode &&
                (optimizelyChangingSavingsDisplay ? (
                  <SummaryContent.Item fontSize={0} fontWeight="bold" color="deepForestGreen.100">
                    <SummaryContent.Description lineHeight="16px">
                      <AlertSuccessIcon color={theme.colors.successGreen} size={'20.48px'} />
                      <Text pl={'5px'}>{editCopyByReplaceString(COPY.PROMO_CODE_COPY, promoCode, '<PROMOCODE>')}</Text>
                    </SummaryContent.Description>
                    <SummaryContent.Amount lineHeight="16px" fontWeight="bold">
                      {`-$${promotionalSavings.toFixed(2)}`}
                    </SummaryContent.Amount>
                  </SummaryContent.Item>
                ) : (
                  <SummaryContent.Item fontSize={0} fontWeight="bold" color="deepForestGreen.100">
                    <SummaryContent.Description lineHeight="16px">
                      {editCopyByReplaceString(COPY.PROMO_CODE_COPY, promoCode, '<PROMOCODE>')}
                    </SummaryContent.Description>
                    <AlertSuccessIcon size={iconSize} />
                  </SummaryContent.Item>
                ))}
              {!isCartVariation && !promoCode && optimizelyChangingSavingsDisplay && (
                <SummaryContent.Item fontWeight="bold">
                  <SummaryContent.Description>
                    <Text
                      lineHeight="16px"
                      color={theme.colors.deepForestGreen[100]}
                      fontWeight={theme.fontWeights.bold}
                      fontSize={theme.fontSizes[0] + 'px'}>
                      {COPY.ROOM_SAVINGS_APPLIED}
                    </Text>
                  </SummaryContent.Description>
                  <SummaryContent.Amount>
                    <Text
                      lineHeight="16px"
                      color={theme.colors.deepForestGreen[100]}
                      fontWeight={theme.fontWeights.bold}
                      fontSize={theme.fontSizes[0] + 'px'}>
                      {`-$${promotionalSavings.toFixed(2)}`}
                    </Text>
                  </SummaryContent.Amount>
                </SummaryContent.Item>
              )}
              {isCartVariation && promoCode ? (
                <Flex alignItems="center" mb={3}>
                  <CheckedIconIcon color={theme.colors.successGreen} size={16}></CheckedIconIcon>
                  <Text
                    fontSize={[0]}
                    lineHeight={[16]}
                    fontWeight="bold"
                    color="deepForestGreen.100"
                    capitalize
                    ml={2}>
                    {editCopyByReplaceString(COPY.PROMOTION_CODE, promoCode, '<PROMO>')}
                  </Text>
                </Flex>
              ) : null}
              {/* Change Suite Selection */}
              {!isCartVariation ? (
                <SummaryContent.Item>
                  <SummaryContent.Action
                    lineHeight="18px"
                    fontSize={{ sm: '10px', lg: '10px' }}
                    onClick={() => history.push(routes.plan.self)}>
                    {COPY.CHANGE_SUITE_SELECTION}
                  </SummaryContent.Action>
                </SummaryContent.Item>
              ) : null}
            </Box>
            {isCartVariation ? (
              <Flex justifyContent="space-between" flexGrow="1">
                <Flex>
                  <Box>
                    <SummaryContent.Action
                      mr={7}
                      lineHeight="16px"
                      fontSize={theme.fontSizes[0]}
                      onClick={handleRemoveSuiteModal}>
                      {COPY.REMOVE}
                    </SummaryContent.Action>
                  </Box>
                  <Box>
                    <SummaryContent.Action
                      lineHeight="16px"
                      fontSize={theme.fontSizes[0]}
                      onClick={handleEditSuiteModal}>
                      {COPY.EDIT}
                    </SummaryContent.Action>
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontSize={[1]}
                    lineHeight={[18]}
                    fontWeight="bold"
                    color="deepLakeBlue.100"
                    mb={isCartVariation ? 0 : 3}>
                    ${suiteTotal.toFixed(2)}
                  </Text>
                </Box>
              </Flex>
            ) : null}
          </Flex>
        </CartCardsContainer>
      </SummaryPackagesCardContainer>
    </>
  );
};

Details.propTypes = propTypes;
