import React from 'react';
import Select from '../ui-kit/Select';
import {
  getDropdownDataForPackage,
  shouldShowQuantitySelect
} from '../../utilities/packagesUtils';

const SelectPackageQuantity = ({
  quantitySelected,
  checkinDateSelection,
  checkoutDateSelection,
  packageItem,
  handleUpdatePackageQuantity,
  numberOfGuests,
  isNiagaraProperty,
  ...props
}) => {
  // Some package information comes in a different shape,
  // we need to map with the information and object properties we need to consume
  const pkgItem = { ...packageItem };
  pkgItem.packageCode = packageItem.packageCode || packageItem.id;
  pkgItem.quantity = packageItem.quantity || packageItem.quantityAdded;

  const isCabana = packageItem.isCabana || false;

  const shouldShowSelect = shouldShowQuantitySelect(pkgItem) && !isCabana;

  if (
    (pkgItem.showQuantitySelect === undefined ||
      pkgItem.showQuantitySelect === true) &&
    shouldShowSelect
  ) {
    const { quantity, options } = getDropdownDataForPackage(
      pkgItem,
      checkinDateSelection,
      checkoutDateSelection,
      numberOfGuests,
      isNiagaraProperty
    );

    return (
      <Select
        value={quantitySelected || quantity}
        onChange={e => handleUpdatePackageQuantity(e, pkgItem)}
        {...props}>
        {options}
      </Select>
    );
  } else {
    return null;
  }
};

export default SelectPackageQuantity;
