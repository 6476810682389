import styled from 'styled-components';
import { themeGet } from 'styled-system';
import colors from '../themes/colors';
import theme, { mediaQueries } from '../themes/theme';
export const CalendarWrapper = styled.div`
  .DayPicker {
    margin: 0 auto;
    & > div {
      box-shadow: ${theme.boxShadows[26]};
    }
  }
  .DayPicker_transitionContainer {
    height: 440px !important;

    ${mediaQueries.lg} {
      height: 591px !important;
    }
  }

  .CalendarMonth_caption {
    padding-top: 32px;
  }

  .DayPicker_weekHeader {
    top: 80px;
  }

  .DayPickerNavigation_button {
    margin-top: 8px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 50px;

    ${mediaQueries.lg} {
      right: 70px;
    }
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 50px;

    ${mediaQueries.lg} {
      left: 70px;
    }
  }

  .CalendarMonth_verticalSpacing {
    margin-top: 26px;
    border-spacing: 7px !important;
    margin-left: -4px;

    ${mediaQueries.lg} {
      margin-top: 26px;
      border-spacing: 10px !important;
      margin-left: -3px;
    }
  }

  .CalendarDay__default {
    ${mediaQueries.lg} {
      width: 50px !important;
      height: 65px !important;
      padding-top: 8px;
      padding-bottom: 24px;
    }
    width: 40px !important;
    padding-top: 0px;
    padding-bottom: 16px;
    font-weight: ${themeGet('fontWeights.medium')};
    position: relative;
    border: 1px solid transparent;
    border-radius: 6px !important;
    transition: background 0.2s;

    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${colors.deepForestGreen[50]};
      position: absolute;
      bottom: 8px;
      left: 16px;

      ${mediaQueries.lg} {
        bottom: 8px;
        left: 20px;
        width: 8px;
        height: 8px;
      }
    }

    &:hover {
      border-radius: 6px !important;
      border: 1px solid ${colors.deepLakeBlue[100]};
    }

    &:active {
      color: ${colors.deepLakeBlue[100]};
      background-color: ${colors.deepLakeBlue[20]};
    }
  }

  .CalendarDay__blocked_out_of_range {
    border: 1px solid transparent !important;
    pointer-events: none;

    &:after {
      display: none;
    }
  }

  .CalendarDay__blocked_calendar {
    color: ${colors.deepLakeBlue[50]};
    border: 1px solid transparent !important;
    pointer-events: none;

    &:after {
      content: '';
      display: inline-block;
      width: 24px;
      border-radius: 0;
      height: 1px;
      background-color: ${colors.deepLakeBlue[50]};
      position: absolute;
      top: 13px;
      left: 8px;
      transform: rotate(-45deg);

      ${mediaQueries.lg} {
        left: 13px;
        top: 21px;
        width: 26px;
      }
    }
  }

  .CalendarDay__selected {
    background: ${colors.deepLakeBlue[100]} !important;
    color: ${colors.pureWhite[0]} !important;
    border-radius: 6px;
    border: 1px solid transparent;

    .CalendarDay__selected:hover > div {
      border: none;
      background: ${colors.deepLakeBlue[100]} !important;
    }

    &:after {
      background-color: ${colors.pureWhite[0]};
    }
  }

  .CalendarDay__highlighted_calendar {
    background-color: transparent;
    pointer-events: none;
    color: ${colors.deepLakeBlue[100]};

    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${colors.deepLakeBlue[50]};
      position: absolute;
      bottom: 8px;
      top: unset;
      left: 16px;
      animation: loading 1.5s linear infinite;

      ${mediaQueries.lg} {
        left: 20px;
        width: 8px;
        height: 8px;
      }
    }
  }

  @keyframes loading {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
`;
