import { getErrorMessageByErrorType } from './utils/ResponseErrorUtils';

export default class ApiResponseError extends Error {
  constructor(errorType, err = {}, details = {}) {
    let { msg, code, validationErrors } = getErrorMessageByErrorType(
      errorType,
      err,
      details
    );

    super(msg);

    // Cannot initialize 'this' before super call
    this.errorType = errorType;
    this.err = err;
    this.details = details;
    this.code = code;
    this.validationErrors = validationErrors;
  }
}
