import colors from '../themes/colors';

export const VARIANTS = {
  YELLOW: {
    color: colors.deepLakeBlue[100],
    background: colors.digitalYellowOrange[100],
    borderColor: colors.digitalYellowOrange[100]
  },
  DARK: {
    color: colors.snowMountainGrey[50],
    background: colors.deepLakeBlue[100],
    borderColor: colors.deepLakeBlue[100]
  },
  GREEN: {
    color: colors.snowMountainGrey[50],
    background: colors.deepForestGreen[100],
    borderColor: colors.deepForestGreen[100]
  },
  LIGHT: {
    color: colors.deepLakeBlue[100],
    background: colors.kiddiePoolBlue[50],
    borderColor: colors.kiddiePoolBlue[50]
  },
  BLACK: {
    color: colors.pureWhite[0],
    background: colors.nightSkyBlack[100],
    borderColor: colors.nightSkyBlack[100]
  },
  RED: {
    color: colors.pureWhite[0],
    background: colors.errorOrange,
    borderColor: colors.errorOrange
  },
  BLUE: {
    color: colors.pureWhite[0],
    background: colors.preciousStoneBlue[100],
    borderColor: colors.preciousStoneBlue[100]
  },
  LIGHT_BLUE: {
    color: colors.deepLakeBlue[100],
    background: colors.kiddiePoolBlue[100],
    borderColor: colors.kiddiePoolBlue[100]
  },
  FOCUS_BLUE: {
    color: colors.pureWhite[0],
    background: colors.focusBlue,
    borderColor: colors.focusBlue
  },
  DEEP_ORANGE_100: {
    color: colors.pureWhite[0],
    background: colors.deepOrange[100],
    borderColor: colors.deepOrange[100]
  },
  SUCCESS_GREEN: {
    color: colors.pureWhite[0],
    background: colors.successGreen,
    borderColor: colors.successGreen
  },
  YELLOW_WHITE_FONT: {
    color: colors.pureWhite[0],
    background: colors.digitalYellowOrange[100],
    borderColor: colors.digitalYellowOrange[100]
  }
};
