import { Accordion, AccordionItem } from 'react-accessible-accordion';
import styled, { css } from 'styled-components';
import Flex from '../Flex';
import theme from '../themes/theme';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  gap: 8px;
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  background: none;
  margin: 1px 0px;
  color: #333;
  ${({ border }) =>
    border
      ? css`
          border: 1px solid ${theme.colors.deepLakeBlue[50]};
          border-radius: 8px;
        `
      : css`
          border: none;
        `}
`;

export const StyledAccordionItem = styled(AccordionItem)`
  margin: 18px 0;
`;

export const IconIMGWrapper = styled(Flex)`
  min-width: 43px;
`;

export const IconIMG = styled.img`
  height: 24px;
  width: auto !important;
`;

export const IconText = styled.div`
  align-self: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.deepLakeBlue[100]};
  padding: 8px;
  line-height: 20px;
`;
