import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Item endpoints
 *
 * @class ItemApiClient
 * @extends {GWRApiClient}
 */
export default class ItemApiClient extends GWRApiClient {
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_ITEM_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  /**
   * function returns the number of available items per day with price and taxes
   *
   * @function
   * @param {Arrival: date, Departure: date, Property: string, ItemCodes: string}
   * Example: {Arrival: 2019-10-01, Departure: 2019-10-02, Property: GURNIL, ItemCodes: LCO/CA,}
   */
  async getItemQuantity(itemQuantityRequest) {
    const urlRequest = `${this.url}/quantity`;

    let request = await super.getApiCall(urlRequest, itemQuantityRequest);

    return request;
  }
}
