import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import Flex from '../Flex';
import Text from '../Text';
import colors from '../themes/colors';

export const BUTTON_VARIATIONS = {
  LARGE: 'large',
  SMALL: 'small'
};

const LargeButtonCSS = () => {
  return css`
    width: 48px;
    height: 48px;
  `;
};

const SmallButtonCSS = () => {
  return css`
    width: 32px;
    height: 32px;
  `;
};

export const CounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: ${({ isNewWidgetStyleActive }) => (isNewWidgetStyleActive ? 'flex-end' : 'center')};
`;

export const StyledButton = styled.button`
  ${({ disabled }) => {
    if (disabled)
      return css`
        opacity: 0.6;
        pointer-events: none;
        background-color: ${({ buttonDisabledBG }) => buttonDisabledBG};
      `;
  }}
  ${({ variation }) => {
    return variation === BUTTON_VARIATIONS.LARGE ? LargeButtonCSS() : SmallButtonCSS();
  }}

  border-radius: 50%;
  border: none;
  outline-color: ${colors.focusBlue};
  outline-offset: 4px;
  transition: background-color 0.2s;
  background-color: ${({ buttonBG }) => buttonBG};
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: ${colors.deepLakeBlue[100]};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:active {
    background-color: ${colors.preciousStoneBlue[100]};

    #animation-circle {
      transform: scale(1);
      background-color: ${colors.deepLakeBlue[50]};
    }
  }
`;

export const AnimationCircle = styled.div`
  ${({ variation }) => (variation === BUTTON_VARIATIONS.LARGE ? LargeButtonCSS() : SmallButtonCSS())}
  border-radius: 50%;
  background-color: ${colors.deepLakeBlue[100]};
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: transform 0.2s, background-color 0.2s;
`;

export const DisplayText = styled(Text)`
  display: flex;
  justify-content: center;
  font-size: ${({ variation, fontSize }) => {
    return fontSize
      ? themeGet('fontSizes.' + fontSize)
      : variation === BUTTON_VARIATIONS.LARGE
      ? themeGet('fontSizes.3')
      : themeGet('fontSizes.7');
  }}px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '24px')};
  font-weight: ${themeGet('fontWeights.bold')};
  width: ${({ variation, width }) => {
    if (width) {
      return width;
    } else return variation === BUTTON_VARIATIONS.LARGE ? 65 : 56;
  }}px;
  color: ${({ color }) => (color ? color : themeGet('colors.deepLakeBlue.100'))};
`;
