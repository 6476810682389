import { number as validCreditCardNumber } from 'card-validator';
import { PHONE_MASK_REGEX } from '../../../utilities/constants';
import { errors } from '../../PaymentForm/ValidationMessages';
import unmaskValue from '../unmaskValue';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const onlyNumberRegExp = /^[0-9]+$/;
export const notOnlyWhiteSpacesRegExp = /^(?!\s*$).+/;

/**
 * Method to validate the specified length of the string
 * @param {string} input
 * @param {number} length
 * @returns {boolean}
 */
export const validateDigitsLength = (input, length) => {
  if (!input) return true;
  const digitValidation = new RegExp(`^[0-9]{${length}}$`);
  const unmaskedValue = unmaskValue(input, PHONE_MASK_REGEX);
  return digitValidation.test(unmaskedValue);
};

/**
 * Method to validate the length of the creadit card number. Is used in reservations lookups
 * Where the last numbers of the credit card are needed
 * @param {string} creditCardNumber
 * @param {number} length
 */
export const validateCreditCardLength = (creditCardNumber, length = 4) => {
  return validateDigitsLength(creditCardNumber, length);
};

/**
 * Validate if the input are only digits
 * @param {string} input
 */
export const onlyDigitsValidation = input => {
  if (!input) return true;
  const digitValidation = new RegExp(onlyNumberRegExp);
  const unmaskedValue = unmaskValue(input, PHONE_MASK_REGEX);
  return digitValidation.test(unmaskedValue);
};

/**
 * Validates if a string length is between a range. The length must be bigger than min param and less than max param
 * @param {string} input
 * @param {number} min
 * @param {number} max
 */
export const strLenIsBetweenRange = (input, min, max) => {
  if (!input) return true;
  const inputLength = input.length;
  return inputLength > min && inputLength < max;
};

/**
 * Validate a credit card number using card-validator package.
 * If the value is empty we return true so this validation can be passed to the
 * is required method, otherwise this method would be the only one running.
 * @param {string} ccNumber Credit card number
 * @return {boolean} True if the credit card number is valid, also true if is
 * empty so this could fallback to other Yup methods like .isRequired()
 */
export const validateCreditCardNumber = function(ccNumber, amexEnabled) {
  if (!ccNumber) return true;

  const ccValidTypes = ['visa', 'mastercard', 'american-express', 'discover'];

  const { isValid, card } = validCreditCardNumber(ccNumber);

  if (!isValid || !ccValidTypes.includes(card.type)) {
    return this.createError({
      message: errors.VALID_CREDIT_CARD_NUMBER
    });
  }

  if (!amexEnabled && card.type === 'american-express') {
    return this.createError({ message: errors.VALID_CREDIT_CARD_TYPE });
  }

  return true;
};
