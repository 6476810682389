import { isDevEnvironment } from '../utilities/env';

const bookingEngineAlert = [
  {
    enableBookingAlert: true,
    startNotificationDate: '01/09/2020',
    endNotificationDate: '30/09/2020',
    startSearchDate: '01/09/2020',
    endSearchDate: '30/09/2020',
    iconImage: '/content/dam/greatwolf/sites/www/global/en/story-time-icon.svg',
    iconAlt: '(1) Booking Engine Alert Icon',
    message:
      "<p><b>(1) Heads Up!</b> This alert needs your attention, but it's not super important. Learn more.\r\n"
  },
  {
    enableBookingAlert: true,
    startNotificationDate: '01/08/2020',
    endNotificationDate: '30/08/2020',
    startSearchDate: '01/08/2020',
    endSearchDate: '30/08/2020',
    iconImage: '/content/dam/greatwolf/sites/www/global/en/story-time-icon.svg',
    iconAlt: '(2) Booking Engine Alert Icon',
    message:
      "<p><b>(2) Heads Up!</b> This alert needs your attention, but it's not super important. Learn more.\r\n"
  },
  {
    enableBookingAlert: true,
    startNotificationDate: '01/08/2020',
    endNotificationDate: '30/08/2020',
    startSearchDate: '01/08/2020',
    endSearchDate: '30/08/2020',
    iconImage: '/content/dam/greatwolf/sites/www/global/en/story-time-icon.svg',
    iconAlt: '(3) Booking Engine Alert Icon',
    message:
      "<p><b>(3) Heads Up!</b> This alert needs your attention, but it's not super important. Learn more.\r\n"
  }
];

if (isDevEnvironment) {
  window.bookingEngineAlert = JSON.stringify(bookingEngineAlert);
}
