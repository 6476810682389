import PropTypes from 'prop-types';
import React from 'react';
import NotificationBanner from '../../../NotificationBanner';
import Flex from '../../../ui-kit/Flex';
import Text from '../../../ui-kit/Text';
import { DayPassContainer } from '../../assets/styles';

const SimpleSummaryHeader = ({
  notificationType,
  notificationMessage,
  showNotification,
  leftTitle = 'LT',
  leftContent = 'LC',
  rightTitle = 'RT',
  rightContent = 'RC'
}) => {
  return (
    <DayPassContainer mx={-3}>
      {showNotification ? (
        <NotificationBanner mt={0} mb={2} type={notificationType} message={notificationMessage} />
      ) : null}

      <Flex
        width={1 / 2}
        alignItems="center"
        height={showNotification ? 146 : 98}
        justifyContent={'center'}
        flexDirection="column"
        py={2}>
        <Text fontWeight="500" mb={2} fontSize={0} pt={showNotification && 60}>
          {leftTitle}
        </Text>
        <Text fontWeight="bold" color="deepLakeBlue.100" fontSize={20}>
          {leftContent}
        </Text>
      </Flex>
      <Flex
        width={1 / 2}
        alignItems="center"
        height={showNotification ? 146 : 98}
        justifyContent={'center'}
        flexDirection="column"
        py={2}>
        <Text fontWeight="500" mb={2} fontSize={0} pt={showNotification && 60}>
          {rightTitle}
        </Text>
        <Text fontWeight="bold" color="deepLakeBlue.100" fontSize={20}>
          {rightContent}
        </Text>
      </Flex>
    </DayPassContainer>
  );
};

SimpleSummaryHeader.propTypes = {
  validOn: PropTypes.string,
  amountOfDayPasses: PropTypes.number
};

export default SimpleSummaryHeader;
