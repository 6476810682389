import { PropTypes } from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import AlertSuccessIcon from '../../components/ui-kit/icons/AlertSuccessIcon';
import { Box } from '../ui-kit';
import colors from '../ui-kit/themes/colors';
import theme from '../ui-kit/themes/theme';
import { BoxIcon, NotificationBox, NotificationMessage } from './style';

/**
 * Notification banner to show error or success messages.
 * @param {Object} props
 * @param {boolean} props.type - type of notification to be shown.
 * @param {function} props.message - Message to be shown on banner.
 */

const NOTIFICATION_COLORS = {
  default: theme.colors.nightSkyBlack[60],
  warning: theme.colors.digitalYellowOrange,
  success: theme.colors.successGreen,
  error: theme.colors.errorOrange
};

const getColor = color => NOTIFICATION_COLORS[color] || NOTIFICATION_COLORS.default;

const NotificationBanner = ({ type, message }) => {
  return (
    <NotificationBox border={'1px solid ' + getColor(type)}>
      <NotificationMessage mt={0} mb={2}>
        {type === 'success' ? (
          <BoxIcon>
            <AlertSuccessIcon color={colors.successGreen} size={21} />
          </BoxIcon>
        ) : null}
        <Box>{ReactHtmlParser(message)}</Box>
      </NotificationMessage>
    </NotificationBox>
  );
};

NotificationBanner.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
};

export default NotificationBanner;
