import PropTypes from 'prop-types';
import React from 'react';

import { Content } from './styles';
import { useAccordionContext } from './SummaryAccordion';

export const SummaryAccordionContent = ({ children, variation, ...restProps }) => {
  const { expanded } = useAccordionContext();
  return (
    <Content {...restProps} expanded={expanded} variation={variation}>
      {children({ expanded: expanded })}
    </Content>
  );
};

SummaryAccordionContent.propTypes = {
  children: PropTypes.func.isRequired
};
