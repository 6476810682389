import React from 'react';

const GoogleButtonIconIcon = ({ size, ...props }) => (
  <svg {...props} viewBox="0 0 19 19" width={size} height={size}>
    <g fill-rule="nonzero" fill="none">
      <path
        d="M18.815 7.64h-.765V7.6H9.5v3.8h5.369A5.698 5.698 0 0 1 3.8 9.5a5.7 5.7 0 0 1 5.7-5.7 5.67 5.67 0 0 1 3.781 1.444l2.688-2.688A9.456 9.456 0 0 0 9.5 0a9.5 9.5 0 1 0 9.315 7.64z"
        fill="#FFC107"
      />
      <path
        d="m1.095 5.078 3.122 2.29A5.698 5.698 0 0 1 9.5 3.8a5.67 5.67 0 0 1 3.781 1.444l2.688-2.688A9.456 9.456 0 0 0 9.5 0a9.495 9.495 0 0 0-8.405 5.078z"
        fill="#FF3D00"
      />
      <path
        d="M9.5 19c2.454 0 4.684-.94 6.37-2.466l-2.941-2.488A5.657 5.657 0 0 1 9.5 15.2a5.697 5.697 0 0 1-5.36-3.774l-3.097 2.387C2.615 16.889 5.808 19 9.5 19z"
        fill="#4CAF50"
      />
      <path
        d="M18.815 7.64h-.765V7.6H9.5v3.8h5.369a5.72 5.72 0 0 1-1.941 2.646h.001l2.94 2.487C15.661 16.723 19 14.25 19 9.5c0-.637-.066-1.259-.185-1.86z"
        fill="#1976D2"
      />
    </g>
  </svg>
);

GoogleButtonIconIcon.displayName = 'GoogleButtonIconIcon';

GoogleButtonIconIcon.defaultProps = {
  size: 24
};

export default GoogleButtonIconIcon;
