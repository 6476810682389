import styled, { css } from 'styled-components';

import { Absolute, Box, Flex, Text } from '../../../../ui-kit';
import theme from '../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../Content';

export const HeaderTextMobile = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.medium};
  margin-right: 12px;
`;

export const HeaderTextTotalContainer = styled(Flex)`
  gap: ${theme.space[2]}px;
  align-items: center;
`;

export const HeaderTextTotalMobile = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.bold};
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const HeaderTextSubtotal = styled(Text)`
  font-size: ${theme.fontSizes[9]}px;
  font-weight: ${theme.fontWeights.medium};
`;

export const HeaderTextDesktop = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.bold};
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const OffersContainer = styled.div`
  background-color: ${theme.colors.springGreen[10]};
  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        padding: 24px 16px;
        margin: 0;
        width: calc(100% + 16px);
      `;
    } else {
      return css`
        padding: 0 16px 24px;
        margin: 0 -16px -24px;
      `;
    }
  }}
`;

export const OfferImageContainer = styled(Box)`
  width: 16px;
  height: 16px;
  & > img {
    width: 16px;
    height: 16px;
    vertical-align: unset !important;
  }
`;

export const CustomSummaryItemTitle = styled(SummaryContent.Item)`
  margin-bottom: 24px;
`;

export const CustomSummaryItemOffer = styled(Box)`
  & > div {
    margin-bottom: 24px;
    margin-top: 0px;
    & > div {
      margin: 0;
    }
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const PointsContainer = styled.div`
  background-color: ${theme.colors.springGreen[10]};

  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        position: relative;
        padding: 24px 16px;
        margin: 0;
        width: calc(100% + 16px);
        ::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0 auto;
          width: calc(100% - 32px);
          height: 1px;
          background-color: ${theme.colors.snowMountainGrey[100]};
        }
      `;
    } else {
      return css`
        padding: 0 16px 24px;
        margin: 0 -16px -24px;
      `;
    }
  }}
`;

/**
 * CART STYLES
 */

// Cart Logo
export const CartLogoContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  max-width: 24px;
  max-height: 24px;

  ${theme.mediaQueries.md} {
    ${({ overlayVisible }) => {
      if (overlayVisible) {
        return css`
          ::after {
            content: '';
            position: absolute;
            border-style: solid;
            border-width: 0 12px 8px;
            border-color: ${theme.colors.kiddiePoolBlue[20]} transparent;
            display: block;
            width: 0;
            z-index: 451;
            bottom: 0;
            left: 6px;
            bottom: -39px;
          }
        `;
      }
    }}
  }

  @media (min-width: 1024px) {
    max-width: 32px;
    max-height: 32px;
  }
`;

export const CartMobileSubtotalTopArrow = styled(Absolute)`
  top: ${({ arrowPosition }) => arrowPosition + 'px'};
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: ${theme.colors.kiddiePoolBlue[20]};
  z-indez: 0;
`;

export const CartBubleTopArrow = styled(Absolute)`
  top: -5px;
  right: 38px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: ${theme.colors.kiddiePoolBlue[20]};
  z-indez: 0;
`;

export const CartBubble = styled(Absolute)`
  display: flex;
  align-items: center;
  top: 40px;
  right: -30px;
  min-width: 146px;
  min-height: 36px;
  padding: 8px 16px;
  background: ${theme.colors.kiddiePoolBlue[20]};
  border-radius: 24px;
  font-weight: ${theme.fontWeights.medium};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

  span {
    position: relative;
    z-index: 1;
    color: ${theme.colors.deepLakeBlue[100]};
    font-size: ${theme.fontSizes[7]}px;
    line-height: 18px;
    font-weight: ${theme.fontWeights.bold};
  }
  label {
    display: flex;
    gap: 12px;
    position: relative;
    line-height: 20px;
    color: ${theme.colors.deepLakeBlue[100]};
    font-size: ${theme.fontSizes[7]}px;
    font-weight: ${theme.fontWeights.medium};
    z-index: 1;
  }
`;

export const CartLogoImg = styled.img`
  width: 24px;
  height: 24px;

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
  }
`;

export const CartLogoWithAnimation = styled(Absolute)`
  width: 14px;
  height: 14px;
  top: -2px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    position: absolute;
  }

  ${({ showAnimation }) => {
    if (showAnimation) {
      return css`
        top: -17.5px;
        right: -19.5px;
        width: 45px;
        height: 45px;
      `;
    }
  }}
`;

export const CartLogoCounter = styled(Box)`
  width: 14px;
  height: 14px;

  border-radius: 50%;
  background: ${theme.colors.deepOrange[100]};
`;

// Cart Overlay
export const Overlay = styled.div`
  ${({ isCartVariation, overlayVisible }) => {
    if (isCartVariation)
      return css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        left: 0;
        top: 0;
        ${'' /* Booking widget is 300 zIndex */}
        z-index: 450;
        ${overlayVisible
          ? css`
              display: block;
            `
          : css`
              display: none;
            `}
      `;
  }}
`;

export const OverlayContainer = styled.div`
  ${({ isCartVariation, overlayVisible, isDevelopment }) => {
    if (isCartVariation)
      return css`
        ${'' /* Booking widget is 300 zIndex */}
        z-index: 450;
        position: fixed;
        right: 0;
        margin-top: 8px;
        width: 100%;
        ${isDevelopment ? 'height: calc(100% - 80px);' : 'height: calc(100% - 64px);'}
       
        background-color: ${theme.colors.pureWhite[0]};
        border-top: 1px solid ${theme.colors.snowMountainGrey[100]};
        display: flex;
        flex-direction: column;
        overflow: hidden;

        ${theme.mediaQueries.md} {
          border-radius: 24px;
          position: absolute;
          height: 100%;
          width: 398px;
          height: fit-content;
          max-height: calc(100vh - 64px);
          border: 2px solid ${theme.colors.kiddiePoolBlue[20]};
          margin-top: 38px;
          box-shadow: 0px 4px 18px 5px rgba(0, 0, 0, 0.07);
          right: -35px;
          ${({ isCartEmpty }) =>
            isCartEmpty
              ? css`
                  height: 499px;
                `
              : ``}

         
        }
        ${
          overlayVisible
            ? css`
                display: flex;
              `
            : css`
                display: none;
              `
        }

        @media (min-width: 640px) and (max-width: 1024px) {
          margin-top: 5px;
        }
      `;
  }}
`;

export const StyledWave = styled(Box)`
  width: 100%;
  height: 24px;
  position: relative;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='380' height='24' viewBox='0 0 380 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 9.46479V0H380V9.52252C339.034 20.4459 235.736 35.7438 150.271 9.52252C94.587 -4.6034 37.1739 -1.90735e-06 0 9.46479Z' fill='%23CCF3F4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -2px;
`;

export const ScrollContainer = styled(Box)`
  ${({ isCartVariation, isCartEmpty }) => {
    if (isCartVariation) {
      return css`
        flex-grow: 1;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: -24px;

${isCartEmpty &&
  css`
    & > div {
      padding-right: 0px;
    }
  `}
        ${({ onPlanPage }) => {
          if (!onPlanPage) {
            return css`
              padding-bottom: 0;
            `;
          }
        }}

        ${theme.mediaQueries.md} {
          padding: 0px;
          ${({ onPlanPage }) => {
            if (!onPlanPage) {
              return css`
                padding-bottom: 0;
              `;
            }
          }};
        }
      `;
    } else {
      return css`
        & > div {
          overflow: unset;
        }
      `;
    }
  }}
`;

// Cart Overlay Empty Content
export const EmptyCartImageContainer = styled(Box)`
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
`;

// Cart Cards
export const CartCardsContainer = styled(Box)`
  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        display: flex;
        padding: 24px 0;
        border-bottom: 1px solid ${theme.colors.snowMountainGrey[100]};
      `;
    }
  }}
`;

export const CartCardTitle = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

export const CartCardImageContainer = styled(Box)`
  width: 88px;
  height: 105px;
  border: 1px solid ${theme.colors.snowMountainGrey[100]};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 73px;
    height: 88px;
  }

  ${({ cover }) => {
    return cover
      ? css`
          img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        `
      : '';
  }}

  ${({ isDiningCredit }) => {
    return isDiningCredit
      ? css`
          img {
            max-width: 64px;
            max-height: 64px;
          }
        `
      : '';
  }}
`;

export const CartPackageListItemContainer = styled(Box)`
  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        flex-grow: 1;
        & > div {
          margin-top: 0px;
          margin-bottom: 0px;
          width: auto;
          justify-content: space-between;
          align-items: unset;
          gap: 0;
          height: 100%;
        }
      `;
    }
  }}
`;

// Cart Modal
export const RemoveModifyItemTitle = styled(Text)`
  display: inline-block;
`;

export const CartModalButtonContainer = styled(Flex)`
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: ${theme.colors.snowMountainGrey[100]};
  }

  ${theme.mediaQueries.md} {
    ::before {
      content: none;
    }
  }
`;

export const CartModalButtonsContainer = styled(Flex)`
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.08);

  ${theme.mediaQueries.md} {
    border-top: 1px solid ${theme.colors.snowMountainGrey[100]};
    justify-content: end;
  }
`;

export const TotalSavingsContainer = styled(Box)`
  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        position: relative;
        padding: 24px 32px;
        margin: 0;
        width: calc(100% + 16px);
        background-color: ${theme.colors.snowMountainGrey[20]};
        & > div {
          margin: 0;
        }
      `;
    }
  }}
`;

export const AddWaterPassesSummary = styled(Flex)`
  background-color: ${theme.colors.kiddiePoolBlue[10]};
  padding: 8px 12px;
  border-radius: 8px;
  column-gap: 10px;
`;

export const AddWaterPassesPageContainer = styled(Box)`
  display: none;
  background-color: ${theme.colors.kiddiePoolBlue[10]};
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.informationBlue};
  margin: 0px 16px;
  margin-top: 33px;
`;

export const DisableAddButtonText = styled(Box)`
  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: ${theme.colors.deepLakeBlue[50]};
  }
`;

export const SummaryPackagesCardContainer = styled(Box)`
  ${({ isCartVariation }) => {
    if (isCartVariation) {
      return css`
        padding-left: 16px;
      `;
    }
  }}
`;
