import React from 'react';
import { Box, Text } from '../../../ui-kit';
import theme from '../../../ui-kit/themes/theme';

const SimpleSummaryParkingFeeWarning = ({ bookingSummaryParkingFee }) => {
  return (
    <>
      {bookingSummaryParkingFee ? (
        <Box p={'20px 0'} id="BookingSummaryParkingFee">
          <Text
            textAlign="left"
            fontWeight={theme.fontWeights.medium}
            color="deepLakeBlue.100"
            fontSize={9}
            lineHeight="16px">
            {bookingSummaryParkingFee}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default SimpleSummaryParkingFeeWarning;
