export function addMinutes(date, minutes) {
  return new Date(date.getTime() + Number(minutes) * 60000);
}
export function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const mins = Math.floor((total / 1000 / 60) % 60);
  const secs = Math.floor((total / 1000) % 60);
  const minutesT = mins < 10 ? '0' + mins : mins;
  const secondsT = secs < 10 ? '0' + secs : secs;
  return {
    total,
    minutesT,
    secondsT
  };
}
