import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isLocationAvailableWithAffirm } from '../../../../../../api/Utils/affirmUtils';
import { isValidPrice } from '../../../../../../utilities/cmpPackagesUtils';
import { SupAmount } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import { handleTotal } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { AffirmLink } from './AffirmLink';

const propTypes = {
  /** Flag to indicate if the user has packages selected */
  hasSelectedPackages: PropTypes.bool,

  /** Subtotal for the current reservation */
  summaryTotal: PropTypes.number.isRequired,

  /** loyalty points balance */
  pointsBalance: PropTypes.number,

  /** loyalty boolean to show or not the value */
  loyaltyOptIn: PropTypes.bool
};

export const Subtotal = ({
  isSuperscriptStaySummarySuite,
  hasSelectedPackages,
  summaryTotal,
  pointsBalance,
  loyaltyOptIn,
  showLoyalty,
  userOffers,
  activeOffers,
  suiteTotal,
  isUserAuthenticated,
  currentSelectedPackages,
  setExcedent,
  resortLocation,
  optimizelyHierarchyFlip
}) => {
  const [refreshAffirm, setRefreshAffirm] = useState(new Date().getTime());

  const handleSummarySubTotal = isUserAuthenticated
    ? handleTotal(
        userOffers,
        activeOffers,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        currentSelectedPackages,
        setExcedent
      ).toFixed(2)
    : summaryTotal?.toFixed(2);

  useEffect(() => {
    setRefreshAffirm(new Date().getTime());
  }, [hasSelectedPackages, summaryTotal, handleSummarySubTotal]);

  return isValidPrice(summaryTotal) ? (
    <>
      <SummaryContent.Divider />

      <SummaryContent.Item
        fontWeight={theme.fontWeights.bold}
        fontSize={optimizelyHierarchyFlip ? theme.fontSizes[0] + 'px' : ['18px', null, null, '18px']}
        mb="4px"
        lineHeight={optimizelyHierarchyFlip ? '18px' : '22px'}>
        <SummaryContent.Description>{COPY.SUBTOTAL}</SummaryContent.Description>
        <SummaryContent.SubtotalAmount fontWeight="bold">
          <SupAmount
            fontSize={optimizelyHierarchyFlip ? theme.fontSizes[0] + 'px' : 7}
            textToUse={`$${handleSummarySubTotal}`}
            avoidFormat={!isSuperscriptStaySummarySuite}
          />
        </SummaryContent.SubtotalAmount>
      </SummaryContent.Item>
      <SummaryContent.Item mb="14px" fontSize={['10px', null, null, '10px']} lineHeight="16px">
        <SummaryContent.Description lineHeight="16px" justifyContent="flex-end">
          ({COPY.TAXES_NOT_INCLUDED})
        </SummaryContent.Description>
      </SummaryContent.Item>
      {isLocationAvailableWithAffirm(resortLocation) ? (
        <SummaryContent.Item mb={`${theme.space[7]}px`}>
          <AffirmLink
            refreshAffirm={refreshAffirm}
            summaryTotal={handleSummarySubTotal}
            optimizelyHierarchyFlip={optimizelyHierarchyFlip}
            resortLocation={resortLocation}
          />
        </SummaryContent.Item>
      ) : null}
    </>
  ) : null;
};
Subtotal.propTypes = propTypes;
Subtotal.defaultProps = {
  hasSelectedPackages: false,
  pointsBalance: 0,
  loyaltyOptIn: false
};
