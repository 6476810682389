import { ASSETS_LOCATIONS, getAssetsUrl } from '../../../utilities/constants';

export const DINING_CREDIT = {
  50: getAssetsUrl(ASSETS_LOCATIONS.SVG, '50.svg'),
  100: getAssetsUrl(ASSETS_LOCATIONS.SVG, '100.svg'),
  150: getAssetsUrl(ASSETS_LOCATIONS.SVG, '150.svg'),
  300: getAssetsUrl(ASSETS_LOCATIONS.SVG, '300.svg'),
  star: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'star.svg'),
  rArrow: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'core-icons-arrows-caret-right.svg'),
  greyDiningImage: getAssetsUrl(ASSETS_LOCATIONS.WEBP, 'dining-illustrations@3x.webp'),
  yellowDiningImage: getAssetsUrl(ASSETS_LOCATIONS.WEBP, 'dining-illustrations-2@3x.webp'),
  big50: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'FlatIllustrations.svg'),
  big100: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'FlatIllustrations2.svg'),
  big150: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'FlatIllustrations3.svg'),
  big300: getAssetsUrl(ASSETS_LOCATIONS.SVG, 'FlatIllustrations3.svg')
};

export const DINING_CREDIT_COINS = [50, 100, 150, 300];

export const MODAL_CREDIT_CARDS_IMAGE = getAssetsUrl(ASSETS_LOCATIONS.SVG, 'icon-dining-type.svg');

export const MODAL_RESTAURANTS_CARDS_IMAGE = getAssetsUrl(ASSETS_LOCATIONS.SVG, 'icon-dining-restaurant.svg');

export const MODAL_CREDIT_CARDS_IMAGE_MOBILE = getAssetsUrl(ASSETS_LOCATIONS.SVG, 'icon-dining-type-mobile.svg');

export const MODAL_RESTAURANTS_CARDS_IMAGE_MOBILE = getAssetsUrl(
  ASSETS_LOCATIONS.SVG,
  'icon-dining-restaurant-mobile.svg'
);
