// Rebrand 2022
// Primary Colors
const kiddiePoolBlue = {
  100: '#00C3C8',
  50: '#7FE1E3',
  20: '#CCF3F4',
  10: '#E5F9F9',
  colorName: 'Kiddie Pool Blue',
  colorCategory: 'Primary'
};
const preciousStoneBlue = {
  100: '#008489',
  50: '#7FC1C4',
  20: '#CCE6E7',
  10: '#E5F2F3',
  colorName: 'Precious Stone Blue',
  colorCategory: 'Primary'
};
const deepLakeBlue = {
  100: '#1C3750',
  80: '#436075',
  50: '#8D9BA7',
  40: '#A4AFB9',
  20: '#D2D7DC',
  10: '#E8EBED',
  colorName: 'Deep Lake Blue',
  colorCategory: 'Primary'
};
const digitalBrightRed = {
  100: '#CF0000',
  50: '#E47C7C',
  20: '#F0C7C7',
  10: '#F5E0E0',
  colorName: 'Digital Bright Red',
  colorCategory: 'Primary'
};
const deepForestGreen = {
  100: '#005C3C',
  70: '#4C8D76',
  50: '#7FAD9D',
  20: '#CCDED8',
  10: '#E5EEEB',
  colorName: 'Deep Forest Green',
  colorCategory: 'Primary'
};
const springGreen = {
  100: '#00DC81',
  50: '#7FEDC0',
  20: '#CCF8E6',
  10: '#E5FBF2',
  colorName: 'Spring Green',
  colorCategory: 'Primary'
};
const sandstoneBuff = {
  100: '#F2D997',
  50: '#F8ECCB',
  20: '#FCF7EA',
  colorName: 'Sandstone Buff',
  colorCategory: 'Primary'
};

// Secondary Colors
const deepPurple = {
  100: '#450C3E',
  50: '#A2859E',
  20: '#DACED8',
  colorName: 'Deep Purple',
  colorCategory: 'Secondary'
};
const lightPurple = {
  100: '#D45DD0',
  50: '#E9AEE7',
  20: '#F6DFF6',
  colorName: 'Light Purple',
  colorCategory: 'Secondary'
};
const magenta = {
  100: '#E80065',
  50: '#F37FB2',
  20: '#FACCE0',
  colorName: 'Magenta',
  colorCategory: 'Secondary'
};
const pink = {
  100: '#FFC2D0',
  50: '#FFE0E7',
  20: '#FFF3F6',
  colorName: 'Pink',
  colorCategory: 'Secondary'
};
const woodpeckerRed = {
  100: '#980000',
  50: '#CB7F7F',
  20: '#EACCCC',
  colorName: 'Woodpecker Red',
  colorCategory: 'Secondary'
};
const deepOrange = {
  100: '#FF6229',
  50: '#FFB094',
  20: '#FFE0D4',
  colorName: 'Deep Orange',
  colorCategory: 'Secondary'
};
const digitalYellowOrange = {
  100: '#F4B615',
  50: '#F9DA8A',
  20: '#FDF0D0',
  colorName: 'Digital Yellow Orange',
  colorCategory: 'Secondary'
};

// Neutral Colors
const nightSkyBlack = {
  100: '#000000',
  80: '#333333',
  60: '#666666',
  colorName: 'Night Sky Black',
  colorCategory: 'Neutral'
};
const snowMountainGrey = {
  100: '#E4EBEE',
  50: '#F1F5F6',
  20: '#FAFBFC',
  colorName: 'Snow Mountain Grey',
  colorCategory: 'Neutral'
};
const pureWhite = {
  0: '#FFFFFF',
  colorName: 'Pure White',
  colorCategory: 'Neutral'
};

// System Colors
const informationBlue = '#4EB9D8';
const successGreen = '#159F7E';
const errorOrange = '#B33F1C';
const focusBlue = '#005BD4';

// Special Custom Colors
const mysteryOffer = {
  woodBrown: '#642d04',
  darkPreciousStoneBlue: '#125e78',
  brightYellow: '#f1e807',
  borderColor: '#193c5f',
  headlineColor: '#042340'
};

const voyagersColors = {
  100: '#008903'
};

const voyagersGreen = '#0D8B44';
const deepForestGreenBorder = `${deepForestGreen[50]}20`;
const blueGradient = `radial-gradient(circle at -21% -28%, ${preciousStoneBlue[100]}, ${deepLakeBlue[100]} 102%)`;
const darkPreciousStoneBlue = '#00676b';
const emailCaptureBg = '#FBFCF9';

const loyaltyGreen = '#009a44';
const loyaltyBackground = '#008903';
const loyaltyHoverBackground = '#006D02';
const affirmBlue = '#4A4AF4';

const colors = {
  // Primary
  kiddiePoolBlue,
  preciousStoneBlue,
  deepLakeBlue,
  digitalBrightRed,
  deepForestGreen,
  springGreen,
  sandstoneBuff,
  // Secondary
  deepPurple,
  lightPurple,
  magenta,
  pink,
  woodpeckerRed,
  deepOrange,
  digitalYellowOrange,
  // Neutrals
  snowMountainGrey,
  nightSkyBlack,
  pureWhite,
  // System Colors
  informationBlue,
  successGreen,
  errorOrange,
  focusBlue,
  mysteryOffer,
  deepForestGreenBorder,
  blueGradient,
  darkPreciousStoneBlue,
  emailCaptureBg,
  loyaltyGreen,
  loyaltyBackground,
  loyaltyHoverBackground,
  affirmBlue,
  voyagersColors,
  voyagersGreen
};

export default colors;
