import React from 'react'

const CloseCircleIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 40 40'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M19.88363,0 C8.92010234,0 0,8.9270985 0,19.9009312 C0,30.874764 8.92010234,39.8018625 19.88363,39.8018625 C30.8471578,39.8018625 39.7672601,30.874764 39.7672601,19.9009312 C39.7672601,8.9270985 30.8471578,0 19.88363,0 Z M19.88363,38.2710216 C9.76362711,38.2710216 1.52951,30.0297398 1.52951,19.9009312 C1.52951,9.77212266 9.76362711,1.53084087 19.88363,1.53084087 C30.003633,1.53084087 38.2377501,9.77212266 38.2377501,19.9009312 C38.2377501,30.0297398 30.003633,38.2710216 19.88363,38.2710216 Z M27.3071069,12.4709951 C27.0080876,12.1717157 26.5247625,12.1717157 26.2257433,12.4709951 L19.88363,18.8186268 L13.5415168,12.4709951 C13.2424976,12.1717157 12.7591725,12.1717157 12.4601532,12.4709951 C12.161134,12.7702745 12.161134,13.2540202 12.4601532,13.5532996 L18.8022665,19.9009312 L12.4601532,26.2485629 C12.161134,26.5478423 12.161134,27.031588 12.4601532,27.3308674 C12.6092805,27.4801244 12.8050578,27.5551356 13.000835,27.5551356 C13.1966123,27.5551356 13.3923896,27.4801244 13.5415168,27.3308674 L19.88363,20.9832357 L26.2257433,27.3308674 C26.3748705,27.4801244 26.5706478,27.5551356 26.7664251,27.5551356 C26.9622023,27.5551356 27.1579796,27.4801244 27.3071069,27.3308674 C27.6061261,27.031588 27.6061261,26.5478423 27.3071069,26.2485629 L20.9649936,19.9009312 L27.3071069,13.5532996 C27.6061261,13.2540202 27.6061261,12.7702745 27.3071069,12.4709951 Z' />
  </svg>
)

CloseCircleIcon.displayName = 'CloseCircleIcon'

CloseCircleIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CloseCircleIcon