import React from 'react'

const CarouselChevronRigthThinIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 551.13 551.13'
    width={size}
    height={size}
    fill={color}
  >
    <path d='m361.679 275.565-223.896 223.897v51.668l275.565-275.565-275.565-275.565v51.668z' />
  </svg>
)

CarouselChevronRigthThinIcon.displayName = 'CarouselChevronRigthThinIcon'

CarouselChevronRigthThinIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CarouselChevronRigthThinIcon