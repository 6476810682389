export const Environments = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test'
};

const _isDevEnvironment = () => {
  return process.env.NODE_ENV === Environments.DEVELOPMENT;
};

const _isProductionEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === Environments.PRODUCTION;
};

const _isTestEnvironment = () => {
  return process.env.NODE_ENV === Environments.TEST;
};

export const isDevEnvironment = _isDevEnvironment();
export const isProdEnvironment = _isProductionEnvironment();
export const isTestEnvironment = _isTestEnvironment();
