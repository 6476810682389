import styled from 'styled-components';
import {
  space,
  width,
  color,
  alignItems,
  justifyContent,
  flexWrap,
  border,
  flexDirection,
  propTypes,
  alignSelf,
  borderTop,
  borderBottom,
  minWidth,
  maxWidth,
  minHeight,
  flex,
  background,
  borderRadius,
  overflow,
  boxShadow,
  style,
  height
} from 'styled-system';
import theme from './themes/theme';

const flexGrow = style({
  prop: 'flexGrow',
  cssProperty: 'flex-grow'
});

const position = style({
  prop: 'position',
  cssProperty: 'position'
});

const Flex = styled.div`
  display:flex;
  gap: ${({ gap }) => `${gap}`};
  ${space} ${width} ${color} ${overflow} ${alignItems} ${justifyContent} ${flexWrap} ${flexDirection} ${border} ${alignSelf} ${minHeight} ${minWidth} ${maxWidth} ${flex} ${background} ${flexGrow} ${borderRadius} ${borderTop} ${borderBottom} ${boxShadow} ${height} ${position} ;
`;

Flex.defaultProps = {
  theme
};

Flex.propTypes = {
  ...propTypes.space,
  ...propTypes.width,
  ...propTypes.color,
  ...propTypes.alignItems,
  ...propTypes.justifyContent,
  ...propTypes.flexWrap,
  ...propTypes.flexDirection,
  ...propTypes.border,
  ...propTypes.borderTop,
  ...propTypes.borderBottom,
  ...propTypes.alignSelf,
  ...propTypes.minWidth,
  ...propTypes.maxWidth,
  ...propTypes.minHeight,
  ...propTypes.flex,
  ...propTypes.background,
  ...propTypes.borderRadius,
  ...boxShadow.propTypes,
  ...height.propTypes
};

Flex.displayName = 'Flex';

export default Flex;
