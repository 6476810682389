import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemState
} from 'react-accessible-accordion';
import MediaQuery from 'react-responsive';

import Absolute from '../../ui-kit/Absolute';
import { Box, Flex } from '../../ui-kit/index';
import Relative from '../../ui-kit/Relative';
import Text from '../../ui-kit/Text';
import theme from '../../ui-kit/themes/theme';
import { Wrapper } from '../assets/styles';
import { SummaryAccordionIcon } from '../assets/SummaryStyles';

const SummaryAccordionIconWrapper = ({ size }) => (
  <SummaryAccordionIcon className="accordion__arrow" size={size} color="deepLakeBlue.100" />
);

const DesktopLayout = ({ title, isExpanded }) => (
  <Flex
    borderRadius={[!isExpanded ? '0 0 25px 25px' : '0', null, null, '25px 25px 0 0']}
    mx={-3}
    pl={3}
    py={3}
    bg={theme.colors.kiddiePoolBlue[20]}>
    <Text textAlign="left" fontSize={1} py={1} color="deepLakeBlue.100" fontWeight="bold">
      {title}
    </Text>
    <Absolute right={3} alignSelf="center">
      <SummaryAccordionIconWrapper size={14} />
    </Absolute>
  </Flex>
);
const isDayPass = window.location.pathname.includes('/daypass/');

const MobileLayout = ({ title, isOnPaymentPage, total }) => (
  <Flex
    flexDirection="row"
    p={3}
    mx="-10px"
    bg={theme.colors.kiddiePoolBlue[20]}
    alignItems="center"
    justifyContent="space-between">
    <Flex>
      <Text textAlign="left" fontSize={1} py={1} color="deepLakeBlue.100" fontWeight="medium">
        {title}:
      </Text>
      <Flex alignItems="center" ml={2}>
        <Text fontSize={1} color="deepLakeBlue.100" fontWeight="bold">
          ${total.toFixed(2)}
        </Text>
        <Text fontSize={9} fontWeight="500" ml={2}>
          {isOnPaymentPage || isDayPass ? '(Total)' : '(Subtotal)'}
        </Text>
      </Flex>
    </Flex>

    <Box>
      <SummaryAccordionIconWrapper size={20} />
    </Box>
  </Flex>
);

const SummaryAccordion = ({ children, total, shouldStartCollapsed, onChange, isOnPaymentPage, title }) => {
  const [isExpanded, setIsExpanded] = useState(!shouldStartCollapsed);
  return (
    <MediaQuery minWidth={theme.breakpoints.lg}>
      {isDesktop => {
        return (
          <Wrapper pb={0} px={[null, null, null, 3]} applyBorder={isDesktop}>
            <Accordion
              allowZeroExpanded={true}
              preExpanded={!isDesktop && shouldStartCollapsed ? [] : ['itemsList']}
              onChange={onChange ? onChange : null}
              className="">
              <AccordionItem uuid={'itemsList'}>
                <AccordionItemHeading>
                  <AccordionItemButton style={{ cursor: 'pointer' }}>
                    <Relative>
                      {isDesktop ? (
                        <DesktopLayout title={title} isExpanded={isExpanded} />
                      ) : (
                        <MobileLayout isOnPaymentPage={isOnPaymentPage} title={title} total={total} />
                      )}
                    </Relative>
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemState>
                  {({ expanded }) => {
                    setIsExpanded(expanded);
                    return children({ expanded, isDesktop });
                  }}
                </AccordionItemState>
              </AccordionItem>
            </Accordion>
          </Wrapper>
        );
      }}
    </MediaQuery>
  );
};

SummaryAccordion.propTypes = {
  total: PropTypes.number,
  shouldStartCollapsed: PropTypes.bool,
  onChange: PropTypes.func,
  isOnPaymentPage: PropTypes.bool
};

SummaryAccordion.defaultProps = {
  total: 0,
  shouldStartCollapsed: true,
  onChange: () => {},
  isOnPaymentPage: false
};

export default SummaryAccordion;
