import axios from 'axios';
import Lottie from 'lottie-web';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import Box from '../Box';

const propTypes = {
  /** Animation path */
  src: PropTypes.string,
  /** Object with the exported animation data. Use this when file is hosted locally */
  animationData: PropTypes.object,
  /** If the animation should loop or not */
  loop: PropTypes.bool,
  /** Play the animation as soon it is ready */
  autoplay: PropTypes.bool,
  /** Animation name for future reference */
  name: PropTypes.string,
  /** Called when the animation finishes loading */
  onLoad: PropTypes.func,
  /** Aria label to improve accessibility */
  label: PropTypes.string
};

const defaultProps = {
  src: null,
  animationData: null,
  loop: true,
  autoplay: true,
  name: null,
  onLoad: null,
  label: null
};

/**
  `Animation` component is used to render `JSON`animations and still have control
   over them. It is a `lottie-web` wrapper, for more details check their documentation 
  https://github.com/airbnb/lottie-web
*/
const Animation = ({ src, onLoad, animationData, loop, autoplay, name, label, ...otherProps }) => {
  const container = useRef();

  const getJSON = useCallback(async () => {
    const res = await axios.get(src).catch(function(error) {
      console.log('ANIMATION ERROR - failed to load animation content from', src);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Data', error.response.data);
        console.log('Status', error.response.status);
        console.log('Headers', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('Request', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Message', error.message);
      }
      return false;
    });

    return await res.data;
  }, [src]);

  const loadAnimation = useCallback(async () => {
    try {
      const data = src ? (await getJSON()) || animationData : animationData;

      const animation = Lottie.loadAnimation({
        container: container.current,
        animationData: data,
        loop,
        autoplay,
        name
      });

      if (onLoad) {
        onLoad(animation);
      }

      return animation;
    } catch (error) {
      console.error('[Error] Animation - Failed to load animation', error);
    }
  }, [animationData, loop, autoplay, name, onLoad, getJSON, src]);

  useEffect(() => {
    loadAnimation();
  }, [loadAnimation]);

  return <Box aria-label={label} {...otherProps} ref={container} />;
};

Animation.defaultProps = defaultProps;

Animation.propTypes = propTypes;

export default Animation;
