import { isDevEnvironment } from '../utilities/env';

// const birthdayPartyTimesAndLocationsJson = [
//   {
//     property: 'ATLAGA',
//     timesAndLocations: [
//       {
//         value: 0,
//         name: '11:30 am to 1:00 pm in a reserved area outside of the water park'
//       },
//       {
//         value: 1,
//         name: '1:30 pm to 3:00 pm in a reserved area outside of the water park'
//       },
//       {
//         value: 2,
//         name: '3:30 pm to 5:00 pm in a reserved area outside of the water park'
//       }
//     ]
//   },
//   {
//     property: 'BLOOMN',
//     timesAndLocations: [
//       { value: 0, name: '12:30 to 1:30 pm at Campfire Kitchen' },
//       { value: 1, name: '2:00 to 3:00 pm at Campfire Kitchen' },
//       { value: 2, name: '3:30 to 4:30 pm at Campfire Kitchen' },
//       {
//         value: 3,
//         name: '4:00 to 5:00 pm at Buckets Incredible Craveables'
//       },
//       {
//         value: 4,
//         name: '6:00 to 7:00 pm at Buckets Incredible Craveables'
//       },
//       {
//         value: 5,
//         name: '7:30 to 8:30 pm at Buckets Incredible Craveables'
//       }
//     ]
//   },
//   {
//     property: 'COLOCO',
//     timesAndLocations: [
//       { value: 0, name: '11:30 am at Hungry As A Wolf' },
//       { value: 1, name: '1:30 pm at Hungry As A Wolf' },
//       { value: 2, name: '3:30 pm at Hungry As A Wolf' },
//       { value: 3, name: '5:30 pm at Hungry As A Wolf' }
//     ]
//   },
//   {
//     property: 'CONCNC',
//     timesAndLocations: [
//       {
//         value: 0,
//         name: '12:30 pm to 2:00 pm at The Loose Moose Cottage'
//       },
//       { value: 1, name: '2:30 pm to 4:00 pm at The Loose Moose Cottage' }
//     ]
//   },
//   {
//     property: 'DELLWI',
//     timesAndLocations: [
//       { value: 0, name: '11 a.m. to 12:30 pm in the water park' },
//       { value: 1, name: '1 to 2:30 pm in the water park' },
//       { value: 2, name: '3 to 4:30 pm in the water park' },
//       { value: 3, name: '5 to 6:30 pm in the water park' },
//       { value: 4, name: '7 to 8:30 pm in the water park' }
//     ]
//   },
//   {
//     property: 'GRANWA',
//     timesAndLocations: [
//       { value: 0, name: '1 to 2:30 pm at The Water Park Mezzanine' },
//       { value: 1, name: '2 to 3:30 pm at The Water Park Mezzanine' },
//       { value: 2, name: '3 to 4:30 pm at The Water Park Mezzanine' }
//     ]
//   },
//   {
//     property: 'GRAPTX',
//     timesAndLocations: [
//       { value: 0, name: '12:30 to 1:30 pm at The Loose Moose Cottage' },
//       { value: 1, name: '2:30 to 4:00 pm at The Loose Moose Cottage' }
//     ]
//   },
//   {
//     property: 'GURNIL',
//     timesAndLocations: [
//       { value: 0, name: '11:00 am to 12:30 pm in Campfire Kitchen' },
//       { value: 1, name: '1:00 pm to 2:30 pm in Campfire Kitchen' },
//       { value: 2, name: '3:00 pm to 4:30 pm in Campfire Kitchen' },
//       { value: 3, name: '5:00 pm to 6:30 pm in Campfire Kitchen' },
//       { value: 4, name: '7:00 pm to 8:30 pm in Campfire Kitchen' }
//     ]
//   },
//   {
//     property: 'KANSKS',
//     timesAndLocations: [
//       {
//         value: 0,
//         name: '12:00 pm to 1:30 pm at Camp Critter Bar & Grille'
//       },
//       {
//         value: 1,
//         name: '2:00 pm to 3:30 pm at Camp Critter Bar & Grille'
//       },
//       {
//         value: 2,
//         name: '4:00 pm to 5:30 pm at Camp Critter Bar & Grille'
//       }
//     ]
//   },
//   {
//     property: 'MASOOH',
//     timesAndLocations: [
//       { value: 0, name: '12:00 pm at Loose Moose' },
//       { value: 1, name: '1:00 pm at Loose Moose' },
//       { value: 2, name: '2:00 pm at Loose Moose' },
//       { value: 3, name: '3:00 pm at Loose Moose' },
//       { value: 4, name: '4:00 pm at Loose Moose' }
//     ]
//   },
//   {
//     property: 'NEWEMA',
//     timesAndLocations: [
//       { value: 0, name: '12:30 to 2:00 pm at Lodge Wood Fired Grill' },
//       { value: 1, name: '2:30 to 4:00 pm at Lodge Wood Fired Grill' }
//     ]
//   },
//   {
//     property: 'NIAGON',
//     timesAndLocations: [{ value: 0, name: 'Unknown' }]
//   },
//   {
//     property: 'POCOPA',
//     timesAndLocations: [
//       {
//         value: 0,
//         name:
//           '1:00 to 2:00 pm Bear Den (located inside The Loose Moose Cottage) or Northwest Territory'
//       },
//       {
//         value: 1,
//         name:
//           '2:00 to 3:00 pm Bear Den (located inside The Loose Moose Cottage) or Northwest Territory'
//       },
//       {
//         value: 2,
//         name:
//           '3:00 to 4:00 pm Bear Den (located inside The Loose Moose Cottage) or Northwest Territory'
//       }
//     ]
//   },
//   {
//     property: 'SANDOH',
//     timesAndLocations: [
//       { value: 0, name: '12:00 to 1:00 pm at Gitchigoomie Grill' },
//       { value: 1, name: '1:00 to 2:00 pm at Gitchigoomie Grill' },
//       { value: 2, name: '2:00 to 3:00 pm at Gitchigoomie Grill' },
//       { value: 3, name: '4:00 to 5:00 pm at Gitchigoomie Grill' }
//     ]
//   },
//   {
//     property: 'SCOTAZ',
//     timesAndLocations: [{ value: 0, name: 'Unknown' }]
//   },
//   {
//     property: 'SOUTCA',
//     timesAndLocations: [{ value: 0, name: 'Unknown' }]
//   },
//   {
//     property: 'TRAVMI',
//     timesAndLocations: [
//       {
//         value: 0,
//         name: '11:00 am to 12:30 pm in the Water Park Birthday Party Area'
//       },
//       {
//         value: 1,
//         name: '1:00 pm to 2:30 pm in the Water Park Birthday Party Area'
//       },
//       {
//         value: 2,
//         name: '3:00 pm to 4:30 pm in the Water Park Birthday Party Area'
//       },
//       {
//         value: 3,
//         name: '5:00 pm to 6:30 pm in the Water Park Birthday Party Area'
//       }
//     ]
//   },
//   {
//     property: 'WILLVA',
//     timesAndLocations: [
//       { value: 0, name: '12:00 pm to 1:30 pm in the restaurant' },
//       { value: 1, name: '12:30 pm to 2:00 pm in the restaurant' },
//       { value: 2, name: '1:00 pm to 2:30 pm in the restaurant' },
//       { value: 3, name: '1:30 pm to 3:00 pm in the restaurant' },
//       { value: 4, name: '2:00 pm to 3:30 pm in the restaurant' },
//       { value: 5, name: '2:30 pm to 4:00 pm in the restaurant' },
//       { value: 6, name: '3:00 pm to 4:30 pm in the restaurant' }
//     ]
//   }
// ];

const birthdayPartyTimesAndLocationsJson = [
  {
    name: '04:00 PM to 05:30 PM at Campfire Kitchen',
    value: 0
  },
  {
    name: '06:00 PM to 07:30 PM at Campfire Kitchen',
    value: 1
  },
  {
    name: '08:00 PM to 09:30 PM at Campfire Kitchen',
    value: 2
  },
  {
    name: '10:00 PM to 11:30 PM at Campfire Kitchen',
    value: 3
  },
  {
    name: '12:00 AM to 01:30 AM at Campfire Kitchen',
    value: 4
  }
];

if (isDevEnvironment) {
  window.birthdayPartyTimesAndLocationsJson = JSON.stringify(
    birthdayPartyTimesAndLocationsJson
  );
}
