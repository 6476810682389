import debounce from 'lodash-es/debounce';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ScrollableContainer } from './styles';

/**
 * Use the `Scrollable` component to add a custom cross-browser
 * scrollbar that is visible just when the use is scrolling.
 */
const Scrollable = ({
  maxHeight,
  scrollColor,
  showTrack = false,
  trackColor,
  isInset = false,
  children,
  className = null,
  verticalPadding = '28px',
  hideScrollWhileScrolling = false
}) => {
  const [isScrolling, setScrolling] = useState();

  const onStopScrolling = debounce(() => {
    if (isScrolling) {
      setScrolling(false);
    }
  }, 300);

  const onScroll = () => {
    if (!isScrolling) {
      setScrolling(true);
    }

    onStopScrolling();
  };

  return (
    <ScrollableContainer
      className={className}
      verticalPadding={verticalPadding}
      maxHeight={maxHeight}
      isScrolling={isScrolling}
      showTrack={showTrack}
      isInset={isInset}
      scrollColor={scrollColor}
      trackColor={trackColor}
      onScroll={onScroll}
      hideScrollWhileScrolling={hideScrollWhileScrolling}>
      {children}
    </ScrollableContainer>
  );
};

Scrollable.propTypes = {
  showTrack: PropTypes.bool,
  isInset: PropTypes.bool,
  scrollColor: PropTypes.string,
  trackColor: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  verticalPadding: PropTypes.string
};

export default Scrollable;
