/**
 * Removes mask from string based aon a provided Regular Expression.
 * @param {string} str string to be manipulated.
 * @param {Object} mask Regular expression of characters to be removed from
 * string.
 * @return {string} Unmasked string.
 */
const unmaskValue = (str, mask) => {
  return str.replace(mask, '').trim();
};

export default unmaskValue;
