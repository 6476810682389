import { EVENT_ACTIONS, logSuiteCheckoutError, logSuitePlanSearchError } from '../../../utilities/analytics';

export const gaTrackingCheckoutErrorCode = errorCode => {
  const cleanErrorCode = errorCode.toUpperCase().replace(/\s/g, '');
  if (cleanErrorCode.includes('RATE_CODE_NOT_AVAILABLE')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutRateCodeUnavailableError);
    return;
  } else if (cleanErrorCode.includes('RATE_UNAVAILABLE')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutRateUnavailableError);
    return;
  } else if (cleanErrorCode.includes('RATES_MISMATCH')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutRateMismatchError);
    return;
  } else if (cleanErrorCode.includes('ROOM_UNAVAILABLE')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutRoomUnavailableError);
    return;
  } else if (cleanErrorCode.includes('ROOM_RESTRICTED')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutRoomRestrictedError);
    return;
  } else if (cleanErrorCode.includes('PROPERTY_RESTRICTED')) {
    logSuiteCheckoutError(EVENT_ACTIONS.suiteCheckoutPropertyRestrictedError);
    return;
  }
};

export const gaTrackingPlanSearchErrorCode = errorCode => {
  const cleanErrorCode = errorCode.toUpperCase().replace(/\s/g, '');
  if (cleanErrorCode.includes('INACTIVE_OFFER_CODE')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchInactiveOfferCodeError);
    return;
  } else if (cleanErrorCode.includes('INVALID_OFFER_CODE')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchInvalidOfferCodeError);
    return;
  } else if (cleanErrorCode.includes('MAXIMUM_ADVANCED_BOOKING')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchMaximumAdvanceBookingError);
    return;
  } else if (cleanErrorCode.includes('MINIMUM_ADVANCED_BOOKING')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchMinimumAdvanceBookingError);
    return;
  } else if (cleanErrorCode.includes('MIN_STAY')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchMinStayError);
    return;
  } else if (cleanErrorCode.includes('MAX_STAY')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchMaxStayError);
    return;
  } else if (cleanErrorCode.includes('INACTIVE_PROMO')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchInactivePromoError);
    return;
  } else if (cleanErrorCode.includes('ROOM_UNAVAILABLE')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchRoomUnavailableError);
    return;
  } else if (cleanErrorCode.includes('ROOM_RESTRICTED')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchRoomRestrictedError);
    return;
  } else if (cleanErrorCode.includes('RATE_CODE_NOT_AVAILABLE')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchRateCodeNotAvailableError);
    return;
  } else if (cleanErrorCode.includes('RATES_MISMATCH')) {
    logSuitePlanSearchError(EVENT_ACTIONS.suitePlanSearchRatesMismatchError);
    return;
  }
};
