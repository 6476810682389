import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '../../../../../ui-kit';
import * as COPY from '../utilities/copy';

import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Flag that indicates that the reservation has discount charges */
  hasDiscountCharges: PropTypes.bool.isRequired,

  /** Vyager points for reservation*/
  voyagerPointsDiscount: PropTypes.number,

  /** Offers points for reservation*/
  offersPointsDiscount: PropTypes.number
};

export const DiscountCharges = ({ hasDiscountCharges, voyagerPointsDiscount, offersPointsDiscount }) => {
  if (!hasDiscountCharges) return null;

  return (
    <>
      <SummaryContent.Divider />
      <SummaryContent.Item fontWeight="bold" mb="24px">
        <SummaryContent.Description>
          <Text fontSize={0} lineHeight={16}>
            {COPY.SUMMARY_DISCOUNT_CHANGES}
          </Text>
        </SummaryContent.Description>
      </SummaryContent.Item>

      {voyagerPointsDiscount !== 0 && (
        <SummaryContent.Item>
          <SummaryContent.Description>
            <Text fontSize={0} lineHeight={18}>
              {COPY.SUMMARY_VOYAGERS_POINTS_APPLIED}
            </Text>
          </SummaryContent.Description>
          <SummaryContent.Amount>
            <Text fontSize={0} lineHeight={18}>{`$${voyagerPointsDiscount.toFixed(2)}`}</Text>
          </SummaryContent.Amount>
        </SummaryContent.Item>
      )}

      {offersPointsDiscount !== 0 && (
        <SummaryContent.Item>
          <SummaryContent.Description>
            <Text fontSize={0} lineHeight={18}>
              {COPY.SUMMARY_BONNUS_DEALS_APPLIED}
            </Text>
          </SummaryContent.Description>
          <SummaryContent.Amount>
            <Text fontSize={0} lineHeight={18}>{`$${offersPointsDiscount.toFixed(2)}`}</Text>
          </SummaryContent.Amount>
        </SummaryContent.Item>
      )}
    </>
  );
};

DiscountCharges.propTypes = propTypes;

DiscountCharges.defaultProps = {
  hasDiscountCharges: false,
  voyagerPointsDiscount: 0,
  offersPointsDiscount: 0
};
