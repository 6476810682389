import { lazy } from 'react';
import withGlobalModal from './withGlobalModal';

export const MODAL_KEYS = {
  MYSTERY_OFFER_SPINNER: 'MYSTERY_OFFER_SPINNER',
  INFO_CARD_DETAILS_MODAL: 'INFO_CARD_DETAILS_MODAL',
  FIND_RESERVATION_BY_ID: 'FIND_RESERVATION_BY_ID',
  AUTH_MODAL: 'AUTH_MODAL',
  LEAD_GEN_MODAL: 'LEAD_GEN_MODAL',
  IE_MODAL: 'IE_MODAL',
  OFFER_CODE_ERROR_TOAST: 'OFFER_CODE_ERROR_TOAST',
  PACKAGE_MODAL: 'PACKAGE_MODAL',
  SMS_SIGN_UP_MODAL: 'SMS_SIGN_UP_MODAL',
  SPECIAL_REQUEST_MODAL: 'SPECIAL_REQUEST_MODAL',
  SUITE_PRICE_MISMATCH: 'SUITE_PRICE_MISMATCH',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  OFFERS_MODAL: 'OFFERS_MODAL',
  CONFETTI_MODAL: 'CONFETTI_MODAL',
  DINING_CREDIT_MODAL: 'DINING_CREDIT_MODAL',
  ATTRACTIONS_INFO_MODAL: 'ATTRACTIONS_INFO_MODAL',
  CMP_ADD_GUESTS_MODAL: 'CMP_ADD_GUESTS_MODAL',
  PARKING_BANNER_MODAL: 'PARKING_BANNER_MODAL',
  CART_MODIFY_MODAL: 'CART_MODIFY_MODAL',
  WATER_PARK_PASSES_VIDEO_MODAL: 'WATER_PARK_PASSES_VIDEO_MODAL',
  BIRTHDAY_CONFIRMATION_MODAL: 'BIRTHDAY_CONFIRMATION_MODAL'
};

export const getModalContentByKey = key => {
  let ContentComponent;

  switch (key) {
    case MODAL_KEYS.MYSTERY_OFFER_SPINNER:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "MysteryOfferSpinnerContainer" */ '../MysteryOfferSpinner/MysteryOfferSpinnerContainer'
        )
      );
      break;
    case MODAL_KEYS.INFO_CARD_DETAILS_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "PackageDetailsModal" */ '../PackageDetailsModal'));
      break;
    case MODAL_KEYS.FIND_RESERVATION_BY_ID:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "FindYourReservationContainer" */ '../FindYourReservationModal/FindYourReservationContainer'
        )
      );
      break;
    case MODAL_KEYS.AUTH_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "AuthModalContent" */ '../Auth/AuthModalContent'));
      break;

    case MODAL_KEYS.LEAD_GEN_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "EmailCaptureContent" */ '../EmailCapture/components/EmailCaptureContent')
      );
      break;
    case MODAL_KEYS.OFFER_CODE_ERROR_TOAST:
      ContentComponent = lazy(() => import(/* webpackChunkName: "CouponErrorHandler" */ '../CouponErrorHandler'));
      break;
    case MODAL_KEYS.IE_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "IEModalContent" */ '../IEModal/IEModalContent'));
      break;
    case MODAL_KEYS.PACKAGE_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "ReservationDetail" */ '../../pages/ReservationDetail/AddPackageModal')
      );
      break;
    case MODAL_KEYS.SMS_SIGN_UP_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "SmsSignUpModal" */ '../SmsSignUpModal'));
      break;
    case MODAL_KEYS.SPECIAL_REQUEST_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "SpecialRequestModal" */ '../SpecialRequestModal'));
      break;
    case MODAL_KEYS.SUITE_PRICE_MISMATCH:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "SuitePriceConflictModal" */ '../../pages/Plan/components/SuitePriceConflictModal')
      );
      break;
    case MODAL_KEYS.RECENT_SEARCHES:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "PreviousSearchModal" */ '../../pages/RecentSearches/PreviousSearchModal')
      );
      break;
    case MODAL_KEYS.OFFERS_MODAL:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "LoyaltySummaryMobileModal" */ '../LoyaltySummary/components/LoyaltySummaryMobileModal'
        )
      );
      break;
    case MODAL_KEYS.CONFETTI_MODAL:
      ContentComponent = lazy(() => import(/* webpackChunkName: "ConfettiModal" */ '../ConfettiModal/ConfettiModal'));
      break;
    case MODAL_KEYS.DINING_CREDIT_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "DiningModal" */ '../DiningCredit/components/DiningModal')
      );
      break;
    case MODAL_KEYS.ATTRACTIONS_INFO_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "DiningModal" */ '../Packages/Attractions/components/AttractionsTableModal')
      );
      break;
    case MODAL_KEYS.CMP_ADD_GUESTS_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "CMPAddGuestsModal" */ '../../pages/ReservationDetail/AddGuestsOnCMPModalContent')
      );
      break;
    case MODAL_KEYS.PARKING_BANNER_MODAL:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "ParkingBannerModal" */ '../../pages/ReservationDetail/ParkingBanner/ParkingBannerModal'
        )
      );
      break;
    case MODAL_KEYS.CART_MODIFY_MODAL:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "CartItemModificationModal" */ '../Summary/components/Suite/Plan/Content/CartItemModificationModal'
        )
      );
      break;
    case MODAL_KEYS.WATER_PARK_PASSES_VIDEO_MODAL:
      ContentComponent = lazy(() =>
        import(/* webpackChunkName: "WaterParkPassesModal" */ '../../pages/Plan/components/WaterParkPassesModal')
      );
      break;
    case MODAL_KEYS.BIRTHDAY_CONFIRMATION_MODAL:
      ContentComponent = lazy(() =>
        import(
          /* webpackChunkName: "BirthdayConfirmationModal" */ '../../pages/BirthdayReservationDetail/ConfirmationModal/BirthdayConfirmationModal'
        )
      );
      break;
    default:
      ContentComponent = () => null;
      break;
  }

  return withGlobalModal(ContentComponent, key);
};
