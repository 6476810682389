import { css } from 'styled-components';
import { style } from 'styled-system';
import { ASSETS_LOCATIONS, getAssetsUrl } from '../../../utilities/constants';
import { THEMES_IMAGES } from './assets/images';
import colors from './colors';

const createMediaQuery = n => `@media screen and (min-width:${n})`;

const addAliases = (arr, aliases) =>
  aliases.forEach((key, i) =>
    Object.defineProperty(arr, key, {
      enumerable: false,
      get() {
        return this[i];
      }
    })
  );

export const rawBreakpoints = [32, 40, 48, 64, 90];
export const breakpoints = rawBreakpoints.map(n => n + 'em');

export const mediaQueries = breakpoints.map(createMediaQuery);

const aliases = ['sm', 'md', 'lg', 'xl', 'xxl'];

// Potential Solution to Buttons, for now see ./ui-kit/Button.js
// const buttonIntentTypes = ["primary", "reverse"];
// addAliases(buttonIntentTypes, buttonIntentAliases);

addAliases(breakpoints, aliases);
addAliases(mediaQueries, aliases);

const deviceSizes = {
  // bigScreen: 1440,
  // desktop: 992,
  // tablet: 768,
  // phone: 576,
  // baseMobileFirst: 0,
  bigScreen: 90,
  desktop: 64,
  tablet: 48,
  phone: 0,
  baseMobileFirst: 0
};

// Iterate through the sizes and create a media template
export const mediaScreens = Object.keys(deviceSizes).reduce((acc, label) => {
  acc[label] = (...args) =>
    css`
      @media screen and (min-width: ${deviceSizes[label].toString()}em) {
        ${css(...args)}
      }
    `;
  return acc;
}, {});
export const space = [0, 4, 8, 16, 32, 64, 128, 24, 6, 12, 48, 40, 60, 20, 80, 14];

export const fontPrimary = `'Raleway','Helvetica Neue',Helvetica,Arial,sans-serif`;
export const fontSecondary = `'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif`;
export const fontMontBold = `'Montserrat-Bold','Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif`;
export const fontMontBlack = `'Montserrat-Black','Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif`;
export const fontOswald = 'Oswald';
export const fontOswaldBold = 'Oswald-Bold';

export const fonts = {
  primary: fontPrimary,
  secondary: fontSecondary,
  tertiary: fontOswald,
  quaternary: fontMontBold,
  quinary: fontMontBlack,
  senary: fontOswaldBold
};

export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 18, 22, 10, 36, 8, 80, 56, 9, 13, 40];
export const lineHeights = [1, 1.5, 2];
export const light = 200;
export const regular = 400;
export const medium = 500;
export const semibold = 600;
export const bold = 700;
export const extraBold = 900;

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const fontWeights = {
  light,
  regular,
  medium,
  bold,
  semibold,
  extraBold
};

const letterSpacings = {
  normal: 'normal',
  caps: '0.025em'
};

export { colors };

const border = '1px solid';
const borderHighlight = '4px solid';
const borderGrayLight = `1px solid ${colors.snowMountainGrey[100]}`;
const kiddiePoolBlueBorder = '1px solid #85c6cc32';

// styled-system's `borderRadius` function can hook into the `radii` object/array
export const radii = [0, 2, 6, 16, 20, 25, 30];
export const radius = '10px';

export const maxContainerWidth = '1280px';
export const maxContainerWidthAkela = '1232px';

// boxShadows
export const boxShadows = [
  `0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 2px 8px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 4px 16px 0 rgba(0,0,0,.16)`,
  `0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)`,
  `2px 0 2px 0 rgba(0,0,0,.08)`,
  `0px 8px 8px -8px rgba(0,0,0,.08)`,
  `0px 2px 4px 0 rgba(0,0,0,.08)`,
  `rgba(0,0,0,0.05) 0 -2px 4px 1px`,
  `rgba(0,0,0,0.05) 0 -3px 4px 1px`,
  `0 2px 7px 1px #F0F0F0`,
  `1px 1px 6px 0 rgba(0, 0, 0, 0.3)`,
  `-2px 2px 3px 0 rgba(0, 0, 0, 0.1)`,
  ` 0 23px 20px 20px #00000040`,
  `0 10px 15px 5px rgba(0, 0, 0, 0.24);`,
  `0 0 4px 2px ${colors.preciousStoneBlue[50]}`,
  `0 0 4px 2px ${colors.deepLakeBlue[100]}50`,
  `0 0 4px 2px ${colors.digitalBrightRed[100]}`,
  `0 0 0 1px ${colors.deepLakeBlue[20]}`,
  `inset 0 1px 4px 0 rgba(28, 103, 85, 0.8)`,
  `0 0 4px 0 rgba(66, 103, 178, 0.5)`,
  `0 0 4px 2px ${colors.pureWhite[0]}`,
  `0 19px 30px -20px rgba(40, 56, 76, 0.5);`,
  `inset 0 0 5px 0 rgba(40, 56, 76, 0.1);`,
  `0 23px 20px 20px rgba(0, 0, 0, 0.25)`,
  '0px 0px 15px 0 rgba(0,0,0,.08)',
  `0 0 4px 2px ${colors.kiddiePoolBlue[50]}`,
  `0 4px 18px 5px rgba(0, 0, 0, 0.076)`,
  '0 2px 5px 0 rgba(0, 0, 0, 0.2)'
];

// elevations (new rebrand-2022 box-shadows)
export const elevation = {
  1: '0 2px 7px 1px rgba(0, 0, 0, 0.06)',
  2: '0 4px 8px 0 rgba(0, 0, 0, 0.06)',
  3: '0 4px 18px 5px rgba(0, 0, 0, 0.076)',
  4: '0 11px 24px 0 rgba(0, 0, 0, 0.23)'
};

// animation duration
export const duration = {
  fast: `150ms`,
  normal: `300ms`,
  slow: `450ms`,
  slowest: `600ms`
};

// animation easing curves
const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)';
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)';

const timingFunctions = {
  easeInOut,
  easeOut,
  easeIn
};

// animation delay
const transitionDelays = {
  small: `60ms`,
  medium: `160ms`,
  large: `260ms`,
  xLarge: `360ms`
};

// borders
const borders = [
  `1px solid ${colors.deepLakeBlue[10]}`,
  `1px solid ${colors.preciousStoneBlue[50]}`,
  'solid 1px rgba(0, 93, 120, 0.15);',
  `1px solid ${colors.pureWhite[0]}`,
  `solid 0.5px rgba(133, 198, 204, 0.5);`,
  `1px solid rgba(40, 56, 76, 0.32)`,
  `1px solid ${colors.kiddiePoolBlue[20]}`
];

export const fontFamily = style({
  prop: 'fontFamily',
  cssProperty: 'font-family',
  key: 'fonts'
});

// z-index stack layers.
const zIndexesOrder = [
  'inputIcon',
  'dropdown',
  'widget',
  'floatingButton',
  'header',
  'navHeader',
  'modal',
  'loader',
  'mobileBookingWidget',
  'paypalBooking',
  'auth'
];

const createZIndex = labels => {
  const increment = 100;
  let base = 100;
  return labels.reduce((acc, label) => {
    acc[label] = base;
    base += increment;
    return acc;
  }, {});
};
const zIndex = createZIndex(zIndexesOrder);

const buttonSize = {
  large: {
    padding: '10px 38px',
    fontSize: fontSizes[2],
    minHeight: '48px',
    lineHeight: '1.5',
    borderRadius: '35px'
  },
  medium: {
    padding: '6px 38px',
    fontSize: fontSizes[2],
    minHeight: '40px',
    lineHeight: '1.5',
    borderRadius: '35px'
  },
  small: {
    padding: '0 22px',
    fontSize: fontSizes[0],
    height: '24px',
    lineHeight: '2',
    borderRadius: '20px'
  },
  extraSmall: {
    padding: '2px 8px',
    fontSize: fontSizes[0],
    lineHeight: '1.71',
    borderRadius: '15px'
  },
  beButton: {
    padding: '8.5px 36px',
    fontSize: fontSizes[1],
    lineHeight: '1',
    height: '40px',
    borderRadius: '25px'
  },
  mobile: {
    padding: '6px 32px',
    fontSize: fontSizes[1],
    minHeight: '34px',
    lineHeight: '18px',
    borderRadius: '35px'
  }
};

const disabledBase = {
  pointerEvents: 'none'
};

const buttonBase = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  fontFamily: fonts.secondary,
  fontWeight: fontWeights.bold,
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  textAlign: 'center',
  textDecoration: 'none',
  border: '2px solid transparent',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ':focus': {
    outline: 'unset',
    boxShadow: `0 0 0 3px ${colors.focusBlue}`
  }
};

const buttonPrimaryBase = {
  ...buttonBase,
  color: colors.pureWhite[0],
  backgroundColor: colors.preciousStoneBlue[100],
  ':hover': {
    backgroundColor: colors.darkPreciousStoneBlue
  },
  ':focus': {
    borderColor: colors.pureWhite[0],
    ...buttonBase[':focus']
  },
  ':active': {
    boxShadow: 'unset',
    backgroundColor: colors.darkPreciousStoneBlue,
    borderColor: 'transparent'
  },
  ':disabled': {
    backgroundColor: colors.preciousStoneBlue[50],
    ...disabledBase
  }
};

const buttonDealsBase = {
  ...buttonPrimaryBase
};

const buttonSecondaryBase = {
  ...buttonBase,
  color: colors.preciousStoneBlue[100],
  backgroundColor: colors.pureWhite[0],
  border: `2px solid ${colors.preciousStoneBlue[100]}`,
  ':hover': {
    backgroundColor: colors.snowMountainGrey[50]
  },
  ':focus': {
    borderColor: colors.pureWhite[0],
    backgroundColor: colors.snowMountainGrey[50],
    ...buttonBase[':focus']
  },
  ':active': {
    outline: 'unset',
    boxShadow: 'unset',
    backgroundColor: colors.snowMountainGrey[50],
    borderColor: colors.preciousStoneBlue[100]
  },
  ':disabled': {
    backgroundColor: colors.deepLakeBlue[20],
    ...disabledBase
  }
};

const buttonGhost = {
  ...buttonBase,
  padding: '6.5px 34px',
  color: colors.pureWhite[0],
  backgroundColor: 'transparent',
  border: `2px solid ${colors.pureWhite[0]}`,
  ':hover': {
    backgroundColor: `${colors.pureWhite[0]}10`
  },
  ':focus': {
    ...buttonBase[':focus']
  },
  ':disabled': {
    opacity: '.5',
    ...disabledBase
  }
};

const buttonBookBase = {
  ...buttonBase,
  backgroundColor: colors.digitalBrightRed[100],
  color: colors.pureWhite[0],
  ':hover': {
    backgroundColor: colors.woodpeckerRed[100]
  },
  ':focus': {
    backgroundColor: colors.woodpeckerRed[100],
    borderColor: colors.pureWhite[0],
    ...buttonBase[':focus']
  },
  ':active': {
    outline: 'unset',
    boxShadow: 'unset',
    backgroundColor: colors.woodpeckerRed[100],
    borderColor: colors.woodpeckerRed[100]
  },
  ':disabled': {
    backgroundColor: colors.woodpeckerRed[50],
    ...disabledBase
  }
};

const socialConnectionButton = {
  position: 'relative',
  height: '40px',
  padding: '10px 16px 9px 50px',
  display: 'inline-block',
  cursor: 'pointer',
  borderRadius: radii[4],
  border: 'none',
  backgroundColor: colors.pureWhite[0],
  color: colors.nightSkyBlack[60],
  boxShadow: boxShadows[27],
  svg: {
    position: 'absolute',
    left: 16,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  ':focus': {
    opacity: '.5',
    ...buttonBase[':focus']
  }
};

const buttonAffirmConnect = {
  ...buttonBase,
  backgroundColor: colors.affirmBlue,
  backgroundSize: '240px 40px !important',
  border: 'none !important',
  color: colors.pureWhite[0],
  height: '40px !important',
  position: 'relative',
  ':focus': {
    opacity: '.5',
    ...buttonBase[':focus']
  },
  textTransform: 'none'
};

const baseArrowButton = {
  position: 'relative',
  transition: 'padding .2s ease-in-out',
  verticalAlign: 'middle',
  '&:after': {
    content: '""',
    width: 16,
    height: 16,
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-100%, -50%)',
    right: '16px',
    opacity: 0,
    transition: 'all .2s ease-in-out',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  ':hover, :focus': {
    paddingLeft: '16px',
    paddingRight: '38px',
    opacity: 1,
    '&:after': {
      transform: 'translate(0, -50%)',
      opacity: 1
    }
  },
  ':focus': {
    ...buttonBase[':focus']
  }
};

const buttonPrimary = buttonPrimaryBase;
const buttonDeals = buttonDealsBase;
const buttonSecondary = buttonSecondaryBase;
const buttonBook = buttonBookBase;

const buttonShort = {
  ...buttonPrimary,
  padding: '2px 24px'
};

const buttonShortSecondary = {
  ...buttonSecondary,
  padding: '2px 24px'
};

const buttons = {
  primary: buttonPrimary,
  secondary: buttonSecondary,
  ghost: buttonGhost,
  deals: buttonDeals,
  book: buttonBook,
  affirmConnect: buttonAffirmConnect,
  short: buttonShort,
  shortSecondary: buttonShortSecondary,
  bookWidget: buttonBookBase,
  socialConnection: socialConnectionButton, // TODO: once ab testing ends, we have to make sure the name of the variant changes as well

  primaryArrow: {
    ...buttonPrimary,
    ...baseArrowButton,
    '&:after': {
      ...baseArrowButton['&:after'],
      backgroundImage: `url("${getAssetsUrl(ASSETS_LOCATIONS.ROOT, THEMES_IMAGES.BTN_ARROW_ICON_WHITE)}");`
    },
    ':focus': {
      border: `2px solid ${colors.pureWhite[0]}`,
      ...buttonBase[':focus']
    },
    ':active': {
      outline: 'unset',
      boxShadow: 'unset'
    }
  },

  secondaryArrow: {
    ...buttonSecondary,
    ...baseArrowButton,
    '&:after': {
      ...baseArrowButton['&:after'],
      backgroundImage: `url("${getAssetsUrl(ASSETS_LOCATIONS.ROOT, THEMES_IMAGES.BTN_ARROW_ICON_GREEN)}");`
    }
  },

  ghostArrow: {
    ...buttonGhost,
    ...baseArrowButton,
    '&:after': {
      ...baseArrowButton['&:after'],
      backgroundImage: `url("${getAssetsUrl(ASSETS_LOCATIONS.ROOT, THEMES_IMAGES.BTN_ARROW_ICON_WHITE)}");`
    }
  },

  dealsArrow: {
    ...buttonDeals,
    ...baseArrowButton,
    '&:after': {
      ...baseArrowButton['&:after'],
      backgroundImage: `url("${getAssetsUrl(ASSETS_LOCATIONS.ROOT, THEMES_IMAGES.BTN_ARROW_ICON_BLUE)}");`
    }
  },

  bookArrow: {
    ...buttonBook,
    ...baseArrowButton,
    '&:after': {
      ...baseArrowButton['&:after'],
      backgroundImage: `url("${getAssetsUrl(ASSETS_LOCATIONS.ROOT, THEMES_IMAGES.BTN_ARROW_ICON_WHITE)}");`
    },
    ':focus': {
      borderColor: colors.pureWhite[0],
      ...buttonBase[':focus']
    },
    ':active': {
      outline: 'unset',
      boxShadow: 'unset'
    }
  },

  bookWidgetArrow: {
    ...buttonBookBase,
    ':focus': {
      ...buttonBookBase[':focus']
    },
    ':active': {
      outline: 'unset',
      boxShadow: 'unset'
    }
  },
  primaryInverted: {
    ...buttonPrimary,
    color: colors.preciousStoneBlue[100],
    backgroundColor: colors.pureWhite[0],
    ':hover': {
      backgroundColor: colors.preciousStoneBlue[10]
    },
    ':focus': {
      borderColor: colors.pureWhite[0],
      ...buttonBase[':focus']
    },
    ':active': {
      boxShadow: 'unset',
      backgroundColor: colors.preciousStoneBlue[10],
      borderColor: 'transparent'
    },
    ':disabled': {
      backgroundColor: colors.deepLakeBlue[20],
      ...disabledBase
    }
  },

  partyRequestForm: {
    ...buttonPrimary,
    backgroundColor: colors.digitalYellowOrange[100],
    color: colors.deepLakeBlue[100],
    borderColor: 'transparent'
  }
};

const alerts = {
  info: {
    backgroundColor: colors.kiddiePoolBlue[10],
    color: colors.deepLakeBlue[100],
    border: `${colors.informationBlue} 1px solid`,
    '.icon': {
      color: colors.preciousStoneBlue[100]
    },
    '.closeIcon': {
      color: colors.preciousStoneBlue[100]
    },
    a: {
      color: 'inherit',
      '&:hover': {
        color: colors.preciousStoneBlue[100]
      }
    }
  },
  success: {
    backgroundColor: colors.deepForestGreen[20],
    color: colors.deepLakeBlue[100],
    border: `${colors.successGreen} 1px solid`,
    '.icon': {
      color: colors.deepForestGreen[100]
    },
    '.closeIcon': {
      color: colors.preciousStoneBlue[100]
    },
    a: {
      color: 'inherit',
      '&:hover': {
        color: colors.deepForestGreen[50]
      }
    }
  },
  warning: {
    backgroundColor: colors.digitalBrightRed[20],
    color: colors.deepLakeBlue[100],
    border: `${colors.errorOrange} 1px solid`,
    '.icon': {
      color: colors.errorOrange
    },
    a: {
      color: 'inherit',
      '&:hover': {
        color: colors.digitalBrightRed[100]
      }
    }
  },
  offer: {
    backgroundColor: colors.sandstoneBuff[20],
    color: colors.deepLakeBlue[100],
    border: `${colors.digitalYellowOrange[100]} 1px solid`,
    '.icon': {
      color: colors.digitalYellowOrange[100]
    },
    a: {
      color: 'inherit',
      '&:hover': {
        color: colors.preciousStoneBlue[100]
      }
    }
  }
};

/**
 * Stacked accordion variants
 */
const stackAccordionHeaderBase = {
  borderRadius: ['10px 10px 10px 10px'],
  color: colors.deepLakeBlue[50],
  colorDetails: colors.deepLakeBlue[100],
  border: '1px solid',
  borderWidth: '1px 1px 1px 3px'
};

const stackAccordionHeaderExpandedBase = {
  borderRadius: ['10px 10px 0px 0px'],
  borderBottom: '1px solid transparent'
};

const stackAccordionBodyBase = {
  borderRadius: ['0px 0px 10px 10px'],
  borderTop: 'none',
  bg: colors.pureWhite[0],
  color: colors.deepLakeBlue[50],
  colorDetails: colors.deepLakeBlue[100]
};

const stackAccordionHeaderPrimary = {
  ...stackAccordionHeaderBase,
  bg: colors.preciousStoneBlue[10],
  borderColor: colors.preciousStoneBlue[100]
};

const stackAccordionBodyPrimary = {
  ...stackAccordionBodyBase,
  baseColor: stackAccordionHeaderPrimary.bg,
  border: `1px solid ${stackAccordionHeaderPrimary.borderColor}`
};

const stackedAccordionPrimary = {
  header: stackAccordionHeaderPrimary,
  body: stackAccordionBodyPrimary,
  expandedHeader: stackAccordionHeaderExpandedBase
};

const stackAccordionHeaderSecondary = {
  ...stackAccordionHeaderBase,
  bg: colors.snowMountainGrey[50],
  borderColor: colors.snowMountainGrey[100]
};

const stackAccordionBodySecondary = {
  ...stackAccordionBodyBase,
  baseColor: stackAccordionHeaderSecondary.bg,
  border: `1px solid ${stackAccordionHeaderSecondary.borderColor}`
};

const stackedAccordionSecondary = {
  header: stackAccordionHeaderSecondary,
  body: stackAccordionBodySecondary,
  expandedHeader: stackAccordionHeaderExpandedBase
};

const stackAccordionHeaderDanger = {
  ...stackAccordionHeaderBase,
  bg: colors.pink[20],
  borderColor: colors.digitalBrightRed[100]
};

const stackAccordionBodyDanger = {
  ...stackAccordionBodyBase,
  baseColor: stackAccordionHeaderDanger.bg,
  border: `1px solid ${stackAccordionHeaderDanger.borderColor}`
};

const stackedAccordionDanger = {
  header: stackAccordionHeaderDanger,
  body: stackAccordionBodyDanger,
  expandedHeader: stackAccordionHeaderExpandedBase
};

const stackedAccordions = {
  primary: stackedAccordionPrimary,
  secondary: stackedAccordionSecondary,
  danger: stackedAccordionDanger
};

const theme = {
  //Potential Exports here, future consideration
  rawBreakpoints,
  // buttonIntentTypes,
  //Exports for component consumption
  borders,
  breakpoints,
  mediaQueries,
  space,
  zIndex,
  fontPrimary,
  fonts,
  fontSecondary,
  fontMontBold,
  fontMontBlack,
  fontOswald,
  fontOswaldBold,
  fontFamily,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  regular,
  bold,
  border,
  borderHighlight,
  borderGrayLight,
  kiddiePoolBlueBorder,
  colors,
  radii,
  radius,
  boxShadows,
  shadows: boxShadows,
  elevation,
  maxContainerWidth,
  maxContainerWidthAkela,
  duration,
  timingFunctions,
  transitionDelays,
  mediaScreens,
  buttons,
  alerts,
  buttonSize,
  stackedAccordions
};

export default theme;
