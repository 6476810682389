import React from 'react';
import { PropTypes } from 'prop-types';

import { Box } from '../ui-kit/index';
import { DateText, CheckInLabel } from './styles';

export const CheckInOutDate = ({ children }) => {
  return <Box>{children}</Box>;
};

CheckInOutDate.Label = ({ children, ...restProps }) => {
  return <CheckInLabel {...restProps}>{children}</CheckInLabel>;
};

CheckInOutDate.Date = ({ size, children, ...restProps }) => {
  return (
    <DateText size={size} {...restProps}>
      {children}
    </DateText>
  );
};

CheckInOutDate.Date.propTypes = {
  size: PropTypes.string.isRequired
};
