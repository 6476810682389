import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Payment endpoints
 *
 * @class PaymentApiClient
 * @extends {GWRApiClient}
 */
export default class PaymentApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_PAYMENT_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  /**
   * Sends a GET request with accessBlock in order to get the wallet(s) config
   * @param accessBlock
   * @param i4GoServerUrl
   * @returns {Promise<AxiosResponse<any>|*>}
   */
  async getShift4WalletConfig(accessBlock, i4GoServerUrl) {
    const walletConfigUrl = `${i4GoServerUrl}?fuseaction=get.get3dsInfo&i4go_accessBlock=${accessBlock}`;

    return await super.getApiCall(walletConfigUrl, {}, false);
  }

  /**
   * Sends a GET request with accessBlock in order to get the wallet(s) config
   * @param accessBlock
   * @param applePayToken
   * @param googlePayToken
   * @param i4GoServerUrl
   * @returns {Promise<AxiosResponse<any>|*>}
   */
  async approveShift4WalletTransaction(accessBlock, applePayToken, googlePayToken, i4GoServerUrl) {
    const form_data = new FormData();
    const formDataValues = {
      i4go_accessBlock: accessBlock,
      i4go_successURL: '',
      i4go_failureURL: '',
      i4go_cardNumber: '',
      i4go_cvv2Code: '',
      i4go_p2peBlock: '',
      i4go_trackInformation: '',
      i4go_applePayToken: applePayToken,
      i4go_googlePayToken: googlePayToken,
      i4go_cardinalInfo: '',
      i4go_referrer: window.location,
      i4go_cardholderName: '',
      cardNumber: '',
      cvv2Code: '',
      i4go_streetAddress: '',
      i4go_postalCode: ''
    };

    if (applePayToken) {
      delete formDataValues.i4go_googlePayToken;
    } else {
      delete formDataValues.i4go_applePayToken;
    }

    for (let key in formDataValues) {
      form_data.append(key, formDataValues[key]);
    }
    const walletConfigUrl = `${i4GoServerUrl}?fuseaction=api.jsonPostCardEntry`;

    return await super.postApiCall(walletConfigUrl, form_data, false);
  }
}
