import React from 'react'

const AlertSuccessIconIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 24 24'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M 12.375 3 A 9.375 9.375 0 0 0 12.375 21.75 A 9.375 9.375 0 0 0 12.375 3 M17.6921573,8.74215729 C17.848367,8.58594757 18.101633,8.58594757 18.2578427,8.74215729 C18.3966958,8.88101037 18.4121239,9.09654989 18.3041271,9.2524444 L18.2578427,9.30784271 L11.0608775,16.5048079 C10.9220244,16.643661 10.7064849,16.6590891 10.5505904,16.5510923 L10.4951921,16.5048079 L6.49215729,12.5017731 C6.33594757,12.3455634 6.33594757,12.0922974 6.49215729,11.9360877 C6.63101037,11.7972346 6.84654989,11.7818065 7.0024444,11.8898033 L7.05784271,11.9360877 L10.7782,15.6562 L17.6921573,8.74215729 Z' />
  </svg>
)

AlertSuccessIconIcon.displayName = 'AlertSuccessIconIcon'

AlertSuccessIconIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default AlertSuccessIconIcon