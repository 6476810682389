import Box from '../ui-kit/Box';
import Text from '../ui-kit/Text';
import styled from 'styled-components';
import theme from '../ui-kit/themes/theme';

export const NotificationMessage = styled(Text)`
  display: flex;
  align-items: center;
  font-family: ${theme.fontSecondary};
  font-size: 10px;
  font-weight: 500;
  padding: 8px 16px;
  min-height: 40px;
  line-height: 16px;
  color: ${theme.colors.nightSkyBlack[80]};
  margin: 0;

  svg {
    margin-right: 10px;
  }
}
`;

export const NotificationBox = styled(Box)`
  position: absolute;
  top: 16px;
  width: calc(100% - 40px);
  margin-left: 20px;
  border-radius: 10px;
  min-height: 40px;
  background: ${theme.colors.deepForestGreen[10]};
`;

export const BoxIcon = styled(Box)`
  height: 20px;
`;
