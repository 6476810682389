import styled from 'styled-components';
import { themeGet } from 'styled-system';

import CarouselChevronRightThinIcon from '../icons/CarouselChevronRigthThin';
import { Flex, Text } from '../index';
import { mediaQueries } from '../themes/theme';

export const Container = styled(Flex)`
  ${({ borderColor }) => `border: 1px solid ${borderColor || 'transparent'};`}
  border-radius: ${themeGet('radii.5')}px;
  padding: ${themeGet('space.3')}px ${themeGet('space.3')}px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${mediaQueries.lg} {
    padding: ${themeGet('space.2')}px ${themeGet('space.4')}px;
  }
`;

export const Image = styled.img`
  margin-right: ${themeGet('space.2')}px;
  height: 80px;
  width: 80px;
  align-self: center;
`;

export const Title = styled(Text)`
  font-size: ${themeGet('fontSizes.7')}px;
  font-weight: ${themeGet('fontWeights.bold')};

  ${mediaQueries.lg} {
    font-size: ${themeGet('fontSizes.4')}px;
  }
`;

export const Description = styled(Text)`
  font-size: ${themeGet('fontSizes.0')}px;
  font-weight: ${themeGet('fontWeights.medium')};
  margin-top: 12px;
  ${mediaQueries.lg} {
    font-size: ${themeGet('fontSizes.1')}px;
  }
`;

export const RightArrow = styled(CarouselChevronRightThinIcon)`
  align-self: flex-start;
`;
