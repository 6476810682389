// Import types
import cartActionTypes from './cart.types';

// Initial State
const INITIAL_STATE = {
  cartResortLocation: null,
  selectedSuite: null,
  selectedPackages: [],
  cartLastVisitedStep: null,
  expiredCart: false
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cartActionTypes.SET_CART_RESORT_LOCATION:
      return {
        ...state,
        cartResortLocation: action.cartResortLocation
      };
    case cartActionTypes.RESET_CART_RESORT_LOCATION:
      return {
        ...state,
        cartResortLocation: null
      };
    case cartActionTypes.SET_CART_SUITE:
      return {
        ...state,
        selectedSuite: action.cartSuite
      };
    case cartActionTypes.SET_CART_PACKAGES:
      return {
        ...state,
        selectedPackages: action.cartPackages
      };
    case cartActionTypes.SET_CART_LAST_VISITED_STEP:
      return {
        ...state,
        cartLastVisitedStep: action.cartLastVisitedStep
      };
    case cartActionTypes.SET_EXPIRED_CART:
      return {
        ...state,
        expiredCart: action.expiredCart
      };
    case cartActionTypes.RESET_EXPIRED_CART:
      return {
        ...state,
        expiredCart: action.expiredCart
      };
    default:
      return state;
  }
};

export default cartReducer;
