// Suite action types

const suiteActionTypes = {
  SELECT_SUITE: 'SELECT_SUITE',
  CLEAR_SUITE_SELECTED: 'CLEAR_SUITE_SELECTED',
  GET_SUITE_DETAILED_AVAILABILITY_INFO: 'GET_SUITE_DETAILED_AVAILABILITY_INFO',
  GET_SUITE_DETAILED_AVAILABILITY_SUCCESS: 'GET_SUITE_DETAILED_AVAILABILITY_SUCCESS',
  GET_SUITE_DETAILED_AVAILABILITY_ERROR: 'GET_SUITE_DETAILED_AVAILABILITY_ERROR',
  GET_SUITE_DETAILED_AVAILABILITY_RESET: 'GET_SUITE_DETAILED_AVAILABILITY_RESET',
  GET_SUITE_PACKAGE_AVAILABILITY_INFO_ERROR: 'GET_PACKAGE_AVAILABILITY_INFO_ERROR__FIX_DUPLICATE',
  GET_SUITE_PLAN_PACKAGES_INFO_TYPE: 'GET_SUITE_PLAN_PACKAGES_INFO_TYPE',
  SET_SUITE_PLAN_PACKAGES_INFO_SUCCESS: 'SET_PLAN_PACKAGES_INFO_SUCCESS__FIX_DUPLICATE',
  SET_SUITE_NEW_RATES: 'SET_SUITE_NEW_RATES',
  GTM_SUITE_IMPRESSIONS: 'GTM_SUITE_IMPRESSIONS',
  SET_SUITE_PERSONALIZATION_PACKAGES: 'SET_SUITE_PERSONALIZATION_PACKAGES'
};

export default suiteActionTypes;
