import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { PAYMENT_METHODS_ICONS } from '../../../../src/utilities/constants';
import { AccordionContentRetro, AccordionTitleTextRetro } from '../../ui-kit/Accordion';
import { Box } from '../../ui-kit/index';
import { Icon, IconIMG, IconIMGWrapper, IconText, StyledAccordion, StyledAccordionItem } from './styles';

const propTypes = {
  border: PropTypes.bool,
  handleOnChange: PropTypes.func,
  selected: PropTypes.string,
  accordionData: PropTypes.arrayOf(
    PropTypes.shape({
      paymentMethod: PropTypes.shape({
        show: PropTypes.bool,
        allowedOnLocation: PropTypes.bool,
        uuid: PropTypes.string
      })
    })
  ).isRequired
};

const defaultProps = {
  border: false
};

/** Wrapper to contain any piece of UI inside an Accordion */
const AccordionWrapper = ({ border, handleOnChange, selected, accordionData }) => {
  //renderHeader is intended to manage the Icon And title of the Accordion
  const renderHeader = paymentMethod => {
    let AccordionIcon = !paymentMethod.isPayment
      ? require(`../../../assets/icons/${paymentMethod.icon}.svg`).default
      : PAYMENT_METHODS_ICONS + paymentMethod.icon + '.png';

    return (
      <Icon>
        <IconIMGWrapper>
          <IconIMG src={AccordionIcon} alt={paymentMethod.icon} />
        </IconIMGWrapper>

        <IconText>{paymentMethod.title}</IconText>
      </Icon>
    );
  };
  // allowZeroExpanded: this property allow the item to colapse on click even if every accordion is closed and autocolapse if not selected
  // preExpanded={[selected]}: this property select a default open accordion, if selected is false then all will be closed

  return (
    <StyledAccordion allowZeroExpanded preExpanded={[selected]} border={border} onChange={handleOnChange}>
      {accordionData &&
        accordionData.map(paneData => {
          return (
            <Fragment key={paneData.paymentMethod.uuid}>
              {paneData.paymentMethod.show && paneData.paymentMethod.allowedOnLocation && (
                <>
                  <StyledAccordionItem uuid={paneData.paymentMethod.uuid}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <Box py="0.5rem" width="100%">
                              <AccordionTitleTextRetro
                                expanded={expanded}
                                color="deepLakeBlue.100"
                                fontFamily="secondary"
                                fontSize={0}>
                                {renderHeader(paneData.paymentMethod)}
                              </AccordionTitleTextRetro>
                            </Box>
                          )}
                        </AccordionItemState>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <AccordionContentRetro
                        color="deepLakeBlue.100"
                        style={{ fontWeight: 'normal' }}
                        fontFamily="secondary">
                        {paneData.paymentMethod.child}
                      </AccordionContentRetro>
                    </AccordionItemPanel>
                  </StyledAccordionItem>
                  <hr style={{ backgroundColor: '#e4ebee', height: '1px', border: '0' }} />
                </>
              )}
            </Fragment>
          );
        })}
    </StyledAccordion>
  );
};

AccordionWrapper.propTypes = propTypes;
AccordionWrapper.defaultProps = defaultProps;

export default AccordionWrapper;
