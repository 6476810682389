import React from 'react'

const ArrowRightIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 25 15'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M24.078 7.198C24.058 7.046 23.994 6.795 23.897 6.676L18.915 0.410997C18.619 0.0389968 18.087 0.00699687 17.747 0.280997C17.407 0.553997 17.346 1.114 17.617 1.456L21.523 6.363H0.83C0.372 6.363 0 6.737 0 7.198C0 7.66 0.372 8.034 0.83 8.034H21.523L17.618 12.941C17.346 13.283 17.422 13.825 17.748 14.116C18.087 14.42 18.643 14.327 18.915 13.986L23.897 7.72C24.037 7.547 24.067 7.419 24.078 7.198V7.198Z' />
  </svg>
)

ArrowRightIcon.displayName = 'ArrowRightIcon'

ArrowRightIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default ArrowRightIcon