import styled, { css } from 'styled-components';

import Box from '../../ui-kit/Box';
import theme from '../themes/theme';

const SAFE_AREA = 14;
const SCROLLBAR_PADDING = 4;
const SCROLLBAR_WIDTH = 8;

const withScrollbarStyles = ({ isScrolling, scrollColor, showTrack, trackColor, verticalPadding }) => {
  const alwaysShowTrack = css`
    margin-right: 0px;
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-color: ${scrollColor || theme.colors.snowMountainGrey[100]}
      ${trackColor || theme.colors.snowMountainGrey[100]}; /* Firefox */

    ::-webkit-scrollbar {
      width: ${SCROLLBAR_PADDING * 2 + SCROLLBAR_WIDTH}px;
      background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-track {
      margin: ${verticalPadding || '28px'};
      border: ${SCROLLBAR_PADDING + 1}px solid transparent;
      background-clip: padding-box;
      border-radius: 30px;
      background-color: ${trackColor || theme.colors.pureWhite[0]};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${scrollColor || theme.colors.snowMountainGrey[100]};
      border-radius: 30px;
      border: ${SCROLLBAR_PADDING + 1}px solid transparent;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
  `;

  const trackOnHover = css`
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
      width: 14px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 14px 14px transparent;
      border: solid 4px transparent;
      border-radius: 30px;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 14px 14px ${scrollColor || theme.colors.snowMountainGrey[100]};
      border: solid 4px transparent;
      border-radius: 30px;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
  `;

  const defaultScrollbar = css`
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    ::-webkit-scrollbar-track {
      transition: all 0.2s;
      box-shadow: none !important;
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 3px !important;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `;

  const defaultStyling = isScrolling ? trackOnHover : defaultScrollbar;

  return showTrack ? alwaysShowTrack : defaultStyling;
};

export const ScrollableContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px`)};
  padding-right: ${({ isInset }) => (isInset ? '0px' : `${SAFE_AREA}px`)};
  margin-right: ${({ isInset }) => (isInset ? '0px' : `-${SAFE_AREA}px`)};

  ${({ hideScrollWhileScrolling }) => {
    if (!hideScrollWhileScrolling) {
      return withScrollbarStyles;
    }
  }}
`;
