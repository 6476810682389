import styled from 'styled-components';
import Box from './Box';
import { top, left, right, bottom, zIndex, overflow } from 'styled-system';

const Relative = styled(Box)`
  position: relative;
  ${top} ${bottom} ${left} ${right} ${zIndex} ${overflow}`;

Relative.propTypes = {
  ...top.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...right.propTypes,
  ...right.zIndex
};

Relative.displayName = 'Relative';

export default Relative;
