/**
 * @function
 * Bound two objects to have exactly the same properties.
 * Returns a new object with the intersection of properties of two objects.
 * @param {Object} o1
 * @param {Object} o2
 * @returns Object
 */
export function objectIntersection(o1, o2) {
  let clonedObject = { ...o1 };
  Object.keys(clonedObject).forEach(function(item, index) {
    if (!o2.hasOwnProperty(item)) {
      delete clonedObject[item];
    }
  });

  return clonedObject;
}

// Sort object or dictionary
export function sortObject(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((x, k) => {
      x[k] = obj[k];
      return x;
    }, {});
}

/**
 * @function
 * Check if the object properties are null or empty
 * @param {Object} obj
 * Object which keys are going to be analyzed
 * @Return {Boolean} If none of the values are null or empty then the result will be true
 */
export const checkObjectProperties = obj => {
  if (!obj) return false;
  const isEmpty = Object.values(obj).some(prop => prop === null || prop === '');
  return !isEmpty;
};

/**
 * Find values tat are in list1 but not in list2 by object propery, or in set theory list1 - list2
 * @param {Array} list1 Array of objects, we are going to look the elements in this array not present in list2
 * @param {Array} list2 Array of objects, we are going to look the elements not present in this array
 * @param {string} byKey Property to look or filter at
 * @returns Array
 */
export const findElementsNotInList = (list1, list2, byKey) => {
  var result = list1.filter(function(obj) {
    return !list2.some(function(obj2) {
      return obj[byKey] === obj2[byKey];
    });
  });

  return result;
};
