import styled from 'styled-components';

export const CloseButton = styled.button`
  position: relative;
  left: -15px;
  top: 10px;
  cursor: pointer;
  border: none;
  background: transparent;
  margin-top: 12px;
`;

export const BackButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  margin-left: -6px;
  padding: 12px 6px;
  background: transparent;
`;

export const SuiteDetailInfoCont = styled.div`
  max-height: 550px;
  overflow-y: auto;
  max-width: 50%;
`;

BackButtonWrapper.displayName = 'BackButtonWrapper';
