import { connectRouter } from 'connected-react-router';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import keyBy from 'lodash-es/keyBy';
import map from 'lodash-es/map';
import uniq from 'lodash-es/uniq';
import { combineReducers } from 'redux';
import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import { DEFAULT_RATE_CODE } from '../components/PaymentForm/PaymentFormHelper';
import {
    TIERS_CONTENT_DAYPASS,
    TIERS_CONTENT_DAYPASS_CONFIRMATION,
    TIERS_CONTENT_MY_RESERVATIONS
} from '../pages/ReservationDetail/constants';
import { optimizelyChargePackagesAtCheckout, optimizelyHideLoyalty } from '../utilities/ABTesting';
import { getTheFirstElementOfArray } from '../utilities/arraysUtils';
import { getNormalizedCode } from '../utilities/availabilityUtils';
import {
    BACKEND_PAYMENT_TYPES,
    CABANAS_CODES_TYPES,
    GUARANTEE_TYPE,
    OFFER_CODE_TYPE,
    PACKAGE_CHARGE_TYPE,
    PACKAGE_TYPE,
    PACKAGES_GROUPS,
    PARKING_PACKAGE_CODE,
    SUITE_TYPES,
    TAXES
} from '../utilities/constants';
import { getNumberOfBillableGuests } from '../utilities/guestsUtils';
import { checkIsLateCheckoutPackageOnly, filterPkgsByAdvanceBookingDays } from '../utilities/packagesUtils';
import { FLEX_TRIP_PACKAGE_CODE } from '../utilities/paymentUtils';
import { getOfferCodeErrorDetails } from '../utilities/planUtils';
import { isValidReservation } from '../utilities/reservationsUtils';
import { getTaxesAndFeesDynamic } from '../utilities/taxes';
import account from './componentStores/Account/account.reducer';
import addApackage from './componentStores/addAPackage/addAPackage.reducer';
import birthdayParty from './componentStores/BirthdayParty/birthdayParty.reducer';
import bookingEngineAlert from './componentStores/BookingEngineAlertEntity/bookingEngineAlertEntity.reducer';
import cart from './componentStores/Cart/cart.reducer';
import currentLodge from './componentStores/CurrentLodge/currentLodge.reducer';
import dates from './componentStores/Dates/dates.reducer';
import dayPasses from './componentStores/DayPasses/dayPasses.reducer';
import deal from './componentStores/Deal/deal.reducer';
import eventDetails from './componentStores/EventCalendar/eventCalendar.reducer';
import flow from './componentStores/Flow/flow.reducer';
import geolocation from './componentStores/Geolocation/geolocation.reducer';
import globalModal from './componentStores/GlobalModal/globalModal.reducer';
import guests from './componentStores/Guests/guests.reducer';
import ieModal from './componentStores/IEModal/ieModal.reducer';
import leadGen from './componentStores/LeadGen/leadGen.reducer';
import lodgeConfig from './componentStores/LodgeConfig/lodgeConfig.reducer';
import lodgeLocations from './componentStores/LodgeLocations/lodgeLocations.reducer';
import {
    clearManageReservationDetails,
    getManageReservationDetails
} from './componentStores/ManageReservation/managereservation.actions';
import manageReservationDetails from './componentStores/ManageReservation/managereservation.reducer';
import offer from './componentStores/Offer/offer.reducer';
import packages from './componentStores/Packages/packages.reducer';
import plans from './componentStores/Plans/plans.reducer';
import preCheckIn from './componentStores/PreCheckIn/preCheckIn.reducer';
import profile from './componentStores/Profile/profile.reducer';
import reservationDetails from './componentStores/ReservationDetailsEntity/reservationDetailsEntity.reducer';
import reservations from './componentStores/Reservations/reservations.reducer';
import suite from './componentStores/Suite/suite.reducer';

import { getAddedpackages } from './componentStores/addAPackage/addAPackage.selectors';
import { getCheckIn, getCheckOut } from './componentStores/Dates/dates.selectors';
import { getTaxAndFeesLabels } from './componentStores/LodgeConfig/lodgeConfig.selectors';
import { getOfferCode } from './componentStores/Offer/offer.selectors';
import * as packagesActionCreators from './componentStores/Packages/packages.actions';
import {
    getCabanaAvailability,
    getCabanas,
    getFilteredPackageTypesBasedOnCabanaAvailability,
    getPackages,
    getPackagesByCode,
    getPackagesGrossTotal,
    getPackagesGroupedByTier,
    getPackagesNetTotal,
    getPackagesTaxes
} from './componentStores/Packages/packages.selectors';
import { getOfferCodeError } from './componentStores/Plans/plans.selectors';
import * as reservationDetailsEntityActionCreators from './componentStores/ReservationDetailsEntity/reservationDetailsEntity.actions';
import {
    getAdults,
    getArrivalDate,
    getCabanasAsPackages,
    getDepartureDate,
    getDueAtCheckinAmountWithCabanas,
    getKidsAges,
    getPackagesWithCabanas,
    getPackageTotal,
    getPackageTotalWithCabanas,
    getOfferCode as getReservationDetailsEntityOfferCode,
    getPackages as getReservationDetailsEntityPackages,
    getReservationType,
    getRoomType,
    getSite,
    getStatus,
    getSummaryTotalWithCabana,
    getTaxesAmountWithCabanas,
    RESERVATION_TYPES
} from './componentStores/ReservationDetailsEntity/reservationDetailsEntity.selectors';
import {
    getParkingFee,
    getResortFee,
    getSuiteGrossTotal,
    getSuiteNetTotal,
    getSuiteTaxes,
    getSustainabilityFee,
    getTaxesAndFeesDetailAvailability
} from './componentStores/Suite/suite.selectors';

// Follow A-Z order
const createRootReducer = history =>
  combineReducers({
    account,
    flow,
    addApackage,
    birthdayParty,
    cart,
    currentLodge,
    dates,
    dayPasses,
    entities: combineReducers({
      reservationDetails,
      eventDetails,
      bookingEngineAlert,
      deal,
      manageReservationDetails
    }),
    geolocation,
    globalModal,
    guests,
    leadGen,
    ieModal,
    lodgeConfig,
    lodgeLocations,
    offer,
    plans,
    packages,
    profile,
    preCheckIn,
    router: connectRouter(history),
    reservations,
    suite
  });

export default createRootReducer;

const fetchManageReservationDetails = async (dispatch, reservationType, reservationId) => {
  dispatch(clearManageReservationDetails());
  if (reservationType === RESERVATION_TYPES.suite) await dispatch(getManageReservationDetails(reservationId));
};

// Action creators
export const actionCreators = {
  // All the actions necessary to get reservation details.
  getReservationDetailsGlobal: id => async (dispatch, getState) => {
    let status = {};
    const { getReservationDetails } = reservationDetailsEntityActionCreators;
    const { getAvailablePackages } = actionCreators;
    await dispatch(getReservationDetails(id));
    const state = getState();
    const reservationDetails = state.entities.reservationDetails;
    const reservationType = getReservationType(reservationDetails);
    await fetchManageReservationDetails(dispatch, reservationType, id);
    const departureDate = getDepartureDate(reservationDetails);
    const reservationStatus = getStatus(reservationDetails);
    if (isValidReservation(departureDate, reservationStatus)) {
      dispatch(getAvailablePackages(reservationDetails));
      status.success = true;
    }
    return status;
  },

  getReservationDetailsGlobalWithLastName: (reservationId, lastName) => async (dispatch, getState) => {
    const { getReservationDetailsByReservationIdAndLastName } = reservationDetailsEntityActionCreators;
    const { getAvailablePackages } = actionCreators;
    await dispatch(getReservationDetailsByReservationIdAndLastName(reservationId, lastName));
    const state = getState();
    const reservationDetails = state.entities.reservationDetails;
    const reservationType = getReservationType(reservationDetails);
    await fetchManageReservationDetails(dispatch, reservationType, reservationId);
    const departureDate = getDepartureDate(reservationDetails);
    const reservationStatus = getStatus(reservationDetails);
    if (isValidReservation(departureDate, reservationStatus)) {
      dispatch(getAvailablePackages(reservationDetails));
    }
  },

  getReservationsByReservationIdAndReservationKey: (reservationId, reservationKey) => async (dispatch, getState) => {
    const { getReservationDetailsByReservationIdAndReservationKey } = reservationDetailsEntityActionCreators;
    const { getAvailablePackages } = actionCreators;
    await dispatch(getReservationDetailsByReservationIdAndReservationKey(reservationId, reservationKey));
    const state = getState();
    const reservationDetails = state.entities.reservationDetails;
    const reservationType = getReservationType(reservationDetails);
    await fetchManageReservationDetails(dispatch, reservationType, reservationId);
    const departureDate = getDepartureDate(reservationDetails);
    const reservationStatus = getStatus(reservationDetails);
    if (isValidReservation(departureDate, reservationStatus)) {
      dispatch(getAvailablePackages(reservationDetails));
    }
  },

  getAvailablePackages: reservation => dispatch => {
    const { getPackageAvailability, getCabanas } = packagesActionCreators;
    const kidsAges = getKidsAges(reservation);
    const arrivalDate = getArrivalDate(reservation);
    const departureDate = getDepartureDate(reservation);
    const site = getSite(reservation);
    const numberOfAdults = getAdults(reservation);
    const suiteCode = getRoomType(reservation);
    //const isDayPass = getIsDayPass(reservation);

    dispatch(
      getPackageAvailability({
        Location: site,
        Arrival: arrivalDate,
        Departure: departureDate,
        offerCode: getReservationDetailsEntityOfferCode(reservation),
        NumberOfAdults: numberOfAdults,
        kidsAges: kidsAges && kidsAges.join(),
        suiteCode
      })
    );
    if (reservation.data.id) {
      dispatch(
        getCabanas({
          /** This is for V2 */
          // startDate: arrivalDate,
          // endDate: isDayPass ? arrivalDate : departureDate,
          // location: site,
          // numberOfGuests: numberOfAdults,
          // itemGroup: CABANAS_CODES_TYPES.group
          /** This is for V3 */
          resId: reservation.data.id,
          queryParams: { 'last-name': reservation.data.lastName }
        })
      );
    }
  }
};

// Selectors
/**
 * Get summary total, this is suite + packages net totals.
 * @param {Object} state Global state
 * @return {number} Summary total
 */
export const getSummaryTotal = state => {
  return getPackagesNetTotal(state.packages) + getSuiteTotalWithFees(state);
};

/**
 * Get Suite Rate Total + Resort Fees to compare
 * @param {object} state Global state
 * @returns {number} Suite Rate total with fees
 */
export const getSuiteTotalWithFees = state => {
  const taxAndFeeLabels = getTaxAndFeesLabels(state.lodgeConfig);
  let totalFees = 0;
  if (!isEmpty(taxAndFeeLabels)) {
    const taxesAndFeesPrices = getTaxesAndFeesDetailAvailability(state.suite);
    const fees = getTaxesAndFeesDynamic(taxAndFeeLabels, taxesAndFeesPrices);
    totalFees = fees.reduce((acc, fee) => {
      if (fee.name !== TAXES) return acc + fee.amount;
      return acc;
    }, 0);
  } else {
    totalFees = getResortFee(state.suite) + getParkingFee(state.suite) + getSustainabilityFee(state.suite);
  }
  return getSuiteNetTotal(state.suite) + totalFees;
};

/**
 * Get summary total, this is suite + packages gross totals.
 * @param {Object} state Global state
 * @return {number} Summary gross total
 */
export const getGrossSummaryTotal = state => {
  return getSuiteGrossTotal(state.suite) + getPackagesGrossTotal(state.packages);
};

/**
 * Get Due today amount.
 * @param {Object} state Suite store
 * @return {number} Due today amount.
 */
export const getDueTodayAmount = state => {
  const { suiteDetailedAvailability } = state.suite;

  let dueTodayAmount = 0;

  const hasDetailedAvailability = suiteDetailedAvailability && suiteDetailedAvailability.length > 0;

  // Add the due today only if guaranteeType is "ADV"
  // We should add the amount and taxes just for the first one night

  dueTodayAmount += hasDetailedAvailability ? suiteDetailedAvailability[0].advancedDeposit : 0;

  dueTodayAmount += getPackagesNetTotalByPacksAtCheckout(state, false, true);

  return dueTodayAmount;
};

/**
 * Get Due at checkin amount.
 * @param {Object} state Suite store
 * @return {number} Due at checkin amount.
 */
export const getDueAtCheckInAmount = state => {
  const summaryTotal = getSummaryTotal(state).toFixed(2);
  const dueTodayAmount = getDueTodayAmount(state).toFixed(2);
  return summaryTotal - dueTodayAmount;
};

/**
 * Get the sum of packages taxes + suite taxes.
 * @param {Object} state Global state
 * @return {number} Taxes from suite and packages
 */
export const getSummaryTaxes = state => {
  return getSuiteTaxes(state.suite) + getPackagesTaxes(state.packages);
};

/**
 * Based on the packages found for the reservation, determine from what tier
 * should we show packages.
 * Packages are divided in tiers(A,B,C) and orders(1,2,3,4), this is configured
 * from AEM; When one of the already added packages is found in one tier we
 * automatically go to the next tier and check until we find a tier that has no
 * packages added to the reservation, this would be the returned tier.
 * @param {Object} state Global state.
 * @return {array} List of packages inside a Tier that is not present in the
 * current reservation.
 */
export const getPackagesFromSuitableTier = (state, isNewReservation) => {
  const suitableTier = getTierToShow(state, isNewReservation);
  const availabilityPackages = getPackages(state.packages);
  const reservationType = getReservationType(state.entities.reservationDetails);
  const tierToUse = getPackagesGroupedByTier(state.packages, reservationType, isNewReservation);

  const { arrival } = state.entities.reservationDetails.data;
  let tierToShow = filterPkgsByAdvanceBookingDays(arrival, tierToUse[suitableTier]);

  if (isEmpty(availabilityPackages) || !suitableTier || !tierToShow) return;
  // if there are packages we should get the ones that are not already added into the reservation.
  const result = map(
    tierToShow.filter(
      pkg => pkg.packageCode !== CABANAS_CODES_TYPES.indoor && pkg.packageCode !== CABANAS_CODES_TYPES.outdoor
    ),
    packageFromTier => {
      const availabilityPackage = find(availabilityPackages, ['packageCode', packageFromTier.packageCode]);
      return {
        ...packageFromTier,
        subTitle: get(availabilityPackage, 'subTitle', ''),
        packageIcon: get(availabilityPackage, 'packageIcon', ''),
        amount: get(availabilityPackage, 'amount'),
        tax: get(availabilityPackage, 'tax'),
        total: get(availabilityPackage, 'total')
      };
    }
  );

  return result;
};

/**
 * Based on the packages found for the reservation, determine from what tier
 * should we show cabanas.
 * Packages are divided in tiers(A,B,C) and orders(1,2,3,4), this is configured
 * from AEM; When one of the already added packages is found in one tier we
 * automatically go to the next tier and check until we find a tier that has no
 * packages added to the reservation, this would be the returned tier.
 * @param {Object} state Global state.
 * @return {array} List of packages inside a Tier that is not present in the
 * current reservation.
 */
export const getCabanasFromSuitableTier = (state, isNewReservation) => {
  const suitableTier = getTierToShow(state, isNewReservation);
  const reservationType = getReservationType(state.entities.reservationDetails);
  const availabilityCabanas = getCabanas(state.packages);
  const tierToUse = getPackagesGroupedByTier(state.packages, reservationType, isNewReservation);
  const tierToShow = tierToUse[suitableTier];

  if (isEmpty(availabilityCabanas) || !suitableTier || !tierToShow) return;
  // Get the cabanas per day information
  const cabanasResult = map(
    tierToShow.filter(
      pkg =>
        (pkg.packageCode === CABANAS_CODES_TYPES.indoor || pkg.packageCode === CABANAS_CODES_TYPES.outdoor) &&
        availabilityCabanas.some(availInfo => availInfo.itemCode === pkg.packageCode)
    ),
    packageFromTier => {
      const availabilityPackages = availabilityCabanas.filter(
        availInfo => availInfo.itemCode === packageFromTier.packageCode
      );
      let cabanaInformation = {
        ...packageFromTier,
        isCabana: true,
        cabanasDetails: [],
        amount: 0, // Avoid the price warning
        tax: 0,
        total: 0
      };
      availabilityPackages.forEach(availabilityPackage => {
        cabanaInformation.cabanasDetails.push({
          amount: get(availabilityPackage, 'roomBaseRate'),
          tax: get(availabilityPackage, 'taxesAndFees'),
          total: get(availabilityPackage, 'roomRateTotal'),
          errorCode: get(availabilityPackage, 'errorCode'),
          statusText: get(availabilityPackage, 'statusText'),
          itemAvailable: get(availabilityPackage, 'itemAvailable'),
          date: get(availabilityPackage, 'date')
        });
      });
      return cabanaInformation;
    }
  );

  return cabanasResult;
};

/**
 * Based on the packages found for the reservation, determine from what tier
 * should we show packages/cabanas.
 * Packages are divided in tiers(A,B,C) and orders(1,2,3,4), this is configured
 * from AEM; When one of the already added packages is found in one tier we
 * automatically go to the next tier and check until we find a tier that has no
 * packages added to the reservation, this would be the returned tier.
 * @param {Object} state Global state.
 * @return {array} List of packages inside a Tier that is not present in the
 * current reservation.
 */
export const getPackagesAndCabanasFromSuitableTier = (state, isNewReservation) => {
  const packagesResult = getPackagesFromSuitableTier(state, isNewReservation) || [];
  const cabanasResult = getCabanasFromSuitableTier(state, isNewReservation) || [];

  return [...packagesResult, ...cabanasResult];
};

/**
 * Shapes the data related to the additional packages added for add a package
 * store. The package for this objects is merged from availability packages
 * data and the actual interactions from the user saved in addApackage strore.
 * @param {Object} state Global state
 * @return {array} List of objects containing additional packages data.
 */
export const getAditionalPackagesFromAddApackage = (state, shouldSplitCabanas) => {
  const availabilityPackages = keyBy(getPackages(state.packages), 'packageCode');

  const addedPackages = getAddedpackages(state.addApackage);

  // console.log('US#29565 - availabilityPackages', availabilityPackages);
  // console.log('US#29565 - addedPackages', addedPackages);

  const additionalPackages = Object.keys(addedPackages).map(packageId => {
    const packageFromAvailability = availabilityPackages[packageId];

    let additionalPackage = null;

    if (packageFromAvailability) {
      additionalPackage = {
        id: packageFromAvailability.packageCode,
        title: packageFromAvailability.title,
        packageAmount: packageFromAvailability.amount * addedPackages[packageId].value,
        quantityAdded: addedPackages[packageId].value,
        options: addedPackages[packageId].options,
        additionalTitle: packageFromAvailability.title,
        packageType: packageFromAvailability.packageType[0],
        chargeType: packageFromAvailability.chargeType
      };
    }

    return additionalPackage;
  });

  // Cabanas
  const allCabanas = getCabanas(state.packages);
  const availabilityCabanas = !shouldSplitCabanas ? keyBy(allCabanas, 'itemCode') : allCabanas;

  const additionalCabanas = Object.keys(addedPackages).map(packageId => {
    const cabana = addedPackages[packageId];
    const composedPackage = packageId.split('~');
    const cabanaFromAvailability = !shouldSplitCabanas
      ? availabilityCabanas[composedPackage[0]]
      : availabilityCabanas.find(
          availabilityCabana =>
            cabana.item &&
            cabana.item.packageCode === availabilityCabana.itemCode &&
            cabana.item.date === availabilityCabana.date
        );

    let additionalCabana = null;
    const completeCabana = availabilityPackages[cabana?.item?.packageCode];

    if (cabanaFromAvailability) {
      additionalCabana = {
        id: `${cabanaFromAvailability.itemCode}~${cabana.item.date}`,
        title: `${completeCabana?.title} ${GwDatesWrapper.format(cabana.item.date, 'ddd, MMM DD')}`,
        packageAmount: cabanaFromAvailability.roomRateTotal,
        quantityAdded: 1,
        options: 0,
        isCabana: true,
        rateCode: cabanaFromAvailability.rateCode
      };
    }

    return additionalCabana;
  });

  const packagesAux = additionalPackages.concat(additionalCabanas);

  return packagesAux.filter(Boolean);
};

/**
 * Get total package price from reservation detail taking into account if there
 * were added packages.
 * @param {Object} state GLobal state.
 * @return {number} Package total.
 */
export const getPackageTotalWithAdditionalPackages = state => {
  const addedPackages = getAddedpackages(state.addApackage);
  const packageTotal = getPackageTotalWithCabanas(state.entities.reservationDetails);

  const availabilityPackagesByCode = keyBy(getPackages(state.packages), 'packageCode');

  if (isEmpty(addedPackages)) return packageTotal;
  const addedPackagesTotal = Object.keys(addedPackages).reduce((packageTotalAcc, addedPackage) => {
    const packageAvailability = availabilityPackagesByCode[addedPackage];

    if (!packageAvailability) return packageTotalAcc;

    return packageTotalAcc + packageAvailability.amount * addedPackages[addedPackage].value;
  }, packageTotal);

  // Cabanas calculation
  const addedCabanasTotal = Object.keys(addedPackages).reduce((cabanaTotalAcc, addedCabana) => {
    const availabilityCabanas = getCabanas(state.packages);
    const composedPackage = addedCabana.split('~');
    const cabanaAvailability = availabilityCabanas.find(
      cabana => cabana.itemCode === composedPackage[0] && cabana.date === composedPackage[1]
    );

    if (!cabanaAvailability) return 0;

    return cabanaTotalAcc + cabanaAvailability.roomRateTotal;
  }, 0);

  return addedPackagesTotal + addedCabanasTotal;
};

/**
 * Get total taxes amount from reservation detail taking into account if there
 * were added packages.
 * @param {Object} state GLobal state.
 * @return {number} Package total.
 */
export const getTaxesAmountWithAdditionalPackages = state => {
  const taxesAmount = getTaxesAmountWithCabanas(state.entities.reservationDetails);
  const addedPackages = getAddedpackages(state.addApackage);
  const availabilityPackagesByCode = keyBy(getPackages(state.packages), 'packageCode');

  if (isEmpty(addedPackages)) return taxesAmount;

  return Object.keys(addedPackages).reduce((packageTotalAcc, addedPackage) => {
    const packageAvailability = availabilityPackagesByCode[addedPackage];

    if (!packageAvailability) return packageTotalAcc;

    return packageTotalAcc + packageAvailability.tax * addedPackages[addedPackage].value;
  }, taxesAmount);
};

/**
 * Get summary total from reservation detail taking into account if there
 * were added packages.
 * @param {Object} state GLobal state.
 * @return {number} Package total.
 */
export const getSummaryTotalWithAdditionalPackages = state => {
  const summaryTotal = getSummaryTotalWithCabana(state.entities.reservationDetails);
  const addedPackages = getAddedpackages(state.addApackage);
  const availabilityPackagesByCode = keyBy(getPackages(state.packages), 'packageCode');

  if (isEmpty(addedPackages)) return summaryTotal;

  const packagesTotal = Object.keys(addedPackages).reduce((packageTotalAcc, addedPackage) => {
    const packageAvailability = availabilityPackagesByCode[addedPackage];

    let packageTotal = packageTotalAcc;

    if (packageAvailability) {
      packageTotal = packageTotalAcc + packageAvailability.total * addedPackages[addedPackage].value;
    }

    return packageTotal;
  }, summaryTotal);

  const cabanasTotal = Object.keys(addedPackages).reduce((cabanasTotalAcc, addedPackage) => {
    // Cabanas
    const availabilityCabanas = getCabanas(state.packages);
    const composedPackage = addedPackage.split('~');
    const cabanaAvailability = availabilityCabanas.find(
      cabana => cabana.itemCode === composedPackage[0] && cabana.date === composedPackage[1]
    );

    if (!cabanaAvailability) return 0;

    return cabanasTotalAcc + cabanaAvailability.roomRateTotal;
  }, 0);

  return packagesTotal + cabanasTotal;
};

/**
 * Get due at check in amount from reservation detail taking into account if
 * there were added packages.
 * @param {Object} state GLobal state.
 * @return {number} Package total.
 */
export const getDueAtCheckinAmountWithAdditionalPackages = state => {
  // If is paid with affirm we return 0
  const { isAlreadyPaidWithAffirm, affirmAmount } = getAffirmPaymentDueAtCheckIn(state, 0);
  if (isAlreadyPaidWithAffirm) {
    return affirmAmount;
  }

  // If is not paid with affirm then the codes flows as normal
  const dueAtCheckinAmount = getDueAtCheckinAmountWithCabanas(state.entities.reservationDetails);

  const addedPackages = getAddedpackages(state.addApackage);

  // Cabanas
  const cabanasTotal = Object.keys(addedPackages).reduce((cabanaTotalAcc, addedPackage) => {
    const availabilityCabanas = getCabanas(state.packages);
    const composedPackage = addedPackage.split('~');
    const cabanaAvailability = availabilityCabanas.find(
      cabana => cabana.itemCode === composedPackage[0] && cabana.date === composedPackage[1]
    );

    let cabanaTotal = 0;

    if (cabanaAvailability) {
      cabanaTotal = cabanaTotalAcc + cabanaAvailability.roomRateTotal;
    }

    return cabanaTotal;
  }, 0);

  // Additional Packages (not cabanas)
  const additionalPackagesTotal = Object.keys(addedPackages).reduce((acc, packageKey) => {
    const { value } = addedPackages[packageKey] || {};
    const selectedPackage = state.packages.packageAvailabilityList.find(item => item.packageCode === packageKey);

    const packageMultiplier = value ? value : 0;

    if (packageMultiplier && selectedPackage && selectedPackage.total) {
      return acc + selectedPackage.total * packageMultiplier;
    }

    return acc;
  }, 0);

  return dueAtCheckinAmount + cabanasTotal + additionalPackagesTotal;
};

/**
 * Get packages from reservation details entity and package amount.
 * @param {Object} state Global state.
 * @return {Object} Object shaped to be used.
 */
export const getPackagesWithAmount = state => {
  const {
    entities: {
      reservationDetails: { data, requestSuccess }
    },
    packages
  } = state;

  //if we get a false on the requestSuccess and we don't have existing data we end the run
  if (!requestSuccess && !data) return;
  //if we are done loading we check if we have data
  if (!data || Object.keys(data).length === 0) return;

  const reservationDetails = state.entities.reservationDetails;
  const reservationPackages = getPackagesWithCabanas(reservationDetails);
  if (!reservationPackages) return;

  const availabilityPackagesByCode = keyBy(getPackages(packages), 'packageCode');
  const packagesByGroup = getPackagesGroup(reservationPackages);
  // Return array of all package with respect to packageGroup with unit amount
  const packageWithUnitAmount = getPackageWithUnitAmount(reservationPackages);
  const packagesByCode = getPackagesCodes(reservationPackages);
  // If the reservation has cabanas, add the cabanas first
  const packagesWithAmount = getPackagesWithAmountForCabanas(reservationPackages);
  if (Object.keys(packagesByCode).length === 0) return packagesWithAmount;

  return [
    ...packagesWithAmount,
    ...getPackageWithAmount(
      data,
      packagesByGroup,
      packagesByCode,
      packageWithUnitAmount,
      availabilityPackagesByCode,
      state
    )
  ];
};

/**
 * Return and offer code error message with dynamic number of days if applies.
 * @param {*} state Global state.
 * @return {string|undefined} Offer code error message or undefined if valid.
 */
export const getOfferCodeErrorMessage = state => {
  const offerCodeErrorDetail = getOfferCodeErrorObj(state);

  return offerCodeErrorDetail && offerCodeErrorDetail.message;
};

export const getOfferCodeErrorObj = state => {
  const offerCode = getOfferCode(state.offer);
  const { errorCode } = getOfferCodeError(state.plans) || {};
  const checkIn = getCheckIn(state.dates);
  const checkOut = getCheckOut(state.dates);

  if (!checkIn || !checkOut) return;

  return getOfferCodeErrorDetails({
    offerCode,
    offerCodeError: errorCode,
    checkIn,
    checkOut
  });
};

/**
 * @Function
 * Gets the amount payed with affirm, if a default amount is setted, then that amount is returned
 * amongside with the isAlreadyPaidWithAffirm flag
 * @param {Object} state
 * @param {Number} defaultAmount
 * @returns {Object} with the keys: {isAlreadyPaidWithAffirm, affirmAmount}.
 * The Flag isAlreadyPaidWithAffirm means the current reservation was paid with affirm
 * Amount is the amount paid with affirm, this value can be overwritten to fullfill some scenarios
 * such the dueAtCheckIn calculation
 */
export const getAffirmPayment = (state, defaultAmount = null) => {
  const reservationData = get(state.entities, 'reservationDetails.data', {});

  // If the reservation data is not empty
  if (!isEmpty(reservationData)) {
    const affirmAmount = get(reservationData, 'rateInfo.total');

    const paymentype = get(reservationData, 'paymentType');

    const isAlreadyPaidWithAffirm = paymentype === BACKEND_PAYMENT_TYPES.Affirm;

    return { isAlreadyPaidWithAffirm, affirmAmount };
  }

  return { isAlreadyPaidWithAffirm: false, affirmAmount: 0 };
};

/**
 * @Function
 * Gets the amount due at checkin with affirm
 * @param {Object} state
 * @param {Number} defaultAmount
 * @returns {Object} with the keys: {isAlreadyPaidWithAffirm, affirmAmount}.
 * The Flag isAlreadyPaidWithAffirm means the current reservation was paid with affirm
 * Amount is the amount paid with affirm, this value can be overwritten to fullfill some scenarios
 * such the dueAtCheckIn calculation
 */
export const getAffirmPaymentDueAtCheckIn = (state, defaultAmount = null) => {
  const reservationData = get(state.entities, 'reservationDetails.data', {});

  // If the reservation data is not empty
  if (!isEmpty(reservationData)) {
    const affirmAmount = defaultAmount ? defaultAmount : 0;

    const isAlreadyPaidWithAffirm = get(reservationData, 'isAffirm');

    return { isAlreadyPaidWithAffirm, affirmAmount };
  }

  return { isAlreadyPaidWithAffirm: false, affirmAmount: defaultAmount ? defaultAmount : 0 };
};

const getTierToShow = (state, isNewReservation = false) => {
  const reservationType = getReservationType(state.entities.reservationDetails);

  if (reservationType === RESERVATION_TYPES.cabana) return 'default';

  // 1. Get packages from AEM grouped by tiers
  const packagesTiers = getPackagesGroupedByTier(state.packages, reservationType, isNewReservation);

  // 2. get packages added to reservation
  const packagesInReservation = getReservationDetailsEntityPackages(state.entities.reservationDetails);
  // 3. get cabanas added to reservation
  const cabanasInReservation = getCabanas(state.entities.reservationDetails);
  // 4. get tier codes
  const tierCodes = Object.keys(packagesTiers).sort();
  // 5. get the default tier
  const defaultTier = tierCodes[tierCodes.indexOf('A')];
  const isCabanaAvailable = getCabanaAvailability(state.packages);
  const isCabanaTier = tier => {
    const packageTiers = packagesTiers[tier];

    if (!packageTiers) return false;

    return packagesTiers[tier].some(packageInTier => {
      return Object.values(CABANAS_CODES_TYPES).includes(packageInTier.packageCode);
    });
  };
  // 5. If the reservation is new or there are no packages added or is late checkout package only we return the default tier, tier A.
  if (
    (isEmpty(packagesInReservation) && isEmpty(cabanasInReservation)) ||
    isNewReservation ||
    checkIsLateCheckoutPackageOnly(packagesInReservation)
  ) {
    return isCabanaTier(defaultTier) && !isCabanaAvailable ? 'default' : defaultTier;
  }

  // 6. Select the suitable tier. If a package from tier A exist fallback to
  // tier B or C and so on until reaching last tear which fallback to default.
  const suitetableTiers = tierCodes.reduce((acc, tierCode) => {
    acc[tierCode] = true;
    return acc;
  }, {});

  suitetableTiers.default = true;

  if (packagesInReservation) {
    packagesInReservation.forEach(packageInReservation => {
      tierCodes.forEach(tierCode => {
        const isInTier = packagesTiers[tierCode].some(packageInTier => {
          if (PACKAGES_GROUPS.includes(packageInReservation.packageGroup)) {
            return packageInTier.packageCode === packageInReservation.packageGroup;
          }
          return packageInTier.packageCode === packageInReservation.packageCode;
        });

        if (isInTier) suitetableTiers[tierCode] = false;
      });
    });
  }

  if (cabanasInReservation) {
    cabanasInReservation.forEach(cabanaInReservation => {
      tierCodes.forEach(tierCode => {
        const isInTier = packagesTiers[tierCode].some(
          packageInTier => packageInTier.packageCode === cabanaInReservation.roomType
        );

        if (isInTier) suitetableTiers[tierCode] = false;
      });
    });
  }

  const suitableTier = Object.keys(suitetableTiers).find(suitableTier => suitetableTiers[suitableTier]);

  return isCabanaTier(suitableTier) && !isCabanaAvailable ? 'default' : suitableTier;
};

/**
 * Gets the dynamic tile based on availability and existing packages
 * @param {Object} state store
 * @param {boolean} isNewReservation flag that indicates if the reservation is a new reservation
 * @return {(Object|undefined)} The dynamic tile content for the respective
 * tier or undefined if there is no tier to show.
 */
export const getDynamicTile = (state, isNewReservation = false) => {
  const validPackageTypes = getValidPackageTypes(state);
  const tiers = getTierForReservationType(state, isNewReservation);

  const selectedTier = Object.keys(tiers).find(tierKey => validPackageTypes.includes(tiers[tierKey].packageType));

  return tiers[selectedTier || 'default'];
};

/**
 * We can get the packages amount from different sources, we check in this order
 * 1. getPackagesNetTotal -- from this Packages Selectors
 * 2. getPackageTotal -- from Reservation Details Entity
 * 3. getAddedpackages -- And at least from getAddedPackages
 * @param {Object} state Store
 * @param {bool} fromReservation Flag to get the amounts from Reservation Detail entity
 */
export const getPackagesNetTotalFromSources = (state, fromReservation = false) => {
  let amount = 0;

  let packages = get(state, 'packages.selectedPackages', null);

  if (packages && !fromReservation) {
    amount = getPackagesNetTotal(state.packages);
  } else {
    // 1. Get the packages directly from reservation
    const reservationDetails = get(state, 'entities.reservationDetails', null);
    const addAPackageState = get(state, 'addApackage', null);
    if (reservationDetails) {
      amount = getPackageTotal(reservationDetails);
    }
    // 2. Get the packages from addAPackage state
    else if (addAPackageState) {
      const addedPackages = getAddedpackages(addAPackageState);
      const availabilityPackagesByCode = keyBy(getPackages(state.packages), 'packageCode');

      if (isEmpty(addedPackages)) return amount;

      Object.keys(addedPackages).reduce((packageTotalAcc, addedPackage) => {
        const packageAvailability = availabilityPackagesByCode[addedPackage];

        let packageTotal = packageTotalAcc;

        if (packageAvailability) {
          packageTotal = packageTotalAcc + packageAvailability.total * addedPackages[addedPackage].value;
        }

        return packageTotal;
      }, amount);
    }
  }

  return amount;
};

/**
 *
 * @param {object} state Redux State
 * @param {boolean} isResDetail Flag to check if the packages charge is at CMP page
 * @param {boolean} isDueToday Flag to check if the package charge is a due today at summary cost
 */
export const getPackagesNetTotalByPacksAtCheckout = (state, isResDetail = false, isDueToday = false) => {
  let guaranteeType = '';
  let offerType = '';
  let offerCode = '';

  // Get the offer type, place the amount if is ADV
  if (isResDetail) {
    guaranteeType = get(state, 'entities.reservationDetails.data.guaranteeType', '');

    offerCode = get(state, 'entities.reservationDetails.data.offerCode', '');

    // Add the due today on Group Offer Codes
    // We should add the amount and taxes just for the first one night
    offerType = get(state, 'suite.selectedSuite.offerType', '');
  } else {
    // Get guarantee and offer by availability call
    const { suiteDetailedAvailability, selectedSuite } = state.suite;
    const hasDetailedAvailability = suiteDetailedAvailability && suiteDetailedAvailability.length > 0;

    offerCode = get(state, 'offer.code', '');

    guaranteeType = hasDetailedAvailability ? get(suiteDetailedAvailability[0], 'guaranteeType', '') : '';

    offerType = get(selectedSuite, 'offerType', '');
  }

  // If we have the packages upfront disabled and
  // If the offer code doesn't require any advance payment
  // Like ADV or a Group Code then we don't send this calculation
  // Or if the offer code is AMTCERT we send a 0 for the due today
  if (
    (isDueToday && !optimizelyChargePackagesAtCheckout) ||
    offerCode === DEFAULT_RATE_CODE ||
    (guaranteeType !== GUARANTEE_TYPE.advancePayment && offerType !== OFFER_CODE_TYPE.group)
  ) {
    // Flex package should be inside the Due Today.
    // So we get the selected packages and see if there is any flex package in there
    // If the condition don't fall here, getPackagesNetTotalFromSources take care about it
    const { packages } = state;
    const selectedPackages = get(packages, 'selectedPackages');
    if (selectedPackages) {
      const flexPackage = selectedPackages.find(pckg => pckg.packageCode === FLEX_TRIP_PACKAGE_CODE);
      return get(flexPackage, 'amount', 0);
    }
    return 0;
  }

  return getPackagesNetTotalFromSources(state, isResDetail);
};

export const getValidOfferCode = state => {
  const offerCode = getOfferCode(state.offer);
  const offerCodeError = getOfferCodeError(state.plans);

  if (offerCode && (!offerCodeError || (offerCodeError && !offerCodeError.errorCode))) {
    return getNormalizedCode(offerCode);
  }

  return '';
};

export const getValidRateCode = (state, suite) => {
  const defaultRateCodeSuites = 'BBAR';
  const defaultRateCodeCondos = 'BARC';

  const offerCode = getValidOfferCode(state);
  const isCondo = suite.suiteType === SUITE_TYPES.condo;
  const defaultRateCode = isCondo ? defaultRateCodeCondos : defaultRateCodeSuites;

  if (!offerCode) {
    return defaultRateCode;
  }

  return suite.rateCode ?? defaultRateCode;
};

/**
 * Get List Packages from Reservation Details
 * @param {Object} state Redux state
 * @return {Array.<Object>} List of packages from Reservation Details
 */
export const getReservationPackages = state => {
  const reservationPackages = getReservationPackages(state);
  return reservationPackages;
};

/**
 * Get the day type or SIP for the selected suite
 * @param {object} state Redux state of the application
 * @returns number
 */
export const getDayTypeForSelectedSuite = state => {
  const dayType = get(state, 'suite.dayType', 0);
  return dayType;
};

/**
 * Gets a package with amount for the package section in the cost summary.
 * @param {string} packageCode for differentiate it in the package section for the cost summary.
 * @param {string} packageTitle which will appear in the package section for the cost summary.
 * @param {Package Amount} amount package amount.
 * @param {number} quantity for the package.
 * @returns Package for the package section.
 */
const buildPackageWithAmount = (
  packageCode,
  packageTitle,
  amount,
  quantity,
  packageType = '',
  packageCharge = '',
  packageAllowance = 0
) => {
  return {
    packageCode: packageCode,
    packageTitle: packageTitle,
    packageAmount: amount,
    quantity: quantity,
    packageType,
    packageCharge,
    packageAllowance: packageAllowance
  };
};

/**
 * Gets the packages codes from the reservation packages
 * @param {*} reservationPackages The reservations packages from the state
 * @returns the packages codes from the reservation packages.
 */
const getPackagesCodes = reservationPackages => {
  return uniq(
    reservationPackages
      .filter(p => !p.isCabana)
      .reduce((acc, reservationPackage) => {
        const { packageCode } = reservationPackage;
        if (packageCode) {
          acc.push(packageCode);
        }

        return acc;
      }, [])
  );
};

/**
 * Gets the packages groups from the reservation packages without cabanas.
 * @param {*} reservationPackages The reservation packages from the state
 * @returns the packages groups from the reservation packages.
 */
const getPackagesGroup = reservationPackages => {
  return uniq(
    reservationPackages
      .filter(p => !p.isCabana)
      .reduce((acc, reservationPackage) => {
        const { packageGroup } = reservationPackage;
        if (packageGroup) {
          acc.push(packageGroup);
        }

        return acc;
      }, [])
  );
};

/**
 * Get packages without cabanas with amount.
 * @param {*} data reservation details entity.
 * @param {*} packagesByGroup the packages groups from the reservationPackages.
 * @param {*} packagesByCode the packages codes from the reservationPackages.
 * @param {*} packageWithUnitAmount the packages with unit amount.
 * @param {*} availabilityPackagesByCode the availability packages by code.
 * @returns the packages without cabanas with amount.
 */
const getPackageWithAmount = (
  data,
  packagesByGroup,
  packagesByCode,
  packageWithUnitAmount,
  availabilityPackagesByCode,
  state
) => {
  const {
    children: kidsAges,
    adults: numberOfAdults,
    selection: { numberOfNights },
    isDayPass,
    isCabana
  } = data;

  const numberOfBillableGuests = getNumberOfBillableGuests(numberOfAdults, kidsAges, !isDayPass);
  let uniqueCodes = [...new Set([...packagesByGroup, ...packagesByCode])];
  const packagesWithAmount = [...uniqueCodes, ...packageWithUnitAmount].reduce((packagesAcc, packageCode) => {
    const details = getPackagesByCode(state)[packageCode];
    let quantity = 0;
    let quantityForAmountCalculation = 0;
    let amount = 0;
    let packageTitle = '';
    let packageType = '';
    let packageCharge = '';

    if (isEmpty(details)) return packagesAcc;

    const pkgAmount = get(availabilityPackagesByCode, `${packageCode}.amount`, 0).toFixed(2);
    const pkgAllowance = get(availabilityPackagesByCode, `${packageCode}.allowance`, 0);
    /**
     * If package quantity is available, check if package code and package group matches and check if package type is 'dining' and charge type is 'person/day', Calculate the actual quantity by dividing it by total number of guests
     * calculate amount of package as per the charge type 'person/day', it is the number of guests(full stay package).
     */
    packageWithUnitAmount &&
      packageWithUnitAmount.forEach(pkg => {
        const packageCodeGroupMatch = pkg.packageCode === packageCode || pkg.packageGroup === packageCode;

        if (!packageCodeGroupMatch) return;

        packageType = getTheFirstElementOfArray(details.packageType);
        packageCharge = details.chargeType;
        const isDiningPerDay =
          getTheFirstElementOfArray(details.packageType) === PACKAGE_TYPE.dining &&
          details.chargeType === PACKAGE_CHARGE_TYPE.personPerDay;
        const isByPackage =
          getTheFirstElementOfArray(details.packageType) === PACKAGE_TYPE.family &&
          details.chargeType === PACKAGE_CHARGE_TYPE.byPackage;
        quantityForAmountCalculation = isByPackage
          ? details.maxPackageLimit
          : isDiningPerDay
          ? numberOfBillableGuests
          : pkg.quantity;
        quantity = pkg.quantity;
        if (+quantityForAmountCalculation > 0) {
          if (isDiningPerDay) {
            amount += pkg.unitAmount * quantityForAmountCalculation * (isDayPass || isCabana ? 1 : numberOfNights);
          } else {
            if (isByPackage && packageCode === PARKING_PACKAGE_CODE) {
              amount = +pkg.packageAmount.toFixed(2);
            } else {
              amount = (pkgAmount * quantityForAmountCalculation).toFixed(2);
            }
          }
        } else {
          amount = pkgAmount;
        }

        packageTitle =
          getTheFirstElementOfArray(details.packageType) === PACKAGE_TYPE.dining
            ? details.packageTitle
            : details.title || '';
      });

    packagesAcc.push(
      buildPackageWithAmount(packageCode, packageTitle, amount, quantity, packageType, packageCharge, pkgAllowance)
    );
    return packagesAcc;
  }, []);
  return packagesWithAmount;
};

/**
 * Gets the cabanas from the reservation packages.
 * @param {*} reservationPackages the reservationPackages from which to obtain the cabanas.
 * @returns the cabanas from the reservation packages.
 */
const getPackagesWithAmountForCabanas = reservationPackages => {
  return reservationPackages
    .filter(p => p.isCabana)
    .map(c => {
      var title = `${c.description} ${GwDatesWrapper.format(c.startDate, 'ddd, MMM DD')}`;
      return buildPackageWithAmount(
        c.packageCode,
        title,
        c.totalRoomRateAndPackages,
        c.quantity,
        c.packageType,
        c.packageCharge
      );
    });
};

/**
 * Gets the reservationPackages with unit amount.
 * @param {*} reservationPackages with unit amount.
 * @returns the reservation packages without cabanas with unit amount.
 */
const getPackageWithUnitAmount = reservationPackages => {
  let packagesWithNoCabans = reservationPackages.filter(p => !p.isCabana);

  let packagesWithUnitAmount = packagesWithNoCabans.reduce((packages, pkgToCheck) => {
    const isPackageAlreadyAdded = packages.find(pkg => {
      return pkg.packageCode === pkgToCheck.packageCode;
    });

    if (isPackageAlreadyAdded) {
      return packages;
    } else {
      let result = { ...pkgToCheck, unitAmount: 0 };
      result.unitAmount += pkgToCheck.unitAmount;
      packages.push(result);
      return packages;
    }
  }, []);
  return packagesWithUnitAmount;
};

/**
 * Get current booking engine rates to send to backend
 * @param {object} state Redux state
 * @param {bool} isBookingFlow Check if isBookingFlow (plan page || isDynamicFlow) this is the reservation normal flow, or daypasses
 * @returns Array.object With the current booking engine rates
 */
export const getSuiteRates = (state, isBookingFlow) => {
  let detailedAvailability = [];

  if (isBookingFlow) {
    detailedAvailability = get(state, 'suite.suiteDetailedAvailability', []);
  } else {
    const { dayPasses } = state;
    const dayPassItems = get(dayPasses, 'item', []);
    const dayPassCode = get(dayPasses, 'selectedItemCode', '');

    for (var key in dayPassItems) {
      var item = dayPassItems[key];
      if (item.itemCode === dayPassCode) {
        detailedAvailability = [
          {
            date: item.date,
            suiteCode: item.itemCode,
            totalNightlyRate: item.roomRateTotal,
            taxesAndFees: item.taxesAndFees
          }
        ];
      }
    }
  }

  return detailedAvailability;
};

export const getAdjustmentCodesByPackageCode = (state, packageCode) => {
  const allPackages = getPackages(state.packages);
  const foundPackage = allPackages.find(pkg => pkg.packageCode === packageCode);

  const { adjustmentCode } = foundPackage;

  return adjustmentCode;
};

const getExistingPackageTypes = state => {
  const packages = getPackages(state.packages);
  const packagesInReservation = getReservationDetailsEntityPackages(state.entities.reservationDetails);
  const cabanasInReservation = getCabanas(state.entities.reservationDetails);

  const existingPackageTypes = [];
  existingPackageTypes.push(...getExistingPackageTypesForPackages(packagesInReservation, packages));
  existingPackageTypes.push(...getExistingPackageTypesForCabanas(cabanasInReservation, packages));
  return existingPackageTypes;
};

const getExistingPackageTypesForCabanas = (cabanasInReservation, packages) => {
  const cabanas = packages.filter(p => p.packageType && p.packageType[0] === PACKAGE_TYPE.cabanasAndFlexTrip);
  return cabanasInReservation.reduce((packageTypes, currentCabana) => {
    const cabanaAvailable = cabanas.filter(cabanaAvailable => cabanaAvailable.packageCode === currentCabana.roomType);

    const containsCabana =
      cabanaAvailable &&
      cabanaAvailable.length > 0 &&
      cabanaAvailable[0].packageType &&
      cabanaAvailable[0].packageType.length > 0;

    if (!containsCabana) return packageTypes;

    return [...packageTypes, cabanaAvailable[0].packageType[0]];
  }, []);
};

const getExistingPackageTypesForPackages = (packagesInReservation, packages) => {
  return packagesInReservation.reduce((packageTypes, currentPackage) => {
    const packageAvailable = packages.filter(
      pkgAvailable =>
        pkgAvailable.packageCode === currentPackage.packageCode ||
        pkgAvailable.packageCode === currentPackage.packageGroup
    );

    const containsPackageType =
      packageAvailable &&
      packageAvailable.length > 0 &&
      packageAvailable[0].packageType &&
      packageAvailable[0].packageType.length > 0;

    if (!containsPackageType) return packageTypes;

    return [...packageTypes, packageAvailable[0].packageType[0]];
  }, []);
};

const getTierForReservationType = (state, isNewReservation) => {
  const reservationType = getReservationType(state.entities.reservationDetails);

  /**
   * Suite and Cabana should use TIERS_CONTENT_MY_RESERVATIONS as the default
   * Daypas on confirmation should show TIERS_CONTENT_DAYPASS_CONFIRMATION
   * Daypass on CMP should show TIERS_CONTENT_DAYPASS
   */
  switch (reservationType) {
    case RESERVATION_TYPES.dayPass:
      if (isNewReservation) return TIERS_CONTENT_DAYPASS_CONFIRMATION;
      return TIERS_CONTENT_DAYPASS;
    default:
      return TIERS_CONTENT_MY_RESERVATIONS;
  }
};

const getValidPackageTypes = state => {
  const filteredPackageTypes = getFilteredPackageTypesBasedOnCabanaAvailability(state);

  const existingPackageTypes = getExistingPackageTypes(state);
  return filteredPackageTypes.reduce((packageTypes, currentPackageType) => {
    if (existingPackageTypes.includes(currentPackageType)) return packageTypes;

    return [...packageTypes, currentPackageType];
  }, []);
};

export const showLoyalty = state => {
  const isLoyaltyEnabled = state.lodgeConfig.isLoyaltyEnabled;
  const isOnLLP = state.router.location.pathname === '/voyagers-club';
  const isPrimerState = state.lodgeConfig.isInPrimerState;
  const isUserSignedIn = state.account.userIsLoggedIn;
  const loyaltyOptIn = state.account.currentLoggedInUser?.loyaltyOptIn || false;

  if (isLoyaltyEnabled === false || optimizelyHideLoyalty === true) return false; // no Loyalty if in 'Dark' state or AB test is true
  if (isOnLLP === true) return true; // show Loyalty if not in 'dark' state and user is in LLP

  // not in 'dark' and not in 'LLP' - BkgEng
  if (isPrimerState === true) {
    // BkgEng with Loyalty in 'Primer' state
    if (isUserSignedIn === true) {
      // BkgEng with Loyalty in 'Primer' state + user is logged in
      if (loyaltyOptIn === true) {
        // BkgEng with Loyalty in 'Primer' state + user is logged in + user is Opted in Loyalty program
        return true; //
      } // BkgEng with Loyalty in 'Primer' state + user is logged in + user is NOT Opted in Loyalty program
      else {
        return false;
      }
    } // BkgEng with Loyalty in 'Primer' state + guest user
    else {
      return false;
    }
  } // BkgEng with Loyalty in 'Public' state
  else {
    return true;
  }
};

/**
 * Get the total amount of the packages
 * @param {*} state
 * @returns
 */
export const packagesWithAmountFromReservationData = state => {
  const {
    entities: {
      reservationDetails: { data, requestSuccess }
    }
  } = state;

  //if we get a false on the requestSuccess and we don't have existing data we end the run
  if (!requestSuccess && !data) return;
  //if we are done loading we check if we have data
  if (!data || Object.keys(data).length === 0) return;

  const reservationPackages = data?.packages?.filter(pkg => !pkg.isAdjustment) ?? [];
  const cabanas = getCabanasAsPackages(state.entities.reservationDetails) ?? [];

  const formattedPackages = [...reservationPackages, ...cabanas]
    .filter(pkg => !pkg.isAdjustment)
    .map(pkg => {
      return {
        amount: pkg.packageAmount ?? 0,
        packageAllowance: pkg.allowance ?? 0,
        packageCharge: pkg.chargeType ?? '',
        packageCode: pkg.packageCode ?? '',
        packageName: pkg.packageTitle ?? pkg.shortDescription ?? pkg.description ?? '',
        packageType: pkg.packageType ?? '',
        quantity: pkg.quantity ?? 0
      };
    });

  return formattedPackages;
};
