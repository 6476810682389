import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box, Text } from '..';
import theme from '../themes/theme';
import { PLACEMENTS, PLACEMENT_CORNERS } from './constants';
import { ContentWrapper, HintWrapper } from './styles';

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const propTypes = {
  /**
   * Controls whether the hint should be shown right from the start instead of waiting for gestures.
   */
  show: PropTypes.bool,
  /**
   * Decides whether or not the onMouseEntered & onMouseLeaved should handle the hint visibility.
   */
  disabledGestures: PropTypes.bool,
  /**
   * The amount of time in ms, that takes the hint to be shown.
   */
  delay: PropTypes.number,
  /**
   * Animation duration in ms.
   */
  duration: PropTypes.number,
  /**
   * Controls the zIndex for the hint content.
   */
  zIndex: PropTypes.number,
  /**
   * Controls the placement corner for the hint.
   */
  placement: PropTypes.oneOf(Object.values(PLACEMENTS)),
  /**
   * A string to render inside the hint.
   */
  content: PropTypes.object.isRequired
};
const defaultProps = {
  show: false,
  disabledGestures: false,
  delay: 250,
  duration: 230,
  zIndex: 1,
  placement: PLACEMENTS.BOTTOM_RIGHT
};

const Hint = ({ show, disabledGestures, delay, duration, zIndex, placement, content, children }) => {
  let timeout;
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(show);
  }, [show]);

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0
  };

  const getContentShape = () => {
    let shape = Array(4).fill('10px');
    const cornerIndex = PLACEMENT_CORNERS[placement || 'bottom-right'];
    shape[cornerIndex] = '0';
    return shape.join(' ');
  };

  const showHint = () => {
    if (!disabledGestures) {
      timeout = setTimeout(() => {
        setActive(true);
      }, delay);
    }
  };

  const hideHint = () => {
    if (!disabledGestures) {
      clearTimeout(timeout);
      setActive(false);
    }
  };

  return (
    <HintWrapper onMouseEnter={showHint} onMouseLeave={hideHint}>
      {children}
      <CSSTransition in={isActive} timeout={duration}>
        {state => {
          return (
            <ContentWrapper
              placement={placement}
              zIndex={zIndex}
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}>
              <Box
                bg="pureWhite.0"
                borderRadius={getContentShape()}
                border="1px solid"
                borderColor={theme.colors.informationBlue}
                py={2}
                px="12px">
                <Text fontSize={9} color="deepLakeBlue.100" fontFamily="secondary" fontWeight="medium">
                  {content}
                </Text>
              </Box>
            </ContentWrapper>
          );
        }}
      </CSSTransition>
    </HintWrapper>
  );
};

Hint.propTypes = propTypes;
Hint.defaultProps = defaultProps;
Hint.PLACEMENTS = PLACEMENTS;

export default Hint;
