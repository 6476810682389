import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../../ui-kit';
import { BoldText } from '../../assets/SummaryStyles';
import SummaryItem from '../SummaryItemPackage';
import * as COPY from './utilities/copy';

const SimpleSummaryFooter = ({ total }) => {
  return (
    <Box>
      <SummaryItem
        labelTitle={COPY.TOTAL_SECTION}
        role="complementary"
        aria-label="Total Price"
        pt={3}
        leftColumn={
          <BoldText large color="deepLakeBlue.100">
            {COPY.TOTAL}
          </BoldText>
        }
        rightColumn={
          <BoldText large color="deepLakeBlue.100">
            ${isNaN(total) ? 0.0 : total.toFixed(2)}
          </BoldText>
        }
      />
    </Box>
  );
};

SimpleSummaryFooter.propTypes = {
  total: PropTypes.number
};

SimpleSummaryFooter.defaultProps = {
  total: 0.0
};

export default SimpleSummaryFooter;
