import { initializeState } from '../../../utilities/storageUtils';
import birthdayPartyActionTypes from './birthdayParty.types';

const INITIAL_STATE = {
  isLoadingBirthdayAvailabilityData: false,
  birthdayAvailabilityData: null,
  birthdayAvailabilityDataError: '',
  partyDate: null,
  partyGuestsCount: null,
  partyTime: null,
  partyAdults: null,
  partyKids: null,
  partyInfant: null,
  partySpectators: null,
  numberOfExtraGuests: null,
  selectedParty: null,
  isDatePickerOpen: false,
  isLoadingCreateBirthdayReservation: false,
  partyReservationError: null
};

const birthdayPartyReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Get Book information reducers
  switch (action.type) {
    case birthdayPartyActionTypes.FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA:
      return {
        ...state,
        isLoadingBirthdayAvailabilityData: true
      };
    case birthdayPartyActionTypes.FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingBirthdayAvailabilityData: false,
        birthdayAvailabilityData: action.birthdayAvailabilityData,
        birthdayAvailabilityDataError: ''
      };
    case birthdayPartyActionTypes.FETCH_PARTY_BOOKING_WIDGET_SEARCH_DATA_ERROR:
      return {
        ...state,
        isLoadingBirthdayAvailabilityData: false,
        birthdayAvailabilityDataError: action.birthdayAvailabilityDataError
      };

    case birthdayPartyActionTypes.SET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA:
      return {
        ...state,
        partyDate: action.partyDate,
        partyGuestsCount: action.partyGuestsCount,
        partyTime: action.partyTime,
        partyAdults: action.partyAdults,
        partyKids: action.partyKids,
        partyInfant: action.partyInfant,
        partySpectators: action.partySpectators,
        ...(!isNaN(action.numberOfExtraGuests) && { numberOfExtraGuests: action.numberOfExtraGuests })
      };

    case birthdayPartyActionTypes.RESET_PARTY_BOOKING_WIDGET_FORM_SEARCH_DATA:
      return {
        ...state,
        partyDate: null,
        partyGuestsCount: null,
        partyTime: null,
        partyAdults: null,
        partyKids: null,
        partyInfant: null,
        partySpectators: null,
        numberOfExtraGuests: null,
        selectedParty: null
      };

    case birthdayPartyActionTypes.SET_SELECTED_BIRTHDAY_PARTY:
      return {
        ...state,
        selectedParty: action.selectedParty
      };

    case birthdayPartyActionTypes.TOGGLE_BIRTHDAY_PARTY_DATE_PICKER:
      return {
        ...state,
        isDatePickerOpen: action.isDatePickerOpen
      };

    case birthdayPartyActionTypes.IS_LOADING_CREATE_PARTY_BOOKING_RESERVATION:
      return {
        ...state,
        isLoadingCreateBirthdayReservation: true,
        partyReservationError: null
      };
    case birthdayPartyActionTypes.CREATE_PARTY_BOOKING_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoadingCreateBirthdayReservation: false,
        partyReservationError: null
      };
    case birthdayPartyActionTypes.CREATE_PARTY_BOOKING_RESERVATION_ERROR:
      return {
        ...state,
        isLoadingCreateBirthdayReservation: false,
        partyReservationError: action.error
      };
    case birthdayPartyActionTypes.CLEAR_PARTY_BOOKING_RESERVATION:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default birthdayPartyReducer;
