// Cart action types
const cartActionTypes = {
  SET_CART_RESORT_LOCATION: 'SET_CART_RESORT_LOCATION',
  RESET_CART_RESORT_LOCATION: 'RESET_CART_RESORT_LOCATION',
  SET_CART_SUITE: 'SET_CART_SUITE',
  SET_CART_PACKAGES: 'SET_CART_PACKAGES',
  SET_CART_LAST_VISITED_STEP: 'SET_CART_LAST_VISITED_STEP',
  SET_EXPIRED_CART: 'SET_EXPIRED_CART',
  RESET_EXPIRED_CART: 'RESET_EXPIRED_CART'
};

export default cartActionTypes;
