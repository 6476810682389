// Lodge Config Actions

import LodgeApiClient from '../../../api/clients/LodgeApiClient';
import { updateCurrentLodge } from '../CurrentLodge/currentLodge.actions';
import { getResortLocation } from '../CurrentLodge/currentLodge.selectors';
import * as plansActionCreators from '../Plans/plans.actions';
import lodgeConfigActionTypes from './lodgeConfig.types';

const verifyIfIsEnabled = option => {
  const optionValue = typeof option === 'boolean' ? option : false;
  return optionValue;
};

const verifyPointsMultiplier = option => {
  const optionValue = typeof option === 'number' ? option / 100 : 0.1;
  return optionValue;
};

const transformStringToArray = option => {
  return JSON.parse(option);
};

export const getLoyaltyConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);
  const lastResortLocation =
    localStorage.getItem('state') && getResortLocation(JSON.parse(localStorage.getItem('state')).currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'loyalty');

    resortLocation !== lastResortLocation && dispatch(plansActionCreators.cleanIsCaliforniaResident());

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING,
      isLoyaltyEnabled: verifyIfIsEnabled(configurationData.data.isLoyaltyEnabled),
      isInPrimerState: verifyIfIsEnabled(configurationData.data.isInPrimerState),
      isOffersEnabled: verifyIfIsEnabled(configurationData.data.isOffersEnabled),
      loyaltyPointsMultiplier: verifyPointsMultiplier(configurationData.data.loyaltyPointsMultiplier),
      isLoyaltyReferralEnabled: verifyIfIsEnabled(configurationData.data.isLoyaltyReferralEnabled),
      showCaliforniaResortFeeVariation: configurationData.data.showCaliforniaResortFeeVariation,
      isLoyaltyPointsExpirationOnProfileEnabled: verifyIfIsEnabled(
        configurationData.data.isLoyaltyPointsExpirationOnProfileEnabled
      ),
      loyaltyPointsToEarn: configurationData.data.loyaltyPointsToEarn,
      loyaltyEarnThresholdNights: configurationData.data.loyaltyEarnThresholdNights ?? 2,
      showPackagesInAddGuestsModal: configurationData.data.showPackagesInAddGuestsModal,
      isManageYourReservationEnabled: configurationData.data.isManageYourReservationEnabled
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG, configFetchIsLoading: false });
};
export const getPlanConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_PLAN });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PLAN, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'plan');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_PLAN,
      config: {
        additionalPersonAmount: configurationData.data.additionalPersonAmount,
        affirmPubKey: configurationData.data.affirmPubKey,
        filters: configurationData.data.filters,
        isFetchRestrictionsEnabled: configurationData.data.isFetchRestrictionsEnabled,
        isHighestRatesEnabled: configurationData.data.isHighestRatesEnabled,
        isRateCalEnabled: configurationData.data.isRateCalEnabled,
        updateSearchFullPageRefresh: configurationData.data.updateSearchFullPageRefresh,
        includeResortFeeInSuitePrice: configurationData.data.includeResortFeeInSuitePrice,
        pricePerWaterPass: configurationData.data.pricePerWaterPass,
        isRoomUnbundledRateWPAvailable: configurationData.data.isRoomUnbundledRateWPAvailable,
        suitePriceIncludedTaxesAndFees: configurationData.data.suitePriceIncludedTaxesAndFees ?? []
      }
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_PLAN, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PLAN, configFetchIsLoading: false });
};
export const getUserSettingsByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_USER_SETTINGS });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_USER_SETTINGS, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'user-settings');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_USER_SETTINGS,
      newPatchEndpoint: configurationData.data['new-patch-endpoint']
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_USER_SETTINGS, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_USER_SETTINGS, configFetchIsLoading: false });
};
export const getCreditPackageConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CREDIT_PACKAGE, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'credit-packages');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CREDIT_PACKAGE,
      isDiningPackagesEnhancedUIEnabled: verifyIfIsEnabled(configurationData.data.isDiningPackagesEnhancedUIEnabled)
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CREDIT_PACKAGE, configFetchIsLoading: false });
};
export const getCmpConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CMP });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CMP, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'cmp');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CMP,
      isDynamicTileEnabled: verifyIfIsEnabled(configurationData.data.isDynamicTileEnabled),
      hideOnlineCheckinText: verifyIfIsEnabled(configurationData.data.hideOnlineCheckinText),
      parkingFeeCallout: verifyIfIsEnabled(configurationData.data?.parkingFeeCallout),
      parkingFeeCalloutTitle: configurationData.data?.parkingFeeCalloutTitle ?? '',
      parkingFeeCalloutDescription: configurationData.data?.parkingFeeCalloutDescription ?? '',
      uffTileEnabled: verifyIfIsEnabled(configurationData.data.uffTileEnabled),
      isCancelReservationEnabled: verifyIfIsEnabled(configurationData.data.isCancelReservationEnabled),
      isAddGuestsEnabled: verifyIfIsEnabled(configurationData.data.isAddGuestsEnabled)
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CMP, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CMP, configFetchIsLoading: false });
};
/* ABCD TEST FOR PARKING FEE */
export const getPaymentConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_PAYMENT });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PAYMENT, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'payment');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_PAYMENT,
      perNightParkingFee1: configurationData.data.perNightParkingFee1,
      perNightParkingFee2: configurationData.data.perNightParkingFee2,
      perNightParkingFee3: configurationData.data.perNightParkingFee3,
      parkingFeeStartDate: configurationData.data.parkingFeeStartDate,
      parkingFeeEndDate: configurationData.data.parkingFeeEndDate,
      clientCardTokenization: configurationData.data.clientCardTokenization,
      creditCardAuthUrl: configurationData.data.creditCardAuthUrl,
      creditEnabled: configurationData.data.creditEnabled,
      affirmEnabled: configurationData.data.affirmEnabled,
      applePayEnabled: configurationData.data.applePayEnabled,
      googlePayEnabled: configurationData.data.googlePayEnabled,
      payPalEnabled: configurationData.data.payPalEnabled,
      amexEnabled: verifyIfIsEnabled(configurationData.data?.isAmexEnabled),
      bookingSummaryParkingFee: configurationData.data?.bookingSummaryParkingFee,
      taxAndFeeLabels: configurationData.data?.taxAndFeeLabels
    });
  } catch (error) {
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_PAYMENT, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PAYMENT, configFetchIsLoading: false });
};
/* ABCD TEST FOR PARKING FEE */
export const getLeadGenConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_LEADGEN });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_LEADGEN, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'lead-gen');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_LEADGEN,
      shouldLeadGenPop: configurationData.data.shouldLeadGenPop
    });
  } catch (error) {
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_LEADGEN, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_LEADGEN, configFetchIsLoading: false });
};
export const resetLodgeConfig = () => async (dispatch, getState) => {
  const {
    lodgeLocations: { locationList }
  } = getState();

  dispatch(updateCurrentLodge(locationList));
  dispatch({ type: lodgeConfigActionTypes.RESET_LODGE_CONFIG });
};
export const getCartConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CART });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CART, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'cart');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CART,
      config: {
        isCartActive: configurationData.data.isCartActive
      }
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CART, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CART, configFetchIsLoading: false });
};
export const getBookingWidgetConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_BOOKING_WIDGET });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_BOOKING_WIDGET, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'booking-widget');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_BOOKING_WIDGET,
      beginReservationsDate: configurationData.data.beginReservationsDate,
      maxOccupancy: configurationData.data.maxOccupancy
    });
  } catch (error) {
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_BOOKING_WIDGET, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_BOOKING_WIDGET, configFetchIsLoading: false });
};

export const getPartyBookingWidgetConfigByResort = () => async (dispatch, getState) => {
  const resortLocation = getResortLocation(getState().currentLodge);

  dispatch({ type: lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET });
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PARTY_BOOKING_WIDGET, configFetchIsLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const configurationData = await configurationClient.getConfiguration(resortLocation, 'bday-booking-widget');

    dispatch({
      type: lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET,
      isBdayBookingWidgetActive: configurationData.data.isBdayBookingWidgetActive,
      birthdayPartyInclusionList: transformStringToArray(configurationData.data.birthdayPartyInclusionList) ?? [],
      beginPartyReservationsDate: configurationData.data.beginReservationsDate,
      partyKidsPerAdultRatio: configurationData.data.kidsPerAdultRatio,
      partyMaxGuestCount: configurationData.data.maxGuestCount,
      minimumBookingDaysRestriction: configurationData.data.minimumBookingDaysRestriction
    });
  } catch (error) {
    dispatch({ type: lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET, configFetchError: error });
  }
  dispatch({ type: lodgeConfigActionTypes.LOADING_LODGE_CONFIG_PARTY_BOOKING_WIDGET, configFetchIsLoading: false });
};
