import styled, { keyframes, css } from 'styled-components';

import { Flex, Text, Box } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';

const breatheAnimationDesk = keyframes`
 0% {   top: 0px; opacity: 0}

 100% {   top: 117px; opacity: 1; }
`;

const breatheAnimationDMobile = keyframes`
 0% {   top: 0px; opacity: 0}

 100% {   top: 80px; opacity: 1; }
`;

export const FloatingMessageContainer = styled(Flex)`
  z-index: 9999;
  position: fixed;
  flex-direction: row;
  max-width: 791px;
  min-height: 56px;
  padding: ${theme.space[3]}px;
  border-radius: 8px;

  ${({ variant }) => {
    switch (variant) {
      case 'error':
        return css`
          border: solid 1px ${theme.colors.digitalBrightRed[100]};
          background-color: ${theme.colors.digitalBrightRed[10]};
        `;
      default:
        return css`
          border: solid 1px ${theme.colors.kiddiePoolBlue[100]};
          background-color: ${theme.colors.kiddiePoolBlue[10]};
        `;
    }
  }};

  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  justify-content: space-between;
  align-items: flex-start;
  animation-name: ${breatheAnimationDMobile};
  animation-duration: 2s;

  ${theme.mediaQueries.md} {
    top: 117px;
    animation-name: ${breatheAnimationDesk};
    width: 100%;
    align-items: center;
  }
`;

export const FloatingMessageImg = styled.img`
  margin-right: ${theme.space[3]}px;
`;

export const FloatingInnerMessageContainer = styled(Flex)`
  align-items: flex-start;

  ${theme.mediaQueries.md} {
    align-items: center;
  }
`;

export const InnerMessage = styled(Text)`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #333;
`;

export const CloseFloatingMessage = styled(Box)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  & > svg {
    width: 16px;
    height: 16px;
  }
  ${theme.mediaQueries.md} {
    width: 24px;
    height: 24px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;
