import styled, { css } from 'styled-components';
import {
  space,
  width,
  height,
  color,
  textAlign,
  borders,
  borderColor,
  minWidth,
  maxWidth,
  minHeight,
  boxShadow,
  borderRadius
} from 'styled-system';
import theme from './themes/theme';

const Box = styled.div`
  ${props =>
    css`
      background-image: ${props.backgroundImage && props.backgroundImage};
      background-size: ${props.backgroundSize && props.backgroundSize};
      background-repeat: ${props.backgroundRepeat && props.backgroundRepeat};
      background-color: ${props.backgroundColor && props.backgroundColor};
    `}

  ${space} ${width} ${maxWidth} ${color} ${textAlign} ${borders} ${borderRadius} ${borderColor} ${height} ${minWidth} ${minHeight} ${boxShadow};
`;

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...boxShadow.propTypes,
  ...borders.propTypes,
  ...borderRadius.propTypes,
  ...borderColor.propTypes,
  ...height.propTypes,
  ...minWidth.propTypes,
  ...minHeight.propTypes,
  ...maxWidth.propTypes
};

Box.defaultProps = {
  theme: theme
};

Box.displayName = 'Box';

export default Box;
