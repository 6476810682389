import { getGeolocationUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Geolocation endpoints
 *
 * @class GeolocationApiClient
 * @extends {GWRApiClient}
 */
export default class GeolocationApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   * The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(token = '') {
    const urlAux = getGeolocationUrl();

    super(urlAux, token);
  }

  /**
   * function to get geolocation data
   *
   * @function
   */
  async getGeolocationData() {
    const urlRequest = `${this.url}`;
    let request = await super.getApiCall(urlRequest);

    return request;
  }
}
