import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '..';
import { deleteState } from '../../../infrastructure/middleware/storage/reduxLocalStorage';
import theme from '../themes/theme';
import { TextLikeLink } from './styles';

const propTypes = {
  /** Changes the hover color for text and icons */
  hoverColor: PropTypes.string,
  /** Changes the default color for text and icons */
  color: PropTypes.string,
  /** Changes the text font weight */
  fontWeight: PropTypes.number,
  /** Use this prop to clear redux local storage on redirects */
  clearStateOnRedirect: PropTypes.bool,
  /** An string indicating if the component should render an element that is different from the normal anchor element. */
  as: PropTypes.string,
  /** Icon to display */
  icon: PropTypes.func,
  /** Size of the icon */
  size: PropTypes.string,
  /** Position for the icon */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** Prop to add the underline to the component */
  underline: PropTypes.bool,
  /** Props to be passed to the icon component */
  iconProps: PropTypes.object
};

const defaultProps = {
  color: theme.colors.preciousStoneBlue[100],
  fontWeight: theme.fontWeights.bold,
  clearStateOnRedirect: false,
  as: 'a',
  iconPosition: 'right',
  size: '14px',
  underline: true,
  iconProps: null,
  lineHeight: null
};

/** Use the `TextLink` component to indicate a clickable text */
const TextLink = ({
  clearStateOnRedirect,
  onClick,
  children,
  icon: Icon,
  size,
  fontSize,
  iconPosition,
  iconProps,
  lineHeight,
  ...restProps
}) => {
  const handleClick = () => {
    if (clearStateOnRedirect) {
      deleteState();
    }

    onClick && onClick();
  };

  return (
    <TextLikeLink {...restProps} onClick={handleClick}>
      {Icon && iconPosition === 'left' && <Icon size={size} data-testid="TextLink:iconLeft" {...iconProps} />}
      <Text as="span" fontSize={fontSize} lineHeight={lineHeight}>
        {children}
      </Text>
      {Icon && iconPosition === 'right' && <Icon size={size} data-testid="TextLink:iconRight" {...iconProps} />}
    </TextLikeLink>
  );
};

TextLink.defaultProps = defaultProps;
TextLink.propTypes = propTypes;

export default TextLink;
