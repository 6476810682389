import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import useGetLodgeConfig from '../../../../../../hooks/api_hooks/useGetLodgeConfig';
import { getTheFirstElementOfArray } from '../../../../../../utilities/arraysUtils';
import {
  LATE_CHECKOUT_PREFIXES,
  PACKAGE_CHARGE_TYPE,
  PACKAGE_TYPE,
  PARKING_PACKAGE_CODE,
  ROOM_RATE_TYPE
} from '../../../../../../utilities/constants';
import { addNewTag } from '../../../../../../utilities/packagesUtils';
import { Box, Text } from '../../../../../ui-kit';
import { editCopyByReplaceMultipleStrings, pluralize } from '../../../../../utilities/copyFunctions';
import { SummaryContent } from '../../../Content';
import * as COPY from '../utilities/copy';

const propTypes = {
  /** Currently selected packages for the reservation */
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      packageName: PropTypes.string.isRequired,
      packageCode: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired
    }).isRequired
  ),

  /** Flag to indicate if there are selected packages for the reservation */
  hasSelectedPackages: PropTypes.bool,

  /** Newly added package code */
  newAddedPackageCode: PropTypes.string
};

const PKGS_WITH_NO_QTY = [LATE_CHECKOUT_PREFIXES.code, PARKING_PACKAGE_CODE];

export const PackagesList = ({
  packages,
  hasSelectedPackages,
  newAddedPackageCode,
  totalBillAndNonBillGuest,
  numberOfNights,
  rateType,
  resortLocation,
  addGuestReservation
}) => {
  const [fetchUFFPackageCode] = useGetLodgeConfig();

  const getUFFPackageCode = useCallback(async () => {
    const onSuccess = result => {
      const uffJSON = JSON.parse(result.uffPackageCodes);
      const UFF_CODES = Object.values(uffJSON);
      PKGS_WITH_NO_QTY.push(...UFF_CODES);
    };
    const onFailure = err => console.log(err);

    await fetchUFFPackageCode(resortLocation?.resortLocation, 'uff', { onSuccess, onFailure });
  }, [resortLocation, fetchUFFPackageCode]);

  useEffect(() => {
    getUFFPackageCode();
  }, [getUFFPackageCode]);

  const objectForCopy = {
    '<NUM_GUESTS>': totalBillAndNonBillGuest,
    '<NUMB_NIGHTS>': numberOfNights,
    '<GUEST_PLURAL>': pluralize('guest', totalBillAndNonBillGuest),
    '<NIGHTS_PLURAL>': pluralize('night', numberOfNights)
  };

  const areWaterParkPassesIncluded = rateType !== ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass;

  return (
    <>
      {addGuestReservation ? <SummaryContent.Divider /> : null}
      {addGuestReservation
        ? addGuestReservation.addOns.map((item, index) => {
            return (
              <SummaryContent.Item key={`packageCode-${index}`}>
                <Box style={{ flexGrow: 1 }}>
                  <SummaryContent.Description>
                    {item?.quantity ? (
                      <Text fontSize={0} lineHeight={18}>
                        {item.quantity} {item.content.title}
                      </Text>
                    ) : (
                      <Text fontSize={0} lineHeight={18}>
                        {item.content.title}
                      </Text>
                    )}
                    {item?.splash && <SummaryContent.NewGuestsTag splashText={item?.splash} />}
                  </SummaryContent.Description>
                </Box>
                <SummaryContent.SubtotalAmount>
                  <Text fontSize={0} lineHeight={18}>
                    {item.totalPrice === 0 ? COPY.SUMMARY_INCLUDED_REGULAR : `$${item.totalPrice.toFixed(2)}`}
                  </Text>
                </SummaryContent.SubtotalAmount>
              </SummaryContent.Item>
            );
          })
        : null}
      {areWaterParkPassesIncluded && !addGuestReservation ? (
        <>
          <SummaryContent.Divider />
          <SummaryContent.Item>
            <SummaryContent.Description>
              <Text fontSize={0} lineHeight={18}>
                {COPY.SUMMARY_WATER_PARK_PASSES}
              </Text>
            </SummaryContent.Description>
            <SummaryContent.SubtotalAmount>
              <Text fontSize={0} lineHeight={18}>
                {COPY.SUMMARY_INCLUDED}
              </Text>
            </SummaryContent.SubtotalAmount>
          </SummaryContent.Item>
        </>
      ) : null}

      {hasSelectedPackages &&
        !addGuestReservation &&
        packages.map((item, index) => {
          // This is for the Dining deals name to match with the rest of the app
          const copyObject = {
            '<ALLOWANCE>': item.packageAllowance,
            '<PACKAGE_NAME>': getTheFirstElementOfArray(item.packageName.split(' '))
          };
          const packageName =
            item.packageType === PACKAGE_TYPE.dining &&
            (item.packageCharge === PACKAGE_CHARGE_TYPE.byPackage ||
              item.chargeType === PACKAGE_CHARGE_TYPE.byPackage) &&
            item.packageAllowance !== 0 &&
            item.packageAllowance !== null
              ? editCopyByReplaceMultipleStrings(COPY.SUMMARY_DINING_CREDIT_PACKAGE_TITLE, copyObject)
              : item.packageName;

          return item.quantity === 0 ? null : (
            <SummaryContent.Item key={`packageCode-${index}`}>
              <Box style={{ flexGrow: 1 }}>
                <SummaryContent.Description>
                  <Text fontSize={0} lineHeight={18}>
                    {`${
                      PKGS_WITH_NO_QTY.some(code => item.packageCode.includes(code)) ||
                      (item.packageType === PACKAGE_TYPE.dining &&
                        (item.packageCharge === PACKAGE_CHARGE_TYPE.personPerDay || item.packageAllowance !== 0))
                        ? ''
                        : item.quantity
                    } `}
                    {packageName}
                  </Text>
                  {addNewTag(newAddedPackageCode, item.packageCode) && <SummaryContent.NewTag />}
                </SummaryContent.Description>
                {item.packageType === PACKAGE_TYPE.dining && item.packageCharge === PACKAGE_CHARGE_TYPE.personPerDay ? (
                  <SummaryContent.Description>
                    <Text fontSize={9} lineHeight={12}>
                      {editCopyByReplaceMultipleStrings(COPY.DINING_DEALS_DETAILS, objectForCopy)}
                    </Text>
                  </SummaryContent.Description>
                ) : null}
              </Box>
              <SummaryContent.SubtotalAmount>
                <Text fontSize={0} lineHeight={18}>{`$${item.amount.toFixed(2)}`}</Text>
              </SummaryContent.SubtotalAmount>
            </SummaryContent.Item>
          );
        })}
    </>
  );
};

PackagesList.propTypes = propTypes;

PackagesList.defaultProps = {
  hasSelectedPackages: false
};
