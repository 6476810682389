import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for document storage endpoints
 *
 * @class DocumentStoreApiClient
 * @extends {GWRApiClient}
 */
export default class DocumentStoreApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * set a common url to call (urlRequest)
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(url, token = '') {
    super(url, token);
    this.urlRequest = url;
  }

  /**
   * @function
   * Get the item for the current user logged in
   */
  async getAll() {
    let request = await super.getApiCallAuth(this.urlRequest);

    return request;
  }

  /**
   * @function
   * Insert/Update item data for the current user logged in
   * @param {string} data
   */
  async upsert(data) {
    let request = await super.postApiCallAuth(this.urlRequest, data);

    return request;
  }

  /**
   * @function
   * Send a Delete request for the item of the current user logged in
   */
  async deleteAll() {
    let request = await super.deleteApiCallAuth(this.urlRequest);

    return request;
  }
}
