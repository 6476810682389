export const SUMMARY_CART_VARIATIONS = {
  CART: 'cart',
  SUMMARY: 'summary'
};

export const CART_MODIFY_MODAL_VARIATIONS = {
  SUITE: 'SUITE',
  PACKAGE: 'PACKAGE'
};

export const CART_MODIFY_MODAL_TYPES = {
  REMOVE: 'REMOVE',
  EDIT: 'EDIT'
};
