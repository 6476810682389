import PropTypes from 'prop-types';
import React from 'react';

import { CheckInOutDate } from '../../../../../CheckInOutDates';
import { Flex } from '../../../../../ui-kit';
import ArrowRightIcon from '../../../../../ui-kit/icons/ArrowRight';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';

const propTypes = {
  /** Checkin Date in the MM/DD format */
  checkIn: PropTypes.string.isRequired,

  /** Checkout Date in the MM/DD format */
  checkOut: PropTypes.string.isRequired,

  /** Number of nights for the reservation */
  numberOfNights: PropTypes.number.isRequired
};

export const Header = ({ checkIn, checkOut, numberOfNights }) => {
  const numberOfNightsLabel = `${numberOfNights} ${numberOfNights && numberOfNights > 1 ? 'Nights' : 'Night'}`;

  return (
    <>
      <SummaryContent.Item mb={`${theme.space[3]}px`} />
      <SummaryContent.Item>
        <CheckInOutDate>
          <CheckInOutDate.Label
            fontSize={['12px', null, null, '12px']}
            lineHeight="18px"
            height={['18px', null, null, '18px']}>
            {COPY.CHECK_IN}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date lineHeight="24px" fontSize={['24px', '22px']}>
            {checkIn}
          </CheckInOutDate.Date>
        </CheckInOutDate>
        <Flex mt={['38px', '30px']} flex="1" justifyContent="center">
          <ArrowRightIcon color={theme.colors.preciousStoneBlue[100]} height="100%" />
        </Flex>
        <CheckInOutDate>
          <CheckInOutDate.Label
            fontSize={['12px', null, null, '12px']}
            lineHeight="18px"
            height={['22px', null, null, '14px']}>
            {COPY.CHECK_OUT}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date lineHeight="24px" fontSize={['24px', null, null, '22px']}>
            {checkOut}
          </CheckInOutDate.Date>
        </CheckInOutDate>
      </SummaryContent.Item>
      <SummaryContent.Item fontWeight="bold" fontSize={['12px', null, null, '12px']}>
        <SummaryContent.Description lineHeight="16px">{numberOfNightsLabel}</SummaryContent.Description>
      </SummaryContent.Item>
    </>
  );
};

Header.propTypes = propTypes;
