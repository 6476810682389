import styled, { css } from 'styled-components';
import { buttonStyle, variant } from 'styled-system';

const widthStyle = ({ fluid }) => {
  if (!fluid) return null;

  return css`
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  `;
};

const floatingStyle = ({ floating }) => {
  if (!floating) return null;

  return css`
    transform: rotate(-90deg) translateY(-100%);
    transform-origin: right top;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `;
};

const buttonSizeStyle = variant({ key: 'buttonSize', prop: 'size' });

export const StyledButton = styled.button`
    ${buttonSizeStyle}
    ${buttonStyle}
    ${widthStyle}
    ${floatingStyle}
  `;
