import React from 'react';
import Flex from '../../ui-kit/Flex';

const SummaryItem = ({ leftColumn, centerColumn, rightColumn, bold, highlight, ...props }) => (
  <Flex mb={3} flexFirection="row" justifyContent="space-between" {...props}>
    <Flex width={[1 / 2, 1 / 3, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}>{leftColumn}</Flex>
    <Flex justifyContent="flex-end" width={[1 / 2, 1 / 3, 1 / 4, 1, 1 / 2, 1 / 2]}>
      {centerColumn}
    </Flex>
    <Flex width={[1 / 4, 1 / 3, 1 / 4, 1 / 4, 1 / 4, 1 / 4]} justifyContent="flex-end">
      {rightColumn}
    </Flex>
  </Flex>
);

export default SummaryItem;
