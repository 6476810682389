const duettoKeys = {
  CATEGORY: 't',
  START_DATE: 'sd',
  END_DATE: 'ed',
  HOTEL_ID: 'h',
  QUOTES: 'qq',
  RATE: 'r',
  ROOM_TYPE: 'rt',
  RATE_CODE: 'rc',
  CONFIRMATION_ID: 'b',
  NUM_ADULTS: 'na',
  NUM_CHILDREN: 'nc'
};

const eventTypes = {
  SEARCH: 's',
  BOOKING: 'b',
  QUOTE: 'q',
  DENIAL: 'd'
};

const initialize = appId => {
  if (!appId) {
    throw new Error('an app ID is required to start tracking.');
  }

  window.duetto = {
    appId: appId,
    tld: getHost(window),
    queue: []
  };

  callDuetto();
};

const callDuetto = () => {
  (function() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'capture.duettoresearch.com/assets/js/duetto/duetto.js';
    var n = document.getElementsByTagName('script')[0];
    n.parentNode.insertBefore(s, n);
  })();
};

const getHost = globalObj => {
  if (!globalObj) return;

  return globalObj.location.host;
};

const trackAvailabilitySearch = ({ resortLocation, startDate, endDate }) => {
  if (!window.duetto) return;
  const payload = {
    [duettoKeys.CATEGORY]: eventTypes.SEARCH,
    [duettoKeys.HOTEL_ID]: resortLocation,
    [duettoKeys.START_DATE]: startDate,
    [duettoKeys.END_DATE]: endDate
  };

  window.duetto.queue.push(payload);
};

const trackAvailabilityDenial = ({ resortLocation, startDate, endDate }) => {
  if (!window.duetto) return;
  const payload = {
    [duettoKeys.CATEGORY]: eventTypes.DENIAL,
    [duettoKeys.HOTEL_ID]: resortLocation,
    [duettoKeys.START_DATE]: startDate,
    [duettoKeys.END_DATE]: endDate
  };

  window.duetto.queue.push(payload);
};

const trackQuote = ({ resortLocation, suites, startDate, endDate }) => {
  if (!window.duetto) return;
  const payload = {
    [duettoKeys.CATEGORY]: eventTypes.QUOTE,
    [duettoKeys.HOTEL_ID]: resortLocation,
    [duettoKeys.QUOTES]: suites.map(suite => ({
      [duettoKeys.RATE]: suite.rate,
      [duettoKeys.ROOM_TYPE]: suite.roomType,
      [duettoKeys.RATE_CODE]: suite.rateCode
    })),
    [duettoKeys.START_DATE]: startDate,
    [duettoKeys.END_DATE]: endDate
  };

  window.duetto.queue.push(payload);
};

const trackBooking = ({
  resortLocation,
  confirmationId,
  roomType,
  rateCode,
  rate,
  numAdults,
  numChildren,
  startDate,
  endDate
}) => {
  if (!window.duetto) return;
  const payload = {
    [duettoKeys.CATEGORY]: eventTypes.BOOKING,
    [duettoKeys.HOTEL_ID]: resortLocation,
    [duettoKeys.CONFIRMATION_ID]: confirmationId,
    [duettoKeys.ROOM_TYPE]: roomType,
    [duettoKeys.RATE_CODE]: rateCode,
    [duettoKeys.RATE]: rate,
    [duettoKeys.NUM_ADULTS]: numAdults,
    [duettoKeys.NUM_CHILDREN]: numChildren,
    [duettoKeys.START_DATE]: startDate,
    [duettoKeys.END_DATE]: endDate
  };

  window.duetto.queue.push(payload);
};

const duetto = {
  initialize,
  trackAvailabilitySearch,
  trackAvailabilityDenial,
  trackQuote,
  trackBooking
};

export default duetto;
