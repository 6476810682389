import React from 'react'

const QuestionIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 16 17'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M8 0.160156C5.87827 0.160156 3.84344 1.00301 2.34315 2.5033C0.842855 4.00359 1.32608e-08 6.03842 0 8.16016C5.30433e-08 10.2819 0.842855 12.3167 2.34315 13.817C3.84344 15.3173 5.87827 16.1602 8 16.1602C10.1217 16.1602 12.1566 15.3173 13.6569 13.817C15.1571 12.3167 16 10.2819 16 8.16016C16 6.03842 15.1571 4.00359 13.6569 2.5033C12.1566 1.00301 10.1217 0.160156 8 0.160156V0.160156ZM8.51172 2.96094C9.51065 2.96094 10.3023 3.18222 10.8887 3.62305C11.4751 4.06387 11.7676 4.63977 11.7676 5.35156C11.7676 5.76003 11.7073 6.12205 11.5859 6.4375C11.4687 6.74891 11.3004 7.01948 11.082 7.25C10.8636 7.48052 10.6023 7.68715 10.2949 7.86914C9.9916 8.05113 9.64788 8.21755 9.26367 8.36719V9.75H7.12695V7.70508C7.4141 7.62824 7.67377 7.54964 7.9043 7.46875C8.13886 7.38786 8.38388 7.25621 8.63867 7.07422C8.87728 6.91245 9.06185 6.72411 9.19531 6.50977C9.33282 6.29542 9.40234 6.05222 9.40234 5.78125C9.40234 5.37682 9.27069 5.08978 9.00781 4.91992C8.74898 4.74602 8.38334 4.66016 7.91016 4.66016C7.61897 4.66016 7.2899 4.72228 6.92188 4.84766C6.55789 4.97303 6.22324 5.13582 5.91992 5.33398H5.67773V3.48242C5.93657 3.37323 6.33516 3.25986 6.87305 3.14258C7.41093 3.02125 7.95765 2.96094 8.51172 2.96094ZM6.98242 10.5742H9.43359V12.1758H6.98242V10.5742Z' />
  </svg>
)

QuestionIcon.displayName = 'QuestionIcon'

QuestionIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default QuestionIcon