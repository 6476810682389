import axios from 'axios';
import ApiResponseError from '../../infrastructure/Exceptions/ApiResponseError';
import { ErrorTypes } from '../../infrastructure/Exceptions/utils/ResponseErrorUtils';
import { encodeUrlQueryParameters } from '../../infrastructure/serializer/axios_serializers';
import { isArrayEmpty } from '../../utilities/arraysUtils';
import AuthClient from '../auth/AuthClient';

const RequestMethods = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};
const headerJson = { 'Content-Type': 'application/json' };

const authClient = new AuthClient();
const renewToken = async () => {
  var user = authClient.getUser();
  if (user) {
    // check to see if we should renew the access token
    if (user.accessTokenExpiry < new Date().getTime()) {
      await authClient.renewToken();
    }
  }
};

/**
 * Abstract wrapper class to call the API Endpoints
 * @abstract
 * @class GWRApiClient
 */
export default class GWRApiClient {
  /**
   * @constructor
   * If a token is provided, then we use that token and we set isLoggedIn as true to perform the API calls
   * If there isn't any token provided, then we use the one from the current logged user.
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(url = '', token = '') {
    this.url = url;
    this.token = token;
    this.isLoggedIn = token !== '';

    if (token === '') {
      var user = authClient.getUser();
      if (user && user.id) {
        this.token = user.accessToken;
        this.isLoggedIn = true;
      }
    }

    encodeUrlQueryParameters();
  }

  /**
   * @function
   * Upper wrapper method to perform the call to the API.
   * @param {string} urlRequest
   * The url to the API endpoint
   * @param {RequestMethods} method
   * The http verb
   * @param {object} requestObject
   * All the information regarding the request: params (query string), data and headers
   */
  async apiCall(urlRequest, method, requestObject, withCredentials = true) {
    const requestObjectWithCredentials = {
      ...requestObject,
      withCredentials: withCredentials
    };

    // GET REQUIRED FIELDS
    const requiredFields = requestObject.requiredFields;

    // VALIDATE REQURED FIELDS ON PARAMS OR DATA
    if (requiredFields && !isArrayEmpty(requiredFields)) {
      const hasParams = 'params' in requestObject;
      const hasData = 'data' in requestObject;

      const verifyRequiredFields = (fields, values) => {
        const missingValueOnRequiredField = fields.filter(key => !values[key]);
        if (!isArrayEmpty(missingValueOnRequiredField)) {
          const error = this.throwResponseError(
            ErrorTypes.MissingRequiredField,
            null,
            `${missingValueOnRequiredField.join(', ')}`
          );
          console.log('error', error);
          return error;
        }
      };

      if (hasParams) {
        verifyRequiredFields(requiredFields, requestObject.params);
      }
      if (hasData) {
        verifyRequiredFields(requiredFields, requestObject.data);
      }
    }

    try {
      switch (method) {
        case RequestMethods.GET:
          return await axios.get(urlRequest, requestObjectWithCredentials);
        case RequestMethods.PUT:
          return await axios.put(urlRequest, requestObject.data, {
            headers: requestObject.headers,
            withCredentials: withCredentials
          });
        case RequestMethods.POST:
          return await axios.post(urlRequest, requestObject.data, {
            headers: requestObject.headers,
            withCredentials: withCredentials
          });
        case RequestMethods.DELETE:
          return await axios.delete(urlRequest, requestObjectWithCredentials);
        case RequestMethods.PATCH:
          return await axios.patch(urlRequest, requestObject.data, {
            headers: requestObject.headers,
            withCredentials: withCredentials
          });
        default:
          return undefined;
      }
    } catch (err) {
      this.throwResponseError(ErrorTypes.ResponseError, err);
    }
  }

  /**
   * @function
   * Function to call a GET request for a non authenticated API endpoint.
   * @param {string} urlRequest
   * @param {object} params
   * sent as a query string parameters
   */
  async getApiCall(urlRequest, params = {}, withCredentials = true, requiredFields = []) {
    let requestObject = {
      headers: headerJson,
      params: params,
      requiredFields
    };
    return this.apiCall(urlRequest, RequestMethods.GET, requestObject, withCredentials);
  }

  /**
   * @function
   * Function to call a GET request for an Authenticated API endpoint.
   * If the user is not logged in nor any token provided then an Error will be thrown.
   * @param {string} urlRequest
   * @param {object} params
   * sent as a query string parameters
   */
  async getApiCallAuth(urlRequest, params = {}, requiredFields = []) {
    if (!authClient.isTokenPresent()) {
      this.throwResponseError(ErrorTypes.UserNotLoggedInError);
    }

    await renewToken();

    let requestObject = {
      headers: {
        headerJson
      },
      params: params,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.GET, requestObject);
  }

  /**
   * @function
   * Function to call a PUT request for an Non-Authenticated API endpoint.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async putApiCall(urlRequest, data, requiredFields = []) {
    let requestObject = {
      headers: headerJson,
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.PUT, requestObject);
  }

  /**
   * @function
   * Function to call a PUT request for an Authenticated API endpoint.
   * If the user is not logged in nor any token provided then an Error will be thrown.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async putApiCallAuth(urlRequest, data, requiredFields = []) {
    if (!authClient.isTokenPresent()) {
      this.throwResponseError(ErrorTypes.UserNotLoggedInError);
    }

    await renewToken();

    let requestObject = {
      headers: {
        headerJson
      },
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.PUT, requestObject);
  }

  /**
   * @function
   * Function to call a POST request for an Non-Authenticated API endpoint.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async postApiCall(urlRequest, data, withCredentials = true, requiredFields = []) {
    let requestObject = {
      headers: headerJson,
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.POST, requestObject, withCredentials);
  }

  /**
   * @function
   * Function to call a POST request for an Authenticated API endpoint.
   * If the user is not logged in nor any token provided then an Error will be thrown.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async postApiCallAuth(urlRequest, data, requiredFields = []) {
    if (!authClient.isTokenPresent()) {
      this.throwResponseError(ErrorTypes.UserNotLoggedInError);
    }

    await renewToken();

    let requestObject = {
      headers: {
        headerJson
      },
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.POST, requestObject);
  }

  /**
   * @function
   * Function to call a DELETE request for an Authenticated API endpoint.
   * If the user is not logged in nor any token provided then an Error will be thrown.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async deleteApiCallAuth(urlRequest, data, requiredFields = []) {
    if (!authClient.isTokenPresent()) {
      this.throwResponseError(ErrorTypes.UserNotLoggedInError);
    }

    await renewToken();

    let requestObject = {
      headers: {
        headerJson
      },
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.DELETE, requestObject);
  }

  /**
   * @function
   * Function to call a PATCH request for an Authenticated API endpoint.
   * If the user is not logged in nor any token provided then an Error will be thrown.
   * @param {string} urlRequest
   * @param {object} data
   * Payload for the request
   */
  async patchApiCallAuth(urlRequest, data, requiredFields = []) {
    if (!authClient.isTokenPresent()) {
      this.throwResponseError(ErrorTypes.UserNotLoggedInError);
    }

    await renewToken();

    let requestObject = {
      headers: {
        headerJson
      },
      data: data,
      requiredFields
    };

    return this.apiCall(urlRequest, RequestMethods.PATCH, requestObject);
  }

  /**
   * @function
   * Error manager
   * @param {ErrorTypes} errorType
   * @param {object} err
   * @param {string} details
   * Any further details for the provided error
   */
  throwResponseError(errorType, err = {}, details = '') {
    throw new ApiResponseError(errorType, err, details);
  }

  /**
   * @function
   * Get if the user is logged in so she/he can use Auth calls
   * @returns {bool}
   */
  shouldUseAuthenticatedCalls() {
    return this.isLoggedIn;
  }
}
