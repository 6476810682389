import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { isLocationAvailableWithAffirm } from '../../../../../../api/Utils/affirmUtils';
import { isValidPrice } from '../../../../../../utilities/cmpPackagesUtils';
import { SupAmount } from '../../../../../ui-kit';
import theme, { fontSizes } from '../../../../../ui-kit/themes/theme';
import { handleTotalWithTaxes } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { AffirmLink } from './AffirmLink';

const propTypes = {
  /** Flag to indicate if exist packages for the reservation */
  hasSelectedPackages: PropTypes.bool,

  /** Total for the reservation */
  summaryTotal: PropTypes.number.isRequired,

  /** loyalty points balance */
  pointsBalance: PropTypes.number,

  /** loyalty boolean to show or not the value */
  loyaltyOptIn: PropTypes.bool
};

export const Totals = ({
  isSuperscriptStaySummarySuite,
  hasSelectedPackages,
  summaryTotal,
  pointsBalance,
  loyaltyOptIn,
  showLoyalty,
  userOffers,
  activeOffers,
  suiteTotal,
  taxesAndFees,
  roomAdjustmentTax,
  isUserAuthenticated,
  currentSelectedPackages,
  setExcedent,
  parkingFeeTotal,
  isParkingFeeABCDTestActive,
  resortLocation,
  optimizelyHierarchyFlip,
  isDesktop
}) => {
  const [refreshAffirm, setRefreshAffirm] = useState(new Date().getTime());

  const handleSummaryTotal = isUserAuthenticated
    ? handleTotalWithTaxes(
        userOffers,
        activeOffers,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        taxesAndFees,
        roomAdjustmentTax,
        currentSelectedPackages,
        setExcedent
      )
    : summaryTotal?.toFixed(2);

  useEffect(() => {
    setRefreshAffirm(new Date().getTime());
  }, [hasSelectedPackages, summaryTotal, handleSummaryTotal]);

  /* ABCD TEST FOR PARKING FEE */
  const ABCDTestForParkingFeeSummaryTotal = () => {
    const summaryTotalWithParkingFee = Number(handleSummaryTotal) + parkingFeeTotal;
    return summaryTotalWithParkingFee.toFixed(2);
  };
  /* ABCD TEST FOR PARKING FEE */

  return isValidPrice(summaryTotal) ? (
    <>
      <SummaryContent.Divider />
      <SummaryContent.Item
        fontSize={
          optimizelyHierarchyFlip
            ? !isDesktop
              ? fontSizes[1] + 'px'
              : fontSizes[0] + 'px'
            : ['18px', null, null, '18px']
        }
        fontWeight={theme.fontWeights.bold}
        role="complementary"
        aria-label="Total Price"
        lineHeight={optimizelyHierarchyFlip ? '18px' : '22px'}>
        <SummaryContent.Description data-testid="suite-total">{COPY.TOTAL}</SummaryContent.Description>
        <SummaryContent.SubtotalAmount fontWeight="bold">
          <SupAmount
            fontSize={optimizelyHierarchyFlip ? fontSizes[0] + 'px' : 7}
            textToUse={`$${isParkingFeeABCDTestActive ? ABCDTestForParkingFeeSummaryTotal() : handleSummaryTotal}`}
            avoidFormat={!isSuperscriptStaySummarySuite}
          />
        </SummaryContent.SubtotalAmount>
      </SummaryContent.Item>

      {isLocationAvailableWithAffirm(resortLocation) ? (
        <SummaryContent.Item mb={`${theme.space[7]}px`}>
          <AffirmLink
            refreshAffirm={refreshAffirm}
            summaryTotal={handleSummaryTotal}
            optimizelyHierarchyFlip={optimizelyHierarchyFlip}
            resortLocation={resortLocation}
          />
        </SummaryContent.Item>
      ) : null}
    </>
  ) : null;
};

Totals.propTypes = propTypes;

Totals.defaultProps = {
  hasSelectedPackages: false,
  pointsBalance: 0,
  loyaltyOptIn: false
};
