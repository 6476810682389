import axios from 'axios';
import ApiResponseError from '../../infrastructure/Exceptions/ApiResponseError';
import { ErrorTypes } from '../../infrastructure/Exceptions/utils/ResponseErrorUtils';
import {
    getRaFSignUpEventObject,
    getSignInEventObject,
    getSignUpEventObject
} from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { GTM_EVENT } from '../../utilities/constants';
import { emitEventsByName, EVENTS_DICTIONARY } from '../../utilities/customEventsHelpers';
import { pushEvent } from '../../utilities/dataLayerUtils';
import { getSpecificItem, SessionStorageKeys } from '../../utilities/storageUtils';
import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';

const successfulAuthCallback = (response, postLoginCallback) => {
  const currentUserData = JSON.parse(localStorage.getItem('user')) || {};
  let user = null;

  // CHECK IF THERE IS A USER IN LOCAL STORAGE
  if (Object.keys(currentUserData).length === 0) {
    // TAKE THE RESPONSE AS IS, IF THERE IS NO USER IN LOCAL STORAGE
    user = { ...response.data };
    // VERIFY IF THE RESPONSE HAS THE LOYALTY OPT IN FIELD AND SET DEFAULT TO FALSE
    user.loyaltyOptIn = response.data.loyaltyOptIn ?? false;
    // VERIFY IF THE POINTS BALANCE IS EXIST AND SET DEFAULT TO 0
    user.pointsBalance = response.data.pointsBalance ?? 0;
  } else {
    // SET THE USER WITH THE CURRENT USER DATA ON LOCAL STORAGE MEANING WE ARE UPDATING AFTER LOGIN
    user = { ...currentUserData };
    // CREATE ARRAY WITH ALL THE KEYS OF THE RENEW TOKEN
    const responseDataKeys = Object.keys(response.data);
    // LOOP THROUGH THE KEYS OF THE RENEW TOKEN
    responseDataKeys.forEach(key => {
      // IF THE KEY IS IN THE CURRENT USER DATA THEN UPDATE AND IF NOT ADD IT
      user[key] = response.data[key];
    });
    // VERIFY IF THE RESPONSE HAS THE LOYALTY OPT IN FIELD AND SET DEFAULT TO CURRENT USER DATA
    user.loyaltyOptIn = response.data.loyaltyOptIn ?? currentUserData.loyaltyOptIn;
    // VERIFY IF THE POINTS BALANCE EXIST AND SET DEFAULT TO CURRENT USER DATA
    user.pointsBalance = response.data.pointsBalance ?? currentUserData.pointsBalance;
  }
  localStorage.setItem('user', JSON.stringify(user));
  if (postLoginCallback && typeof postLoginCallback === typeof Function) {
    postLoginCallback();
  }

  // Refer a Friend 25/50 test - GA Event
  if (!response.data.referralNotEligible) {
    const rafEventData = getRaFSignUpEventObject();
    pushEvent(rafEventData);
  }
  return user;
};

class AuthClient {
  constructor() {
    this.url = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2');
    this.url_v_2_1 = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.1');
    this.url_v_2_2 = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.2');
    this.url_v_2_3 = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.3');
    this.currentRenew = null;
  }

  /**
   * @function
   * Gets the currently logged in user if the user is logged in
   */
  getUser() {
    const user = localStorage.getItem('user');
    if (!user) return null;
    else return JSON.parse(user);
  }

  /**
   * @function
   * Attempts a login request with the given credentials
   * @param {string} username
   * Email
   * @param {string} password
   * Password
   */
  async login(username, password, url) {
    const actionUrl = `auth/login`;
    const urlRequest = `${this.url_v_2_2}/${actionUrl}`;
    const eventData = getSignInEventObject(GTM_EVENT.ACTION.signInAttempt, GTM_EVENT.LABEL.greatWolf);
    pushEvent(eventData);

    return await axios
      .post(
        urlRequest,
        {
          username: username,
          password: password,
          url: url
        },
        {
          // This header must be like this, to be able to perfom a string POST
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      .then(response => {
        const eventData = getSignInEventObject(GTM_EVENT.ACTION.signInSuccess, GTM_EVENT.LABEL.greatWolf);
        pushEvent(eventData);
        successfulAuthCallback(response);
      })
      .catch(err => {
        const eventData = getSignInEventObject(GTM_EVENT.ACTION.signInFailure, GTM_EVENT.LABEL.greatWolf);
        pushEvent(eventData);
        throw new ApiResponseError(ErrorTypes.ResponseError, err);
      });
  }

  /**
   * @function
   * Authenticates user using the reservation Id and the lastname
   * @param {string} resId
   * Reservation ID
   * @param {string} lastname
   * Lastname
   */
  async authByResrvIdAndLastName(resId, lastname) {
    const actionUrl = `auth/reservation/last-name`;
    const urlRequest = `${this.url_v_2_1}/${actionUrl}`;

    return await axios
      .post(
        urlRequest,
        {},
        {
          // This header must be like this, to be able to perfom a string POST
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          params: {
            ReservationId: resId,
            LastName: lastname
          }
        }
      )
      .then(response => {
        successfulAuthCallback(response);
      })
      .catch(err => {
        throw new ApiResponseError(ErrorTypes.ResponseError, err);
      });
  }

  /**
   * @function
   * Refreshes the access token
   */
  async renewToken() {
    const actionUrl = `auth/renew-token`;
    const urlRequest = `${this.url_v_2_3}/${actionUrl}`;
    const user = this.getUser();
    if (!user) return;

    if (!this.currentRenew) {
      this.currentRenew = axios.post(
        urlRequest,
        {
          userId: user.id,
          refreshToken: user.refreshToken
        },
        {
          // This header must be like this, to be able to perfom a string POST
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );

      this.currentRenew.then(
        response => {
          this.currentRenew = null;
          successfulAuthCallback(response);
        },
        err => {
          this.currentRenew = null;
          throw new ApiResponseError(ErrorTypes.ResponseError, err);
        }
      );
    }

    return this.currentRenew;
  }

  /**
   * @function
   * Logs the user out the website
   */
  async logout() {
    localStorage.removeItem('user');

    const actionUrl = `auth/log-out`;
    const urlRequest = `${this.url_v_2_1}/${actionUrl}`;

    return await axios
      .post(
        urlRequest,
        {},
        {
          // This header must be like this, to be able to perfom a string POST
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      .catch(err => {
        throw new ApiResponseError(ErrorTypes.ResponseError, err);
      });
  }

  /**
   * @function
   * Authenticates with the GW backend to retrieve an internal access token and user data
   * @param {string} provider
   * The external auth provider (Facebook or Google)
   * @param {string} accessToken
   * The external auth provider's access token for the user
   * @param {string} id
   */
  async thirdPartyLogin(
    provider,
    accessToken,
    id,
    postLoginCallback = null,
    appleUser = null,
    isAPI_2_2_Active = false,
    isSignIn = false,
    locationCode = ''
  ) {
    const actionUrl = `auth/provider-signin`;
    let urlRequest = `${this.url_v_2_1}/${actionUrl}`;

    if (isAPI_2_2_Active) {
      urlRequest = `${this.url_v_2_2}/${actionUrl}`;
    }

    let requestObject = {
      provider,
      token: accessToken,
      key: id,
      appleUser,
      isSignIn,
      property: locationCode
    };
    if (sessionStorage.getItem(SessionStorageKeys.REFER_A_FRIEND)) {
      requestObject.LoyaltyReferralCode = getSpecificItem('ReferralCode', SessionStorageKeys.REFER_A_FRIEND);
    }

    try {
      const response = await axios.post(urlRequest, requestObject, {
        // This header must be like this, to be able to perfom a string POST
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      successfulAuthCallback(response, postLoginCallback);
      emitEventsByName(EVENTS_DICTIONARY.GWR_LOGIN);
      return response.data;
    } catch (err) {
      throw new ApiResponseError(ErrorTypes.ResponseError, err);
    }
  }

  /**
   * @function
   * Creates a new user account and logs him/her in
   * @param {object} newUser
   * The new user to create
   */
  async createAccount(newUser, isAPI_2_2_Active = false, isLeadGenSignUp = false) {
    let urlRequest = `${getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.1')}/User`;
    if (isAPI_2_2_Active) {
      urlRequest = `${getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.2')}/User`;
    }

    const contactByPhone = true; // This is true by default on new users
    const eventData = getSignUpEventObject(GTM_EVENT.ACTION.signUpAttempt, GTM_EVENT.LABEL.greatWolf, isLeadGenSignUp);

    pushEvent(eventData);

    // Refer a Friend 25/50 test - GA Event
    const rafEventData = getRaFSignUpEventObject();
    pushEvent(rafEventData);

    return await axios
      .post(
        urlRequest,
        {
          email: newUser.email,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          zip: newUser.zip,
          password: newUser.password,
          property: newUser.property,
          caslOptIn: newUser.caslOptIn || '',
          marketingEmailOptIn: newUser.marketingEmailOptions || '',
          canContactByPhone: contactByPhone,
          canContactByEmail: !!newUser.canContactByEmail,
          canContactByMail: !!newUser.canContactByMail,
          canContactBySms: !!newUser.contactBySms,
          loyaltyReferralCode: newUser.ReferralCode || '',
          refereeId: newUser.MemberId || ''
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      .then(
        response => {
          const eventData = getSignUpEventObject(
            GTM_EVENT.ACTION.signUpSuccess,
            GTM_EVENT.LABEL.greatWolf,
            isLeadGenSignUp
          );
          pushEvent(eventData);
          successfulAuthCallback(response);
          emitEventsByName(EVENTS_DICTIONARY.GWR_LOGIN);
          return response.data;
        },
        err => {
          throw new ApiResponseError(ErrorTypes.ResponseError, err);
        }
      );
  }

  /**
   * @function
   * Creates a new LeadGen user account and logs him/her in
   * @param {object} leadGenUser
   * The new user to create
   */

  async createLeadGenAccount(leadGenUser, isAPI_2_2_Active = false) {
    let urlRequest = `${getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.1')}/User`;
    if (isAPI_2_2_Active) {
      urlRequest = `${getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, 'v2.2')}/User`;
    }
    const actionUrl = '/profile';

    const eventData = getSignUpEventObject(GTM_EVENT.ACTION.signUpAttempt, GTM_EVENT.LABEL.greatWolf);
    pushEvent(eventData);

    // Refer a Friend 25/50 test - GA Event
    const rafEventData = getRaFSignUpEventObject();
    pushEvent(rafEventData);

    return await axios
      .post(
        `${urlRequest}${actionUrl}`,
        {
          profileKey: leadGenUser.profileKey,
          password: leadGenUser.password,
          property: leadGenUser.property,
          profileId: leadGenUser.profileId,
          marketingEmailOptIn: leadGenUser.marketingEmailOptions || '',
          loyaltyReferralCode: leadGenUser.ReferralCode || '',
          refereeId: leadGenUser.MemberId || ''
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      )
      .then(
        response => {
          const eventData = getSignUpEventObject(GTM_EVENT.ACTION.signUpSuccess, GTM_EVENT.LABEL.greatWolf);
          pushEvent(eventData);
          successfulAuthCallback(response);
          emitEventsByName(EVENTS_DICTIONARY.GWR_LOGIN);
          return response.data;
        },
        err => {
          throw new ApiResponseError(ErrorTypes.ResponseError, err);
        }
      );
  }

  /**
   * A user can create a reservation without setting a password, so he ends up
   * with an account he cannot access. The allows him to set a password without
   * needing to run through the email validation step. We accept that profileId
   * plus email is enough to authorize the user.
   *
   * @param {{ email: string, profileId: string, password: string}} user New
   * user payload.
   * @memberof AuthClient
   */
  async ensureWebUser(user) {
    const actionUrl = `auth/ensure-web-user`;
    const urlRequest = `${this.url_v_2_1}/${actionUrl}`;

    return await axios
      .post(urlRequest, user, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then(
        response => {
          successfulAuthCallback(response);
        },
        err => {
          throw new ApiResponseError(ErrorTypes.ResponseError, err);
        }
      );
  }

  /**
   * @function
   * Check if the current user is logged in
   * @returns {boolean}
   */
  isLoggedIn() {
    var user = this.getUser();

    return user !== undefined && user !== null && user.id !== null;
  }

  /**
   * @function
   * Get user ID if logged in
   * @returns {boolean}
   */
  getUserId() {
    var user = this.getUser();

    if (user !== undefined && user !== null && user.id !== null) {
      return user.id;
    } else {
      return null;
    }
  }

  /**
   * @function
   * Check if there is an auth token present
   *   Note: JS is forced to use presence of 'user.accessTokenExpiry' field as a telltale sign that an auth token is
   *         present, since actual token is stored in a secured cookie and we can't access it via JS)
   * @returns {boolean}
   */
  isTokenPresent() {
    var user = this.getUser();

    return user !== undefined && user !== null && user.accessTokenExpiry !== null;
  }
}

export default AuthClient;
