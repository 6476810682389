export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Try again in a few minutes.';

/**
 * Gives the formatted error message.
 * @param {String} message - Actual error message
 * @return {String} Formatted error message.
 */
export const getErrorMessage = (message = null, usePrefix = true) => {
  if (!usePrefix) {
    return message || DEFAULT_ERROR_MESSAGE;
  }

  return `Oops, ${message ? message : DEFAULT_ERROR_MESSAGE}`;
};

export const getPassMessage = (passQty, passTitle) => {
  return `You added <b>${passQty}&nbsp;${passTitle}</b>&nbsp;to your reservation!`;
};
