import styled, { css } from 'styled-components';
import { Accordion } from 'react-accessible-accordion';

const BAR_WIDTH = '10px';

export const AccordionStyle = styled(Accordion)`
  cursor: pointer;
`;

export const ArrowIcon = styled.div`
  height: 20px;
  width: 20px;
  display: inline-block;
  padding: 0.5em;
  margin-left: 0.5em;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  & > span {
    top: ${BAR_WIDTH};
    position: absolute;
    background-color: transparent;
    width: ${BAR_WIDTH};
    height: 5px;
    display: block;
    float: right;
    border-radius: 2px;
    &:after {
      width: ${BAR_WIDTH};
      content: '';
      background-color: #28384c;
      height: 1px;
      display: block;
      float: right;
      transition: all 0.7s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    }
  }

  ${({ isExpanded }) => {
    if (isExpanded) {
      return css`
        & > span:nth-child(1) {
          &:after {
            transform-origin: center center;
            transform: rotate(-85deg);
          }
        }
        & > span:nth-child(2) {
          &:after {
            transform-origin: center center;
            transform: rotate(85deg);
          }
        }
      `;
    }
  }}
`;

export const Leftbar = styled.span`
  left: 0;
  transform: rotate(45deg);
  &:after {
    border-radius: 6px 10px 10px 6px;
  }
`;

export const RightBar = styled.span`
  left: ${BAR_WIDTH};
  transform: rotate(-45deg);
  &:after {
    border-radius: 10px 6px 6px 10px;
    z-index: -1;
  }
`;
