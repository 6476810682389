import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, SeverityLevel } from '@microsoft/applicationinsights-web';
import get from 'lodash-es/get';
import { store } from '../store/configureStore';
import { maskPIIProperties } from './personallyIdentifiableInformation/piiUtils';

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    distributedTracingMode: DistributedTracingModes.W3C,
    enableCorsCorrelation: true,
    correlationHeaderDomains: ['*.greatwolf.com'],
    disableAjaxTracking: true,
    disableFetchTracking: true
  }
});

ai.addTelemetryInitializer(function(envelope) {
  envelope = maskPIIProperties(envelope);
});

ai.loadAppInsights();

const appInsights = ai.appInsights;

export const trackInteractionFailureEvent = (errorMessage, eventName) => {
  trackError(eventName, errorMessage);
};

/**
 * @param {String} eventName
 * @param {String} errorMessage
 * @param {Object} errorObject
 */
export const trackReservationFailureEvent = (eventName, errorMessage, errorObject) => {
  // We stringify so we force the Error object to materialize all of it's properties
  // Then we need to parse it to a JSON object so maskPIIProperties knows how to traverse and mask it's PII properties
  const stringfiedErrorObject = JSON.stringify(errorObject);
  errorObject = maskPIIProperties(JSON.parse(stringfiedErrorObject));
  trackError(eventName, `${errorMessage} Error: ${JSON.stringify(errorObject)}`);
};

/**
 * Track error on availability, highest rates and detailed availability
 * @param {Object} state Redux state object
 * @param {String} errorTitle Error title shown on availability error
 */
export const availabilityTrackError = (state, errorType, errorTitle, isDetailedAvailabilty = false) => {
  const errorMessage = !isDetailedAvailabilty
    ? get(state, 'availabilityErrorMessage', '')
    : get(state, 'onSuiteSelectionError', '');
  trackPlanPageFailureEvent(errorType, errorTitle, errorMessage, isDetailedAvailabilty);
};

export const trackPlanPageFailureEvent = (errorType, errorTitle, errorMessage, isDetailedAvailabilty) => {
  // fail silently
  try {
    const storage = store.getState();

    // Gather information from user's selection
    let userSelection = {
      arrival: get(storage, 'dates.checkinDateSelection', ''),
      departure: get(storage, 'dates.checkoutDateSelection', ''),
      adultCount: get(storage, 'guests.adultsCount', ''),
      kidsAges: get(storage, 'guests.kidsAges', ''),
      offerCode: get(storage, 'offer.code', ''),
      property: get(storage, 'currentLodge.resortLocation', '')
    };

    if (isDetailedAvailabilty) {
      userSelection.suiteCode = get(storage, 'suite.selectedSuite.suiteCode', '');
    }

    const userChoice = JSON.stringify(userSelection);
    const errorLogInfo = `Error on plan page, ${errorTitle}. ${errorMessage};`;

    appInsights.trackException({
      exception: new Error(errorLogInfo),
      severityLevel: SeverityLevel.Error,
      properties: {
        component: 'BookingEngine',
        method: errorType,
        message: userChoice
      }
    });
  } catch (error) {}
};

export const trackError = (eventName, errorMessage) => {
  try {
    appInsights.trackEvent({
      name: eventName,
      properties: { error: errorMessage }
    });
  } catch (error) {}
};

export const trackVariationJEventError = (eventName, trackInformation) => {
  try {
    appInsights.trackEvent({
      name: eventName,
      properties: {
        lodge: trackInformation.lodge,
        dates: trackInformation.dates,
        promoCode: trackInformation.promoCode,
        partySize: trackInformation.partySize,
        californiaResidentFlag: trackInformation.californiaResidentFlag,
        rateCodes: trackInformation.rateCodes,
        suiteStyle: trackInformation.suiteStyle
      }
    });
  } catch (error) {}
};

export const trackVariationHEventError = (eventName, trackInformation) => {
  try {
    appInsights.trackEvent({
      name: eventName,
      properties: {
        lodge: trackInformation.lodge,
        dates: trackInformation.dates,
        promoCode: trackInformation.promoCode,
        partySize: trackInformation.partySize,
        suiteStyle: trackInformation.suiteStyle,
        savings: trackInformation.savings
      }
    });
  } catch (error) {}
};

export const EVENT_CATEGORIES = {
  bookingEngineBookReservationError: 'OnBookReservationError',
  OnVersionJEventError: 'OnVersionJEventError',
  OnVersionHEventError: 'OnVersionHEventError'
};

const withAppInsights = Component => withAITracking(reactPlugin, Component);

export default withAppInsights;
