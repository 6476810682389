import Text from './Text';
import theme from './themes/theme';

const Heading = Text.withComponent('h3');

Heading.defaultProps = {
  fontWeight: 'bold',
  fontSize: 4,
  theme: theme,
  fontFamily: theme.fontPrimary
};

Heading.displayName = 'Heading';

Heading.H1 = Heading.withComponent('h1');
Heading.H1.defaultProps = {
  fontSize: 6,
  fontWeight: 'bold',
  theme: theme,
  fontFamily: theme.fontPrimary
};

Heading.H2 = Heading.withComponent('h2');
Heading.H2.defaultProps = {
  fontSize: 5,
  fontWeight: 'bold',
  theme: theme,
  fontFamily: theme.fontPrimary
};

Heading.H3 = Heading.withComponent('h3');
Heading.H3.defaultProps = {
  fontSize: 8,
  fontWeight: 'bold',
  theme: theme,
  fontFamily: theme.fontMontBold
};

Heading.H4 = Heading.withComponent('h4');
Heading.H4.defaultProps = {
  fontSize: 1,
  theme: theme,
  fontFamily: theme.fontMontBlack
};

Heading.H5 = Heading.withComponent('h5');
Heading.H5.defaultProps = {
  fontSize: 7,
  fontWeight: 'bold',
  theme: theme,
  fontFamily: theme.fontOswaldBold
};

Heading.H6 = Heading.withComponent('h6');
Heading.H6.defaultProps = {
  fontSize: 0,
  fontWeight: 'bold',
  theme: theme,
  fontFamily: theme.fontPrimary
};

export default Heading;
