export const FLEX_TRIP_PACKAGE_CODE = 'FLXTRPCXL';
export const HOWL_N_LEARN_CODE = 'HOWLEARN';

export const PHONE_TEXT_AGREEMENT_NIAGARA = `Yes! I provide my signature authorizing Great Wolf to call
                    and text me at the mobile number I provided using automated
                    dialing technology to let me know about products, services,
                    promotions, and/or events that may interest me. This consent
                    is not required as a condition of purchasing any goods or
                    services, and each message will include an option to opt-out
                    of receiving future messages.`;
export const PHONE_TEXT_AGREEMENT = `Yes! By submitting this form, I  authorize Great Wolf to call and text me at the mobile number I provided using automated dialing technology to let me know about products, services, promotions, and/or events that may interest me. This consent is not required as a condition of purchasing any goods or services, and each message will include an option to opt-out of receiving future messages.`;
