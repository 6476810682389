// Reservation Details action types

const reservationDetailsActionTypes = {
  FETCH_RESERVATION_DETAILS_REQUEST: 'FETCH_RESERVATION_DETAILS_REQUEST',
  FETCH_RESERVATION_DETAILS_SUCCESS: 'FETCH_RESERVATION_DETAILS_SUCCESS',
  FETCH_RESERVATION_DETAILS_FAILURE: 'FETCH_RESERVATION_DETAILS_FAILURE',
  FETCH_RESERVATION_DETAILS_CLEAR: 'FETCH_RESERVATION_DETAILS_CLEAR',
  UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS: 'UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS',
  GTM_POST_RESERVATION_DETAILS_SALE_PACKAGE_ADDED: 'GTM_POST_RESERVATION_DETAILS_SALE_PACKAGE_ADDED',
  RESERVATION_DETAILS_CABANA_IS_LOADING: 'RESERVATION_DETAILS_CABANA_IS_LOADING',
  RESERVATION_DETAILS_CABANA_SUCCESS: 'RESERVATION_DETAILS_CABANA_SUCCESS',
  RESERVATION_DETAILS_CABANA_FAILURE: 'RESERVATION_DETAILS_CABANA_FAILURE',
  CLEAR_RESERVATION_DETAILS_CABANA_DETAILS: 'CLEAR_RESERVATION_DETAILS_CABANA_DETAILS',
  CLEAR_RESERVATION_DETAILS_DETAILS_ERROR: 'CLEAR_RESERVATION_DETAILS_DETAILS_ERROR',
  PACKAGE_RESERVATION_DETAILS_MESSAGE_ERROR: 'PACKAGE_RESERVATION_DETAILS_MESSAGE_ERROR',
  CLEAR_RESERVATION_DETAILS_PACKAGE_MESSAGE_ERROR: 'CLEAR_RESERVATION_DETAILS_PACKAGE_MESSAGE_ERROR',
  UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_SUCCESS:
    'UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_SUCCESS',
  UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_CLEAR: 'UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_CLEAR',
  UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR: 'UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR',
  UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR_CLEAR:
    'UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR_CLEAR'
};

export default reservationDetailsActionTypes;
