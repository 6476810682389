import { isDevEnvironment } from '../utilities/env';

const promoCodeListJson = [
  { promoCode: 'EMDDIN', isExclusive: 'true' },
  { promoCode: 'YEAREND', isExclusive: 'true' },
  { promoCode: 'HOWL', isExclusive: 'false' },
  { promoCode: 'SNOWLAND', isExclusive: 'false' },
  { promoCode: 'MOREFUN', isExclusive: 'false' },
  { promoCode: 'ESAVE', isExclusive: 'true' },
  { promoCode: 'ESAVER', isExclusive: 'true' },
  { promoCode: 'HEROES', isExclusive: 'false' },
  { promoCode: 'TRIPLEFUN', isExclusive: 'false' },
  { promoCode: 'FINAL', isExclusive: 'true' },
  { promoCode: 'COUNTDOWN', isExclusive: 'true' },
  { promoCode: 'SUMMERFN', isExclusive: 'true' },
  { promoCode: 'SCHOOL', isExclusive: 'true' }
];

if (isDevEnvironment) {
  window.promoCodeList = JSON.stringify(promoCodeListJson);
}
