import colors from '../ui-kit/themes/colors';

// ineligibilityReason
export const INELIGIBILITY_REASONS = {
  TOO_EARLY: 'TOO_EARLY', // The reservation isnt due the next 24h.
  PRE_CHECKED_IN: 'PRE_CHECKED_IN', // User has already done online check-in.
  OTHER: 'OTHER' // Nor1 Api hasn't synced..
};

export const CHECK_IN_MESSAGES = {
  available: {
    description: 'Mobile pre check-in is available now!',
    color: colors.nightSkyBlack[80]
  },
  unavailable: {
    description: 'Mobile pre check-in will be available 48 hours prior to your reservation.',
    color: colors.nightSkyBlack[80]
  },
  successful: {
    title: 'Mobile pre check-in successful!',
    description: 'Check your email to see more information',
    color: colors.deepForestGreen[100]
  },
  invalid: {
    title: 'Your reservation is not valid for mobile pre check-in',
    description: 'Please check-in at the lodge when you arrive',
    color: colors.errorOrange
  },
  mobile: {
    description: 'Mobile pre check-in will be available 48 hours before you arrive via email and SMS',
    color: colors.nightSkyBlack[80]
  }
};

export const ICON_STYLES = {
  size: '21px',
  color: colors.preciousStoneBlue[100]
};
