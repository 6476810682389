import React from 'react'

const CheckIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='-2.5 -6 21 21'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M6.2,7.95 L1.75,3.25 C1.27993197,3.05340136 0.946598639,3.05340136 0.75,3.25 C0.553401361,3.44659864 0.553401361,3.77993197 0.75,4.25 L6.2,10.05 L14.75,1.25 C14.8864098,0.719743131 14.8864098,0.386409798 14.75,0.25 C14.6135902,0.113590202 14.2802569,0.113590202 13.75,0.25 L6.2,7.95 Z' />
  </svg>
)

CheckIcon.displayName = 'CheckIcon'

CheckIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default CheckIcon