/**
 * Pushes current value into the accumulator and returns the accumulator to be
 * used in a reduce function.
 *
 * @param {Array} accumulator Array of items
 * @param {number} currentValue Array key
 * @returns {Array} Accumulator with new item
 */
function transformationFn(accumulator, currentValue) {
  accumulator.push(currentValue);
  return accumulator;
}

/**
 * Generates an array based on the length and transformation passed, if no
 * transformastion is passed to the array it will return an array with the
 * array length keys as items.
 *
 * @export
 * @param {number} [arrayLength=0]
 * @param {function} [transformation=transformationFn]
 * @returns {undefined|Array} Undefined if called without arguments otherwise,
 *    returns an array with the umber of items provided.
 */
function generateArray(arrayLength = 0, transformation = transformationFn) {
  if (!arrayLength) return [];
  return [...Array(arrayLength).keys()].reduce(transformation, []);
}

export default generateArray;
