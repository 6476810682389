import styled from 'styled-components';
import { buttonStyle, themeGet } from 'styled-system';
import { Text } from '..';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding-right: 16px;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 50px;
  border-radius: ${themeGet('radius')};
  background-color: ${themeGet('colors.kiddiePoolBlue.100')};

  svg {
    background: ${themeGet('colors.snowMountainGrey.100')};
    border-radius: ${themeGet('radius')};
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const EmailText = styled(Text)`
  color: ${themeGet('colos.deepLakeBlue.100')};
  font-weight: ${themeGet('fontWeights.medium')};
  width: 100%;
  letter-spacing: 0.25px;
`;

export const StyledConnectionButton = styled.button`
  ${buttonStyle}
`;
