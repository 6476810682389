import styled, { css } from 'styled-components';
import { fontSize } from 'styled-system';
import Box from '../ui-kit/Box';
import theme, { mediaQueries } from '../ui-kit/themes/theme';

export const AffirmContainer = styled(Box)`
  ${theme.mediaScreens.phone`
    margin-right: auto;
    border-radius: 25px;
    margin-left: auto;
    display: inline-block;
  `}
  ${theme.mediaScreens.tablet`
    margin-right: ${props => (props.rightAlign ? '0' : 'auto')};
  `}
`;

export const StyledFinancingOptionText = styled.p`
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  color: ${props => props.color || theme.colors.deepLakeBlue[100]};
  ${fontSize}
  font-weight: ${props => props.fontWeight || theme.fontWeights.regular};
  text-decoration: ${props => (props.textDecoration && props.textDecoration.sm) || props.textDecoration || 'none'};
  cursor: pointer;
  text-decoration-color: ${props => props.color || theme.colors.deepLakeBlue[100]};
  border-bottom: 1px solid transparent;
  
  .affirm-ala-price {
    padding-left: 4px;
   }
   
  .affirm-promo-underline {
    margin-right: 5px;
    text-decoration: none;
    cursor: pointer;
  }

  .__affirm-logo {
    margin-left: 7px;
  }

  .affirm-modal-trigger {
    display: none;
  }

  &:hover {
    ${theme.mediaScreens.tablet`
    border-bottom: 1px solid ${props => props.color || theme.colors.deepLakeBlue[100]};
  `}
  }
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
  ${mediaQueries.lg} {
    text-decoration: ${props => (props.textDecoration && props.textDecoration.lg) || props.textDecoration || 'none'};
  }
`;
