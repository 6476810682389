import { useCallback } from 'react';
import { waitFor } from '../../utilities/waitFor';

/**
 * This is a simple hook that returns 2 methods, one for simple scroll using scrollIntoView and
 * a custmon scroll based on the top.
 *
 * @param {options}
 * ref, behavior, block, inline, delay, custom.yOffset
 * @returns { simpleScrollTo, customScrollTo }
 *
 * Horizontal scroll is pending for more custom options
 */
const useScrollToItem = () => {
  const simpleScrollTo = useCallback(async options => {
    const { ref, behavior = 'auto', block = 'start', inline, delay } = options;
    if (delay) await waitFor(delay);

    ref.current.scrollIntoView({
      behavior,
      block,
      ...(inline && { inline })
    });
  }, []);

  const customScrollTo = useCallback(async options => {
    const { ref, behavior = 'auto', custom, delay } = options;
    const yOffset = custom.yOffset;
    const element = ref.current;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if (delay) await waitFor(delay);
    window.scrollTo({ top: y, behavior });
  }, []);

  return { simpleScrollTo, customScrollTo };
};

export default useScrollToItem;
