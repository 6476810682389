import React from 'react'

const ArrowCaretLeftIcon = ({
  size,
  color,
  ...props
}) => (
  <svg
    {...props}
    viewBox='0 0 7 12'
    width={size}
    height={size}
    fill={color}
  >
    <path d='M6.449.002a.497.497 0 0 0-.29.132L.151 5.654a.463.463 0 0 0-.151.34c0 .129.055.252.151.34l6.009 5.52a.498.498 0 0 0 .706 0 .463.463 0 0 0 .133-.35l-.005-.048a.465.465 0 0 0-.168-.286L1.193 5.996 6.83.816a.458.458 0 0 0 .113-.54.491.491 0 0 0-.494-.274z' />
  </svg>
)

ArrowCaretLeftIcon.displayName = 'ArrowCaretLeftIcon'

ArrowCaretLeftIcon.defaultProps = {
  size: 24,
  color: 'currentcolor'
}

export default ArrowCaretLeftIcon