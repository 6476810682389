import { PACKAGE_CHARGE_TYPE } from '../../../../../utilities/constants';
import { DINING_CREDIT, DINING_CREDIT_COINS } from '../../../../DiningCredit/assets/images';

// Get the correct image to display from the packages
export function getPkgImage(pkg) {
  // check if the package is dining credit and return image
  if (
    pkg.packageName &&
    pkg.packageType?.includes('dining') &&
    pkg.packageName.toLowerCase().includes('dining credit')
  ) {
    if (pkg.allowance && DINING_CREDIT_COINS.includes(pkg.allowance)) {
      return DINING_CREDIT[pkg.allowance];
    }
  }

  // check if the package has a packageIcon
  if (pkg.packageIcon) return pkg.packageIcon;
  // check if image is a string
  if (typeof pkg.image === 'string') return pkg.image;
  // check if image is an object and return the url
  if (pkg.image && pkg.image.url) return pkg.image.url;
}

// check if the package is a json
export function getPkgImageValidation(pkg) {
  if (typeof pkg.image === 'string' && pkg.image.includes('.json')) return true;

  return false;
}

// validate if is dining credit
export function isDiningCredit(pkg) {
  return (
    pkg.packageName && pkg.packageType?.includes('dining') && pkg.packageName.toLowerCase().includes('dining credit')
  );
}

// show counter if package has a queryByTestId, is not bundle or dining and is per package charge
export function showCounter(packageItem) {
  return (
    packageItem.quantity &&
    packageItem.chargeType === PACKAGE_CHARGE_TYPE.byPackage &&
    !packageItem.packageType?.includes('bundle') &&
    !packageItem.packageType?.includes('dining')
  );
}
