import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { Accordion, AccordionItemButton } from 'react-accessible-accordion';
import { Flex } from '../index';
import { Link } from 'react-router-dom';
import { Text } from '../../ui-kit';

const itemStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: ${themeGet('space.8')}px ${themeGet('space.2')}px;
  font-size: ${themeGet('fontSizes.1')}px;
  font-weight: ${themeGet('fontWeights.medium')};
  margin-bottom: ${themeGet('space.3')}px;
  border-radius: 0 100px 100px 0;
  transition: background-color 0.2s;

  :after {
    width: 0;
  }
`;

const accordionItemStyles = css`
  border-radius: 0;
  margin-bottom: ${themeGet('space.2')}px;
`;

const accordionNotActive = css`
  background-color: ${themeGet('colors.snowMountainGrey.50')};
  color: ${themeGet('colors.deepLakeBlue.50')};

  :before {
    background-color: ${themeGet('colors.deepLakeBlue.100')};
  }
`;

const beforeDecorationStyles = css`
  :before {
    content: '';
    display: inline-block;
    width: ${themeGet('space.1')}px;
    min-width: ${themeGet('space.1')}px;
    height: 12px;
    margin-right: ${themeGet('space.2')}px;
    border-radius: 2px;
  }
`;

const activeItem = css`
  background-color: ${themeGet('colors.deepLakeBlue.100')};
  color: ${themeGet('colors.snowMountainGrey.50')};

  :before {
    background-color: ${themeGet('colors.snowMountainGrey.50')};
  }
`;

const notActiveItem = css`
  color: ${themeGet('colors.deepLakeBlue.50')};

  :before {
    background-color: ${themeGet('colors.deepLakeBlue.20')};
  }

  :hover {
    background-color: ${themeGet('colors.snowMountainGrey.50')};
  }
`;

export const NavigationMenuWrapper = styled(Flex)`
  flex-direction: column;

  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const NavigationItemWrapper = styled(({ isActive, isAccordion, ...rest }) => <Link {...rest} />)`
  ${itemStyles}
  ${beforeDecorationStyles}
  ${({ isActive }) => (isActive ? activeItem : notActiveItem)}
  ${({ isAccordion }) => isAccordion && accordionItemStyles}
  ${({ isActive, isAccordion }) => isActive && isAccordion && accordionNotActive}
`;

export const StyledAccordionItemButton = styled(AccordionItemButton)`
  ${itemStyles}
  ${activeItem}
  ${accordionItemStyles}
  ${beforeDecorationStyles}
  position: relative;

  &[aria-expanded=true]::after {
    transform: rotate(-135deg);
    margin-bottom: -4px;
  }

  :after {
    display: inline-block;
    position: absolute;
    right: 20px;
    content: '';
    height: 10px;
    width: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.2s, margin 0.2s;
  }
`;

export const StyledAccordion = styled(({ optionsNumber, ...rest }) => <Accordion {...rest} />)`
  .accordion__panel {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    ${({ optionsNumber }) => `max-height: ${optionsNumber * 60}px`}

    &[hidden] {
      display: block;
      max-height: 0;
    }
  }
`;

export const SelectedOption = styled(Text)`
  font-size: ${themeGet('fontSizes.7')}px;
  font-weight: ${themeGet('fontWeights.bold')};

  @media screen and (max-width: 32em) {
    font-size: ${themeGet('fontSizes.1')}px;
    color: ${themeGet('colors.pureWhite.0')};
  }
`;
