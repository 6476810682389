import { LocalStorageKeys } from '../utilities/storageUtils';
import { RECOMMENDED_FOR_YOU, IS_CLASSES } from './constants';

export const getInteractionsUserId = () => {
  const interactionsUserId = localStorage.getItem(LocalStorageKeys.InteractionsUserId);

  return interactionsUserId ? interactionsUserId : null;
};

export const renderInteractionsAtributes = (suiteFlags, interactionsData) => {
  let attributes = {};

  const isRecommended = suiteFlags?.includes(RECOMMENDED_FOR_YOU);

  if (isRecommended && interactionsData) {
    attributes['data-sfis-recommended'] = true;
    attributes['data-is-campaignId'] = interactionsData.campaignId;
    attributes['data-is-experienceId'] = interactionsData.experienceId;
    attributes['data-is-userGroup'] = interactionsData.userGroup;
  }
  attributes['className'] = IS_CLASSES.suiteContainer;
  return attributes;
};
